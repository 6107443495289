import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type DialogState = {
  isOpen: boolean;
  title: string;
  message: string;
  isError: boolean;
};

const initialState: DialogState = {
  isOpen: false,
  title: "",
  message: "",
  isError: false,
};

const stateReactiveVar = createReactiveVar<DialogState>(initialState);

// ダイアログの開閉状態を管理
const useNlj002Dialog = (): ReactiveVarHooks<DialogState> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNlj002Dialog = () => {
  stateReactiveVar({ ...stateReactiveVar(), isOpen: true });
};

export const closeNlj002Dialog = () => {
  stateReactiveVar({ ...stateReactiveVar(), isOpen: false });
};

export const setMessage = (title: string, message: string) => {
  stateReactiveVar({ ...stateReactiveVar(), title, message });
};

export const setIsError = (isError: boolean) => {
  stateReactiveVar({ ...stateReactiveVar(), isError });
};

export default useNlj002Dialog;
