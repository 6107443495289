import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { getMongoDb, getNewPatientMessageCount } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { Collection } from "../../../constants/common";
import useUsj002Dialog from "../../pages/USJ002/useUsj002Dialog";

const useHeaderActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [newNotificationCount, setNewNotificationCount] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);

  // メッセージ詳細を監視
  const [isMessageDetailDialog] = useUsj002Dialog();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbNotifications = getMongoDb(
          currentUser,
          Collection.PATIENT_MESSAGE_RELATIONSHIPS,
        );

        const aggregateNotifications = getNewPatientMessageCount(
          currentUser?.id ?? "",
        );

        // 新着の個数
        const fetchNewNotificationResult =
          (await mongoDbNotifications.aggregate(
            aggregateNotifications,
          )) as mongoDbCountResult[];
        const fetchNewNotificationCount =
          fetchNewNotificationResult.length > 0
            ? fetchNewNotificationResult[0].count
            : 0;

        setNewNotificationCount(fetchNewNotificationCount);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, isMessageDetailDialog]);

  return {
    newNotificationCount,
    error,
  };
};

export default useHeaderActions;
