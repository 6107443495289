import SpButton from "../components/Button/SpButton";
import Icon from "../components/Icon/Icon";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { setPath } from "../hooks/common/usePath";

const WithdrawnCard = () => {
  const navigate = useNavigateWithUrl();

  // 再登録する
  const onReRegisterButtonClicked = () => {
    setPath(PATIENT.USB001);
    navigate(PATIENT.USA004);
  };

  return (
    <Sheet type="card" padding="24px 16px" className="util-sp-pt-16">
      <LayoutBox gap="3x" direction="column" align="center" fullWidth>
        <LayoutBox direction="column" gap="2x" align="center">
          <Icon color="#D6A000" icon="warning" size="xl" />
          <Text
            bold
            size="2xl"
            color="neutralUserBlack"
            lineHeight="150%"
            textAlign="center"
          >
            退会済みです。
          </Text>
          <Text lineHeight="150%" textAlign="center" size="xl">
            サービスをご利用いただく場合は、 再度会員登録を行なってください。
          </Text>
        </LayoutBox>
        <SpButton
          type="primary"
          width="311px"
          withArrowRight
          size="medium"
          onClick={onReRegisterButtonClicked}
        >
          会員再登録
        </SpButton>
      </LayoutBox>
    </Sheet>
  );
};
export default WithdrawnCard;
