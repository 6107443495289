import React from "react";
import LayoutBox from "../LayoutBox/LayoutBox";
import Text from "../Text/Text";
import Divider from "../Divider/Divider";
import Sheet from "../Sheet/Sheet";
import SpButton from "../Button/SpButton";
import CardCartItem from "./CardCartItem";
import { mobileView } from "../../constants/common";

export type CardCartProps = {
  items: {
    // changeEmp
    id?: string;
    tag?: string;
    name?: string;
    description?: string;
    price?: number;
    tax?: number;
    image?: string;
    // changeEmp
    quantity?: number;
    // changeEmp
    stock?: number;
  }[];
  deliveryCharge?: number;
  gap?: "none" | "1/2x" | "1x" | "2x" | "3x" | "4x" | "5x" | "10px";
  padding?: string;
  withButton?: boolean;
  type?: "cart" | "view" | "cancel";
  mobileType?: "cart" | "view" | "cancel";
  withBox?: boolean;
  // changeEmp
  onClickConfirm?: () => void;
  // changeEmp
  onClickItem?: (id: string) => void;
  // changeEmp
  onClickDelete?: (id: string) => void;
  // changeEmp
  onChangeQuantity?: (id: string, quantity: number) => void;
  // changeEmp
  total?: number;
  // changeEmp
  subtotal?: number;
  // changeEmp
  tax?: number;
};

const CardCart = ({
  items,
  deliveryCharge = 0,
  gap,
  padding = "8px",
  withButton = false,
  type = "cart",
  mobileType = "cart",
  withBox = true,
  onClickConfirm,
  onClickItem,
  onClickDelete,
  onChangeQuantity,
  total,
  subtotal,
  tax,
}: CardCartProps) => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileView);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const _subtotal =
    subtotal ?? items.reduce((total, item) => total + (item.price || 0), 0);
  const totalTax = items.reduce((total, item) => total + (item.tax || 0), 0);
  const _total = total ?? _subtotal + deliveryCharge;

  return (
    <>
      <LayoutBox
        gap={gap}
        direction="column"
        fullWidth
        justify="center"
        align="center"
      >
        {withBox && (
          <Sheet
            padding="24px 16px"
            color="primary-lightest"
            borderRadius="8px"
          >
            <LayoutBox gap="2x" direction="column" fullWidth>
              <LayoutBox gap="10px" fullWidth>
                <Text width="134.5px" size="xl">
                  合計
                </Text>
                <Text width="100%" textAlign="right" size="xl" bold>
                  {_total.toLocaleString()}円
                </Text>
              </LayoutBox>
              <Divider primaryMain orange={false} margin="0" />
              <LayoutBox gap="10px" fullWidth>
                <Text width="100%" size="large">
                  商品の小計
                </Text>
                <Text width="100%" textAlign="right" size="large">
                  {(_subtotal - totalTax).toLocaleString()}円
                </Text>
              </LayoutBox>
              <LayoutBox gap="10px" fullWidth>
                <Text width="100%" size="large">
                  配送料
                </Text>
                <Text width="100%" textAlign="right" size="large">
                  {/** //changeEmp */}
                  {deliveryCharge.toLocaleString()}円
                </Text>
              </LayoutBox>
              <LayoutBox gap="10px" fullWidth>
                <Text width="100%" size="large">
                  内税
                </Text>
                <Text width="100%" textAlign="right" size="large">
                  {tax?.toLocaleString()}円
                </Text>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        )}

        <CardCartItem
          items={items}
          type={isMobile ? mobileType : type}
          padding={padding}
          onClick={onClickItem}
          onClickDelete={onClickDelete}
          onChangeQuantity={onChangeQuantity}
        />
        {withButton && (
          <div className="card-cart__button">
            <SpButton
              width="344px"
              size="medium"
              // changeEmp
              onClick={onClickConfirm}
              withArrowRight
            >
              注文を確定する
            </SpButton>
          </div>
        )}
      </LayoutBox>
    </>
  );
};

CardCart.defaultProps = {
  deliveryCharge: 0,
  gap: "none",
  padding: "8px",
  withButton: false,
  type: "cart",
  mobileType: "cart",
  withBox: true,
  // changeEmp
  onClickConfirm: undefined,
  onClickDelete: undefined,
  onChangeQuantity: undefined,
  total: undefined,
  subtotal: undefined,
  tax: undefined,
  onClickItem: undefined,
};

export default CardCart;
