import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  nameSei: ValidationResultType<string>;
  nameMei: ValidationResultType<string>;
  nameSeiKana: ValidationResultType<string>;
  nameMeiKana: ValidationResultType<string>;
  tel: ValidationResultType<string>;
  gender: ValidationResultType<string>;
  birthYear: ValidationResultType<string>;
  birthMonth: ValidationResultType<string>;
  birthDay: ValidationResultType<string>;
  // selectedNotice: ValidationResultType<string>;
};

export type UserInvitationInputType = DefaultValidationType;

export const INIT_STATE: UserInvitationInputType = {
  nameSei: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  nameMei: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  nameSeiKana: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  nameMeiKana: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  tel: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  gender: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  birthYear: {
    value: "1960",
    ...INIT_VALIDATION_RESULT,
  },
  birthMonth: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  birthDay: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  // selectedNotice: {
  //   value: "",
  //   ...INIT_VALIDATION_RESULT,
  // },
};

// 初期値
const localStateStr = sessionStorage.getItem("userInvitationInput");
const initialState = localStateStr
  ? (JSON.parse(localStateStr) as UserInvitationInputType)
  : INIT_STATE;

// 入力状態を管理
const stateReactiveVar =
  createReactiveVar<UserInvitationInputType>(initialState);
const useUserInvitationInput = (): ReactiveVarHooks<UserInvitationInputType> =>
  useReactiveVarHooks(stateReactiveVar);

// 値をセット セッションストレージにも保存することでリロードに対応
const setState = (value: UserInvitationInputType) => {
  sessionStorage.setItem("userInvitationInput", JSON.stringify(value));
  stateReactiveVar(value);
};

// 値を削除して初期化
export const resetUserInvitationInput = () => {
  sessionStorage.removeItem("userInvitationInput");
  stateReactiveVar(INIT_STATE);
};

// バリデーションチェックを行いながら値をセット
const _setUserEditValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setNameSei = (
  value: DefaultValidationType["nameSei"]["value"],
) => {
  _setUserEditValidation("nameSei", value, VALIDATION_TYPE.USER_NAME_REQUIRED);
};
export const setNameMei = (
  value: DefaultValidationType["nameMei"]["value"],
) => {
  _setUserEditValidation("nameMei", value, VALIDATION_TYPE.USER_NAME_REQUIRED);
};
export const setNameSeiKana = (
  value: DefaultValidationType["nameSeiKana"]["value"],
) => {
  _setUserEditValidation(
    "nameSeiKana",
    value,
    VALIDATION_TYPE.USER_NAME_REQUIRED,
  );
};
export const setNameMeiKana = (
  value: DefaultValidationType["nameMeiKana"]["value"],
) => {
  _setUserEditValidation(
    "nameMeiKana",
    value,
    VALIDATION_TYPE.USER_NAME_REQUIRED,
  );
};
export const setTel = (value: DefaultValidationType["tel"]["value"]) => {
  _setUserEditValidation("tel", value, VALIDATION_TYPE.TEL_REQUIRED);
};
export const setGender = (value: DefaultValidationType["gender"]["value"]) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    gender: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};
export const setBirthYear = (
  value: DefaultValidationType["birthYear"]["value"],
) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    birthYear: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};
export const setBirthMonth = (
  value: DefaultValidationType["birthMonth"]["value"],
) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    birthMonth: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};
export const setBirthDay = (
  value: DefaultValidationType["birthDay"]["value"],
) => {
  const currentInput = stateReactiveVar();
  setState({
    ...currentInput,
    birthDay: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};

// 一旦非表示
// export const setSelectedNotice = (
//   value: DefaultValidationType["selectedNotice"]["value"],
// ) => {
//   const currentInput = stateReactiveVar();
//   setState({
//     ...currentInput,
//     selectedNotice: {
//       value,
//       isError: false,
//       validationMessage: "",
//     },
//   });
// };

// 値をすべて更新
export const setUserInvitationInput = ({
  nameSei,
  nameMei,
  nameSeiKana,
  nameMeiKana,
  tel,
  birthYear,
  birthMonth,
  birthDay,
  gender,
}: // selectedNotice,
{
  nameSei: DefaultValidationType["nameSei"]["value"];
  nameMei: DefaultValidationType["nameMei"]["value"];
  nameSeiKana: DefaultValidationType["nameSeiKana"]["value"];
  nameMeiKana: DefaultValidationType["nameMeiKana"]["value"];
  gender: DefaultValidationType["gender"]["value"];
  birthYear: DefaultValidationType["birthYear"]["value"];
  birthMonth: DefaultValidationType["birthMonth"]["value"];
  birthDay: DefaultValidationType["birthDay"]["value"];
  tel: DefaultValidationType["tel"]["value"];
  // selectedNotice: DefaultValidationType["selectedNotice"]["value"];
}) => {
  setNameSei(nameSei);
  setNameMei(nameMei);
  setNameSeiKana(nameSeiKana);
  setNameMeiKana(nameMeiKana);
  setTel(tel);
  setBirthYear(birthYear);
  setBirthMonth(birthMonth);
  setBirthDay(birthDay);
  setGender(gender);
  // setSelectedNotice(selectedNotice);
};

// 初期化
export const setInitUserEditInput = () => {
  setState(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkUserInvitationInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const { ...validationInfos } = currentInfos;
  const validationValues = Object.values(validationInfos);

  // 必須チェック
  const isEmpty = validationValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

export default useUserInvitationInput;
