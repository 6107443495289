import { useState, useEffect } from "react";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getMongoDb, getAggregateYoseNewCount } from "../../../utils/query";

type totalCount = {
  totalCount: number;
}[];

const useMenuNavigationFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [isNewYose, setIsNewYose] = useState(false);
  const [isInspectionResult, setExistInspectionResult] = useState(false);
  const [consultingChatCounts, setConsultingChatCounts] = useState(0);
  const [fetchError, setFetchError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        // 寄席の新着を取得
        const mongoDbYoses = getMongoDb(currentUser, Collection.YOSES);
        const yoseAgreegate = getAggregateYoseNewCount();
        const aggregateResult = (await mongoDbYoses.aggregate(
          yoseAgreegate,
        )) as totalCount;

        // 医師チャットの未読の記事を取得
        const mongoDbChats = getMongoDb(currentUser, Collection.CHATS);
        const chats = await mongoDbChats.find({
          patient_id: currentUser?.id,
          commented_user: "admin",
          is_read: false,
        });

        setIsNewYose(!!aggregateResult?.[0]?.totalCount);
        setConsultingChatCounts(chats.length);

        // TODO 検査のデータを取得するようにする phase2以降の対応
        setExistInspectionResult(false);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser]);

  return {
    isNewYose,
    isInspectionResult,
    consultingChatCounts,
    fetchError,
  };
};

export default useMenuNavigationFetch;
