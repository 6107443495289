import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  redirectToNoDataPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateOnePatientUser, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useForceUpdate from "./useForceUpdate";

// ログインユーザのPatientユーザ情報を取得
const usePatientInfoFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [patientInfo, setPatientInfo] = useState<PatientsType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const id = currentUser?.id;

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        // ログインユーザのPatientユーザ情報を取得
        const mongoDbPatient = getMongoDb(currentUser, Collection.USERS);
        const conditions = { _id: id };
        const aggregate = getAggregateOnePatientUser(conditions);
        const searchPatientInfo = (await mongoDbPatient.aggregate(
          aggregate,
        )) as PatientsType[];

        if (searchPatientInfo.length < 1) redirectToNoDataPage();
        setPatientInfo(searchPatientInfo[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, forceUpdate.forceUpdateCount]);

  return { error, patientInfo };
};

export default usePatientInfoFetch;
