import React from "react";
import Icon from "../Icon/Icon";

export type LinkProps = {
  icon?: string;
  size?: string;
  url?: string;
  disabled?: boolean;
  external?: boolean;
  // changeEmp
  externalIcon?: boolean;
  children: React.ReactNode;
  linkClassName?: string;
  color?: "white" | "primary";
};

const Link = ({
  size = "medium",
  icon = "",
  url = "",
  disabled = false,
  external = true,
  // changeEmp
  externalIcon = true,
  children,
  linkClassName = "",
  color = "white",
}: LinkProps) => {
  const linkClass = React.useMemo(() => {
    const sizeClass = [`link--${size}`];
    const disabledClass = disabled ? ["link--disabled"] : [];
    const colorClass = [`link--color-${color}`];

    return ["link", ...sizeClass, ...disabledClass, ...colorClass].join(" ");
  }, [disabled, size, color]);

  const target = React.useMemo(
    () => (external ? "_blank" : "_self"),
    [external],
  );

  return (
    <span className={linkClass}>
      <a href={url} target={target} className={linkClassName}>
        {icon && <Icon icon={icon} size={size === "medium" ? "xs" : "2xs"} />}

        <span className="link__content">{children}</span>
        {/* changeEmp */}
        {external && externalIcon && (
          <Icon
            className="external-link-icon"
            icon="open_in_new"
            size={size === "medium" ? "xs" : "2xs"}
          />
        )}
      </a>
    </span>
  );
};

Link.defaultProps = {
  icon: "",
  size: "medium",
  url: "",
  disabled: false,
  external: true,
  // changeEmp
  externalIcon: true,
  linkClassName: "",
  color: "white",
};

export default Link;
