import React from "react";

const ArchBackground = () => (
  <svg width="0" height="0">
    <defs>
      <clipPath id="ArchBackground" clipPathUnits="objectBoundingBox">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.558,0.103 H1 V1 H0 V0.103 H0.443 C0.446,0.045,0.471,0,0.5,0 C0.53,0,0.555,0.045,0.558,0.103"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ArchBackground;
