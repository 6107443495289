import React, { useEffect, useState } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Input from "../components/Input/Input";
import SpButton from "../components/Button/SpButton";
import SpFormSet from "../components/FormSet/SpFormSet";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import SpDropdown from "../components/Dropdown/SpDropdown";
import BulletList from "../components/List/BulletList";
import CheckboxGroup from "../components/Checkbox/CheckboxGroup";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import useUserRegisterInput, {
  checkUserRegisterInputError,
  setBirthDay,
  setBirthMonth,
  setBirthYear,
  setCity,
  setEnabledModality,
  setPastDiagnosis,
  setGender,
  setNameMei,
  setNameMeiKana,
  setNameSei,
  setNameSeiKana,
  setPref,
  // setSelectedNotice,
  setTel,
  setTown1,
  setTown2,
  setUserRegisterInput,
  setZipcode,
} from "../hooks/input/useUserRegisterInput";
import usePatientInfoFetch from "../hooks/common/usePatientInfoFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  BULLET_LIST_ITEMS,
  DROPDOWN_MONTH_OPTIONS,
  DROPDOWN_YEAR_OPTIONS,
  PREFECTURE_OPTIONS,
  RADIO_GENDER_ITEMS,
  // RADIO_NOTICE_ITEMS,
  PatientTypeID,
} from "../constants/common";
import PatientFooter from "../features/PatientFooter";
import useUsa004DBActions from "../hooks/pages/USA004/useUsa004DBActions";
import { generateDays } from "../utils/utils";

// FIXME: 仕様未決定のため、一旦非表示
// const RADIO_BUTTON_ITEMS = [
//   {
//     label: (
//       <LayoutBox gap="1x" direction="column">
//         <Text size="xl" bold>
//           月払いコース
//         </Text>
//         <LayoutBox gap="1/2x" align="center">
//           <Text color="primary" bold size="2xl">
//             2,999円
//           </Text>
//           <Text color="primary" bold size="large">
//             /月
//           </Text>
//         </LayoutBox>
//         <Text size="base" color="neutralLight">
//           ダミーテキストダミーテキストダミーテキストダミーテキスト
//         </Text>
//       </LayoutBox>
//     ),
//     value: "1",
//   },
//   {
//     label: (
//       <LayoutBox gap="1x" direction="column">
//         <Text size="xl" bold>
//           年払いコース
//         </Text>
//         <LayoutBox gap="1/2x" align="center">
//           <Text color="primary" bold size="2xl">
//             2,999円
//           </Text>
//           <Text color="primary" bold size="large">
//             /月
//           </Text>
//         </LayoutBox>
//         <Text size="base" color="neutralLight">
//           ダミーテキストダミーテキストダミーテキストダミーテキスト
//         </Text>
//       </LayoutBox>
//     ),
//     value: "2",
//   },
// ];

const USG006 = () => {
  const [dayOptions, setDayOptions] = useState<
    { label: string; value: string }[]
  >([]);
  // FIXME: 仕様未決定のため、一旦非表示
  // const [selectedPaymentMethod, setSelectedDeliveryTime] =
  //   React.useState<string>("");
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  const { fetchAddress, updateUser } = useUsa004DBActions();
  const [
    {
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      gender,
      birthYear,
      birthMonth,
      birthDay,
      tel,
      zipcode,
      pref,
      city,
      town1,
      town2,
      enabledModality,
      pastDiagnosis,
      // selectedNotice,
    },
  ] = useUserRegisterInput();

  useCheckErrorThrowError([patientInfoError]);

  useEffect(() => {
    setUserRegisterInput({
      nameSei: patientInfo?.name_sei || "",
      nameMei: patientInfo?.name_mei || "",
      nameSeiKana: patientInfo?.name_sei_kana || "",
      nameMeiKana: patientInfo?.name_mei_kana || "",
      tel: patientInfo?.tel || "",
      zipcode: patientInfo?.zipcode || "",
      pref: patientInfo?.pref || "",
      city: patientInfo?.city || "",
      town1: patientInfo?.town1 || "",
      town2: patientInfo?.town2 || "",
      birthYear: patientInfo?.birth_year.toString() || "",
      birthMonth: patientInfo?.birth_month.toString() || "",
      birthDay: patientInfo?.birth_day.toString() || "",
      gender: patientInfo?.gender === "male" ? "1" : "2",
      enabledModality: patientInfo?.is_enabled_modality
        ? [{ label: "当てはまる", checked: false, value: "0" }]
        : [{ label: "当てはまらない", checked: true, value: "1" }],
      pastDiagnosis: patientInfo?.past_diagnosis
        ? [
            {
              label: "認知症 もしくはMCI(軽度認知障害) と診断されたことがない",
              checked: true,
              value: "1",
            },
          ]
        : [
            {
              label: "認知症 もしくはMCI(軽度認知障害) と診断されたことがない",
              checked: false,
              value: "0",
            },
          ],
      // selectedNotice: patientInfo?.is_notification ? "1" : "2",
      selectedWithCode: patientInfo?.campaign_code ? "1" : "2",
      email: patientInfo?.email || "",
      campaignCode: patientInfo?.campaign_code || "",
    });
  }, [
    patientInfo?.name_sei,
    patientInfo?.name_mei,
    patientInfo?.name_sei_kana,
    patientInfo?.name_mei_kana,
    patientInfo?.gender,
    patientInfo?.birth_year,
    patientInfo?.birth_month,
    patientInfo?.birth_day,
    patientInfo?.tel,
    patientInfo?.zipcode,
    patientInfo?.pref,
    patientInfo?.city,
    patientInfo?.town1,
    patientInfo?.town2,
    patientInfo?.is_enabled_modality,
    patientInfo?.past_diagnosis,
    patientInfo?.is_notification,
    patientInfo?.patient_type,
    patientInfo?.email,
    patientInfo?.campaign_code,
  ]);

  useEffect(() => {
    if (birthYear && birthMonth) {
      const validDays = generateDays(birthYear.value, birthMonth.value);
      setDayOptions(validDays.map((day) => ({ label: `${day}`, value: day })));

      // 既に選択されている日が有効な日より多い場合はリセット
      if (birthDay && Number(birthDay.value) > validDays.length) {
        setBirthDay("");
      }
    } else {
      // 年と月が選択されていない場合は日をリセット
      setDayOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthYear, birthMonth]);

  // 住所検索
  const handleFetchAddress = () => {
    fetchAddress();
  };

  // 受検条件チェックボックス
  const handleEnabledModalityChange = (value: string) => {
    const updatedCheckboxItems = enabledModality.map((item) =>
      item.value === value ? { ...item, checked: !item.checked } : item,
    );

    setEnabledModality(updatedCheckboxItems);
  };

  // 過去の診断チェックボックス
  const handlePastDiagnosisChange = (value: string) => {
    const updatedCheckboxItems = pastDiagnosis.map((item) =>
      item.value === value ? { ...item, checked: !item.checked } : item,
    );

    setPastDiagnosis(updatedCheckboxItems);
  };

  const goToAccountInfoPage = useNavigateWithBack(PATIENT.USG005);

  // 保存ボタン押下
  const handleUpdateButton = () => {
    void (async () => {
      const isSuccess = await updateUser();

      if (!isSuccess) return;

      goToAccountInfoPage();
      window.location.reload(); // ヘッダーを無理やり更新
    })();
  };

  // FIXME: 仕様未決定のため、一旦非表示
  // const onChangePaymentMethod = React.useCallback((value: string) => {
  //   setSelectedDeliveryTime(value);
  // }, []);

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser />
        <div className="admin-inner area-with-header-footer">
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="会員情報を編集"
            withButton
            borderBottom="primary"
            onClickBack={goToAccountInfoPage}
          />
          <Sheet padding="40px 0" className="util-sp-py-24">
            <LayoutBox direction="column" maxWidth="1063px" fullWidth gap="2x">
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" fullWidth gap="3x">
                  <Title
                    type="h2"
                    header="会員情報"
                    withButton={false}
                    borderBottom="primaryLight"
                  />

                  <SpFormSet
                    label="お名前"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                  >
                    <LayoutBox
                      gap="1x"
                      direction="column"
                      className="util-py-8"
                      minWidth="400px"
                    >
                      <LayoutBox gap="1x" fullWidth>
                        <SpFormSet
                          label="姓"
                          vertical
                          errorTextList={[nameSei.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameSei.value}
                            onChange={(value) => setNameSei(value)}
                            error={nameSei.isError}
                          />
                        </SpFormSet>
                        <SpFormSet
                          label="名"
                          vertical
                          errorTextList={[nameMei.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameMei.value}
                            onChange={(value) => setNameMei(value)}
                            error={nameMei.isError}
                          />
                        </SpFormSet>
                      </LayoutBox>
                      <LayoutBox gap="1x" fullWidth>
                        <SpFormSet
                          label="セイ"
                          vertical
                          errorTextList={[nameSeiKana.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameSeiKana.value}
                            onChange={(value) => setNameSeiKana(value)}
                            error={nameSeiKana.isError}
                          />
                        </SpFormSet>
                        <SpFormSet
                          label="メイ"
                          vertical
                          errorTextList={[nameMeiKana.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameMeiKana.value}
                            onChange={(value) => setNameMeiKana(value)}
                            error={nameMeiKana.isError}
                          />
                        </SpFormSet>
                      </LayoutBox>
                    </LayoutBox>
                  </SpFormSet>
                  <SpFormSet
                    label="性別"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                    errorTextList={[gender.validationMessage]}
                  >
                    <LayoutBox minWidth="400px" className="util-sp-full-width">
                      <RadioButtonGroup
                        name="RadioGender"
                        items={RADIO_GENDER_ITEMS}
                        onChange={(value) => setGender(value)}
                        selectedValue={gender.value}
                        withBorder
                        width="100%"
                        column={2}
                        radioGap="8px"
                      />
                    </LayoutBox>
                  </SpFormSet>
                  <SpFormSet
                    label="生年月日"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                    errorTextList={[birthDay.validationMessage]}
                  >
                    <LayoutBox gap="1x" direction="column" fullWidth>
                      <LayoutBox gap="1x" fullWidth align="center">
                        <SpDropdown
                          placeholder="XXXX（昭和37）"
                          items={DROPDOWN_YEAR_OPTIONS}
                          onChange={(value) => setBirthYear(value)}
                          width="256px"
                          value={birthYear.value}
                        />
                        <Text size="xl" color="neutralUserBlack">
                          年
                        </Text>
                      </LayoutBox>
                      <LayoutBox gap="1x" fullWidth>
                        <LayoutBox gap="1x" align="center">
                          <SpDropdown
                            placeholder="MM"
                            items={DROPDOWN_MONTH_OPTIONS}
                            onChange={(value) => setBirthMonth(value)}
                            width="97px"
                            value={birthMonth.value}
                          />
                          <Text size="xl" color="neutralUserBlack" width="45px">
                            月
                          </Text>
                        </LayoutBox>
                        <LayoutBox gap="1x" align="center">
                          <SpDropdown
                            placeholder="DD"
                            items={dayOptions}
                            onChange={(value) => setBirthDay(value)}
                            width="97px"
                            value={birthDay.value}
                          />
                          <Text size="xl" color="neutralUserBlack" width="45px">
                            日
                          </Text>
                        </LayoutBox>
                      </LayoutBox>
                    </LayoutBox>
                  </SpFormSet>
                  <SpFormSet
                    label="電話番号"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                    errorTextList={[tel.validationMessage]}
                  >
                    <Input
                      value={tel.value}
                      width="335px"
                      onChange={(value) => setTel(value)}
                      error={tel.isError}
                      inputMode="tel"
                    />
                  </SpFormSet>
                  {patientInfo?.patient_type !== PatientTypeID.INVITATION && (
                    <SpFormSet
                      label="住所"
                      base
                      required="badge"
                      labelSize="medium"
                      labelWidth="200px"
                      spToColumnAndFullWidth
                    >
                      <LayoutBox gap="1x" direction="column" fullWidth>
                        <SpFormSet
                          label="郵便番号"
                          vertical
                          required="icon"
                          errorTextList={[zipcode.validationMessage]}
                        >
                          <LayoutBox fullWidth gap="1x">
                            <Input
                              width="151.5px"
                              value={zipcode.value}
                              onChange={(value) => setZipcode(value)}
                              error={zipcode.isError}
                              inputMode="numeric"
                            />
                            <SpButton
                              type="secondary"
                              width="99px"
                              onClick={handleFetchAddress}
                            >
                              住所検索
                            </SpButton>
                          </LayoutBox>
                        </SpFormSet>
                        <SpFormSet
                          label="都道府県"
                          vertical
                          required="icon"
                          errorTextList={[pref.validationMessage]}
                        >
                          <SpDropdown
                            placeholder=""
                            items={PREFECTURE_OPTIONS}
                            onChange={(value) => setPref(value)}
                            width="151.5px"
                            value={pref.value}
                          />
                        </SpFormSet>
                        <SpFormSet
                          label="市区町村"
                          vertical
                          required="icon"
                          errorTextList={[city.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={city.value}
                            onChange={(value) => setCity(value)}
                            error={city.isError}
                          />
                        </SpFormSet>
                        <SpFormSet
                          label="町域・番地"
                          vertical
                          required="icon"
                          errorTextList={[town1.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={town1.value}
                            onChange={(value) => setTown1(value)}
                            error={town1.isError}
                          />
                        </SpFormSet>
                        <SpFormSet label="建物名など" vertical>
                          <Input
                            width="100%"
                            value={town2}
                            onChange={(value) => setTown2(value)}
                          />
                        </SpFormSet>
                      </LayoutBox>
                    </SpFormSet>
                  )}
                </LayoutBox>
              </Sheet>
              {patientInfo?.patient_type !== PatientTypeID.INVITATION && (
                <>
                  <Sheet
                    type="card"
                    padding="16px 32px 32px 32px"
                    maxWidth="1064px"
                    width="100%"
                  >
                    <LayoutBox direction="column" gap="3x" fullWidth>
                      <Title
                        header="過去の診断について"
                        borderBottom="primaryLight"
                        withButton={false}
                        type="h2"
                      />
                      <Text size="xl" color="neutralUserBlack">
                        本サービスは、認知症 もしくはMCI（軽度認知障害）
                        と診断されたことがない方を対象とします。
                        <br />
                        これまでに該当する診断をされていないことを確認してください。
                      </Text>
                      <CheckboxGroup
                        items={pastDiagnosis}
                        withContainer
                        onChecked={(value) => handlePastDiagnosisChange(value)}
                      />
                    </LayoutBox>
                  </Sheet>
                  <Sheet type="card" padding="16px 16px 32px 16px">
                    <LayoutBox direction="column" fullWidth gap="3x">
                      <Title
                        header="MRI検査の禁忌事項について"
                        borderBottom="primaryLight"
                        withButton={false}
                        type="h2"
                      />
                      <LayoutBox direction="column" gap="3x" fullWidth>
                        <LayoutBox direction="column">
                          <Text size="xl" color="error">
                            本サービスにはMRI検査が含まれるため、MRI検査を受検できない方は会員登録することがきません。
                          </Text>
                          <Text size="xl" color="error">
                            以下に当てはまる項目がないことをご確認ください。
                          </Text>
                        </LayoutBox>
                        <Sheet
                          type="border-red"
                          borderRadius="8px"
                          padding="16px"
                        >
                          <BulletList
                            title="体内に下記の医療機器がある方"
                            items={BULLET_LIST_ITEMS}
                            color="red"
                          />
                        </Sheet>
                      </LayoutBox>
                      <CheckboxGroup
                        items={enabledModality}
                        withContainer
                        onChecked={(value) =>
                          handleEnabledModalityChange(value)
                        }
                      />
                    </LayoutBox>
                  </Sheet>
                </>
              )}
              {/* FIXME: 仕様未決定のため、一旦非表示 */}
              {/* <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" fullWidth gap="3x">
                  <Title
                    type="h2"
                    header="お知らせ"
                    withButton={false}
                    borderBottom="primaryLight"
                  />
                  <LayoutBox fullWidth>
                    <SpFormSet
                      errorTextList={
                        selectedNotice.validationMessage
                          ? ["お知らせが選択されていません。"]
                          : []
                      }
                    >
                      <RadioButtonGroup
                        name="RadioNotice"
                        items={RADIO_NOTICE_ITEMS}
                        onChange={(value) => setSelectedNotice(value)}
                        selectedValue={selectedNotice.value}
                        withBorder
                        width="100%"
                        column={2}
                        radioGap="8px"
                      />
                    </SpFormSet>
                  </LayoutBox>
                </LayoutBox>
              </Sheet> */}
              {/* FIXME: 仕様未決定のため、一旦非表示 */}
              {/* {patientInfo?.patient_type !== PatientTypeID.PRESENT && (
                <Sheet type="card" padding="16px 16px 32px 16px">
                  <LayoutBox direction="column" fullWidth gap="3x">
                    <Title
                      type="h2"
                      header="お支払い情報"
                      withButton={false}
                      borderBottom="primaryLight"
                    />
                    <SpFormSet
                      label="お支払い方法"
                      base
                      labelSize="medium"
                      labelWidth="200px"
                      spToColumnAndFullWidth
                      vertical
                    >
                      <LayoutBox fullWidth>
                        <RadioButtonGroup
                          name="paymentMethod"
                          items={RADIO_BUTTON_ITEMS}
                          onChange={onChangePaymentMethod}
                          selectedValue={selectedPaymentMethod}
                          withBorder
                          width="100%"
                          column={2}
                          radioGap="8px"
                          toColumn
                        />
                      </LayoutBox>
                    </SpFormSet>
                  </LayoutBox>
                </Sheet>
              )} */}
              <LayoutBox
                width="100%"
                justify="between"
                gap="2x"
                className="util-sp-full-width"
                wrapReverse
              >
                <SpButton
                  type="secondary"
                  width="400px"
                  size="medium"
                  withArrowLeft
                  mobileFullWidth
                  onClick={goToAccountInfoPage}
                >
                  戻る
                </SpButton>
                <SpButton
                  type="primary"
                  width="400px"
                  size="medium"
                  onClick={handleUpdateButton}
                  mobileFullWidth
                  disabled={checkUserRegisterInputError(
                    patientInfo?.patient_type === PatientTypeID.INVITATION,
                  )}
                >
                  保存
                </SpButton>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
          <div />
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USG006;
