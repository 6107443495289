import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregatePatientNouknow, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";

// PatientNouknowsテーブル検索
const usePatientNouknowsFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [patientNouknows, setPatientNouknows] = useStateCustomObj<
    PatientNouknows[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  const id = currentUser?.id;

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const collection = getMongoDb(currentUser, Collection.PATIENT_NOUKNOWS);
        const aggregate = getAggregatePatientNouknow(currentUser?.id ?? "");
        const fetchResult = (await collection.aggregate(
          aggregate,
        )) as PatientNouknows[];

        setPatientNouknows(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, setPatientNouknows]);

  return { error, patientNouknows };
};

export default usePatientNouknowsFetch;
