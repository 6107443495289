// changeEmp コンポーネントを新規追加
// Containerコンポーネント(機能や状態などロジックを記載)

import { useMemo } from "react";
import SpPagination from "./SpPagination";
import usePagination, {
  setPage,
} from "../../hooks/component/pagination/usePagination";

const SpPaginationContainer = () => {
  const [pagination] = usePagination();
  const { total, perPage } = pagination;
  const currentPage = pagination.page;

  const totalPageNumber = useMemo(
    () => Math.ceil(total / perPage),
    [perPage, total],
  );

  const onClickRightBtn = () => {
    setPage(currentPage + 1);
  };

  const onClickLeftBtn = () => {
    setPage(currentPage - 1);
  };

  return (
    <SpPagination
      pageNumber={currentPage}
      total={totalPageNumber}
      leftButtonText="前へ"
      rightButtonText="次へ"
      leftButtonDisabled={currentPage === 1}
      rightButtonDisabled={currentPage === totalPageNumber}
      rightButtonType="secondary"
      width="118px"
      onClickRight={onClickRightBtn}
      onClickLeft={onClickLeftBtn}
    />
  );
};

export default SpPaginationContainer;
