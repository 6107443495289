import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  selectPetId: string;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  selectPetId: sessionStorage.getItem("petId") || "",
});

const useUsc013Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setSelectPetId = (selectPetId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    selectPetId,
  });
  sessionStorage.setItem("petId", selectPetId);
};

export default useUsc013Conditions;
