import { useState, useEffect } from "react";
import { Collection } from "../../../constants/common";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateUsm001, getMongoDb } from "../../../utils/query";
import useStateCustomObj from "../../base/useStateCustomObj";

const useUsm001Fetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [notifications, setNotifications] = useStateCustomObj<
    PatientNotification[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collection = getMongoDb(currentUser, Collection.NOTIFICATIONS);
        const aggregate = getAggregateUsm001();
        const fetchResult = (await collection.aggregate(
          aggregate,
        )) as PatientNotification[];

        setNotifications(fetchResult);
        setIsLoaded(true);
      } catch (err) {
        setError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, setNotifications]);

  return { error, notifications, isLoaded };
};

export default useUsm001Fetch;
