import { useCallback, useMemo, useState } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import CardCart from "../components/Card/CardCart";
import CalendarDay from "../components/CalendarDay/CalendarDay";
import Divider from "../components/Divider/Divider";
import FooterButton from "../components/Footer/FooterButton";
import useUsf008DBActions from "../hooks/pages/USF008/useUsf008DBActions";
import {
  useNavigateCustom,
  useNavigateWithBack,
  useNavigateWithCurrentState,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import usePatientInfoFetch from "../hooks/common/usePatientInfoFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { PATIENT } from "../constants/pagePaths";
import { setId } from "../hooks/common/useId";
import { openUsf006Dialog } from "../hooks/pages/USF006/useUsf006Dialog";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import SpButton from "../components/Button/SpButton";
import { DELIVERY_AVAILABLE_DATE } from "../constants/common";
import { addHyphenToZipcode } from "../utils/utils";
import PatientFooter from "../features/PatientFooter";
import useShippingsFetch from "../hooks/common/useShippingsFetch";

const RADIO_BUTTON_ITEMS = [
  {
    label: "通常配送",
    value: "1",
  },
  {
    label: "お届け日指定",
    value: "2",
  },
];

type DeliveryTimeValue = "1" | "2" | "3" | "4" | "5";

const USF008 = () => {
  const {
    cartInfo,
    handleOrder,
    error: cartError,
    actionError,
  } = useUsf008DBActions();

  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  const navigate = useNavigateCustom();
  const handleGoBack = useNavigateWithBack(PATIENT.USF001);
  const navigateWithUrl = useNavigateWithUrl();
  const navigateWithCurrentState = useNavigateWithCurrentState();
  const { fetchShippingResult, fetchShippingError } = useShippingsFetch();

  useCheckErrorThrowError([
    cartError,
    patientInfoError,
    actionError,
    fetchShippingError,
  ]);

  const zipcode = addHyphenToZipcode(patientInfo?.zipcode ?? "") as string;

  // 配達時間の紐づけ
  const deliveryTimeValueMap = useMemo(() => {
    const map: { [key: string]: number } = {};
    (fetchShippingResult?.deliveryTimes ?? []).forEach(
      (deliveryTime, index) => {
        map[(index + 1).toString()] = deliveryTime.start;
      },
    );

    return map;
  }, [fetchShippingResult?.deliveryTimes]);

  const initialCurrentDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + DELIVERY_AVAILABLE_DATE);

    return date;
  }, []);

  const [selectedDeliveryType, setSelectedDeliveryType] = useState("2");
  const [selectedDeliveryTime, setSelectedDeliveryTime] =
    useState<DeliveryTimeValue>("1");
  const [desiredDeliveryDate, setDesiredDeliveryDate] =
    useState<Date>(initialCurrentDate);

  const navigateToPaymentConfirm = useCallback(() => {
    navigateWithCurrentState(PATIENT.USF007);
  }, [navigateWithCurrentState]);

  const onChangeDeliveryType = useCallback(
    (value: string) => {
      if (value === "1") navigateToPaymentConfirm();
      setSelectedDeliveryType(value);
    },
    [navigateToPaymentConfirm],
  );

  const onChangeDeliveryTime = useCallback((value: string) => {
    setSelectedDeliveryTime(value as DeliveryTimeValue);
  }, []);

  const handleChangeDesiredDeliveryDate = useCallback((date: Date) => {
    setDesiredDeliveryDate(date);
  }, []);

  const handleConfirm = useCallback(async () => {
    const desiredDeliveryDateTime = new Date(desiredDeliveryDate.getTime());
    desiredDeliveryDateTime.setHours(
      deliveryTimeValueMap[selectedDeliveryTime],
    );
    desiredDeliveryDateTime.setMinutes(0);
    desiredDeliveryDateTime.setSeconds(0);
    const stripeUrl = await handleOrder(desiredDeliveryDateTime);
    if (stripeUrl && cartInfo) {
      setId(cartInfo?._id);

      window.location.replace(stripeUrl);
    }
  }, [
    desiredDeliveryDate,
    cartInfo,
    handleOrder,
    selectedDeliveryTime,
    deliveryTimeValueMap,
  ]);

  const navigateToItemDetail = useCallback(
    (id: string) => {
      const transactionInfo = cartInfo?.transactions_info.find(
        (transactionInfo) => transactionInfo._id === id,
      );
      if (transactionInfo) {
        setId(transactionInfo.skus_info.item_id);
        navigateWithUrl(PATIENT.USF005);
      }
    },
    [cartInfo?.transactions_info, navigateWithUrl],
  );

  const navigateToPatientEdit = useCallback(() => {
    if (patientInfo?._id) {
      setId(patientInfo?._id);
      navigate(PATIENT.USG006);
    }
  }, [navigate, patientInfo?._id]);

  const cardCartItems = useMemo(
    () =>
      (cartInfo?.transactions_info ?? []).map((transactionInfo) => ({
        id: transactionInfo._id,
        name: transactionInfo.item_name,
        description: transactionInfo.sku_name,
        price: transactionInfo.skus_info.amount,
        image: transactionInfo.skus_info.image_urls[0],
        quantity: transactionInfo.quantity,
        stock: transactionInfo.skus_info.stock,
      })),
    [cartInfo?.transactions_info],
  );

  // 配達時間の選択肢
  const deliveryTimeItems = useMemo(
    () =>
      (fetchShippingResult?.deliveryTimes ?? []).map((deliveryTime, index) => ({
        label: `${deliveryTime.start}:00-${deliveryTime.end}:00`,
        value: (index + 1).toString(),
      })),
    [fetchShippingResult?.deliveryTimes],
  );

  const taxAmount = useMemo(() => {
    const reducer = (sum: number, currentValue: number) => sum + currentValue;

    return cartInfo?.total_amount_per_tax
      .map((taxObj) => taxObj.tax)
      .reduce(reducer);
  }, [cartInfo?.total_amount_per_tax]);

  const handleGoBackCart = useCallback(() => {
    openUsf006Dialog();
    handleGoBack();
  }, [handleGoBack]);

  return (
    <>
      <div className="admin-area admin-area-with-button-footer">
        <HeaderUser />
        <div className="admin-inner area-with-header-footer">
          <MenuNavigation
            selectedTab={SELECTED_TAB_NUM.shop}
            minWidth="1076px"
          />
          <Title
            padding="16px 108px 24px 108px"
            background="rough-blue"
            header="会計"
            onClickBack={handleGoBackCart}
            minWidth="1076px"
          />
          <Sheet padding="40px 108px 24px 108px" minWidth="1076px">
            <LayoutBox direction="column" width="1064px" gap="2x">
              <LayoutBox direction="column" fullWidth>
                <Sheet type="card" padding="16px">
                  <LayoutBox
                    fullWidth
                    justify="center"
                    align="center"
                    gap="2x"
                    toColumn
                  >
                    <LayoutBox direction="column" fullWidth gap="1x">
                      <Text color="neutralLight" size="large">
                        お届け先
                      </Text>
                      <Text size="xl">
                        {patientInfo?.name_sei}
                        {patientInfo?.name_mei} 様
                      </Text>
                      <Text size="xl">
                        {`${zipcode} ${patientInfo?.pref ?? ""}${
                          patientInfo?.city ?? ""
                        }${patientInfo?.town1 ?? ""}${
                          patientInfo?.town2 ?? ""
                        }`}
                      </Text>
                    </LayoutBox>
                    <SpButton
                      type="secondary"
                      width="316px"
                      icon="edit"
                      onClick={navigateToPatientEdit}
                    >
                      会員情報を編集
                    </SpButton>
                  </LayoutBox>
                </Sheet>
              </LayoutBox>
              <LayoutBox direction="column" fullWidth>
                <Sheet type="card" padding="16px">
                  <LayoutBox direction="column" fullWidth gap="2x">
                    <RadioButtonGroup
                      name="deliveryType"
                      items={RADIO_BUTTON_ITEMS}
                      onChange={onChangeDeliveryType}
                      selectedValue={selectedDeliveryType}
                      withBorder
                      width="100%"
                      column={2}
                      radioGap="8px"
                      toColumn
                    />
                    <LayoutBox fullWidth>
                      <CalendarDay
                        daysNumber={7}
                        onChange={handleChangeDesiredDeliveryDate}
                        initialCurrentDate={initialCurrentDate}
                      />
                    </LayoutBox>
                    <Divider darkColor orange={false} margin="2px" />

                    <RadioButtonGroup
                      name="deliveryTime"
                      items={deliveryTimeItems}
                      onChange={onChangeDeliveryTime}
                      selectedValue={selectedDeliveryTime}
                      withBorder
                      width="100%"
                      column={4}
                      radioGap="8px"
                      toColumn
                    />
                  </LayoutBox>
                </Sheet>
              </LayoutBox>
              <LayoutBox direction="column" fullWidth>
                <Sheet type="card" padding="16px">
                  <LayoutBox
                    direction="column"
                    fullWidth
                    justify="center"
                    align="center"
                    gap="10px"
                  >
                    <CardCart
                      items={cardCartItems}
                      padding="0"
                      gap="10px"
                      withButton
                      type="view"
                      mobileType="view"
                      onClickConfirm={() => {
                        void handleConfirm();
                      }}
                      onClickItem={navigateToItemDetail}
                      deliveryCharge={cartInfo?.delivery_fee}
                      subtotal={cartInfo?.total_item_amount}
                      total={cartInfo?.total_amount}
                      tax={taxAmount}
                    />
                  </LayoutBox>
                </Sheet>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </div>
        <PatientFooter />
      </div>
      <FooterButton
        width="400px"
        leftButtonText="カートに戻る"
        rightButtonText="注文を確定する"
        justify="between"
        scrollable
        onClickLeft={handleGoBackCart}
        onClickRight={() => {
          void handleConfirm();
        }}
      />
    </>
  );
};

export default USF008;
