import React from "react";
import Tab from "./Tab";
import { mobileView } from "../../constants/common";

export type TabsProps = {
  selectedTab: string | number | undefined;
  size?: "medium" | "large";
  tabs: {
    title: string;
    titleMobile?: string;
    icon?: string;
    image?: string;
    imageMobile?: string;
    counter?: number;
    error?: boolean;
    disabled?: boolean;
    badge?: string;
    onClick?: () => void;
  }[];
  tabClassName?: string;
  bottomBorder?: boolean;
  // changeEmp
  onClick?: (value: number) => void | (() => void);
};

const Tabs = ({
  selectedTab,
  tabs,
  size,
  tabClassName,
  bottomBorder,
  // changeEmp
  onClick = () => {},
}: TabsProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState(selectedTab);

  // changeEmp
  const selectEvent = (index: number) => {
    // 親で設定したクリックイベントにindexを渡す
    onClick(index);
    setSelectedIndex(index);
  };

  const tabClass = React.useMemo(() => {
    const borderClass = bottomBorder ? [`tabs--bordered`] : "";

    return ["tabs", ...borderClass].join(" ");
  }, [bottomBorder]);

  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileView);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className={tabClass}>
        <div className="tabs--container">
          {tabs.map((tab, index) => {
            const isSelected = index === selectedIndex;

            return (
              <Tab
                {...tab}
                index={index}
                isSelected={isSelected}
                key={`tab-${index}`}
                tabClassName={tabClassName}
                size={size}
                // changeEmp
                selectTab={() => {
                  tab.onClick?.();
                  selectEvent(index);
                }}
              >
                <span className="tab__label-title">
                  {isMobile && tab.titleMobile ? tab.titleMobile : tab.title}
                </span>
              </Tab>
            );
          })}
        </div>
      </div>
    </>
  );
};

Tabs.defaultProps = {
  tabClassName: "",
  size: "large",
  bottomBorder: false,
  // changeEmp
  onClick: () => {},
};

export default Tabs;
