import React from "react";

export type IconProps = {
  icon?: string;
  type?: "outlined" | "filled";
  color?: string;
  size?: "2xs" | "xs" | "small" | "medium" | "large" | "xl" | "2xl";
  className?: string;
  // changeEmp
  onClick?: () => void;
};

const Icon = ({
  icon = "",
  type = "filled",
  color = "inherit",
  size = "medium",
  className = "",
  // changeEmp
  onClick,
}: IconProps) => {
  const iconSize = React.useMemo(() => {
    switch (size) {
      case "2xs":
        return 14;
      case "xs":
        return 16;
      case "small":
        return 20;
      case "medium":
        return 24;
      case "large":
        return 32;
      case "xl":
        return 40;
      case "2xl":
        return 96;
      default:
        return 24;
    }
  }, [size]);

  const iconClass = React.useMemo(() => {
    switch (type) {
      case "outlined":
        return "material-symbols-outlined";
      case "filled":
        return "material-icons";
      default:
        return "material-icons";
    }
  }, [type]);

  return (
    <span
      className="icon"
      style={{
        width: `${iconSize}px`,
        height: `${iconSize}px`,
        fontSize: `${iconSize}px`,
      }}
      // changeEmp
      onClick={onClick ? () => onClick() : undefined}
    >
      <span
        className={`${iconClass} ${className}`}
        style={{ color, fontSize: "inherit" }}
      >
        {icon}
      </span>
    </span>
  );
};

Icon.defaultProps = {
  icon: "",
  type: "filled",
  color: "inherit",
  size: "medium",
  className: "",
  // changeEmp
  onClick: "",
};

export default Icon;
