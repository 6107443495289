import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Divider from "../components/Divider/Divider";
import FormSet from "../components/FormSet/FormSet";
import SpFormSet from "../components/FormSet/SpFormSet";
import SpButton from "../components/Button/SpButton";
import PatientFooter from "../features/PatientFooter";
import usePatientInfoFetch from "../hooks/common/usePatientInfoFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { convertUsg005 } from "../utils/convertDisplay";
import {
  useNavigateWithBack,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import {
  INVITATION,
  NON_ACTIVE_PRESENT,
  PATIENT,
} from "../constants/pagePaths";
import USG007 from "./US-G007";
import {
  BULLET_LIST_ITEMS,
  PatientTypeID,
  UserStatusID,
} from "../constants/common";
import useUsg005DBActions from "../hooks/pages/USG005/useUsg005DBActions";
import { addHyphenToZipcode, isActiveUser } from "../utils/utils";
import useStripeActions from "../hooks/common/useStripeActions";
import useStripeSubscriptionFetch from "../hooks/common/useStripeSubscriptionFetch";
import { useRealmApp } from "../contexts/RealmApp";
import useInvitationUserFetch from "../hooks/common/useInvitationUserFetch";
import Collapse from "../components/Collapse/Collapse";
import BulletList from "../components/List/BulletList";

const USG005 = () => {
  const realmAppContext = useRealmApp();
  const navigate = useNavigateWithUrl();
  const stateNavigate = useNavigate();
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  const { nextUpdateDateStr, error: fetchNextUpdateDateError } =
    useStripeSubscriptionFetch(realmAppContext.currentUser?.id ?? "");
  const { deleteMemberShip, error } = useUsg005DBActions();
  const { fetchStripeCardUpdateLink, stripeError } = useStripeActions();
  const { invitationUserInfo, error: fetchInvitationUserError } =
    useInvitationUserFetch();

  useCheckErrorThrowError([
    error,
    patientInfoError,
    stripeError,
    fetchNextUpdateDateError,
    fetchInvitationUserError,
  ]);

  //  退会ダイアログの表示状態
  const [isDialogOpen, toggleIsDialogOpen] = useReducer((v) => !v, false);

  const isActive = isActiveUser(realmAppContext.currentUser);
  const isInvitationUser =
    patientInfo?.patient_type === PatientTypeID.INVITATION;
  const isNonActivePresentUser =
    patientInfo?.patient_type === PatientTypeID.PRESENT &&
    patientInfo?.status === UserStatusID.UNREGISTERED;

  const {
    id,
    nameSei,
    nameMei,
    nameSeiKana,
    nameMeiKana,
    gender,
    birth,
    tel,
    zipcode,
    address,
    email,
    mriPetConditions,
    // notification,
    invitation,
    planType,
  } = convertUsg005(patientInfo);

  const userId =
    (realmAppContext.currentUser?.customData.user_id as string) ?? "";

  const backPage = () => {
    if (isInvitationUser) {
      return INVITATION.USA029;
    }
    if (isNonActivePresentUser) {
      return NON_ACTIVE_PRESENT.USA036;
    }

    return PATIENT.USB001;
  };

  // 戻るボタン
  const handleGoBack = useNavigateWithBack(backPage());

  // 編集ボタン
  const handleEdit = () => {
    navigate(PATIENT.USG006);
  };

  // 退会ボタン
  const handleWithdrawal = () => {
    toggleIsDialogOpen();
  };

  // ユーザーを退会させます。
  const removeMembership = () => {
    void (async () => {
      const isSuccess = await deleteMemberShip(id);
      if (!isSuccess) return;
      stateNavigate(PATIENT.USG008);
      window.location.reload(); // ヘッダーを無理やり更新
    })();
  };

  const handleEditCreditCard = async () => {
    const stripeSubscriptionId = patientInfo?.stripe_subscription_id;
    if (stripeSubscriptionId) {
      const stripeUrl = await fetchStripeCardUpdateLink(
        stripeSubscriptionId,
        PATIENT.USG005,
      );
      if (stripeUrl) {
        window.location.href = stripeUrl;
      }
    }
  };

  return (
    <>
      <div className="admin-area admin-area_no-header">
        <div className="admin-inner area-with-header-footer">
          <HeaderUser />
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="会員情報"
            borderBottom="primary"
            onClickBack={handleGoBack}
          />
          <Sheet padding="40px 0">
            <LayoutBox gap="3x" direction="column">
              <LayoutBox
                direction="column"
                justify="center"
                align="center"
                gap="2x"
                fullWidth
                minWidth="1064px"
              >
                <Sheet
                  type="card"
                  padding="16px 32px 32px 32px"
                  maxWidth="1064px"
                >
                  <LayoutBox direction="column" gap="3x" fullWidth>
                    <LayoutBox direction="column" fullWidth>
                      <Text size="2xl" bold>
                        会員情報
                      </Text>
                      <Divider borderWidth="3px" margin="0" />
                    </LayoutBox>
                    <LayoutBox direction="column" gap="3x" fullWidth>
                      <SpFormSet
                        label="会員ID"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          gap="1x"
                          minWidth="400px"
                          className="util-sp-full-width util-mt-8"
                          justify="end"
                          fullHeight
                        >
                          <Text size="xl">{userId}</Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                      <SpFormSet
                        label="お名前"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          className="util-sp-full-width util-mt-8"
                        >
                          <LayoutBox gap="1x" fullWidth justify="between">
                            <FormSet label="姓" vertical width="196px">
                              <LayoutBox direction="column" gap="1x" fullWidth>
                                <Text size="xl">{nameSei}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                            <FormSet label="名" vertical width="196px">
                              <LayoutBox direction="column" gap="1x" fullWidth>
                                <Text size="xl">{nameMei}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                          </LayoutBox>
                          <LayoutBox gap="1x" fullWidth justify="between">
                            <FormSet label="セイ" vertical width="196px">
                              <LayoutBox direction="column" gap="1x" fullWidth>
                                <Text size="xl">{nameSeiKana}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                            <FormSet label="メイ" vertical width="196px">
                              <LayoutBox direction="column" gap="1x" fullWidth>
                                <Text size="xl">{nameMeiKana}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                          </LayoutBox>
                        </LayoutBox>
                      </SpFormSet>
                      <SpFormSet
                        label="性別"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          gap="1x"
                          minWidth="400px"
                          className="util-sp-full-width util-mt-8"
                          justify="end"
                          fullHeight
                        >
                          <Text size="xl">{gender}</Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                      <SpFormSet
                        label="生年月日"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          gap="1x"
                          minWidth="400px"
                          className="util-sp-full-width util-mt-8"
                          justify="end"
                          fullHeight
                        >
                          <Text size="xl">{birth}</Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                      <SpFormSet
                        label="電話番号"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          gap="1x"
                          minWidth="400px"
                          className="util-sp-full-width util-mt-8"
                          justify="end"
                          fullHeight
                        >
                          <Text size="xl">{tel}</Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                      {!isInvitationUser && (
                        <SpFormSet
                          label="住所"
                          base
                          labelSize="medium"
                          labelWidth="200px"
                          spToColumnAndFullWidth
                          labelGap="large"
                        >
                          <LayoutBox
                            direction="column"
                            gap="1x"
                            minWidth="400px"
                            maxWidth="774px"
                            className="util-sp-full-width util-mt-8"
                            justify="end"
                            fullHeight
                          >
                            <Text size="xl" width="100%">
                              {addHyphenToZipcode(zipcode)} <br />
                              {address}
                            </Text>
                            <Divider margin="0" darkColor />
                          </LayoutBox>
                        </SpFormSet>
                      )}
                      <SpFormSet
                        label="メールアドレス"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          gap="1x"
                          minWidth="400px"
                          maxWidth="774px"
                          className="util-sp-full-width util-mt-8"
                          justify="end"
                          fullHeight
                        >
                          <Text size="xl" width="100%">
                            {email}
                          </Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                    </LayoutBox>
                  </LayoutBox>
                </Sheet>
                {!isInvitationUser && (
                  <>
                    <Sheet
                      type="card"
                      padding="16px 32px 32px 32px"
                      maxWidth="1064px"
                      width="100%"
                    >
                      <LayoutBox direction="column" gap="3x" fullWidth>
                        <LayoutBox direction="column" fullWidth>
                          <Text size="2xl" bold>
                            過去の診断について
                          </Text>
                          <Divider borderWidth="3px" margin="0" />
                        </LayoutBox>
                        <LayoutBox direction="column" gap="2x" fullWidth>
                          <Text size="xl" color="neutralUserBlack">
                            認知症 もしくはMCI（軽度認知障害）
                            と診断されたことがない
                          </Text>
                          <Collapse label="内容" open width="100%">
                            <Text size="xl">
                              本サービスは、認知症 もしくはMCI（軽度認知障害）
                              と診断されたことがない方を対象とします。
                              これまでに該当する診断をされていないことを確認してください。
                            </Text>
                          </Collapse>
                        </LayoutBox>
                      </LayoutBox>
                    </Sheet>
                    <Sheet type="card" padding="16px 16px 32px 16px">
                      <LayoutBox direction="column" fullWidth gap="3x">
                        <Title
                          header="MRI検査の禁忌事項について"
                          borderBottom="primaryLight"
                          withButton={false}
                          type="h2"
                        />
                        <LayoutBox direction="column" gap="2x" fullWidth>
                          <Text size="xl" color="neutralUserBlack">
                            {mriPetConditions}
                          </Text>
                          <Collapse width="100%" label="内容">
                            <LayoutBox direction="column">
                              <Text size="xl" color="error">
                                本サービスにはMRI検査が含まれるため、MRI検査を受検できない方は会員登録することがきません。
                              </Text>
                              <Text size="xl" color="error">
                                以下に当てはまる項目がないことをご確認ください。
                              </Text>
                            </LayoutBox>
                            <Sheet
                              type="border-red"
                              className="util-mt-16"
                              borderRadius="8px"
                              padding="16px"
                            >
                              <BulletList
                                title="体内に下記の医療機器がある方"
                                items={BULLET_LIST_ITEMS}
                                color="red"
                              />
                            </Sheet>
                          </Collapse>
                        </LayoutBox>
                      </LayoutBox>
                    </Sheet>
                  </>
                )}
                {/* FIXME: 仕様未決定のため、一旦非表示 */}
                {/* <Sheet
                  type="card"
                  padding="16px 32px 32px 32px"
                  maxWidth="1064px"
                >
                  <LayoutBox direction="column" gap="3x" fullWidth>
                    <LayoutBox direction="column" fullWidth>
                      <Text size="2xl" bold>
                        お知らせ
                      </Text>
                      <Divider borderWidth="3px" margin="0" />
                    </LayoutBox>
                    <Text size="xl">{notification}</Text>
                  </LayoutBox>
                </Sheet> */}
                {isActive && !isInvitationUser && (
                  <Sheet
                    type="card"
                    padding="16px 32px 32px 32px"
                    maxWidth="1064px"
                  >
                    <LayoutBox direction="column" gap="3x" fullWidth>
                      {patientInfo?.patient_type === PatientTypeID.PRESENT ? (
                        <>
                          <LayoutBox direction="column" fullWidth>
                            <Text size="2xl" bold>
                              お支払いコース
                            </Text>
                            <Divider borderWidth="3px" margin="0" />
                          </LayoutBox>
                          <SpFormSet
                            label="会費お支払い者様"
                            base
                            labelSize="medium"
                            labelWidth="200px"
                            spToColumnAndFullWidth
                          >
                            <LayoutBox
                              direction="column"
                              gap="1x"
                              minWidth="400px"
                              className="util-sp-full-width util-mt-8"
                              justify="end"
                              fullHeight
                            >
                              <Text size="xl">{invitationUserInfo?.name}</Text>
                              <Divider margin="0" darkColor />
                            </LayoutBox>
                          </SpFormSet>
                          <SpFormSet
                            label="キャンペーンコード"
                            base
                            labelSize="medium"
                            labelWidth="200px"
                            spToColumnAndFullWidth
                          >
                            <LayoutBox
                              direction="column"
                              gap="1x"
                              minWidth="400px"
                              className="util-sp-full-width util-mt-8"
                              justify="end"
                              fullHeight
                            >
                              <Text size="xl">なし</Text>
                              <Divider margin="0" darkColor />
                            </LayoutBox>
                          </SpFormSet>
                        </>
                      ) : (
                        <>
                          <LayoutBox direction="column" fullWidth>
                            <Text size="2xl" bold>
                              お支払い情報
                            </Text>
                            <Divider borderWidth="3px" margin="0" />
                          </LayoutBox>
                          <SpFormSet
                            label="カード情報"
                            base
                            labelSize="medium"
                            labelWidth="200px"
                            spToColumnAndFullWidth
                          >
                            <SpButton
                              type="primary"
                              width="400px"
                              size="small"
                              withArrowRight
                              mobileFullWidth
                              className="util-mt-8"
                              onClick={() => {
                                void handleEditCreditCard();
                              }}
                            >
                              カード情報を編集
                            </SpButton>
                          </SpFormSet>
                          <SpFormSet
                            label="キャンペーンコード"
                            base
                            labelSize="medium"
                            labelWidth="200px"
                            spToColumnAndFullWidth
                          >
                            <LayoutBox
                              direction="column"
                              gap="1x"
                              minWidth="400px"
                              className="util-sp-full-width util-mt-8"
                              justify="end"
                              fullHeight
                            >
                              <Text size="xl">{invitation}</Text>
                              <Divider margin="0" darkColor />
                            </LayoutBox>
                          </SpFormSet>
                          <SpFormSet
                            label="お支払いコース"
                            base
                            labelSize="medium"
                            labelWidth="200px"
                            spToColumnAndFullWidth
                          >
                            <LayoutBox
                              direction="column"
                              gap="1x"
                              minWidth="400px"
                              className="util-sp-full-width util-mt-8"
                              justify="end"
                              fullHeight
                            >
                              <Text size="xl">{planType}</Text>
                              <Divider margin="0" darkColor />
                            </LayoutBox>
                          </SpFormSet>
                          <SpFormSet
                            label="次回更新日"
                            base
                            labelSize="medium"
                            labelWidth="200px"
                            spToColumnAndFullWidth
                          >
                            <LayoutBox
                              direction="column"
                              gap="1x"
                              minWidth="400px"
                              className="util-sp-full-width util-mt-8"
                              justify="end"
                              fullHeight
                            >
                              <Text size="xl">{nextUpdateDateStr}</Text>
                              <Divider margin="0" darkColor />
                            </LayoutBox>
                          </SpFormSet>
                        </>
                      )}
                    </LayoutBox>
                  </Sheet>
                )}
              </LayoutBox>
              {isActive && (
                <LayoutBox
                  justify={isInvitationUser ? "center" : "between"}
                  gap="2x"
                  className="util-sp-full-width"
                  wrapReverse
                  minWidth="1064px"
                >
                  {!isInvitationUser && (
                    <SpButton
                      type="danger"
                      width="400px"
                      size="medium"
                      mobileFullWidth
                      icon="block"
                      iconSize="large"
                      onClick={handleWithdrawal}
                    >
                      退会
                    </SpButton>
                  )}
                  <SpButton
                    type="primary"
                    width="400px"
                    size="medium"
                    withArrowRight
                    mobileFullWidth
                    onClick={handleEdit}
                  >
                    編集
                  </SpButton>
                </LayoutBox>
              )}
            </LayoutBox>
          </Sheet>
          <div />
        </div>
        <PatientFooter />
      </div>
      {isDialogOpen && (
        <USG007
          onCloseBtnClicked={toggleIsDialogOpen}
          onUnsubscribeBtnClicked={removeMembership}
        />
      )}
    </>
  );
};

export default USG005;
