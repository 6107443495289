import React from "react";

export type LayoutBoxProps = {
  children: React.ReactNode;
  direction?: "row" | "column";
  gap?: "none" | "1/2x" | "1x" | "2x" | "3x" | "4x" | "5x" | "7x" | "10px";
  rowGap?: "none" | "1/2x" | "1x" | "2x" | "3x" | "4x" | "5x";
  align?: "start" | "center" | "end" | "stretch";
  justify?: "start" | "center" | "end" | "between" | "around";
  wrap?: boolean;
  wrapReverse?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  flexShrink?: number;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  className?: string;
  toColumn?: boolean;
  onClick?: () => void;
  minHeight?: string;
};

const LayoutBox = ({
  children,
  direction,
  gap,
  rowGap,
  align,
  justify,
  wrap,
  wrapReverse,
  fullWidth,
  fullHeight,
  flexShrink,
  width,
  minWidth,
  maxWidth,
  className = "",
  toColumn = false,
  onClick,
  minHeight,
}: LayoutBoxProps) => {
  const getLayoutBoxClass = React.useCallback(() => {
    const directionClass = direction ? [`layout-box--${direction}`] : [];
    const gapClass = gap ? [`layout-box--gap-${gap.replace("/", "_")}`] : [];
    const rowGapClass = rowGap
      ? [`layout-box--row-gap-${rowGap.replace("/", "_")}`]
      : [];
    const alignClass = align ? [`layout-box--align-${align}`] : [];
    const justifyClass = justify ? [`layout-box--justify-${justify}`] : [];
    const wrapClass = wrap ? ["layout-box--wrap"] : [];
    const wrapReverseClass = wrapReverse ? ["layout-box--wrap-reverse"] : [];
    const fullWidthClass = fullWidth ? ["layout-box--full-width"] : [];
    const fullHeightClass = fullHeight ? ["layout-box--full-height"] : [];
    const toColumnClass = toColumn ? ["layout-box--to-column"] : [];

    return [
      "layout-box",
      ...directionClass,
      ...gapClass,
      ...rowGapClass,
      ...alignClass,
      ...justifyClass,
      ...wrapClass,
      ...wrapReverseClass,
      ...fullWidthClass,
      ...fullHeightClass,
      ...toColumnClass,
    ].join(" ");
  }, [
    direction,
    rowGap,
    gap,
    align,
    justify,
    wrap,
    wrapReverse,
    fullWidth,
    fullHeight,
    toColumn,
  ]);

  return (
    <div
      className={`${getLayoutBoxClass()} ${className}`}
      style={{ flexShrink, width, minWidth, maxWidth, minHeight }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

LayoutBox.defaultProps = {
  direction: "row",
  gap: "none",
  rowGap: "none",
  align: "start",
  justify: "start",
  wrap: false,
  fullWidth: false,
  fullHeight: false,
  flexShrink: 1,
  width: "",
  maxWidth: "",
  minWidth: "",
  className: "",
  wrapReverse: undefined,
  toColumn: false,
  onClick: () => undefined,
  minHeight: "",
};

export default LayoutBox;
