import React from "react";
import LayoutBox from "../LayoutBox/LayoutBox";
import Text from "../Text/Text";
import useGetThumbnail from "../../hooks/common/useGetThumbnail";
import { mobileView } from "../../constants/common";

export type VideoThumbnailProps = {
  videoId: string;
  height?: string;
  width?: string;
  spWidth?: string;
  spHeight?: string;

  // changeEmp
  isPublic?: boolean; // trueで公開中
};

const VideoThumbnail = ({
  videoId,
  height = "100%",
  width = "100%",
  spWidth,
  spHeight,
  isPublic = false,
}: VideoThumbnailProps) => {
  // changeEmp
  const { imgUrl } = useGetThumbnail({ vimeoId: videoId });

  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileView);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {imgUrl && (
        <div
          className="video-thumbnail"
          style={{
            // changeEmp
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(${imgUrl})`,
            backgroundSize: `${
              spWidth !== undefined && isMobile ? spWidth : width
            } ${spHeight !== undefined && isMobile ? spHeight : height}`,
            width: spWidth !== undefined && isMobile ? spWidth : width,
            height: spHeight !== undefined && isMobile ? spHeight : height,
          }}
        >
          {/* changeEmp */}
          {!isPublic && (
            <LayoutBox fullWidth fullHeight align="center" justify="center">
              <Text color="white" size="xl" bold>
                公開は終了しました
              </Text>
            </LayoutBox>
          )}
        </div>
      )}
    </>
  );
};

VideoThumbnail.defaultProps = {
  height: "100%",
  width: "100%",
  spWidth: undefined,
  spHeight: undefined,

  // changeEmp
  isPublic: false,
};

export default VideoThumbnail;
