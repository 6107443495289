import React from "react";
import { format, getMonth, getYear } from "date-fns";
import { enUS, ja } from "date-fns/locale";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

export type MonthSelectProps = {
  value: Date;
  id?: number;
  isOpen: boolean;
  locale: "ja" | "en";
  dateFormat?: string;
  includeDates?: Date[];
  onChange: (date: Date) => void;
  onToggleOpen?: (value: boolean) => void;
};

const MonthSelect = ({
  id,
  value,
  isOpen,
  locale,
  dateFormat = "yyyy/MM",
  includeDates,
  onChange,
  onToggleOpen,
}: MonthSelectProps) => {
  const [selectedYear, setSelectedYear] = React.useState(getYear(value));
  const [disabledPrev, setDisabledPrev] = React.useState(false);
  const [disabledNext, setDisabledNext] = React.useState(false);

  React.useEffect(() => {
    setDisabledPrev(
      includeDates?.every((date) => getYear(date) >= selectedYear) ?? false,
    );
    setDisabledNext(
      includeDates?.every((date) => getYear(date) <= selectedYear) ?? false,
    );
  }, [selectedYear, includeDates]);

  const onPopoverToggleOpen = React.useCallback(
    (open: boolean) => {
      if (onToggleOpen) {
        onToggleOpen(open);
      }
    },
    [onToggleOpen],
  );

  const selectedValue = React.useMemo(
    () => format(value, dateFormat),
    [dateFormat, value],
  );

  const months = React.useMemo((): string[] => {
    if (locale === "ja") {
      return new Array(12).fill("").map(
        (_, i) =>
          ja.localize?.month(i, {
            width: "abbreviated",
          }) as string,
      );
    }

    return new Array(12)
      .fill("")
      .map(
        (_, i) => enUS?.localize?.month(i, { width: "abbreviated" }) as string,
      );
  }, [locale]);

  const decreaseYear = React.useCallback(
    () => setSelectedYear((prev) => prev - 1),
    [],
  );

  const increaseYear = React.useCallback(
    () => setSelectedYear((prev) => prev + 1),
    [],
  );

  const onClickItem = React.useCallback(
    (index: number) => {
      const monthOffset = id ? index + 2 - id : index + 1;
      const date = new Date(`${selectedYear}-${monthOffset}`);
      onChange(date);
      onPopoverToggleOpen(false);
    },
    [onChange, onPopoverToggleOpen, selectedYear, id],
  );

  const today = React.useMemo(() => new Date(), []);

  return (
    <div className="select-month">
      <button
        type="button"
        className="select-month_btn"
        onClick={() => onPopoverToggleOpen(!isOpen)}
      >
        {selectedValue}
        <Icon icon="arrow_drop_down" size="small" color="#777777" />
      </button>

      {isOpen && (
        <div className="select-month_popover-container">
          <div className="select-month_nav-header">
            <Button
              onClick={decreaseYear}
              type="sub"
              color="background-dark"
              size="small"
              icon="chevron_left"
              disabled={disabledPrev}
            />
            {selectedYear}
            <Button
              onClick={increaseYear}
              type="sub"
              color="background-dark"
              size="small"
              icon="chevron_right"
              disabled={disabledNext}
            />
          </div>
          <div className="select-month__item-list">
            {months.map((month, index) => {
              const disableMonth =
                includeDates?.some(
                  (date) =>
                    getMonth(date) === index && getYear(date) === selectedYear,
                ) ?? false;
              const isSelectedClass =
                getYear(value) === selectedYear && getMonth(value) === index
                  ? ["is-active"]
                  : [];
              const isCurrentClass =
                format(today, "yyyy/M") === `${selectedYear}/${index + 1}`
                  ? ["is-current"]
                  : [];
              const disableClass = !disableMonth ? ["is-disabled"] : [];
              const itemClass = [
                "select-month__item",
                ...isSelectedClass,
                ...isCurrentClass,
                ...disableClass,
              ].join(" ");

              return (
                <button
                  type="button"
                  className={itemClass}
                  onClick={() => onClickItem(index)}
                  key={month}
                  disabled={!disableMonth}
                >
                  {month}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

MonthSelect.defaultProps = {
  id: undefined,
  dateFormat: "yyyy/MM",
  onToggleOpen: undefined,
  includeDates: [],
};

export default MonthSelect;
