import React, { useCallback, useEffect, useState } from "react";
import HeaderUser from "../components/Header/HeaderUser";
import Title from "../components/Title/Title";
import Text from "../components/Text/Text";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import {
  useNavigateWithBack,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import {
  endOfPeriodForMri,
  getUserYearSinceActivation,
  isActiveUser,
} from "../utils/utils";
import { useRealmApp } from "../contexts/RealmApp";
import Sheet from "../components/Sheet/Sheet";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import SpButton from "../components/Button/SpButton";
import Divider from "../components/Divider/Divider";
import Notification from "../components/Notification/Notification";
import {
  MriStatusID,
  PatientNouKnowStatusID,
  USC_021_TAB_ITEMS,
} from "../constants/common";
import usePatientNouknowsFetch from "../hooks/common/usePatientNouknowsFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useUsc007Fetch from "../hooks/pages/USC007/useUsc007Fetch";
import usePatientMriFetch from "../hooks/common/usePatientMriFetch";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import BrainSuite from "../assets/brain_suite.png";
import InformationDialog from "../components/Dialog/InformationDialog";
import ExternalMedicalInstitutionsButton from "../components/Button/ExternalMedicalInstitutionsButton";
import usePath from "../hooks/common/usePath";
import useNouknowEligibility from "../hooks/common/useNouknowEligibility";
import useUsc024Actions from "../hooks/pages/USC024/useUsc024DBActions";

const USC024 = () => {
  const realmAppContext = useRealmApp();
  const navigate = useNavigateWithUrl();
  const isActive = isActiveUser(realmAppContext.currentUser);
  const [selectedButton, setSelectedId] = React.useState<string | number>("1");
  const [nouKnowUrl, setNouKnowUrl] = useState("");
  const { isOnlineTest, onlineTestUrl } = useUsc007Fetch();
  const { updateMriPetCancel } = useUsc024Actions();
  const [fixBookMinutes, setFixBookMinutes] = useState(0);
  const [mriCancel, setMriCancel] = useState(false);
  const [path] = usePath();
  const navigateWithBack = useNavigateWithBack(path);
  const { isNouknowsEligible } = useNouknowEligibility();

  // 何年目のユーザか
  const userYearSinceActivation = getUserYearSinceActivation(
    realmAppContext.currentUser,
  );

  const { patientMri, error: mriGetError } = usePatientMriFetch(
    userYearSinceActivation,
  );
  const { patientNouknows, error: nouknowError } = usePatientNouknowsFetch();

  // エラー処理
  useCheckErrorThrowError([nouknowError, mriGetError]);
  const [isOpen, setIsOpen] = React.useState(false);

  // キャンセルダイアログclose
  const handleCloseCancelConfirm = () => {
    setIsOpen(false);
  };

  // キャンセルダイアログopen
  const handleCancelConfirm = () => {
    setIsOpen(true);
  };

  // Top画面に遷移
  const handleClickBack = useCallback(
    () => navigateWithBack(),
    [navigateWithBack],
  );

  // タブの切り替え
  const handleTabChange = useCallback(
    (selected: string | number) => {
      setSelectedId(selected);
      if (selected === "2") navigate(PATIENT.USC026);
    },
    [navigate],
  );

  // のうKNOWテスト
  useEffect(() => {
    if (!patientNouknows.length) {
      return;
    }

    // 期間内でテスト未実施のデータが対象
    const enabledPatientNouknows = patientNouknows.filter((item) => {
      const now = new Date();

      return (
        item.status === PatientNouKnowStatusID.PRETEST &&
        now >= item.exam_start &&
        now <= item.exam_end
      );
    });

    if (enabledPatientNouknows.length === 0) {
      return;
    }

    setNouKnowUrl(enabledPatientNouknows[0].nouknow_url);
  }, [patientNouknows]);

  // MRI検査時間取得
  useEffect(() => {
    if (!patientMri.length) {
      return;
    }

    if (patientMri[0].fix_book_date_start && patientMri[0].fix_book_date_end) {
      const startDate = new Date(patientMri[0].fix_book_date_start);
      const endDate = new Date(patientMri[0].fix_book_date_end);
      const today = new Date();

      // 検査時間が確定している場合差を計算
      const timeDifferenceInMs = endDate.getTime() - startDate.getTime();
      const timeDifferenceInMinutes = Math.floor(
        timeDifferenceInMs / (1000 * 60),
      );

      // 8日前以降予約キャンセル不可
      const eightDaysInMs = 7 * 24 * 60 * 60 * 1000;
      const timeMriCancel = startDate.getTime() - today.getTime();
      const isValidForCancel = timeMriCancel <= eightDaysInMs;
      setFixBookMinutes(Number(timeDifferenceInMinutes));
      setMriCancel(isValidForCancel);
    }
  }, [patientMri, patientMri.length, patientNouknows]);

  // テスト開始画面に遷移
  const handleNavigateTestStart = () => {
    window.open(nouKnowUrl, "_blank");
  };

  // MRI受検可能か
  const isMriPossible = () => {
    // 当年度のMRIのデータが存在する場合、受検不可
    if (patientMri.length > 0) {
      return false;
    }

    // オンラインテスト未受検の場合、受検不可
    if (!isOnlineTest) {
      return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // attempts を取得
    const attempts = parseInt(
      process.env.REACT_APP_NOUKNOW_ATTEMPTS || "4",
      10,
    );

    // のうKnow4回目が実施されているか
    // POCの場合のうKnow1回目が実施されているかどうか
    const isValidItem = (item: {
      status: number;
      count_of_nouknow: number;
    }) => {
      const countCondition = attempts === 1 ? 1 : attempts === 4 ? 4 : 0;

      return (
        ![
          PatientNouKnowStatusID.PRETEST,
          PatientNouKnowStatusID.CANCELLATION,
        ].includes(item.status) &&
        item.count_of_nouknow >= 1 &&
        item.count_of_nouknow <= countCondition
      );
    };

    const validNouknows = patientNouknows.filter(isValidItem);
    if (validNouknows.length === 0) {
      return false;
    }

    const activationDateObject = realmAppContext.currentUser?.customData
      .activation_date as CustomDateType;
    const activationDate = new Date(
      Number(activationDateObject.$date.$numberLong),
    );
    activationDate.setHours(0, 0, 0, 0);

    // アクティベート日付にPET受検可能期間を加算
    activationDate.setDate(
      activationDate.getDate() + endOfPeriodForMri(realmAppContext.currentUser),
    );

    if (today <= activationDate) {
      return true;
    }

    return false;
  };

  // MRI検査のボタン
  const renderMriContent = (
    onClick: () => void | undefined,
    disabled: boolean,
    icon: string,
    content: string,
  ) => {
    const buttonType = icon === "block" ? "danger" : "primary";

    return (
      <SpButton
        size="medium"
        mobileFullWidth
        onClick={onClick}
        disabled={disabled}
        icon={icon}
        type={buttonType}
      >
        {content}
      </SpButton>
    );
  };

  // MRI検査のカードコンテンツ
  const renderMri = (
    patientMri: PatientMri[],
    navigate: (path: string) => void,
    isMriPossible: boolean,
  ) => {
    let icon;
    let content;
    let onClick;
    let disabled;
    if (patientMri.length) {
      const mriObj = patientMri[0];

      switch (mriObj.mri_status) {
        case MriStatusID.RESERVED:
          icon = "block";
          content = "予約取消";
          onClick = handleCancelConfirm;
          disabled = false;
          break;
        case MriStatusID.RESERVATION_CONFIRMED:
          icon = "block";
          content = "予約取消";
          onClick = handleCancelConfirm;
          disabled = mriCancel;
          break;
        case MriStatusID.DONE_IMAGE_NOT_LINKED:
        case MriStatusID.DONE_AI_DIAGNOSING:
        case MriStatusID.DONE_AI_DIAGNOSED:
        case MriStatusID.DONE_DOCTOR_DIAGNOSED:
          icon = "block";
          content = "予約取消";
          disabled = true;
          break;
        case MriStatusID.USER_REPORTED:
        case MriStatusID.NO_EXAMINATION:
          icon = "";
          content = "予約する";
          disabled = true;
          break;
        default:
          icon = "";
          content = "予約する";
          onClick = () => navigate(PATIENT.USC007);
          disabled = false;
          break;
      }
    } else {
      icon = "";
      content = "予約する";
      onClick = () => navigate(PATIENT.USC007);
      disabled = !isMriPossible;
    }

    return renderMriContent(onClick ?? (() => {}), disabled, icon, content);
  };

  const handleClickCancel = (mriId: string) => {
    void (async () => {
      const isSuccess = await updateMriPetCancel(mriId, "", true);
      if (!isSuccess) return;
      navigate(PATIENT.USC039);
      window.location.reload(); // ヘッダーを無理やり更新
    })();
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
          isActiveUser={isActive}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="検査"
          content="各種検査の受検と結果の確認ができます"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <ToggleButton
                size="large"
                width="100%"
                items={USC_021_TAB_ITEMS.slice(0, 2)}
                onClick={handleTabChange}
                selectedButton={selectedButton}
              />
            </LayoutBox>
            <Title
              type="h2"
              header="MRI検査＋AI解析"
              borderBottom="primaryLight"
              withButton={false}
            />
          </LayoutBox>
        </Sheet>
        <LayoutBox direction="column" gap="3x" fullWidth>
          <Sheet>
            <Sheet type="card" maxWidth="1064px">
              <LayoutBox direction="column" fullWidth align="center" gap="3x">
                <LayoutBox direction="column" fullWidth align="start" gap="2x">
                  <LayoutBox direction="row" align="center" gap="2x" fullWidth>
                    <img
                      src={BrainSuite}
                      alt="nouknow"
                      className="image--brain_suite"
                    />
                    <Text size="xl">MRI検査＋BrainSuite®</Text>
                  </LayoutBox>
                  <Divider darkColor orange={false} />
                  {patientMri[0]?.mri_status === MriStatusID.USER_REPORTED ? (
                    <Notification text="受検期間外です" colorType="warning" />
                  ) : null}
                  <Text size="xl">年に1回受検してください。</Text>
                  <Text size="base" color="neutralUserLight">
                    ※ご予約前に「のうKNOW®」とオンライン問診を受けてください。
                  </Text>
                  <Text bold size="xl">
                    検査の前に
                  </Text>
                  <Sheet
                    padding="16px"
                    color="neutral-lightest"
                    type="gray-sp"
                    maxHeight="300px"
                    borderRadius="8px"
                    border
                    overflow
                  >
                    <LayoutBox justify="between" align="center">
                      <Text bold size="xl">
                        のうKNOW®（オンライン認知機能検査）
                      </Text>
                      <SpButton
                        size="small"
                        type="secondary"
                        onClick={handleNavigateTestStart}
                        disabled={!isNouknowsEligible || nouKnowUrl === ""}
                      >
                        {nouKnowUrl === "" || !isActive
                          ? "受検済み"
                          : "受検する"}
                      </SpButton>
                    </LayoutBox>
                  </Sheet>
                  <Sheet
                    padding="16px"
                    type="gray-sp"
                    maxHeight="300px"
                    borderRadius="8px"
                    border
                    overflow
                  >
                    <LayoutBox justify="between" align="center">
                      <Text bold size="xl">
                        オンライン問診
                      </Text>
                      <SpButton
                        size="small"
                        type="secondary"
                        onClick={
                          isOnlineTest
                            ? () => {}
                            : () => window.open(onlineTestUrl, "_blank")
                        }
                        disabled={isOnlineTest}
                      >
                        {isOnlineTest ? "受検済み" : "受検する"}
                      </SpButton>
                    </LayoutBox>
                  </Sheet>
                  <Text bold size="xl">
                    医療機関の予約
                  </Text>
                  <Text size="xl">MRI検査＋BrainSuite®（AI解析）</Text>
                </LayoutBox>
                {patientMri[0]?.mri_status === MriStatusID.RESERVED ? (
                  <LayoutBox
                    direction="column"
                    fullWidth
                    align="start"
                    gap="2x"
                  >
                    <Notification
                      text="現在予約を調整中です。"
                      colorType="warning"
                    />
                    <Text size="xl" color="neutralUserLight">
                      都道府県
                    </Text>
                    <Text size="xl">{patientMri[0]?.prefecture}</Text>
                    <Divider darkColor orange={false} />
                    <Text size="xl" color="neutralUserLight">
                      提携医療機関
                    </Text>
                    <Text size="xl">{patientMri[0]?.hospital_name}</Text>
                    <Divider darkColor orange={false} />
                    <Text size="xl" bold>
                      第一候補
                    </Text>
                    <Text size="xl">
                      {patientMri[0]?.candidate_book_dates.first}の間に来院
                    </Text>
                    <Divider darkColor orange={false} />
                    <Text size="xl" bold>
                      第ニ候補
                    </Text>
                    <Text size="xl">
                      {patientMri[0]?.candidate_book_dates.second}の間に来院
                    </Text>
                    <Divider darkColor orange={false} />
                    <Text size="xl" bold>
                      第三候補
                    </Text>
                    <Text size="xl">
                      {patientMri[0]?.candidate_book_dates.third}の間に来院
                    </Text>
                  </LayoutBox>
                ) : null}
                {patientMri[0]?.mri_status ===
                  MriStatusID.RESERVATION_CONFIRMED ||
                patientMri[0]?.mri_status ===
                  MriStatusID.DONE_IMAGE_NOT_LINKED ||
                patientMri[0]?.mri_status === MriStatusID.DONE_AI_DIAGNOSING ||
                patientMri[0]?.mri_status === MriStatusID.DONE_AI_DIAGNOSED ||
                patientMri[0]?.mri_status ===
                  MriStatusID.DONE_DOCTOR_DIAGNOSED ? (
                  <LayoutBox
                    direction="column"
                    fullWidth
                    align="start"
                    gap="2x"
                  >
                    <Text size="xl" color="neutralUserLight">
                      都道府県
                    </Text>
                    <Text size="xl">{patientMri[0]?.prefecture}</Text>
                    <Divider darkColor orange={false} />
                    <Text size="xl" color="neutralUserLight">
                      提携医療機関
                    </Text>
                    <Text size="xl">{patientMri[0]?.hospital_name}</Text>
                    <Divider darkColor orange={false} />
                    <Text size="xl" color="neutralUserLight">
                      検査時間
                    </Text>
                    <Text size="xl">
                      {patientMri[0]?.fix_book_date}（{fixBookMinutes}分）
                    </Text>
                  </LayoutBox>
                ) : null}
                {patientMri.length === 0 ||
                patientMri[0].mri_status === MriStatusID.USER_REPORTED ? (
                  <ExternalMedicalInstitutionsButton />
                ) : null}
                {isActive ? (
                  renderMri(patientMri, navigate, isMriPossible())
                ) : (
                  <SpButton
                    size="medium"
                    mobileFullWidth
                    withArrowRight
                    disabled
                  >
                    予約する
                  </SpButton>
                )}
              </LayoutBox>
            </Sheet>
          </Sheet>
        </LayoutBox>
      </div>
      <PatientFooter />
      {isOpen && (
        <InformationDialog
          open={isOpen}
          closeDialog={handleCloseCancelConfirm}
          title="予約取消"
          isShowCloseButton={false}
          alertLevel="error"
          width="350px"
          footer={
            <>
              <LayoutBox justify="center" wrapReverse gap="2x">
                <SpButton
                  type="danger"
                  width="275px"
                  onClick={() => handleClickCancel(patientMri[0]._id)}
                >
                  予約取消
                </SpButton>
                <SpButton
                  type="secondary"
                  width="275px"
                  onClick={handleCloseCancelConfirm}
                >
                  キャンセル
                </SpButton>
              </LayoutBox>
            </>
          }
        >
          <LayoutBox align="center">
            <LayoutBox
              direction="column"
              gap="none"
              align="start"
              justify="start"
            >
              <Text size="xl">
                MRI検査＋AI解析の予約を取り消しますがよろしいですか？
              </Text>
            </LayoutBox>
          </LayoutBox>
        </InformationDialog>
      )}
    </div>
  );
};

export default USC024;
