import React from "react";

const WavyBackground = () => (
  <svg width="0" height="0">
    <defs>
      <clipPath id="WavyBackground" clipPathUnits="objectBoundingBox">
        <path d="M0,-0.008 C0.333,-0.008,0.666,-0.008,1,-0.008 C1,0.008,1,0.023,1,0.038 C0.942,0.29,0.884,0.513,0.825,0.708 C0.767,0.912,0.709,0.999,0.65,0.969 C0.625,0.948,0.6,0.891,0.575,0.8 C0.551,0.702,0.526,0.6,0.502,0.492 C0.472,0.352,0.441,0.278,0.41,0.269 C0.383,0.272,0.356,0.336,0.33,0.462 C0.301,0.602,0.271,0.735,0.242,0.862 C0.224,0.928,0.205,0.972,0.187,0.992 C0.181,0.992,0.176,0.992,0.17,0.992 C0.141,0.965,0.113,0.891,0.085,0.769 C0.056,0.635,0.028,0.483,0,0.315 C0,0.208,0,0.1,0,-0.008" />
      </clipPath>
    </defs>
  </svg>
);

export default WavyBackground;
