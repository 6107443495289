import React from "react";

export type TableColumnProps = {
  id?: string;
  textAlign?: "left" | "right" | "center" | "justify";
  textSize?: "medium" | "small";
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  sortedDirection?: "asc" | "desc" | string;
  stickyLeft?: string;
  stickyRight?: string;
  onClickSort?: (sortDirection: "asc" | "desc" | "", id: string) => void;
  onCheckFilter?: (value: string) => void;
  children?: React.ReactNode;
};

const TableColumn = ({
  id = "",
  textAlign = "left",
  textSize = "medium",
  width = "auto",
  minWidth,
  maxWidth,
  height,
  sortedDirection,
  stickyLeft,
  stickyRight,
  onClickSort,
  onCheckFilter,
  children,
}: TableColumnProps) => {
  const _onClickSort = React.useCallback(() => {
    const sortDirection =
      sortedDirection === "asc"
        ? "desc"
        : sortedDirection === "desc"
        ? "asc"
        : "desc";

    if (onClickSort) {
      onClickSort(sortDirection, id);
    }
  }, [onClickSort, id, sortedDirection]);

  const _onCheckFilter = React.useCallback(
    (filterValue: string) => {
      if (onCheckFilter) {
        onCheckFilter(filterValue);
      }
    },
    [onCheckFilter],
  );

  const tableCellClass = React.useMemo(() => {
    const sizeClass = [`table__cell--${textSize}`];
    const stickyClass = stickyLeft
      ? ["table__cell--sticky"]
      : stickyRight
      ? ["table__cell--sticky table__cell--sticky-right"]
      : [];

    return ["table__cell", ...sizeClass, ...stickyClass].join(" ");
  }, [stickyLeft, stickyRight, textSize]);

  return (
    <th
      className={tableCellClass}
      style={{
        textAlign,
        minWidth,
        width,
        maxWidth,
        height,
        left: stickyLeft,
        right: stickyRight,
      }}
      id={`table-column-${id}`}
    >
      <div className="table__cell-inner">
        <div
          className="table__cell-inner-text"
          style={{
            textAlign,
            width: "100%",
          }}
        >
          {children}
        </div>
      </div>
    </th>
  );
};

TableColumn.defaultProps = {
  id: "",
  textAlign: "left",
  textSize: "medium",
  width: "auto",
  minWidth: "",
  maxWidth: "",
  height: "",
  sortedDirection: "",
  stickyLeft: "",
  stickyRight: "",
  onClickSort: undefined,
  onCheckFilter: undefined,
  children: undefined,
};

export default TableColumn;
