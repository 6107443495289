import React from "react";

export type SimpleCardProps = {
  children: React.ReactNode;
  type?: "primary" | "secondary";
  // changeEmp
  onClick?: () => void;
};

const SimpleCard = ({
  children,
  type = "primary",
  // changeEmp
  onClick,
}: SimpleCardProps) => {
  const simpleCardClass = React.useMemo(() => {
    const typeClass = [`simple-card--${type}`];

    return ["simple-card", ...typeClass].join(" ");
  }, [type]);

  return (
    <>
      <div className={simpleCardClass} onClick={onClick}>
        {children}
      </div>
    </>
  );
};

SimpleCard.defaultProps = {
  type: "primary",
  // changeEmp
  onClick: undefined,
};

export default SimpleCard;
