import { useState } from "react";
import { useRealmApp } from "../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";

const useGetSignedUrl = () => {
  const [actionError, setActionError] = useState<Error | null>(null);
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  /**
   *  GCSのpathから著名付きのURLを返します。
   */
  const getSignedUrl = async (props: { fileGcsPath: string }) => {
    const { fileGcsPath } = props;
    addLoadCount();

    try {
      const signedUrl = (await currentUser?.functions.getSignUrlApi(
        fileGcsPath,
      )) as string;

      return signedUrl;
    } catch (err) {
      setActionError(checkActionErr(err));

      return undefined;
    } finally {
      decrementLoadCount();
    }
  };

  return {
    getSignedUrl,
    actionError,
  };
};

export default useGetSignedUrl;
