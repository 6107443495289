import React from "react";

export type SpDropdownItemProps = {
  label: string;
  value: string;
  isSelected: boolean;
  isFocused?: boolean;
  selectItem: (value: string) => void;
};

const SpDropdownItem = ({
  label,
  value,
  isSelected,
  isFocused,
  selectItem,
}: SpDropdownItemProps) => {
  const SpDropdownItemClass = React.useMemo(() => {
    const selectedClass = isSelected ? ["sp-dropdown-item--selected"] : [];
    const focusedClass = isFocused ? ["sp-dropdown-item--focused"] : [];

    return ["sp-dropdown-item", ...selectedClass, ...focusedClass].join(" ");
  }, [isFocused, isSelected]);

  const selectOptionInner = React.useCallback(() => {
    selectItem(value);
  }, [selectItem, value]);

  return (
    <div
      className={SpDropdownItemClass}
      onClick={(e) => {
        // changeEmp
        e.stopPropagation();
        selectOptionInner();
      }}
    >
      {label}
    </div>
  );
};

SpDropdownItem.defaultProps = {
  isFocused: false,
};

export default SpDropdownItem;
