import React from "react";
import Checkbox from "./Checkbox";

export type CheckboxGroupProps = {
  direction?: "row" | "column";
  gap?: string;
  withContainer: boolean;
  width?: string;
  items?: {
    label: React.ReactNode;
    value: string;
    checked: boolean;
    disabled?: boolean;
    className?: string;
  }[];
  onChecked: (value: string) => void;
};

const CheckboxGroup = ({
  direction = "column",
  items = [],
  onChecked,
  withContainer,
  gap = "16px",
  width = "100%",
}: CheckboxGroupProps) => {
  const hasItemChecked = items.some((item) => item.checked);

  const checkboxClass = React.useMemo(() => {
    const directionClass = [`checkbox-group--${direction}`];
    const hasItemCheckedClass = hasItemChecked
      ? [`checkbox-group--hasChecked`]
      : [];

    return [`checkbox-group`, ...directionClass, ...hasItemCheckedClass].join(
      " ",
    );
  }, [direction, hasItemChecked]);

  return (
    <div className={checkboxClass} style={{ gap, width }}>
      {items !== undefined &&
        // changeEmp add key
        items.map((item, index) => (
          <Checkbox
            {...item}
            key={index}
            label={item.label}
            onChecked={() => onChecked(item.value)}
            withContainer={withContainer}
            checked={item.checked}
            disabled={item.disabled}
          />
        ))}
    </div>
  );
};

CheckboxGroup.defaultProps = {
  direction: "column",
  items: [],
  gap: "16px",
  width: "100%",
};

export default CheckboxGroup;
