import { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import SpButton from "../components/Button/SpButton";
import List from "../components/List/List";
import Icon from "../components/Icon/Icon";
import Badge from "../components/Badge/Badge";
import Spinner from "../components/Loading/Spinner";
import useUsj001Dialog, {
  closeUsj001Dialog,
} from "../hooks/pages/USJ001/useUsj001Dialog";
import useUsj001Fetch from "../hooks/pages/USJ001/useUsj001Fetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { PatientMessageRelationshipStatusID } from "../constants/common";
import USJ002 from "./US-J002";
import useUsj002Dialog, {
  openUsj002Dialog,
} from "../hooks/pages/USJ002/useUsj002Dialog";
import DialogCustom from "../components/Dialog/DialogCustom";
import useUsk001Dialog from "../hooks/pages/USK001/useUsk001Dialog";
import USK001 from "./US-K001";

const USJ001 = () => {
  const [isMessageListDialog] = useUsj001Dialog();
  const [isHeadCheckDialog] = useUsk001Dialog();
  const [isMessageDetailDialog] = useUsj002Dialog();
  const { patientMessages, error, isLoaded } = useUsj001Fetch();
  const perItems = 20;
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [records, setRecords] = useState(perItems);

  const [messageId, setMessageId] = useState("");

  useCheckErrorThrowError([error]);

  // メッセージリスト作成
  const listItems = patientMessages.map((message) => ({
    id: message._id,
    left: <></>,
    center: (
      <>
        <LayoutBox gap="1/2x" fullWidth direction="column">
          <LayoutBox gap="1x" fullWidth>
            <Text size="base" color="neutralLight">
              {message.created_at}{" "}
            </Text>
            {message.status === PatientMessageRelationshipStatusID.UNREAD && (
              <Badge color="information">NEW</Badge>
            )}
          </LayoutBox>
          <Text size="large" bold>
            {message.subject}
          </Text>
        </LayoutBox>
      </>
    ),
    right: (
      <>
        <Icon icon="expand_circle_right" type="outlined" color="#CF4900" />
      </>
    ),
  }));

  // 読み込み表示
  const loadMore = () => {
    if (records >= listItems.length) {
      setHasMoreItems(false);
    } else {
      setTimeout(() => {
        setRecords(records + perItems);
      }, 1000);
    }
  };

  // メッセージ押下
  const handleClickMessage = (id: string) => {
    setMessageId(id);
    openUsj002Dialog();
  };

  return (
    <>
      <DialogCustom
        open={isMessageListDialog}
        closeDialog={() => closeUsj001Dialog()}
        title="メッセージ"
        isShowCloseButton={false}
        height="100%"
        isMobile
        paddingFooter="16px"
        paddingBody="16px 0"
        footerRight={
          <SpButton
            size="medium"
            type="secondary"
            width="100%"
            onClick={() => closeUsj001Dialog()}
          >
            閉じる
          </SpButton>
        }
      >
        {!isLoaded && (
          <LayoutBox gap="5x" fullWidth direction="column" align="center">
            <div className="util-flex util-flex--align-center util-flex--justify-center util-mt-16 util-mb-16">
              <Spinner size="medium" color="gray" />
            </div>
          </LayoutBox>
        )}
        {listItems.length > 0 ? (
          <div className="util-full-width util-overflow-y">
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={hasMoreItems}
              loader={
                <div
                  key="infinite-scroll-loader"
                  className="util-flex util-flex--align-center util-flex--justify-center util-mt-16 util-mb-16"
                >
                  <Spinner size="medium" color="gray" />
                </div>
              }
              useWindow={false}
            >
              <List
                items={listItems}
                maxLoad={records}
                selectedIds={[]}
                onClick={handleClickMessage}
              />
            </InfiniteScroll>
          </div>
        ) : (
          isLoaded && (
            <LayoutBox gap="5x" fullWidth direction="column" align="center">
              <div style={{ marginTop: "10%" }}>
                <Text size="large" color="neutralLight">
                  表示する結果がありません
                </Text>
              </div>
            </LayoutBox>
          )
        )}
      </DialogCustom>
      {isMessageDetailDialog && <USJ002 messageId={messageId} />}
      {isHeadCheckDialog && <USK001 messageId={messageId} />}
    </>
  );
};

export default USJ001;
