import { useCallback, useMemo } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import List from "../components/List/List";
import Divider from "../components/Divider/Divider";
import Image from "../components/Image/Image";
import SpButton from "../components/Button/SpButton";
import Title from "../components/Title/Title";

import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import {
  useNavigateWithBack,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import usePatientInfoFetch from "../hooks/common/usePatientInfoFetch";
import useUsf011DBActions from "../hooks/pages/USF011/useUsf011DBActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { openUsf006Dialog } from "../hooks/pages/USF006/useUsf006Dialog";
import { openUsf003Dialog } from "../hooks/pages/USF003/useUsf003Dialog";
import USF003 from "./US-F003";
import USF006 from "./US-F006";
import { addHyphenToZipcode } from "../utils/utils";
import PatientFooter from "../features/PatientFooter";

const USF011 = () => {
  const {
    orderInfo,
    orderCancel,
    error: orderError,
    actionError,
  } = useUsf011DBActions();
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  const handleGoBack = useNavigateWithBack(PATIENT.USF001);
  const navigateWithUrl = useNavigateWithUrl();
  useCheckErrorThrowError([orderError, patientInfoError, actionError]);

  const handleCancelClick = useCallback(async () => {
    await orderCancel();
    navigateWithUrl(PATIENT.USF012);
  }, [navigateWithUrl, orderCancel]);

  const taxAmount = useMemo(() => {
    const reducer = (sum: number, currentValue: number) => sum + currentValue;

    return orderInfo?.total_amount_per_tax
      .map((taxObj) => taxObj.tax)
      .reduce(reducer);
  }, [orderInfo?.total_amount_per_tax]);

  const cardList = useMemo(
    () =>
      orderInfo?.transactions_info.map((transactionInfo) => ({
        id: transactionInfo._id,
        left: (
          <>
            <Image
              url={transactionInfo.skus_info.image_urls[0]}
              width="88px"
              height="88px"
            />
          </>
        ),
        center: (
          <>
            <LayoutBox gap="1/2x" fullWidth direction="column">
              <Text size="xl" bold>
                {transactionInfo.item_name}
              </Text>
              <Text size="large">{transactionInfo.sku_name}</Text>
              <div className="util-mb-4" />
              <LayoutBox fullWidth>
                <Text size="2xl" bold>
                  {(transactionInfo.total_amount ?? 0).toLocaleString()}
                </Text>
                <Text size="xl" bold>
                  円
                </Text>
              </LayoutBox>
              <div className="util-mt-4" />
              <Text size="large">数量：{transactionInfo.quantity}</Text>
            </LayoutBox>
          </>
        ),
        right: <></>,
      })),
    [orderInfo?.transactions_info],
  );

  const handleClickCart = useCallback(() => openUsf006Dialog(), []);
  const handleClickAddress = useCallback(() => openUsf003Dialog(), []);

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation minWidth="1076px" selectedTab={SELECTED_TAB_NUM.shop} />
        <Sheet
          padding="16px 108px"
          color="secondary-lightest"
          minWidth="1076px"
        >
          <LayoutBox justify="between" width="1064px">
            <div onClick={handleClickAddress} style={{ cursor: "pointer" }}>
              <LayoutBox direction="column" gap="1/2x" minWidth="0">
                <Text color="neutralLight" size="large">
                  お届け先
                </Text>
                <Text size="xl" width="100%" ellipsis>
                  {addHyphenToZipcode(patientInfo?.zipcode)} {patientInfo?.pref}
                  {patientInfo?.city}
                  {patientInfo?.town1}
                  {patientInfo?.town2}
                </Text>
              </LayoutBox>
            </div>
            <LayoutBox minWidth="0">
              <SpButton
                type="shop"
                size="small"
                width="140px"
                badge={patientInfo?.cart_info?.item_num}
                onClick={handleClickCart}
              >
                カート
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <Title
          padding="16px 108px 24px 108px"
          background="rough-blue"
          header="注文キャンセル"
          content="以下の注文をキャンセルしますがよろしいですか。"
          onClickBack={handleGoBack}
          minWidth="1076px"
        />
        <Sheet padding="40px 108px" minWidth="1076px">
          <LayoutBox direction="column" gap="2x" width="1064px">
            <LayoutBox gap="2x" fullWidth direction="column">
              <Sheet type="border" color="white" borderRadius="8px">
                <LayoutBox gap="2x" fullWidth direction="column">
                  <Sheet color="yellow" borderRadius="8px">
                    <LayoutBox fullWidth gap="2x" direction="column">
                      <LayoutBox fullWidth justify="between">
                        <Text size="xl">合計</Text>
                        <Text size="xl" bold>
                          {(
                            (orderInfo?.total_item_amount ?? 0) +
                            (orderInfo?.delivery_fee ?? 0)
                          ).toLocaleString()}
                          円
                        </Text>
                      </LayoutBox>
                      <Divider orange borderWidth="1px" margin="0" />
                      <LayoutBox fullWidth justify="between">
                        <Text size="large">商品の小計</Text>
                        <Text size="large">
                          {orderInfo?.total_item_amount}円
                        </Text>
                      </LayoutBox>
                      <LayoutBox fullWidth justify="between">
                        <Text size="large">配送料</Text>
                        <Text size="large">{orderInfo?.delivery_fee}円</Text>
                      </LayoutBox>
                      <LayoutBox fullWidth justify="between">
                        <Text size="large">内税</Text>
                        <Text size="large">
                          {(taxAmount ?? 0).toLocaleString()}円
                        </Text>
                      </LayoutBox>
                    </LayoutBox>
                  </Sheet>
                  <List items={cardList ?? []} selectedIds={[""]} />
                </LayoutBox>
              </Sheet>
              <LayoutBox fullWidth justify="center">
                <SpButton
                  width="316px"
                  withArrowRight
                  size="medium"
                  onClick={() => {
                    void handleCancelClick();
                  }}
                >
                  注文をキャンセルする
                </SpButton>
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
      <USF006 />
      <USF003 />
    </div>
  );
};

export default USF011;
