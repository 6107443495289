import React from "react";
import Button from "../Button/Button";

export type TableProps = {
  type?: "condensed" | "regular" | "relaxed";
  width?: string;
  height?: string;
  fixedLayout?: boolean;
  colGroups?: JSX.Element;
  head: JSX.Element | string;
  body: JSX.Element | JSX.Element[] | string;
  footer?: JSX.Element | string;
  shortcuts?: {
    id: string;
    label: string;
  }[];
};

const Table = ({
  type = "regular",
  width = "",
  height = "auto",
  fixedLayout = false,
  colGroups,
  head,
  body,
  footer,
  shortcuts = [],
}: TableProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const tableRef = React.useRef<HTMLTableElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  const tableClass = React.useMemo(() => {
    const fixedClass = fixedLayout ? [`table--fixed-layout`] : [];
    const typeClass = type ? [`table--${type}`] : [];
    const overflowingClass = isOverflowing ? [`table--overflowing`] : [];

    return ["table", ...fixedClass, ...typeClass, ...overflowingClass].join(
      " ",
    );
  }, [fixedLayout, isOverflowing, type]);

  const checkOverflow = React.useCallback(() => {
    const container = containerRef.current;
    const table = tableRef.current;
    if (container && table) {
      const containerWidth = container.clientWidth;
      const tableWidth = table.clientWidth;
      if (containerWidth < tableWidth) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }
  }, []);

  const onResize = React.useCallback(() => {
    checkOverflow();
  }, [checkOverflow]);

  React.useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  const onClickShortcut = React.useCallback((id: string) => {
    const tableEl = document.getElementById(`table-column-${id}`);
    if (tableEl) {
      tableEl.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, []);

  return (
    <>
      {shortcuts.length > 0 && (
        <div className="table-shortcuts" style={{ width }}>
          <span> ショートカット </span>
          <span className="table-shortcuts__buttons">
            {shortcuts.map((shortcut) => (
              <Button
                key={`shortcut-${shortcut.id}`}
                icon="shortcut"
                type="sub"
                size="small"
                onClick={() => onClickShortcut(shortcut.id)}
              >
                {shortcut.label}
              </Button>
            ))}
          </span>
        </div>
      )}

      <div
        ref={containerRef}
        className="table-container"
        style={{ width, height }}
      >
        <table ref={tableRef} className={tableClass}>
          {colGroups !== undefined && colGroups}
          <thead>{head}</thead>
          <tbody>{body}</tbody>
        </table>
      </div>
      {footer && (
        <div className="table-footer" style={{ width }}>
          {footer}
        </div>
      )}
    </>
  );
};

Table.defaultProps = {
  type: "regular",
  width: "",
  height: "auto",
  fixedLayout: false,
  colGroups: undefined,
  footer: undefined,
  shortcuts: [],
};

export default Table;
