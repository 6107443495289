import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  selectedYear: string;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  selectedYear: "All",
});

const useUsc001Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setSelectedYear = (year: string) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    selectedYear: year,
  });
};

export default useUsc001Conditions;
