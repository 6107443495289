import { useCallback } from "react";
import Card from "../components/Card/Card";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import Divider from "../components/Divider/Divider";

const ChatCard = () => {
  const navigate = useNavigateWithUrl();

  const handleClickChat = useCallback(
    () => navigate(PATIENT.USD003),
    [navigate],
  );

  return (
    <Card
      header={
        <>
          <Text bold size="xl">
            チャット相談
          </Text>
          <Divider darkColor margin="4px" />
        </>
      }
      content={
        <Text size="large" color="neutralUserLight">
          医師やコミュニケーターに直接相談・連絡ができます。各種検査に関するご連絡事項もこちらでご案内いたします。
        </Text>
      }
      onClick={handleClickChat}
      minHeight="mh-80px"
    />
  );
};
export default ChatCard;
