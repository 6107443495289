import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";
import { formatDate } from "../../utils/utils";

// patient_brain_checksテーブルに_idを元に1件取得
const useBrainCheckFetchById = (id: string) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [brainCheck, setBrainCheck] = useStateCustomObj({
    exam_start_date: "",
    note: "",
  });
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_BRAIN_CHECKS,
        );
        const result = (await collection.findOne({
          _id: id,
        })) as BrainCheckById;

        setBrainCheck({
          exam_start_date: result.exam_start_date
            ? formatDate(result.exam_start_date)
            : "" || "",
          note: result.note || "",
        });
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, setBrainCheck, id]);

  return { error, brainCheck };
};

export default useBrainCheckFetchById;
