import { useEffect } from "react";
import SpButton from "../components/Button/SpButton";
import HeaderUser from "../components/Header/HeaderUser";
import Input from "../components/Input/Input";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Title from "../components/Title/Title";
import { useRealmApp } from "../contexts/RealmApp";
import PatientFooter from "../features/PatientFooter";
import usePasswordInput, {
  setInitPasswordInput,
  setPasswordError,
  checkPasswordInputError,
  setPassword,
  setRePassword,
} from "../hooks/input/usePasswordInput";
import { PATIENT } from "../constants/pagePaths";
import {
  useNavigateWithBack,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import { checkActionErr } from "../contexts/CustomErrorBoundary";

const USG015 = () => {
  const realmAppContext = useRealmApp();
  const navigate = useNavigateWithUrl();
  const { currentUser } = realmAppContext;
  const [{ password, rePassword }] = usePasswordInput();

  const handleOnSubmit = async () => {
    if (password.value !== rePassword.value) {
      setPasswordError();

      return;
    }
    // パスワード変更
    const email = currentUser?.customData.email as string;
    try {
      const customToken = await realmAppContext.app.currentUser?.callFunction(
        "auth/getToken",
        {
          operation: "resetPassword",
          email,
        },
      );
      await realmAppContext.app.emailPasswordAuth.callResetPasswordFunction(
        {
          email,
          password: password.value,
        },
        customToken,
      );

      setInitPasswordInput();
      navigate(PATIENT.USG016);
    } catch (error) {
      const err = error as Error;
      checkActionErr(err);
    }
  };

  // パスワード入力初期化
  useEffect(() => {
    setInitPasswordInput();
  }, []);

  // 閉じる、キャンセルボタン押下
  const handleGoBack = useNavigateWithBack(PATIENT.USB001);

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser withRightControl={false} />
        <div className="admin-inner area-with-header-footer">
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="パスワード変更"
            borderBottom="primary"
            onClickBack={handleGoBack}
          />
          <Sheet padding="40px 0" className="util-sp-py-24">
            <LayoutBox
              direction="column"
              justify="center"
              align="center"
              minWidth="440px"
              className="util-sp-full-width"
            >
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" gap="2x" fullWidth>
                  <LayoutBox gap="1x" direction="column" fullWidth>
                    <Text bold size="xl" color="neutralBlack" lineHeight="150%">
                      新しいパスワード
                    </Text>
                    <Input
                      width="100%"
                      leftIcon="lock"
                      rightButton="password"
                      password
                      autoComplete="new-password"
                      value={password.value}
                      onChange={(value) => setPassword(value)}
                      error={password.isError}
                    />
                    {[password.validationMessage].map((errorText, index) => (
                      <div
                        key={`caption-massage-${index}`}
                        className="sp-form-set__caption-massage--error"
                      >
                        {errorText}
                      </div>
                    ))}
                  </LayoutBox>
                  <LayoutBox gap="1x" direction="column" fullWidth>
                    <Text bold size="xl" color="neutralBlack" lineHeight="150%">
                      新しいパスワード（確認用）
                    </Text>
                    <Input
                      width="100%"
                      leftIcon="lock"
                      rightButton="password"
                      password
                      autoComplete="new-password"
                      value={rePassword.value}
                      onChange={(value) => setRePassword(value)}
                      error={rePassword.isError}
                    />
                    {[rePassword.validationMessage].map((errorText, index) => (
                      <div
                        key={`caption-massage-${index}`}
                        className="sp-form-set__caption-massage--error"
                      >
                        {errorText}
                      </div>
                    ))}
                  </LayoutBox>
                  <SpButton
                    width="100%"
                    size="medium"
                    disabled={checkPasswordInputError()}
                    onClick={() => {
                      void handleOnSubmit();
                    }}
                  >
                    変更を保存する
                  </SpButton>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USG015;
