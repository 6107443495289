import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Input from "../components/Input/Input";
import PatientFooter from "../features/PatientFooter";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import { useRealmApp } from "../contexts/RealmApp";
import useEmailInput, { setEmail } from "../hooks/input/useEmailInput";
import { checkActionErr } from "../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";
import SpButton from "../components/Button/SpButton";

const USG001 = () => {
  const navigate = useNavigateCustom();
  const realmAppContext = useRealmApp();
  const [{ email }] = useEmailInput();

  // 送信ボタン押下
  const handlePasswordReset = async () => {
    try {
      addLoadCount();
      // ファンクションの実行
      await realmAppContext.app.emailPasswordAuth.callResetPasswordFunction({
        email: email.value,
        password: "DummyPassword",
      });
      // メール送信完了画面
      navigate(PATIENT.USG002);
    } catch (error) {
      const err = error as Error;
      checkActionErr(err);
    } finally {
      decrementLoadCount();
    }
  };

  // ログインへ戻るボタン
  const handleNavigateLogin = () => {
    navigate(PATIENT.USA001);
  };

  return (
    <div className="admin-area admin-area_no-header">
      <HeaderUser withRightControl={false} />
      <div className="admin-inner area-with-header-footer">
        <LayoutBox
          fullWidth
          justify="center"
          align="center"
          gap="5x"
          direction="column"
        >
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="パスワードを忘れた方"
            withButton={false}
            borderBottom="primary"
          />
          <Sheet padding="0">
            <LayoutBox
              direction="column"
              gap="3x"
              justify="center"
              align="center"
              maxWidth="440px"
            >
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox
                  direction="column"
                  justify="center"
                  align="center"
                  gap="2x"
                >
                  <Text size="xl" color="neutralBlack" lineHeight="150%">
                    パスワードの再設定を行います。
                    登録しているメールアドレスを入力し、「送信」を押してしてください。
                    再設定ページのURLをメールで送信します。
                  </Text>
                  <LayoutBox
                    direction="column"
                    justify="center"
                    align="center"
                    fullWidth
                  >
                    <Text bold size="xl" color="neutralBlack" lineHeight="150%">
                      メールアドレス
                    </Text>
                    <Input
                      width="311px"
                      value={email.value}
                      onChange={(value) => setEmail(value)}
                      error={email.isError}
                    />
                    {[email.validationMessage].map((errorText, index) => (
                      <div
                        key={`caption-massage-${index}`}
                        className="sp-form-set__caption-massage--error"
                      >
                        {errorText}
                      </div>
                    ))}
                  </LayoutBox>
                  <SpButton
                    width="311px"
                    size="medium"
                    onClick={() => {
                      void handlePasswordReset();
                    }}
                  >
                    送信
                  </SpButton>
                </LayoutBox>
              </Sheet>
              <SpButton
                type="secondary"
                width="343px"
                size="medium"
                withArrowLeft
                onClick={handleNavigateLogin}
              >
                ログインへ戻る
              </SpButton>
            </LayoutBox>
          </Sheet>

          <div />
        </LayoutBox>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USG001;
