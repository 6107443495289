import React from "react";
import ReactDOM from "react-dom";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

export type DialogProps = {
  open?: boolean;
  size?: "xs" | "small" | "medium" | "large" | "xl" | "fullscreen";
  title?: string;
  subTitle?: string;
  height?: string;
  isShowCloseButton?: boolean;
  closeDialog: () => void;
  headerRight?: JSX.Element;
  footerLeft?: JSX.Element;
  footerRight?: JSX.Element;
  children: React.ReactNode;
  padding?: string;
  headerPadding?: string;
  isShowBackButton?: boolean;
  justifyFooter?: string;
};

const Dialog = ({
  open = false,
  size = "medium",
  title,
  subTitle,
  height = "",
  isShowCloseButton = true,
  closeDialog,
  headerRight,
  footerLeft,
  footerRight,
  children,
  padding,
  headerPadding,
  isShowBackButton,
  justifyFooter,
}: DialogProps) => {
  const dialogRef = React.useRef<HTMLDialogElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const dialogClass = React.useMemo(() => {
    const sizeClass = [`dialog--${size}`];

    return ["dialog", ...sizeClass].join(" ");
  }, [size]);

  const _height = React.useMemo(() => {
    const defaultHeightBySize =
      size === "fullscreen" ? "calc(100% - 64px)" : "542px";

    return height || defaultHeightBySize;
  }, [height, size]);

  const showHideDialog = React.useCallback(() => {
    if (!dialogRef?.current) {
      return;
    }
    if (open) {
      dialogRef.current.showModal();
    } else {
      dialogRef.current.classList.add("close");
      dialogRef.current.addEventListener(
        "animationend",
        () => {
          if (dialogRef.current) {
            dialogRef.current.classList.remove("close");
            dialogRef.current.close();
          }
        },
        { once: true },
      );
    }
  }, [open]);

  const onClose = React.useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  React.useEffect(() => {
    if (open !== isOpen) {
      showHideDialog();
      setIsOpen(open);
    }
  }, [isOpen, open, showHideDialog]);

  const isShowHeader = React.useMemo(
    () =>
      title !== undefined ||
      subTitle !== undefined ||
      headerRight !== undefined,
    [subTitle, title, headerRight],
  );

  const isShowFooter = React.useMemo(
    () => footerLeft !== undefined || footerRight !== undefined,
    [footerLeft, footerRight],
  );

  return ReactDOM.createPortal(
    <dialog ref={dialogRef} className={dialogClass} style={{ height: _height }}>
      <div className="dialog-content-container">
        {isShowHeader && (
          <div className="dialog__header" style={{ padding: headerPadding }}>
            <div className="dialog__header-inner">
              {/* changeEmp */}
              {isShowBackButton && (
                <Icon icon="arrow_back" color="#CF4900" onClick={onClose} />
              )}
              <span className="dialog__title">{title}</span>
              <span className="dialog__sub-title">{subTitle}</span>
            </div>
            <div>{headerRight}</div>
            {isShowCloseButton && (
              <Button
                className="dialog__close-button"
                icon="close"
                shape="square"
                size="medium"
                color="neutral"
                type="sub"
                onClick={onClose}
              />
            )}
          </div>
        )}

        <div className="dialog__content" style={{ padding }}>
          {children}
        </div>
        {isShowFooter && (
          <div
            className="dialog__footer"
            style={{ justifyContent: justifyFooter }}
          >
            <div>{footerLeft}</div>
            <div>{footerRight}</div>
          </div>
        )}
      </div>
    </dialog>,
    document.body,
  );
};

Dialog.defaultProps = {
  title: "",
  subTitle: "",
  open: false,
  size: "medium",
  height: "",
  headerRight: undefined,
  footerLeft: undefined,
  footerRight: undefined,
  isShowCloseButton: true,
  padding: "",
  justifyFooter: "",
  isShowBackButton: false,
  headerPadding: "",
};

export default Dialog;
