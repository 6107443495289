import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";

const initialState = sessionStorage.getItem("id") || "";
const stateReactiveVar = createReactiveVar<string>(initialState);

const useId = (): ReactiveVarHooks<string> =>
  useReactiveVarHooks(stateReactiveVar);

export const setId = (id: string) => {
  stateReactiveVar(id);
  sessionStorage.setItem("id", id);
};

export default useId;
