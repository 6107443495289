import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  selectMriId: string;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  selectMriId: sessionStorage.getItem("mriId") || "",
});

const useUsc011Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setSelectMriId = (selectMriId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    selectMriId,
  });
  sessionStorage.setItem("mriId", selectMriId);
};

export default useUsc011Conditions;
