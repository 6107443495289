import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  selectBrainCheckId: string;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  selectBrainCheckId: sessionStorage.getItem("brainCheckId") || "",
});

const useUsk003Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setSelectBrainCheckId = (selectBrainCheckId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    selectBrainCheckId,
  });
  sessionStorage.setItem("brainCheckId", selectBrainCheckId);
};

export default useUsk003Conditions;
