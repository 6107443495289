import { useEffect, useState } from "react";

import {
  CAMPAIGNS_BANNER_POSITION,
  Collection,
  EmbeddedPageID,
} from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { getMongoDb, getAggregateCampaigns } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { useRealmApp } from "../../contexts/RealmApp";

type campaign = USF002CampaignType | undefined;

// トップとボトムに表示させるキャンペーンを取得します。
const useGetCampaigns = (props: {
  embeddedPage: ValueOf<typeof EmbeddedPageID>;
}) => {
  const { embeddedPage } = props;
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [campaigns, setCampaigns] = useState<{
    top: campaign;
    bottom: campaign;
  }>({
    top: undefined,
    bottom: undefined,
  });
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbCampaigns = getMongoDb(currentUser, Collection.CAMPAIGNS);
        const aggregateUse002 = getAggregateCampaigns({
          selectedEmbeddedPage: embeddedPage,
        });

        const campaignsData = (await mongoDbCampaigns.aggregate(
          aggregateUse002,
        )) as USF002CampaignType[];

        const getCampaignByPosition = (
          position: ValueOf<typeof CAMPAIGNS_BANNER_POSITION>,
        ) => campaignsData.find((x) => x.embedded_location === position);

        setCampaigns({
          top: getCampaignByPosition(CAMPAIGNS_BANNER_POSITION.top),
          bottom: getCampaignByPosition(CAMPAIGNS_BANNER_POSITION.bottom),
        });
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, embeddedPage]);

  return {
    campaigns,
    error,
  };
};

export default useGetCampaigns;
