import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToNoDataPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateOneSetting, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useForceUpdate from "./useForceUpdate";

// Setting情報を取得
const useSettingInfoFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [settingsInfo, setSettingInfo] = useState<SettingsType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbSetting = getMongoDb(currentUser, Collection.SETTINGS);
        const conditions = { _id: "standard" };
        const aggregate = getAggregateOneSetting(conditions);
        const searchSettingInfo = (await mongoDbSetting.aggregate(
          aggregate,
        )) as SettingsType[];

        if (searchSettingInfo.length < 1) redirectToNoDataPage();
        setSettingInfo(searchSettingInfo[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount]);

  return { error, settingsInfo };
};

export default useSettingInfoFetch;
