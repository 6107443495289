import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregatePatientPet, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";

// patient_modality_booksテーブルのPETを取得
const usePatientPetFetch = (yearsSinceActivation?: number | undefined) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [patientPet, setPatientPet] = useStateCustomObj<PatientPet[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        const aggregate = getAggregatePatientPet(
          currentUser?.id ?? "",
          yearsSinceActivation,
        );
        const fetchResult = (await collection.aggregate(
          aggregate,
        )) as PatientPet[];

        setPatientPet(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, setPatientPet, yearsSinceActivation]);

  return { error, patientPet };
};

export default usePatientPetFetch;
