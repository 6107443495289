import SpButton from "./SpButton";

// 受検可能な医療機関を探すボタン
const ExternalMedicalInstitutionsButton = () => {
  const handleClick = () => {
    // FIXME: 正式なURL
    window.open("https://dementia365.com/", "_blank");
  };

  return (
    <SpButton
      size="medium"
      type="secondary"
      icon="open_in_new"
      iconSize="medium"
      mobileFullWidth
      width="350px"
      onClick={handleClick}
    >
      受検可能な医療機関を探す
    </SpButton>
  );
};

export default ExternalMedicalInstitutionsButton;
