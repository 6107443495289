import { useEffect } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Icon from "../components/Icon/Icon";
import SpButton from "../components/Button/SpButton";
import StepBar from "../components/StepBar/StepBar";
import { navigateRefresh } from "../hooks/base/usePageTransitionCustom";
import { INVITATION, PATIENT } from "../constants/pagePaths";
import useUSA020DbActions from "../hooks/pages/USA020/useUSA020DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { redirectToInvalidAccessPage } from "../contexts/CustomErrorBoundary";
import PatientFooter from "../features/PatientFooter";
import { REGISTER_STEP_ITEMS } from "../constants/common";
import usePath from "../hooks/common/usePath";

const USA020 = () => {
  const [path] = usePath();
  const goToMyPage = () =>
    navigateRefresh(
      path === INVITATION.USA032 ? INVITATION.USA029 : PATIENT.USB001,
    );

  // セッションストレージの値クリア
  sessionStorage.removeItem("paymentPeriodId");
  sessionStorage.removeItem("policyChecks");

  const { fetchError } = useUSA020DbActions();
  useCheckErrorThrowError([fetchError]);

  useEffect(() => {
    const shouldRedirect = !window.location.search.includes("session_id");
    if (shouldRedirect) redirectToInvalidAccessPage();
  }, []);

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser withRightControl={false} />
        <div className="admin-inner admin-area_no-header">
          <StepBar currentStepId="3" steps={REGISTER_STEP_ITEMS} />
          <Sheet padding="40px" className="util-sp-py-24">
            <LayoutBox direction="column" maxWidth="440px" width="440px">
              <Sheet type="card" padding="24px 16px" className="util-sp-pt-16">
                <LayoutBox gap="3x" direction="column" align="center" fullWidth>
                  <LayoutBox direction="column" gap="2x" align="center">
                    <Icon color="#388e3c" icon="check_circle" size="xl" />
                    <Text
                      bold
                      size="2xl"
                      color="neutralUserBlack"
                      lineHeight="150%"
                      textAlign="center"
                    >
                      登録が完了しました
                    </Text>
                  </LayoutBox>
                  <SpButton
                    type="primary"
                    width="100%"
                    size="medium"
                    withArrowRight
                    onClick={goToMyPage}
                  >
                    トップへ
                  </SpButton>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USA020;
