import { useMemo, useState } from "react";
import SpButton from "../components/Button/SpButton";
import Icon from "../components/Icon/Icon";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import List from "../components/List/List";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import useNotificationTopFetch from "../hooks/common/useNotificationTopFetch";
import useUsm001Dialog, {
  openUsm001Dialog,
} from "../hooks/pages/USM001/useUsm001Dialog";
import { useCheckErrorThrowError } from "../utils/checkError";
import useUsm002Dialog, {
  openUsm002FromTopDialog,
} from "../hooks/pages/USM002/useUsm002Dialog";
import USM002 from "../pages/US-M002";
import USM001 from "../pages/US-M001";

const NotificationCard = () => {
  const [notificationId, setNotificationId] = useState("");
  const { notifications, error } = useNotificationTopFetch();
  const [isNoticeListDialogOpen] = useUsm001Dialog();
  const [{ isFromTopOpen }] = useUsm002Dialog();
  useCheckErrorThrowError([error]);

  // お知らせ
  const notificationLists = useMemo(
    () =>
      notifications.map((notification) => ({
        id: notification._id,
        left: <></>,
        center: (
          <>
            <LayoutBox gap="1/2x" fullWidth direction="column">
              <Text size="large" color="neutralLight">
                {notification.deliveried_at}
              </Text>
              <Text size="1xl" bold>
                {notification.title}
              </Text>
            </LayoutBox>
          </>
        ),
        right: (
          <>
            <Icon icon="expand_circle_right" type="outlined" color="#CF4900" />
          </>
        ),
      })),
    [notifications],
  );

  // お知らせ押下
  const handleClickNotification = (id: string) => {
    setNotificationId(id);
    openUsm002FromTopDialog();
  };

  return (
    <>
      <Sheet
        color="white"
        type="border"
        padding="24px 24px"
        minWidth="1076px"
        withBorderRadius
        spPadding={false}
      >
        <LayoutBox direction="column" gap="1x" fullWidth>
          <LayoutBox gap="2x" rowGap="1x" wrap fullWidth>
            <Text size="2xl" bold yellowUnderline lineHeight="80%" titleFont>
              お知らせ
            </Text>
          </LayoutBox>
          {notificationLists.length > 0 ? (
            <>
              <LayoutBox fullWidth>
                <List
                  onClick={handleClickNotification}
                  selectedIds={[]}
                  items={notificationLists}
                />
              </LayoutBox>
              <div className="util-mt-4" />
              <LayoutBox justify="center" fullWidth>
                <SpButton
                  width="291px"
                  fontSize="small"
                  withArrowRight
                  onClick={openUsm001Dialog}
                >
                  すべてのお知らせを確認する
                </SpButton>
              </LayoutBox>
            </>
          ) : (
            <LayoutBox justify="center" align="center" fullWidth>
              <Text size="large" color="neutralLight">
                現在、お知らせはありません
              </Text>
            </LayoutBox>
          )}
        </LayoutBox>
      </Sheet>
      {isNoticeListDialogOpen && <USM001 />}
      {isFromTopOpen && <USM002 notificationId={notificationId} isTop />}
    </>
  );
};

export default NotificationCard;
