// 共通エラー処理
import { useEffect } from "react";

type ErrorType = Error | null;
type ErrorsType = ErrorType[];

// エラー時にthrow new Errorする場合
export const useCheckErrorThrowError = (errors: ErrorsType) => {
  const errorCount = errors.filter((item) => item === null).length;
  useEffect(() => {
    errors.forEach((error) => {
      if (error) throw new Error(error.message);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCount]);
};

// エラー時にアラートする場合
export const checkErrorAlert = (errors: ErrorsType) => {
  errors.forEach((error) => {
    // eslint-disable-next-line no-alert
    if (error) alert(error);
  });
};
