import React from "react";

export type HeaderProps = {
  leftControl?: JSX.Element;
  content?: JSX.Element;
  rightControl?: JSX.Element;
  backgroundColor?: string;
};

const Header = ({
  leftControl,
  content,
  rightControl,
  backgroundColor = "white",
}: HeaderProps) => {
  const headerClass = React.useMemo(() => {
    const bgColorClass = [`header--bg-${backgroundColor} `];
    const withRightControl = !rightControl ? ["header--no-right-control"] : [];

    return ["header", ...bgColorClass, ...withRightControl].join(" ");
  }, [backgroundColor, rightControl]);

  return (
    <div className={headerClass}>
      <div className="header__left">
        {leftControl ? (
          <div className="header__left-ctrl">{leftControl}</div>
        ) : null}
        {content}
      </div>
      {rightControl ? (
        <div className="header__right">{rightControl}</div>
      ) : null}
    </div>
  );
};

Header.defaultProps = {
  leftControl: undefined,
  content: undefined,
  rightControl: undefined,
  backgroundColor: "white",
};

export default Header;
