import React from "react";

export type DividerProps = {
  width?: string;
  minWidth?: string;
  height?: string;
  margin?: string;
  vertical?: boolean;
  darkColor?: boolean;
  white?: boolean;
  orange?: boolean;
  ghost?: boolean;
  borderWidth?: string;
  primaryMain?: boolean;
  className?: string;
};

const Divider = ({
  width = "",
  minWidth = "",
  height = "",
  margin = "8px",
  vertical = false,
  darkColor = false,
  orange,
  white,
  ghost,
  borderWidth,
  className = "",
  primaryMain,
}: DividerProps) => {
  const componentClass = React.useMemo(() => {
    const verticalClass = vertical ? ["divider--vertical"] : [];
    const colorClass = darkColor
      ? ["divider--darkColor"]
      : white
      ? ["divider--white"]
      : orange
      ? ["divider--orange"]
      : ghost
      ? ["divider--ghost"]
      : primaryMain
      ? ["divider--primary-main"]
      : [];

    return ["divider", ...verticalClass, ...colorClass].join(" ");
  }, [darkColor, vertical, white, orange, ghost, primaryMain]);

  const dividerStyle = React.useMemo(
    () => ({
      width: !vertical && width ? width : undefined,
      height: vertical && height ? height : undefined,
      margin: vertical ? `0 ${margin}` : `${margin} 0`,
      minWidth: minWidth || undefined,
      borderWidth,
    }),
    [height, margin, vertical, width, minWidth, borderWidth],
  );

  return (
    <hr className={`${componentClass} ${className}`} style={dividerStyle} />
  );
};

Divider.defaultProps = {
  width: "",
  height: "",
  margin: "8px",
  vertical: false,
  darkColor: false,
  white: false,
  orange: true,
  ghost: false,
  borderWidth: "1px",
  primaryMain: false,
  className: "",
  minWidth: "",
};

export default Divider;
