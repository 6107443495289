import { type ReactElement, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./scss/index.scss";
import { INVITATION, NON_ACTIVE_PRESENT, PATIENT } from "./constants/pagePaths";
import NotFound from "./pages/NotFound";
import IndexPage from "./pages/IndexPage";
import Spinner from "./components/Loading/Spinner";
import PublicElement from "./components/PublicElement";
import RealmApolloProvider from "./contexts/RealmApolloProvider_dev";
import PageCommon from "./contexts/PageCommon";
import CustomErrorBoundary from "./contexts/CustomErrorBoundary";
import USA001 from "./pages/US-A001";
import USA002 from "./pages/US-A002";
import USA003 from "./pages/US-A003";
import USA004 from "./pages/US-A004";
import USA006 from "./pages/US-A006";
import USA020 from "./pages/US-A020";
import USA022 from "./pages/US-A022";
import USA024 from "./pages/US-A024";
import USA025 from "./pages/US-A025";
import USA026 from "./pages/US-A026";
import USA027 from "./pages/US-A027";
import USA028 from "./pages/US-A028";
import USA029 from "./pages/US-A029";
import USA030 from "./pages/US-A030";
import USA031 from "./pages/US-A031";
import USA032 from "./pages/US-A032";
import USA033 from "./pages/US-A033";
import USB001 from "./pages/US-B001";
import USC001 from "./pages/US-C001";
import USC002 from "./pages/US-C002";
import USC003 from "./pages/US-C003";
import USC004 from "./pages/US-C004";
import USC007 from "./pages/US-C007";
import USC009 from "./pages/US-C009";
import USC013 from "./pages/US-C013";
import USC021 from "./pages/US-C021";
import USC022 from "./pages/US-C022";
import USC023 from "./pages/US-C023";
import USC024 from "./pages/US-C024";
import USC025 from "./pages/US-C025";
import USC026 from "./pages/US-C026";
import USC039 from "./pages/US-C039";
import USC040 from "./pages/US-C040";
import USD002 from "./pages/US-D002";
import USD003 from "./pages/US-D003";
import USE002 from "./pages/US-E002";
import USE004 from "./pages/US-E004";
import USF001 from "./pages/US-F001";
import USF002 from "./pages/US-F002";
import USF003 from "./pages/US-F003";
import USF004 from "./pages/US-F004";
import USF005 from "./pages/US-F005";
import USF007 from "./pages/US-F007";
import USF008 from "./pages/US-F008";
import USF009 from "./pages/US-F009";
import USF010 from "./pages/US-F010";
import USF011 from "./pages/US-F011";
import USF012 from "./pages/US-F012";
import USG001 from "./pages/US-G001";
import USG002 from "./pages/US-G002";
import USG003 from "./pages/US-G003";
import USG004 from "./pages/US-G004";
import USG008 from "./pages/US-G008";
import USG009 from "./pages/US-G009";
import LoginProcessing from "./pages/LoginProcessing";
import USG010 from "./pages/US-G010";
import ExternalLogin from "./pages/ExternalLogin";
import USG005 from "./pages/US-G005";
import USG006 from "./pages/US-G006";
import USF006 from "./pages/US-F006";
import USJ001 from "./pages/US-J001";
import USK002 from "./pages/US-K002";
import USK003 from "./pages/US-K003";
import RequiredLoginActiveElement from "./components/RequiredLoginActiveElement";
import RequiredLoginUnregisteredElement from "./components/RequiredLoginUnregisteredElement";
import RequiredLoginElement from "./components/RequiredLoginElement";
import USC008 from "./pages/US-C008";
import USC011 from "./pages/US-C011";
import USC012 from "./pages/US-C012";
import USB003 from "./pages/US-B003";
import USD004 from "./pages/US-D004";
import USG011 from "./pages/US-G011";
import USD001 from "./pages/US-D001";
import USA034 from "./pages/US-A034";
import USA035 from "./pages/US-A035";
import USC014 from "./pages/US-C014";
import USC015 from "./pages/US-C015";
import USA038 from "./pages/US-A038";
import PreBookLoading from "./pages/PreBookLoading";
import USD005 from "./pages/US-D005";
import USC016 from "./pages/US-C016";
import USA036 from "./pages/US-A036";
import USD008 from "./pages/US-D008";
import USC017 from "./pages/US-C017";
import USC018 from "./pages/US-C018";
import USG012 from "./pages/US-G012";
import ReceiptDefault from "./pages/ReceiptDefault";
import USA039 from "./pages/US-A039";
import USG015 from "./pages/US-G015";
import USG016 from "./pages/US-G016";
import USA042 from "./pages/US-A042";
import USA043 from "./pages/US-A043";
import USC019 from "./pages/US-C019";
import USC020 from "./pages/US-C020";
import USD009 from "./pages/US-D009";
import USD010 from "./pages/US-D010";

// ログイン不要画面設定一覧
const publicPagesSettingArr = [
  { path: PATIENT.USA001, element: <USA001 /> },
  { path: PATIENT.USA002, element: <USA002 /> },
  { path: PATIENT.USA003, element: <USA003 /> },
  { path: PATIENT.USG001, element: <USG001 /> },
  { path: PATIENT.USG002, element: <USG002 /> },
  { path: PATIENT.USG003, element: <USG003 /> },
  { path: PATIENT.USG004, element: <USG004 /> },
  { path: PATIENT.USG008, element: <USG008 /> },
  { path: PATIENT.USG011, element: <USG011 /> },
  { path: PATIENT.USG009, element: <USG009 /> },
  { path: PATIENT.USG010, element: <USG010 /> },
  { path: PATIENT.EXTERNAL_LOGIN, element: <ExternalLogin /> },
];
// ログイン必須画面設定一覧
const requiredLoginPagesSettingArr = [
  { path: PATIENT.LOGIN_PROCESSING, element: <LoginProcessing /> },
  { path: PATIENT.USA020, element: <USA020 /> },
];
// ログイン必須画面設定一覧(アクティブユーザ)
const requiredLoginActivePagesSettingArr = [
  { path: PATIENT.USB001, element: <USB001 /> },
  { path: PATIENT.USB003, element: <USB003 /> },
  { path: PATIENT.USC001, element: <USC001 /> },
  { path: PATIENT.USC002, element: <USC002 /> },
  { path: PATIENT.USC003, element: <USC003 /> },
  { path: PATIENT.USC004, element: <USC004 /> },
  { path: PATIENT.USC007, element: <USC007 /> },
  { path: PATIENT.USC008, element: <USC008 /> },
  { path: PATIENT.USC009, element: <USC009 /> },
  { path: PATIENT.USC011, element: <USC011 /> },
  { path: PATIENT.USC012, element: <USC012 /> },
  { path: PATIENT.USC013, element: <USC013 /> },
  { path: PATIENT.USC014, element: <USC014 /> },
  { path: PATIENT.USC015, element: <USC015 /> },
  { path: PATIENT.USC016, element: <USC016 /> },
  { path: PATIENT.USC017, element: <USC017 /> },
  { path: PATIENT.USC018, element: <USC018 /> },
  { path: PATIENT.USC019, element: <USC019 /> },
  { path: PATIENT.USC020, element: <USC020 /> },
  { path: PATIENT.USC021, element: <USC021 /> },
  { path: PATIENT.USC022, element: <USC022 /> },
  { path: PATIENT.USC023, element: <USC023 /> },
  { path: PATIENT.USC024, element: <USC024 /> },
  { path: PATIENT.USC025, element: <USC025 /> },
  { path: PATIENT.USC026, element: <USC026 /> },
  { path: PATIENT.USC039, element: <USC039 /> },
  { path: PATIENT.USC040, element: <USC040 /> },
  { path: PATIENT.USD001, element: <USD001 /> },
  { path: PATIENT.USD002, element: <USD002 /> },
  { path: PATIENT.USD003, element: <USD003 /> },
  { path: PATIENT.USD004, element: <USD004 /> },
  { path: PATIENT.USD005, element: <USD005 /> },
  { path: PATIENT.USD008, element: <USD008 /> },
  { path: PATIENT.USD009, element: <USD009 /> },
  { path: PATIENT.USD010, element: <USD010 /> },
  { path: PATIENT.USE002, element: <USE002 /> },
  { path: PATIENT.USE004, element: <USE004 /> },
  { path: PATIENT.USF001, element: <USF001 /> },
  { path: PATIENT.USF002, element: <USF002 /> },
  { path: PATIENT.USF003, element: <USF003 /> },
  { path: PATIENT.USF004, element: <USF004 /> },
  { path: PATIENT.USF005, element: <USF005 /> },
  { path: PATIENT.USF006, element: <USF006 /> },
  { path: PATIENT.USF007, element: <USF007 /> },
  { path: PATIENT.USF008, element: <USF008 /> },
  { path: PATIENT.USF009, element: <USF009 /> },
  { path: PATIENT.USF010, element: <USF010 /> },
  { path: PATIENT.USF011, element: <USF011 /> },
  { path: PATIENT.USF012, element: <USF012 /> },
  { path: PATIENT.USG005, element: <USG005 /> },
  { path: PATIENT.USG006, element: <USG006 /> },
  { path: PATIENT.USG012, element: <USG012 /> },
  { path: PATIENT.USJ001, element: <USJ001 /> },
  { path: PATIENT.USK002, element: <USK002 /> },
  { path: PATIENT.USK003, element: <USK003 /> },
  { path: INVITATION.USA027, element: <USA027 /> },
  { path: PATIENT.USA028, element: <USA028 /> },
  { path: INVITATION.USA029, element: <USA029 /> },
  { path: INVITATION.USA030, element: <USA030 /> },
  { path: INVITATION.USA031, element: <USA031 /> },
  { path: INVITATION.USA032, element: <USA032 /> },
  { path: INVITATION.USA033, element: <USA033 /> },
  { path: INVITATION.USA034, element: <USA034 /> },
  { path: INVITATION.USA035, element: <USA035 /> },
  { path: INVITATION.USA038, element: <USA038 /> },
  { path: INVITATION.USA039, element: <USA039 /> },
  // { path: PATIENT.USJ002, element: <USJ002 /> },
  { path: PATIENT.PRE_BOOK_LOADING, element: <PreBookLoading /> },
  { path: PATIENT.RECEIPT, element: <ReceiptDefault /> },
  { path: PATIENT.USG015, element: <USG015 /> },
  { path: PATIENT.USG016, element: <USG016 /> },
  { path: NON_ACTIVE_PRESENT.USA036, element: <USA036 /> },
];
// ログイン必須画面設定一覧(ユーザ情報未登録ユーザ)
const requiredLoginUnregisteredPagesSettingArr = [
  { path: PATIENT.USA004, element: <USA004 /> },
  { path: PATIENT.USA006, element: <USA006 /> },
  { path: PATIENT.USA022, element: <USA022 /> },
  { path: PATIENT.USA024, element: <USA024 /> },
  { path: PATIENT.USA025, element: <USA025 /> },
  { path: PATIENT.USA026, element: <USA026 /> },
  { path: INVITATION.USA027, element: <USA027 /> },
  { path: PATIENT.USA028, element: <USA028 /> },
  { path: PATIENT.USA042, element: <USA042 /> },
  { path: PATIENT.USA043, element: <USA043 /> },
];

const App = (): ReactElement => (
  <BrowserRouter>
    <CustomErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <RealmApolloProvider>
          <PageCommon>
            <Routes>
              {process.env.NODE_ENV === "development" && (
                <Route path="/" element={<IndexPage />} />
              )}
              {/* ログイン不要画面 */}
              {publicPagesSettingArr.map(({ path, element }) => (
                <Route
                  key={path}
                  path={path}
                  element={<PublicElement element={element} />}
                />
              ))}
              {/* ログイン必須画面 */}
              {requiredLoginPagesSettingArr.map(({ path, element }) => (
                <Route
                  key={path}
                  path={path}
                  element={<RequiredLoginElement element={element} />}
                />
              ))}
              {requiredLoginActivePagesSettingArr.map(({ path, element }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <RequiredLoginActiveElement element={element} path={path} />
                  }
                />
              ))}
              {requiredLoginUnregisteredPagesSettingArr.map(
                ({ path, element }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <RequiredLoginUnregisteredElement element={element} />
                    }
                  />
                ),
              )}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </PageCommon>
        </RealmApolloProvider>
      </Suspense>
    </CustomErrorBoundary>
  </BrowserRouter>
);

export default App;
