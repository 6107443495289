import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  nouknowId: string;
  selectedDate: string;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  nouknowId: sessionStorage.getItem("nouknowId") || "",
  selectedDate: sessionStorage.getItem("nouknowFinishedAt") || "",
});

const useUsc004Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setNouknowId = (id: string) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    nouknowId: id,
  });
  sessionStorage.setItem("nouknowId", id);
};

export const setSelectedDate = (selectedDate: string) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    selectedDate,
  });
  sessionStorage.setItem("nouknowFinishedAt", selectedDate);
};

export default useUsc004Conditions;
