import * as Realm from "realm-web";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useRealmApp } from "../contexts/RealmApp";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";

// 外部サービスログイン処理
const ExternalLogin = () => {
  const navigate = useNavigateCustom();
  const realmAppContext = useRealmApp();
  const isFirstRender = useRef(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code") || "";
  const state = queryParams.get("state") || "";

  useEffect(() => {
    void (async () => {
      addLoadCount();
      try {
        if (!code || !state) {
          return;
        }

        if (isFirstRender.current) {
          // 初回のみ実行
          isFirstRender.current = false;

          const provider = state.split("_")[0];

          // ファンクション
          await realmAppContext.logIn(
            Realm.Credentials.function({
              provider,
              code,
              redirect_uri: `${window.location.protocol}//${window.location.host}/ExternalLogin`,
            }),
          );
          navigate(PATIENT.LOGIN_PROCESSING);
        }
      } catch (e) {
        // ログアウトして、エラー画面
        void realmAppContext.logOut();
        navigate(PATIENT.USG010);
      } finally {
        decrementLoadCount();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, state]);

  return <></>;
};

export default ExternalLogin;
