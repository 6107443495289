import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregatePatientInterview, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";

// PatientInterviewsテーブル検索
const usePatientInterviewsFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [patientInterviews, setPatientInterviews] = useStateCustomObj<
    PatientInterviews[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_INTERVIEWS,
        );
        const aggregate = getAggregatePatientInterview(currentUser?.id ?? "");
        const fetchResult = (await collection.aggregate(
          aggregate,
        )) as PatientInterviews[];

        setPatientInterviews(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, setPatientInterviews]);

  return { error, patientInterviews };
};

export default usePatientInterviewsFetch;
