import { useCallback, useEffect, useMemo, useRef } from "react";

import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import SpButton from "../components/Button/SpButton";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";

import ToggleButton from "../components/ToggleButton/ToggleButton";
import OrderHistory from "../components/OrderHistory/OrderHistory";
import useUsf002Actions from "../hooks/pages/USF002/useUsf002DBActions";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import { setId } from "../hooks/common/useId";
import { nextPage, setPage } from "../hooks/component/pagination/usePagination";
import { useCheckErrorThrowError } from "../utils/checkError";
import { openUsf006Dialog } from "../hooks/pages/USF006/useUsf006Dialog";
import USF006 from "./US-F006";
import { addHyphenToZipcode, formatDate } from "../utils/utils";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { openUsf003Dialog } from "../hooks/pages/USF003/useUsf003Dialog";
import USF003 from "./US-F003";
import { isCancelableOrder } from "../utils/checkCancelableOrder";
import { OrderStatusID } from "../constants/common";
import PatientFooter from "../features/PatientFooter";

const SP_TAB_ITEMS = [
  {
    id: "1",
    label: "ショップ",
  },
  {
    id: "2",
    label: "注文履歴",
  },
];

const USF002 = () => {
  const navigate = useNavigateCustom();
  const { result, patientInfo, error, patientInfoError } = useUsf002Actions();
  const bottomRef = useRef<HTMLDivElement>(null);
  const initializeRef = useRef(false);

  useCheckErrorThrowError([error, patientInfoError]);

  // paginationの初期化
  useEffect(() => {
    setPage(1);
  }, []);

  const navigateToCancelPage = useCallback(
    (id: string) => {
      setId(id);
      navigate(PATIENT.USF011);
    },
    [navigate],
  );

  const navigateToDetailPage = useCallback(
    (id: string) => {
      setId(id);
      navigate(PATIENT.USF010);
    },
    [navigate],
  );

  const getOrderButtons = useCallback(
    (settlementTime: Date, orderId: string, status: number) => {
      if (isCancelableOrder(settlementTime, status)) {
        return {
          buttonLeft: "注文キャンセル",
          onClickButtonLeft: () => navigateToCancelPage(orderId),
          buttonRight: "配送状況を確認",
          onClickButtonRight: () => navigateToDetailPage(orderId),
        };
      }

      return {
        buttonLeft: "配送状況を確認",
        onClickButtonLeft: () => navigateToDetailPage(orderId),
        buttonRight: "",
      };
    },
    [navigateToCancelPage, navigateToDetailPage],
  );

  const orderHistoryItems = useMemo(
    () =>
      (result ?? []).map((order) => ({
        number: order._id,
        date: order.sales_info ? formatDate(order.sales_info.payment_date) : "",
        total: `${order.total_amount ?? 0}円`,
        deliveryDate: order.desired_delivery_date
          ? formatDate(order.desired_delivery_date)
          : "",
        images: (order?.transactions_info ?? []).map((transaction) => ({
          url: transaction.skus_info?.image_urls[0],
          alt: transaction?.sku_name,
        })),
        cancelled: order.status === OrderStatusID.CANCELLED,
        ...getOrderButtons(order.settlement_time, order._id, order.status),
      })),
    [getOrderButtons, result],
  );

  const onReachedEnd = useCallback(() => {
    nextPage();
  }, []);

  // changeEmp
  const scrollObserver = useCallback(
    () =>
      new IntersectionObserver((entries) => {
        if (initializeRef.current) {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              onReachedEnd();
            }
          });
        }
        initializeRef.current = true;
      }),
    [onReachedEnd],
  );
  // changeEmp
  useEffect(() => {
    const target = bottomRef.current;
    if (target) {
      const observer = scrollObserver();
      observer.observe(target);

      return () => {
        observer.unobserve(target);
      };
    }

    return () => {};
  }, [scrollObserver]);

  const handleClickTab = useCallback(
    (selected: string | number) => {
      if (selected === "1") navigate(PATIENT.USF001);
    },
    [navigate],
  );

  const handleClickBack = useCallback(
    () => navigate(PATIENT.USB001),
    [navigate],
  );

  const handleClickCart = useCallback(() => openUsf006Dialog(), []);

  const handleClickAddress = useCallback(() => openUsf003Dialog(), []);

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation minWidth="1076px" selectedTab={SELECTED_TAB_NUM.shop} />
        <Sheet
          padding="16px 108px"
          color="secondary-lightest"
          minWidth="1076px"
        >
          <LayoutBox justify="between" width="1064px" gap="1x">
            <div onClick={handleClickAddress} style={{ cursor: "pointer" }}>
              <LayoutBox direction="column" gap="1/2x" minWidth="0">
                <Text color="neutralLight" size="large">
                  お届け先
                </Text>
                <Text ellipsis size="xl" width="100%">
                  {addHyphenToZipcode(patientInfo?.zipcode)} {patientInfo?.pref}
                  {patientInfo?.city}
                  {patientInfo?.town1}
                  {patientInfo?.town2}
                </Text>
              </LayoutBox>
            </div>
            <LayoutBox minWidth="0">
              <SpButton
                type="shop"
                size="small"
                width="140px"
                badge={patientInfo?.cart_info?.item_num}
                onClick={handleClickCart}
              >
                カート
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <Title
          padding="16px 108px 24px 108px"
          background="rough-blue"
          header="オンラインショップ"
          content="  日常でも認知症予防をしたい方向けの商品を販売しています"
          minWidth="1076px"
          onClickBack={handleClickBack}
        />
        <Sheet padding="40px 108px" minWidth="1076px">
          <LayoutBox width="1064px" justify="center">
            <ToggleButton
              fullWidth
              size="large"
              width="100%"
              items={SP_TAB_ITEMS}
              selectedButton="2"
              onClick={handleClickTab}
            />
          </LayoutBox>
        </Sheet>
        <Sheet padding="0 108px 118px 108px" minWidth="1076px">
          <OrderHistory orders={orderHistoryItems} />
        </Sheet>
      </div>
      <div ref={bottomRef} style={{ width: "100%" }} />
      <PatientFooter />
      <USF006 />
      <USF003 />
    </div>
  );
};

export default USF002;
