import { useCallback, useState } from "react";
import { useRealmApp } from "../../contexts/RealmApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import {
  Collection,
  PatientMessageRelationshipStatusID,
} from "../../constants/common";
import { getMongoDb } from "../../utils/query";

// メッセージテーブルのステータス更新
const useUpdateMessageStatus = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [actionError, setActionError] = useState<Error | null>(null);

  const updateStatus = useCallback(
    async (messageId: string) => {
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MESSAGE_RELATIONSHIPS,
        );
        await collection.updateOne(
          {
            _id: messageId,
            status: { $ne: PatientMessageRelationshipStatusID.ALREADY_READ },
          },
          {
            $set: {
              status: PatientMessageRelationshipStatusID.ALREADY_READ,
              updated_at: new Date(),
            },
          },
        );
      } catch (err) {
        setActionError(checkActionErr(err));
      }
    },
    [currentUser],
  );

  return {
    updateStatus,
    actionError,
  };
};

export default useUpdateMessageStatus;
