import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

// メッセージ一覧ダイアログの開閉状態を管理
const useUsj001Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openUsj001Dialog = () => {
  stateReactiveVar(true);
};

export const closeUsj001Dialog = () => {
  stateReactiveVar(false);
};

export default useUsj001Dialog;
