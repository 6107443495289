import { useCallback } from "react";
import Divider from "../components/Divider/Divider";
import HeaderUser from "../components/Header/HeaderUser";
import Icon from "../components/Icon/Icon";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Text from "../components/Text/Text";
import SpFormSet from "../components/FormSet/SpFormSet";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import usePatientMriFetch from "../hooks/common/usePatientMriFetch";
import { useCheckErrorThrowError } from "../utils/checkError";

const USC017 = () => {
  const navigate = useNavigateCustom();

  const { patientMri, error } = usePatientMriFetch();

  useCheckErrorThrowError([error]);

  // 最新を使用
  const mriData = patientMri[0];

  const handleClickBack = useCallback(
    () => navigate(PATIENT.USB001),
    [navigate],
  );

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          selectedTab={SELECTED_TAB_NUM.examination}
          minWidth="1076px"
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="MRI検査"
          content="MRI検査を受診することができます。"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <LayoutBox justify="center">
          <Sheet padding="24px 16px 0px" maxWidth="1096px">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <LayoutBox direction="column" fullWidth>
                <Text size="2xl" bold color="neutralUserBlack">
                  医療機関の予約
                </Text>
                <Divider orange margin="0" borderWidth="3px" />
              </LayoutBox>
              <LayoutBox direction="column" fullWidth>
                <Sheet type="card" padding="24px 16px">
                  <LayoutBox direction="column" gap="3x" fullWidth>
                    <LayoutBox
                      direction="column"
                      align="center"
                      gap="2x"
                      fullWidth
                    >
                      <Icon icon="warning" size="xl" color="#D6A000" />
                      <Text size="2xl" bold color="neutralUserBlack">
                        現在予約を調整中です。
                      </Text>
                    </LayoutBox>
                    <Divider darkColor margin="0" />
                    <LayoutBox direction="column" gap="1x" fullWidth>
                      <SpFormSet label="都道府県" vertical>
                        <Text size="xl" color="neutralUserBlack">
                          {mriData?.prefecture}
                        </Text>
                        <Divider darkColor margin="0" />
                      </SpFormSet>
                      <SpFormSet label="提携医療機関" vertical>
                        <Text size="xl" color="neutralUserBlack">
                          {mriData?.hospital_name}
                        </Text>
                        <Divider darkColor margin="0" />
                      </SpFormSet>
                    </LayoutBox>
                    {mriData && mriData.candidate_book_dates && (
                      <LayoutBox direction="column" gap="3x" fullWidth>
                        {Object.entries(mriData.candidate_book_dates).map(
                          ([key, value], index) =>
                            value ? (
                              <LayoutBox
                                direction="column"
                                gap="1x"
                                fullWidth
                                key={key}
                              >
                                <Text size="xl" bold color="neutralUserBlack">
                                  第{index + 1}候補
                                </Text>
                                <Text size="xl" color="neutralUserBlack">
                                  {value}
                                </Text>
                                <Divider darkColor margin="0" />
                              </LayoutBox>
                            ) : null,
                        )}
                      </LayoutBox>
                    )}
                  </LayoutBox>
                </Sheet>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC017;
