import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";

const initialState = sessionStorage.getItem("path") || "";
const stateReactiveVar = createReactiveVar<string>(initialState);

const usePath = (): ReactiveVarHooks<string> =>
  useReactiveVarHooks(stateReactiveVar);

export const setPath = (path: string) => {
  stateReactiveVar(path);
  sessionStorage.setItem("path", path);
};

export default usePath;
