import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Tabs from "../components/Tab/Tabs";
import Sheet from "../components/Sheet/Sheet";
import Cart from "../assets/tabs-icons/cart-icon.svg";
import ChatBubble from "../assets/tabs-icons/chat-bubble-icon.svg";
import House from "../assets/tabs-icons/house-icon.svg";
import Notebook from "../assets/tabs-icons/notebook-icon.svg";
import Vaudeville from "../assets/tabs-icons/vaudeville-icon.svg";
import Megaphone from "../assets/megaphone.png";
import ChatBubble2 from "../assets/chat_bubbles.png";
import Card from "../components/Card/Card";
import PatientFooter from "../features/PatientFooter";

const TAB_ITEMS = [
  { title: "トップ", image: House },
  { title: "検査", image: Notebook, badge: "結果" },
  { title: "健康相談", image: ChatBubble, badge: "1" },
  {
    title: "オンライン寄席",
    titleMobile: "寄席",
    image: Vaudeville,
    badge: "結果",
  },
  { title: "ショップ", image: Cart, badge: "1" },
];

const USB003 = () => (
  <div className="admin-area ">
    <HeaderUser backgroundColor="rough-yellow" />
    <div className="admin-inner area-with-header-footer">
      <Sheet padding="0 16px" minWidth="1076px" color="rough-yellow">
        <Tabs bottomBorder selectedTab={0} tabs={TAB_ITEMS} />
      </Sheet>
      <Sheet minWidth="1076px" padding="16px" color="rough-yellow">
        <LayoutBox width="1064px" direction="column" gap="2x">
          <LayoutBox direction="column" gap="1x" fullWidth>
            <LayoutBox gap="1x" className="util-py-8">
              <img src={Megaphone} alt="megaphone" />
              <div className="util-mt-20">
                <Text size="2xl" bold yellowUnderline lineHeight="50%">
                  検査
                </Text>
              </div>
            </LayoutBox>
            <Text size="xl">次になにをしたらよいかお知らせいたします</Text>
            <Card
              header={
                <Text bold size="xl">
                  のうKNOW
                </Text>
              }
              content={
                <Text size="large" color="neutralLight">
                  月に1回認知機能テストを受けられます
                </Text>
              }
            />
            <Card
              header={
                <Text bold size="xl">
                  MRI検査
                </Text>
              }
              content={
                <Text size="large" color="neutralLight">
                  2022/04/01 &nbsp;10:00〜11:00 &nbsp;〇〇〇病院
                </Text>
              }
            />
            <Card
              disabled
              header={
                <Text bold size="xl">
                  FDG PET検査
                </Text>
              }
            />
          </LayoutBox>
          <LayoutBox direction="column" gap="1x" fullWidth>
            <LayoutBox gap="1x" className="util-py-8">
              <img src={ChatBubble2} alt="megaphone" />
              <div className="util-mt-20">
                <Text size="2xl" bold yellowUnderline lineHeight="50%">
                  健康相談
                </Text>
              </div>
            </LayoutBox>
            <Text size="xl">
              オンラインやチャットで医師に気になることを相談できます
            </Text>
            <Card
              header={
                <Text bold size="xl">
                  オンライン面談
                </Text>
              }
              content={
                <LayoutBox direction="column" gap="1x">
                  <Text size="large" color="neutralLight">
                    オンライン会議システム（Zoom）を使って医師と面談することができます。
                  </Text>
                </LayoutBox>
              }
              footerLabel="1回分無料"
              footerText="2023/12/31まで"
            />
            <Card
              header={
                <Text bold size="xl">
                  チャット
                </Text>
              }
              content={
                <Text size="large" color="neutralLight">
                  医師やコミュニケーターにチャットフォームで相談・連絡することができます。また、コミュニケーターから検査に関するレポートを送らせていただきます。
                </Text>
              }
            />
          </LayoutBox>
        </LayoutBox>
      </Sheet>
      <Sheet relative color="rough-yellow" padding="50px">
        <div />
      </Sheet>
      <Sheet relative color="rough-yellow-wavy" padding="50px">
        <div />
      </Sheet>

      <div className="util-mb-40" />
    </div>
    <PatientFooter />
  </div>
);

export default USB003;
