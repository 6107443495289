import HeaderUser from "../components/Header/HeaderUser";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Title from "../components/Title/Title";
import Icon from "../components/Icon/Icon";
import SpButton from "../components/Button/SpButton";
import Divider from "../components/Divider/Divider";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import PatientFooter from "../features/PatientFooter";
import useUsc014DBActions from "../hooks/pages/USC014/useUsc014DBActions";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import {
  getUserYearSinceActivation,
  isWithinExaminationPeriod,
} from "../utils/utils";
import { useRealmApp } from "../contexts/RealmApp";
import usePatientMriFetch from "../hooks/common/usePatientMriFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { MriStatusID } from "../constants/common";

const USC014 = () => {
  const realmAppContext = useRealmApp();
  const navigate = useNavigateCustom();

  // 何年目のユーザか
  const userYearSinceActivation = getUserYearSinceActivation(
    realmAppContext.currentUser,
  );

  const { patientMri, error } = usePatientMriFetch(userYearSinceActivation);

  // エラー処理
  useCheckErrorThrowError([error]);

  // MRI,PETが受診可能か
  const isMriPetWithinExaminationPeriod = isWithinExaminationPeriod(
    realmAppContext.currentUser,
  );

  // MRIボタン押下可能か
  const isMriClickable =
    (isMriPetWithinExaminationPeriod && patientMri.length === 0) ||
    (isMriPetWithinExaminationPeriod &&
      patientMri.length > 0 &&
      patientMri[0].mri_status !== MriStatusID.USER_REPORTED);

  // patient_nouknows更新
  useUsc014DBActions();

  // トップに戻る
  const handleGoTop = () => {
    navigate(PATIENT.USB001);
  };
  const handleGoMri = () => {
    navigate(PATIENT.USC024);
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          selectedTab={SELECTED_TAB_NUM.examination}
          minWidth="1076px"
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="のうKNOW®"
          borderBottom="primary"
          withButton={false}
        />
        <LayoutBox justify="center">
          <Sheet padding="24px" maxWidth="1112px">
            <Sheet type="card">
              <LayoutBox direction="column" fullWidth align="center" gap="3x">
                <LayoutBox direction="column" fullWidth align="center" gap="2x">
                  <Icon color="#388e3c" icon="check_circle" size="xl" />
                  <Text bold size="2xl">
                    テストが完了しました
                  </Text>
                  <Text textAlign="center" size="xl">
                    数日後に結果画面からテスト結果とレポートを確認できるようになります。
                  </Text>
                </LayoutBox>
                <Divider orange={false} darkColor margin="0" />
                {isMriClickable && (
                  <SpButton
                    width="311px"
                    size="medium"
                    mobileFullWidth
                    iconSize="large"
                    withArrowLeft
                    onClick={handleGoMri}
                  >
                    MRI検査＋AI解析へ
                  </SpButton>
                )}

                <SpButton
                  type="secondary"
                  width="311px"
                  size="medium"
                  mobileFullWidth
                  iconSize="large"
                  withArrowLeft
                  onClick={handleGoTop}
                >
                  トップに戻る
                </SpButton>
              </LayoutBox>
            </Sheet>
          </Sheet>
        </LayoutBox>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC014;
