import { useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";

const useUsc024Actions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);

  // 予約取消API 失敗時にはfalseを返します。
  const updateMriPetCancel = async (
    id: string,
    note: string,
    userFlag: boolean,
  ) => {
    openUpdateActionLoading();
    try {
      // 予約取消API
      await currentUser?.functions["booking/cancelBooking"](id, note, userFlag);

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  return {
    error,
    updateMriPetCancel,
  };
};

export default useUsc024Actions;
