import React from "react";
import SpButton from "../Button/SpButton";
import LayoutBox from "../LayoutBox/LayoutBox";
import Text from "../Text/Text";
import { mobileView } from "../../constants/common";

export type FooterButtonProps = {
  pageNumber?: number;
  total?: number;
  padding?: string;
  justify?: "start" | "center" | "end" | "between" | "around";
  leftButtonText?: string;
  leftButtonType?: "primary" | "secondary" | "white" | "danger" | "shop";
  leftButtonDisabled?: boolean;
  onClickLeft?: (value?: React.MouseEvent<HTMLButtonElement>) => void;
  rightButtonText?: string;
  rightButtonType?: "primary" | "secondary" | "white" | "danger" | "shop";
  rightButtonDisabled?: boolean;
  onClickRight?: (value?: React.MouseEvent<HTMLButtonElement>) => void;
  width?: string;
  scrollable?: boolean;
};

const FooterButton = ({
  pageNumber,
  total,
  padding = "",
  justify = "center",
  leftButtonType = "secondary",
  leftButtonText = "",
  leftButtonDisabled = false,
  onClickLeft,
  rightButtonType = "primary",
  rightButtonText = "",
  rightButtonDisabled = false,
  onClickRight,
  width = "",
  scrollable,
}: FooterButtonProps) => {
  const [isMobile, setIsMobile] = React.useState(false);

  const footerButtonClass = React.useMemo(() => {
    const scrollableClass = scrollable ? [`footer-button--scrollable`] : [];

    return ["footer-button", ...scrollableClass].join(" ");
  }, [scrollable]);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileView);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={footerButtonClass} style={{ padding }}>
      <div className="footer-button-container">
        <LayoutBox
          justify={justify}
          fullWidth
          align="center"
          gap="2x"
          maxWidth="1064px"
        >
          <SpButton
            type={leftButtonType}
            width={width}
            size="medium"
            disabled={leftButtonDisabled}
            withArrowLeft={!isMobile}
            onClick={onClickLeft}
          >
            {isMobile ? "カートに戻る" : leftButtonText}
          </SpButton>

          {pageNumber && total ? (
            <Text size="2xl" bold>
              {pageNumber}
              <span>/</span>
              {total}
            </Text>
          ) : (
            ""
          )}
          <SpButton
            type={rightButtonType}
            width={width}
            size="medium"
            disabled={rightButtonDisabled}
            withArrowRight={!isMobile}
            onClick={onClickRight}
          >
            {rightButtonText}
          </SpButton>
        </LayoutBox>
      </div>
    </div>
  );
};

FooterButton.defaultProps = {
  pageNumber: undefined,
  total: undefined,
  justify: "center",
  padding: "0",
  leftButtonType: "secondary",
  leftButtonText: "",
  leftButtonDisabled: false,
  rightButtonType: "primary",
  rightButtonText: "",
  rightButtonDisabled: false,
  width: "",
  onClickLeft: () => [],
  onClickRight: () => [],
  scrollable: false,
};

export default FooterButton;
