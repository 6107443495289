import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregatePetHospitals, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";

// PET受診可能な病院を都道府県で取得
const usePetHospitalsFetch = (prefecture: string) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [petHospitals, setPetHospitals] = useStateCustomObj<PetHospital[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(currentUser, Collection.HOSPITALS);
        const aggregate = getAggregatePetHospitals(prefecture);
        const fetchResult = (await collection.aggregate(
          aggregate,
        )) as PetHospital[];

        setPetHospitals(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (prefecture) void fetchData();
  }, [currentUser, setPetHospitals, prefecture]);

  return { error, petHospitals };
};

export default usePetHospitalsFetch;
