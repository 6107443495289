// Presentationalコンポーネント(デザインに関する情報を記載)

import React from "react";
import Header from "./Header";
import Link from "../Link/Link";
import MailIcon from "../../assets/Mail.svg";
import Badge from "../Badge/Badge";
import AccountMenu from "../Menu/AccountMenu";
import { HEADER_LINK } from "../../constants/pagePaths";
import Logo from "../Logo/Logo";
import { mobileView } from "../../constants/common";

export type HeaderUserComponentProps = {
  userName?: string;
  withRightControl?: boolean;
  backgroundColor?: "white" | "rough-yellow" | "none" | undefined;
  newNotificationCount?: number;
  handleShowProfile?: () => void;
  handleShowPassword?: () => void;
  handleShowReceiptList?: () => void;
  handleInvite?: () => void;
  handleLogout?: () => void;
  // changeEmp
  handleMessage?: () => void;
  isInvitationMenu?: boolean;
  handleTop?: () => void;
  hasInvitationUrls?: boolean;
};

const HeaderUserComponent = ({
  userName = "",
  withRightControl = true,
  backgroundColor = "white",
  newNotificationCount = undefined,
  handleShowProfile = () => undefined,
  handleShowPassword = () => undefined,
  handleShowReceiptList = () => undefined,
  handleInvite = () => undefined,
  handleLogout = () => undefined,
  // changeEmp
  handleMessage = () => undefined,
  isInvitationMenu = false,
  handleTop = () => undefined,
  hasInvitationUrls = false,
}: HeaderUserComponentProps) => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileView);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Header
      backgroundColor={backgroundColor}
      leftControl={
        <Link
          url={HEADER_LINK}
          external={false}
          linkClassName="header__left-logo"
        >
          <Logo size="medium" type="horizontal" />
        </Link>
      }
      rightControl={
        withRightControl ? (
          <>
            {newNotificationCount !== undefined && (
              <button
                type="button"
                className="header__btn header__btn-message"
                onClick={handleMessage}
              >
                <div className="header__btn-message-container">
                  <div className="header__btn-message__icon">
                    <img src={MailIcon} alt="message_icon" />
                    {newNotificationCount > 0 && (
                      <Badge counter={newNotificationCount} />
                    )}
                  </div>
                  <span>{!isMobile ? "メッセージ" : "通知"}</span>
                </div>
              </button>
            )}
            <AccountMenu
              onClickProfile={handleShowProfile}
              onClickPassword={handleShowPassword}
              onClickReceipt={handleShowReceiptList}
              onClickInvitedAccount={handleInvite}
              onClickLogout={handleLogout}
              userName={userName}
              isInvitationMenu={isInvitationMenu}
              onClickTop={handleTop}
              hasInvitationUrls={hasInvitationUrls}
            />
          </>
        ) : undefined
      }
    />
  );
};

HeaderUserComponent.defaultProps = {
  userName: "",
  withRightControl: true,
  backgroundColor: "white",
  newNotificationCount: undefined,
  handleShowProfile: () => undefined,
  handleShowPassword: () => undefined,
  handleShowReceiptList: () => undefined,
  handleInvite: () => undefined,
  handleLogout: () => undefined,
  // changeEmp
  handleMessage: () => undefined,
  isInvitationMenu: false,
  handleTop: () => undefined,
  hasInvitationUrls: false,
};

export default HeaderUserComponent;
