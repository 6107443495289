import React, { CSSProperties } from "react";

export type TextProps = {
  color?:
    | "neutralBlack"
    | "neutralLight"
    // changeEmp 既に実装で使用していたため残す
    | "neutralUserLightest"
    | "neutralUserBlack"
    | "neutralUserLight"
    | "neutralUserLightest"
    | "placeholder"
    | "white"
    | "primary"
    | "secondary"
    | "secondaryMain"
    | "error"
    | "success"
    | "information"
    | "warning"
    | "warningDark"
    | "orange"
    | "red"
    | "disabled"
    | "normal"
    | "blue"
    | "darkBlue"

    // changeEmp
    | "A21B11";

  size?:
    | "5xl"
    | "4xl"
    | "3xl"
    | "2xl"
    | "1xl"
    | "xl"
    | "large"
    | "base"
    | "small"
    | "xs"
    | "2xs"
    | "3xs";
  textAlign?: "left" | "right" | "center" | "justify";
  bold?: boolean;
  semibold?: boolean;
  lineHeight?: string;
  noWrap?: boolean;
  ellipsis?: boolean;
  ellipsisLine?: string;
  width?: string;
  backgroundColor?: string;
  children: React.ReactNode;
  titleFont?: boolean;
  underline?: boolean;
  yellowUnderline?: boolean;
  borderRadius?: string;
  inline?: boolean;
  // changeEmp
  whiteSpace?: CSSProperties["whiteSpace"];
};

const Text = ({
  color = "neutralBlack",
  size = "base",
  textAlign = "left",
  bold = false,
  semibold = false,
  lineHeight = "150%",
  noWrap = false,
  ellipsis = false,
  width = undefined,
  backgroundColor = undefined,
  children,
  titleFont = false,
  ellipsisLine = "1",
  underline,
  yellowUnderline,
  borderRadius,
  inline,
  // changeEmp
  whiteSpace = "normal",
}: TextProps) => {
  const textClass = React.useMemo(() => {
    const boldClass = bold ? ["text--bold"] : [];
    const semiboldClass = semibold ? ["text--semibold"] : [];
    const colorClass = color ? [`text--${color}`] : [];
    const noWrapClass = noWrap ? ["text--nowrap"] : [];
    const ellipsisClass = ellipsis ? ["text--ellipsis"] : [];
    const backgroundColorClass = backgroundColor
      ? ["text--background-color"]
      : [];
    const titleFontClass = titleFont ? ["text--title-font"] : [];
    const underlineClass = underline ? ["text--underline"] : [];
    const inlineClass = inline ? ["text--inline"] : [];
    const yellowUnderlineClass = yellowUnderline
      ? ["text--underline-yellow"]
      : [];

    return [
      "text",
      `text--${size}`,
      ...boldClass,
      ...semiboldClass,
      ...colorClass,
      ...noWrapClass,
      ...ellipsisClass,
      ...backgroundColorClass,
      ...titleFontClass,
      ...underlineClass,
      ...yellowUnderlineClass,
      ...inlineClass,
    ].join(" ");
  }, [
    backgroundColor,
    bold,
    semibold,
    color,
    ellipsis,
    noWrap,
    size,
    titleFont,
    underline,
    yellowUnderline,
    inline,
  ]);

  return (
    <span
      className={textClass}
      style={{
        lineHeight,
        width,
        backgroundColor,
        textAlign,
        WebkitLineClamp: ellipsisLine,
        borderRadius,
        // changeEmp
        whiteSpace: noWrap ? "nowrap" : whiteSpace,
      }}
    >
      {children}
    </span>
  );
};

Text.defaultProps = {
  color: "neutralBlack",
  size: "base",
  bold: false,
  inline: false,
  semibold: false,
  lineHeight: "150%",
  noWrap: false,
  ellipsis: false,
  width: undefined,
  backgroundColor: undefined,
  textAlign: "left",
  titleFont: false,
  ellipsisLine: "1",
  underline: false,
  yellowUnderline: false,
  borderRadius: "",
  whiteSpace: "normal",
};

export default Text;
