import ListItem from "./ListItem";

export type ListProps = {
  height?: string;
  onClick?: (value: string) => void;
  items: {
    id: string;
    left?: JSX.Element;
    center: JSX.Element;
    right: JSX.Element;
  }[];
  selectedIds: string[] | [];
  rightTop?: boolean;
  hasCheckbox?: boolean;
  maxLoad?: number;
};

const List = ({
  height = "auto",
  onClick,
  items,
  selectedIds = [],
  rightTop,
  hasCheckbox,
  maxLoad,
}: ListProps) => (
  <div className="list" style={{ height }}>
    {items.slice(0, maxLoad).map((item) => {
      const isSelected = selectedIds.some(
        (selectedId) => selectedId === item.id,
      );

      return (
        <ListItem
          {...item}
          isSelected={isSelected}
          onClick={onClick}
          key={`list-item-${item.id}`}
          rightTop={rightTop}
          hasCheckbox={hasCheckbox}
        />
      );
    })}
  </div>
);

List.defaultProps = {
  height: "auto",
  rightTop: false,
  hasCheckbox: false,
  onClick: undefined,
  maxLoad: undefined,
};

export default List;
