import useSWR from "swr";
import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateUSD003, getMongoDb } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import generateUniqueId from "../../../utils/generateUniqueId";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";

const useUSD003Actions = () => {
  const [actionError, setActionError] = useState<Error | null>(null);
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  /**
   *  ユーザーの全てのチャットを取得します。
   */
  const getAllChatData = async (): Promise<Chats[]> => {
    const mongoDb = getMongoDb(currentUser, Collection.CHATS);
    const aggregate = getAggregateUSD003(currentUser?.id);
    const chats = (await mongoDb.aggregate(aggregate)) as unknown as Chats[];

    return chats;
  };

  // 1秒間隔で、チャットデータが更新されたかを取得
  const { data: allChatData = [], error: fetchError = null } = useSWR<
    Partial<Chats>[],
    Error
  >("getAllChatData", getAllChatData, {
    refreshInterval: 1000,
  });

  /**
   *  チャットを追加します。
   * @param {String} ご相談内容
   */
  const addComment = async (props: { comment: string }) => {
    const { comment } = props;

    try {
      const mongoDb = getMongoDb(currentUser, Collection.CHATS);
      const updateData = {
        _id: generateUniqueId(),
        comment,
        patient_id: currentUser?.id,
        admin_user_id: undefined,
        commented_user: "patient",
        register_date: new Date(),
        files: undefined,
        monthly_report: false,
        is_read: false,
        created_at: new Date(),
        updated_at: new Date(),
      };
      await mongoDb.insertOne(updateData);
    } catch (err) {
      setActionError(checkActionErr(err));
    }
  };

  useEffect(() => {
    // 管理者側のメッセージを既読に変更します。
    const changeToRead = async () => {
      try {
        const mongoDb = getMongoDb(currentUser, Collection.CHATS);
        const updateData = {
          is_read: true,
        };
        await mongoDb.updateMany(
          {
            patient_id: currentUser?.id,
            commented_user: "admin",
            is_read: false,
          },
          { $set: updateData },
        );
      } catch (err) {
        setActionError(checkActionErr(err));
      }
    };
    void changeToRead();
  }, [currentUser, allChatData]);

  return {
    fetchError,
    actionError,
    allChatData,
    addComment,
  };
};

export default useUSD003Actions;
