import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  checkFetchErr,
  redirectToNoDataPage,
} from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateNotificationById, getMongoDb } from "../../utils/query";
import useStateCustomObj from "../base/useStateCustomObj";

const useNotificationFetchById = (notificationId: string) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [notification, setNotification] =
    useStateCustomObj<PatientNotification | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collection = getMongoDb(currentUser, Collection.NOTIFICATIONS);
        const aggregate = getAggregateNotificationById(notificationId);
        const fetchResult = (await collection.aggregate(
          aggregate,
        )) as PatientNotification[];

        if (fetchResult.length < 1) redirectToNoDataPage();

        setNotification(fetchResult[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, setNotification, notificationId]);

  return { error, notification };
};

export default useNotificationFetchById;
