import React, { useCallback } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import SpDropdown from "../components/Dropdown/SpDropdown";
import SpButton from "../components/Button/SpButton";
import { INVITATION } from "../constants/pagePaths";
import useUsa033Actions from "../hooks/pages/USA033/useUsa033DBActions";
import useUsa033Conditions from "../hooks/pages/USA033/useUsa033Conditions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import Divider from "../components/Divider/Divider";
import PatientFooter from "../features/PatientFooter";
import { convertToCommaSeparatedString } from "../utils/utils";

const SP_TAB_ITEMS = [
  {
    id: "1",
    label: "招待したアカウント",
  },
  {
    id: "2",
    label: "支払い状況",
  },
];

const USA033 = () => {
  const [conditions] = useUsa033Conditions();
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  const now = new Date();
  const { result, error, selectOrderYear, fetchError } = useUsa033Actions({
    orderYear: searchCriteria.orderYear
      ? searchCriteria.orderYear
      : String(now.getFullYear()),
  });
  const navigate = useNavigateWithUrl();
  const [selectedButton, setSelectedId] = React.useState<string | number>("2");

  // エラー処理
  useCheckErrorThrowError([fetchError, error]);

  // openCard格納データ
  const cardData = (month: number) => ({
    id: String(month + 1).padStart(2, "0"),
    month: String(month + 1),
    opened: false,
  });

  // 日時の初期情報を取得
  const OLDEST_MONTH = new Date(now.getFullYear() - 1, 12);
  const resultMonth: React.SetStateAction<
    { id: string; month: string; opened: boolean }[]
  > = [];
  for (
    let month = OLDEST_MONTH;
    month <= now;
    month.setMonth(month.getMonth() + 1)
  ) {
    if (String(month.getFullYear()) === String(now.getFullYear())) {
      resultMonth.push(cardData(month.getMonth()));
    }
  }
  const [openCard, setOpenCard] = React.useState(resultMonth);

  // 表示年セレクトボックス押下時
  const handleChangeYear = (value: string) => {
    const OLDEST_MONTH_CHANGE = new Date(Number(value) - 1, 12);
    const resultMonthChange: React.SetStateAction<
      { id: string; month: string; opened: boolean }[]
    > = [];
    for (
      let month = OLDEST_MONTH_CHANGE;
      month <= now;
      month.setMonth(month.getMonth() + 1)
    ) {
      if (value === String(month.getFullYear())) {
        resultMonthChange.push(cardData(month.getMonth()));
      }
    }
    setOpenCard(resultMonthChange);
    setSearchCriteria((prevState) => ({ ...prevState, orderYear: value }));
  };

  // アコーディオン押下時の動作
  const handleButtonClick = (cardId: string) => {
    const updatedCards = openCard.map((card) =>
      card.id === cardId ? { ...card, opened: !card.opened } : card,
    );

    setOpenCard(updatedCards);
  };

  // 招待したアカウントに遷移
  const handleClickTab = useCallback(
    (selected: string | number) => {
      setSelectedId(selected);
      if (selected === "1") navigate(INVITATION.USA030);
    },
    [navigate],
  );

  // 前画面に戻る
  const handleClickBack = () => navigate(INVITATION.USA029);

  // 月ごとの合計値
  const handleCharges = (
    result: Array<USA033Type>,
    value: string,
    id: string,
  ) => {
    const totalPrice = result.reduce((total, data) => {
      if (value === data.year && id === data.month) {
        return total + Number(data.amount); // もしくは parseInt(data.amount, 10)
      }

      return total;
    }, 0);

    return convertToCommaSeparatedString(totalPrice);
  };

  return (
    <div className="admin-area user-body-sp-full-height">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header="招待したアカウント"
          borderBottom="primary"
          minWidth="1078px"
          onClickBack={handleClickBack}
        />
        <Sheet padding="40px 0" className="util-sp-py-24" minWidth="1076px">
          <LayoutBox direction="column" width="1064px" gap="3x">
            <ToggleButton
              size="large"
              width="343px"
              items={SP_TAB_ITEMS}
              selectedButton={selectedButton}
              onClick={handleClickTab}
            />
            <SpDropdown
              items={selectOrderYear}
              value={
                searchCriteria.orderYear
                  ? searchCriteria.orderYear
                  : String(now.getFullYear())
              }
              onChange={handleChangeYear}
              width="195px"
            />
            <LayoutBox gap="1x" fullWidth direction="column">
              {openCard.map((card) => (
                <Sheet key={card.month} type="card" padding="16px">
                  <LayoutBox fullWidth gap="1x">
                    <SpButton
                      type="iconOnly"
                      icon={
                        card.opened
                          ? "keyboard_arrow_up"
                          : "keyboard_arrow_down"
                      }
                      iconSize="medium"
                      onClick={() => handleButtonClick(card.id)}
                    />
                    <LayoutBox direction="column" fullWidth gap="1x">
                      <LayoutBox justify="between" fullWidth>
                        <Text size="large" color="normal">
                          {card.month}月
                        </Text>
                        <Text size="large" color="normal" bold>
                          {handleCharges(
                            result,
                            searchCriteria.orderYear
                              ? searchCriteria.orderYear
                              : String(now.getFullYear()),
                            card.id,
                          )}
                          円
                        </Text>
                      </LayoutBox>
                      {card.opened && (
                        <LayoutBox direction="column" fullWidth gap="1x">
                          {result
                            .filter((data) => data.month === card.id)
                            .map((data, index) => (
                              <React.Fragment key={index}>
                                <LayoutBox justify="between" fullWidth>
                                  <Text size="large" color="normal">
                                    {data.full_name}
                                  </Text>
                                  <Text size="large" color="normal">
                                    {`${convertToCommaSeparatedString(
                                      data.amount,
                                    )}円`}
                                  </Text>
                                </LayoutBox>
                                <Divider darkColor orange={false} margin="0" />
                              </React.Fragment>
                            ))}
                        </LayoutBox>
                      )}
                    </LayoutBox>
                  </LayoutBox>
                </Sheet>
              ))}
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div className="util-py-40" />
      </div>
      <PatientFooter />
    </div>
  );
};
export default USA033;
