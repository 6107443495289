import React from "react";
import Checkbox from "../Checkbox/Checkbox";

export type ListItemProps = {
  id: string;
  isSelected?: boolean;
  narrow?: boolean;
  noBorder?: boolean;
  left?: JSX.Element;
  right: JSX.Element;
  center: JSX.Element;
  rightTop?: boolean;
  hasCheckbox?: boolean;
  onClick?: (value: string) => void;
};

const ListItem = ({
  id,
  isSelected = false,
  narrow = false,
  noBorder = false,
  left,
  right,
  center,
  rightTop,
  hasCheckbox,
  onClick,
}: ListItemProps) => {
  const listItemClass = React.useMemo(() => {
    const noBorderClass = noBorder ? ["list-item--no-border"] : [];
    const narrowClass = narrow ? ["list-item--narrow"] : [];
    const selectedClass = isSelected ? ["list-item--selected"] : [];

    return [
      "list-item",
      ...selectedClass,
      ...narrowClass,
      ...noBorderClass,
    ].join(" ");
  }, [isSelected, narrow, noBorder]);

  const _onClick = React.useCallback(() => {
    if (onClick) onClick(id);
  }, [id, onClick]);

  return (
    <div className={listItemClass} onClick={_onClick}>
      {hasCheckbox && <Checkbox />}
      {left !== undefined && <span className="list-item__left">{left}</span>}
      {center && <span className="list-item__center">{center}</span>}
      {right && rightTop ? (
        <span className="list-item__right--top">{right}</span>
      ) : (
        <span className="list-item__right">{right}</span>
      )}
    </div>
  );
};

ListItem.defaultProps = {
  isSelected: false,
  narrow: false,
  noBorder: false,
  rightTop: false,
  hasCheckbox: false,
  left: undefined,
  onClick: undefined,
};

export default ListItem;
