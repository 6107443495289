import { useEffect, useState } from "react";
import { PatientNouKnowStatusID } from "../../constants/common";
import { useCheckErrorThrowError } from "../../utils/checkError";
import { isDateWithinRange } from "../../utils/utils";
import usePatientNouknowsFetch from "./usePatientNouknowsFetch";

// nouknowが受検可能か
const useNouknowEligibility = () => {
  const { patientNouknows, error: nouknowError } = usePatientNouknowsFetch();
  const [isNouknowsEligible, setIsNouknowsEligible] = useState(false);

  useCheckErrorThrowError([nouknowError]);

  useEffect(() => {
    const checkNouknowEligibility = () => {
      if (patientNouknows.length === 0) {
        setIsNouknowsEligible(false);

        return;
      }

      // 期間内でテスト未実施のデータが対象
      const enabledPatientNouknows = patientNouknows.filter(
        (item) =>
          isDateWithinRange(item.exam_start, item.exam_end, new Date()) &&
          item.status === PatientNouKnowStatusID.PRETEST,
      );

      if (enabledPatientNouknows.length > 0) {
        setIsNouknowsEligible(true);
      } else {
        setIsNouknowsEligible(false);
      }
    };

    checkNouknowEligibility();
  }, [patientNouknows]);

  return { isNouknowsEligible };
};

export default useNouknowEligibility;
