import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Icon from "../components/Icon/Icon";
import SpButton from "../components/Button/SpButton";
import { INVITATION, PATIENT } from "../constants/pagePaths";
import PatientFooter from "../features/PatientFooter";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import usePath from "../hooks/common/usePath";

const USG016 = () => {
  const navigate = useNavigateWithUrl();
  const [path] = usePath();

  // トップへ
  const handleNavigateTop = () => {
    if (path === PATIENT.USB001) {
      navigate(PATIENT.USB001);
    } else {
      navigate(INVITATION.USA029);
    }
  };

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser withRightControl={false} />
        <div className="admin-inner area-with-header-footer">
          <LayoutBox
            fullWidth
            justify="center"
            align="center"
            direction="column"
          >
            <Title
              padding="16px 108px 24px 108px"
              background="default"
              header="パスワード変更"
              borderBottom="primary"
              withButton={false}
            />
            <Sheet className="util-py-40 util-sp-py-24">
              <LayoutBox direction="column" maxWidth="440px" width="440px">
                <Sheet
                  type="card"
                  padding="24px 16px 32px 16px"
                  className="util-sp-pt-16"
                >
                  <LayoutBox
                    gap="3x"
                    direction="column"
                    align="center"
                    fullWidth
                  >
                    <LayoutBox direction="column" gap="2x" align="center">
                      <Icon color="#388e3c" icon="check_circle" size="xl" />
                      <Text
                        bold
                        size="2xl"
                        color="neutralUserBlack"
                        lineHeight="150%"
                        textAlign="center"
                      >
                        パスワードの変更が <br />
                        完了しました
                      </Text>
                      <Text
                        size="xl"
                        color="neutralUserBlack"
                        lineHeight="150%"
                        textAlign="center"
                      >
                        今後は新しいパスワードを使用して
                        <br />
                        ログインしてください。
                      </Text>
                    </LayoutBox>
                    <SpButton
                      type="primary"
                      width="100%"
                      size="medium"
                      onClick={handleNavigateTop}
                    >
                      トップへ戻る
                    </SpButton>
                  </LayoutBox>
                </Sheet>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USG016;
