import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useRealmApp } from "../contexts/RealmApp";
import {
  INVITATION,
  NON_ACTIVE_PRESENT,
  PATIENT,
  WITHDRAWAL,
} from "../constants/pagePaths";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";
import { UserStatusID } from "../constants/common";

// ログイン必須画面(アクティブユーザ)
const RequiredLoginActiveElement: React.FC<{
  element: JSX.Element;
  path: string;
}> = ({ element, path }) => {
  const realmAppContext = useRealmApp();
  const isLogin = Boolean(realmAppContext.currentUser);
  const location = useLocation();

  // ステータス
  const statusObject = realmAppContext.currentUser?.customData
    .status as usersNumberIntType;
  const statusString = statusObject ? statusObject.$numberInt : null;
  const status = statusString ? Number(statusString) : null;

  const invitationAccessPaths: string[] = Object.values(INVITATION);
  const patientAccessPaths: string[] = Object.values(PATIENT);
  const nonActivePresentAccessPaths: string[] =
    Object.values(NON_ACTIVE_PRESENT);
  const withdrawalAccessPaths: string[] = Object.values(WITHDRAWAL);

  const isInvitationPage = invitationAccessPaths.includes(path);
  const isPatientPage = patientAccessPaths.includes(path);
  const isNonActivePresentPage = nonActivePresentAccessPaths.includes(path);
  const isWithdrawalPage = withdrawalAccessPaths.includes(path);

  const isInvitedUser = status === UserStatusID.INVITATION;
  const isWithdrawalUser = status === UserStatusID.CANCELLATION;
  const isNonActivePresentUser =
    status === UserStatusID.UNREGISTERED &&
    realmAppContext.currentUser?.customData.activation_date;

  // ストライプ セッションストレージクリア
  useEffect(() => {
    const allowedPaths = [
      PATIENT.USC012,
      PATIENT.USD001,
      PATIENT.USC007,
      PATIENT.USC019,
      PATIENT.USC020,
    ] as string[];
    const currentPath = location.pathname;

    if (!allowedPaths.includes(currentPath)) {
      sessionStorage.removeItem("petBookingInput");
      sessionStorage.removeItem("mriBookingInput");
      sessionStorage.removeItem("interviewBookingInput");
    }

    const handleBeforeUnload = () => {
      const currentPath = window.location.pathname;
      if (!allowedPaths.includes(currentPath)) {
        sessionStorage.removeItem("petBookingInput");
        sessionStorage.removeItem("mriBookingInput");
        sessionStorage.removeItem("interviewBookingInput");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.pathname]);

  // 初期表示時データをリフレッシュ
  const [isRefresh, setIsRefresh] = useState(false);
  useEffect(() => {
    const refreshData = async () => {
      addLoadCount();
      try {
        await realmAppContext.currentUser?.refreshCustomData();
        setIsRefresh(true);
      } finally {
        decrementLoadCount();
      }
    };
    if (!isRefresh) void refreshData();
  }, [isRefresh, realmAppContext.currentUser]);

  // リフレッシュ中は非表示
  if (!isRefresh) return <></>;

  if (!isLogin) {
    return <Navigate to={PATIENT.USA001} />;
  }

  // ステータスがない場合、NotFound
  if (status == null) {
    return <Navigate to={PATIENT.NOT_FOUND} />;
  }

  // 招待者専用ページへのアクセスを制御
  if (isInvitedUser && !isInvitationPage) {
    return <Navigate to={PATIENT.NOT_FOUND} />;
  }

  // 会員ページへのアクセスを制御
  if (!isInvitedUser && !isNonActivePresentUser && !isPatientPage) {
    return <Navigate to={PATIENT.NOT_FOUND} />;
  }

  // 被招待者で承認待ち
  if (isNonActivePresentUser && !isNonActivePresentPage) {
    return <Navigate to={PATIENT.NOT_FOUND} />;
  }

  // 退会ユーザ
  if (isWithdrawalUser && !isWithdrawalPage) {
    return <Navigate to={PATIENT.NOT_FOUND} />;
  }

  return isLogin ? element : <Navigate to={PATIENT.USA001} />;
};

export default RequiredLoginActiveElement;
