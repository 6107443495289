import React from "react";
import { Navigate } from "react-router-dom";

import { useRealmApp } from "../contexts/RealmApp";
import { PATIENT } from "../constants/pagePaths";

// ログイン不要画面
const PublicElement: React.FC<{ element: JSX.Element }> = ({ element }) => {
  const realmAppContext = useRealmApp();
  const isLogin = Boolean(realmAppContext.currentUser);

  return isLogin ? <Navigate to={PATIENT.LOGIN_PROCESSING} /> : element;
};

export default PublicElement;
