import React from "react";
import LayoutBox from "../LayoutBox/LayoutBox";
import Image from "../Image/Image";
import Text from "../Text/Text";
import SpFormSet from "../FormSet/SpFormSet";
import Divider from "../Divider/Divider";
import SpButton from "../Button/SpButton";
import SpDropdown from "../Dropdown/SpDropdown";
import Tag from "../Tag/Tag";
import { getAmountDropdownItems } from "../../utils/getAmountDropdownItems";

const NUM_LIST = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

export type CardCartItemProps = {
  type?: "cart" | "view" | "cancel";
  items: {
    // changeEmp
    id?: string;
    tag?: string;
    name?: string;
    description?: string;
    price?: number;
    image?: string;
    // changeEmp
    quantity?: number;
    // changeEmp
    stock?: number;
  }[];
  padding?: string;
  // changeEmp
  onClickDelete?: (id: string) => void;
  // changeEmp
  onChangeQuantity?: (id: string, quantity: number) => void;
  // changeEmp
  onClick?: (id: string) => void;
};

const CardCartItem = ({
  type = "cart",
  items,
  padding = "8px",
  // changeEmp
  onClickDelete,
  // changeEmp
  onChangeQuantity,
  // changeEmp
  onClick,
}: CardCartItemProps) => {
  const handleChangeNum = React.useCallback(
    (id: string, value: string) => {
      // changeEmp
      if (onChangeQuantity) onChangeQuantity(id, Number(value));
    },
    [onChangeQuantity],
  );

  // changeEmp
  const handleClickDelete = React.useCallback(
    (id?: string) => {
      if (onClickDelete && id) onClickDelete(id);
    },
    [onClickDelete],
  );

  // changeEmp
  const handleClick = React.useCallback(
    (id?: string) => {
      if (onClick && id) onClick(id);
    },
    [onClick],
  );

  return (
    <>
      {items.map((item, index) => (
        <div
          className="card-cart"
          key={index}
          onClick={() => handleClick(item.id)}
        >
          <LayoutBox fullWidth direction="column">
            <div className="card-cart__container">
              <div className="card-cart__detail" style={{ padding }}>
                <LayoutBox gap="3x" fullWidth align="center">
                  <div className="card-cart__image">
                    <Image
                      url={item.image}
                      height="120px"
                      width="120px"
                      className="image--cart"
                    />
                  </div>
                  <LayoutBox direction="column" width="100%">
                    {item.tag && (
                      <Tag label={item.tag} state="neutral" showIcon={false} />
                    )}
                    <Text size="xl" color="normal" bold>
                      {item.name}
                    </Text>
                    <Text size="large" color="normal">
                      {item.description}
                    </Text>
                    <div className="util-mt-4 util-mb-4">
                      <LayoutBox gap="1/2x" align="center" justify="center">
                        <Text size="2xl" bold textAlign="center">
                          {item.price && item.price.toLocaleString()}
                        </Text>
                        <Text size="large" bold>
                          円
                        </Text>
                      </LayoutBox>
                    </div>
                    {type === "view" && (
                      <Text size="large" color="normal">
                        数量：{item.quantity}
                      </Text>
                    )}
                  </LayoutBox>
                </LayoutBox>
                {type === "cart" && (
                  <div className="card-cart__button-form">
                    <SpButton
                      width="88px"
                      icon="delete"
                      type="danger"
                      // changeEmp
                      onClick={(e) => {
                        e?.stopPropagation();
                        handleClickDelete(item?.id);
                      }}
                    >
                      削除
                    </SpButton>
                    <SpFormSet label="数量" vertical>
                      <SpDropdown
                        value={item.quantity?.toString()}
                        items={
                          // changeEmp
                          getAmountDropdownItems(item.stock)
                        }
                        onChange={(value) =>
                          handleChangeNum(item.id ?? "", value)
                        }
                        width="88px"
                      />
                    </SpFormSet>
                  </div>
                )}
              </div>
            </div>

            <Divider darkColor orange={false} margin="0" />
          </LayoutBox>
        </div>
      ))}
    </>
  );
};

CardCartItem.defaultProps = {
  type: "cart",
  padding: "8px",
  // changeEmp
  onClickDelete: undefined,
  // changeEmp
  onChangeQuantity: undefined,
  // changeEmp
  onClick: undefined,
};

export default CardCartItem;
