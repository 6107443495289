import { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import SpButton from "../components/Button/SpButton";
import DialogCustom from "../components/Dialog/DialogCustom";
import Icon from "../components/Icon/Icon";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import List from "../components/List/List";
import Text from "../components/Text/Text";
import useUsm001Dialog, {
  closeUsm001Dialog,
} from "../hooks/pages/USM001/useUsm001Dialog";
import useUsm001Fetch from "../hooks/pages/USM001/useUsm001Fetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import Spinner from "../components/Loading/Spinner";
import useUsm002Dialog, {
  openUsm002FromM001Dialog,
} from "../hooks/pages/USM002/useUsm002Dialog";
import USM002 from "./US-M002";

const USM001 = () => {
  const [isNoticeListDialogOpen] = useUsm001Dialog();
  const [{ isFromM001Open }] = useUsm002Dialog();
  const { notifications, error, isLoaded } = useUsm001Fetch();
  const perItems = 20;
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [records, setRecords] = useState(perItems);

  const [notificationId, setNotificationId] = useState("");

  useCheckErrorThrowError([error]);

  // お知らせリスト作成
  const listItems = notifications.map((notification) => ({
    id: notification._id,
    left: <></>,
    center: (
      <>
        <LayoutBox gap="1/2x" fullWidth direction="column">
          <LayoutBox gap="1x" fullWidth>
            <Text size="base" color="neutralLight">
              {notification.deliveriedAt}{" "}
            </Text>
          </LayoutBox>
          <Text size="large" bold>
            {notification.title}
          </Text>
        </LayoutBox>
      </>
    ),
    right: (
      <>
        <Icon icon="expand_circle_right" type="outlined" color="#CF4900" />
      </>
    ),
  }));

  // 読み込み表示
  const loadMore = () => {
    if (records >= listItems.length) {
      setHasMoreItems(false);
    } else {
      setTimeout(() => {
        setRecords(records + perItems);
      }, 1000);
    }
  };

  // お知らせ押下
  const handleClickNotification = (id: string) => {
    setNotificationId(id);
    openUsm002FromM001Dialog();
  };

  return (
    <>
      <DialogCustom
        open={isNoticeListDialogOpen}
        closeDialog={() => closeUsm001Dialog()}
        title="お知らせ"
        isShowCloseButton={false}
        height="100%"
        isMobile
        paddingFooter="16px"
        paddingBody="16px 0"
        footerRight={
          <SpButton
            size="medium"
            type="secondary"
            width="100%"
            onClick={() => closeUsm001Dialog()}
          >
            閉じる
          </SpButton>
        }
      >
        {!isLoaded && (
          <LayoutBox gap="5x" fullWidth direction="column" align="center">
            <div className="util-flex util-flex--align-center util-flex--justify-center util-mt-16 util-mb-16">
              <Spinner size="medium" color="gray" />
            </div>
          </LayoutBox>
        )}
        {listItems.length > 0 ? (
          <div className="util-full-width util-overflow-y">
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={hasMoreItems}
              loader={
                <div
                  key="infinite-scroll-loader"
                  className="util-flex util-flex--align-center util-flex--justify-center util-mt-16 util-mb-16"
                >
                  <Spinner size="medium" color="gray" />
                </div>
              }
              useWindow={false}
            >
              <List
                items={listItems}
                maxLoad={records}
                selectedIds={[]}
                onClick={handleClickNotification}
              />
            </InfiniteScroll>
          </div>
        ) : (
          isLoaded && (
            <LayoutBox gap="5x" fullWidth direction="column" align="center">
              <div style={{ marginTop: "10%" }}>
                <Text size="large" color="neutralLight">
                  表示する結果がありません
                </Text>
              </div>
            </LayoutBox>
          )
        )}
      </DialogCustom>
      {isFromM001Open && <USM002 notificationId={notificationId} />}
    </>
  );
};

export default USM001;
