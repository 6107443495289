import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Icon from "../components/Icon/Icon";
import SpButton from "../components/Button/SpButton";
import { useRealmApp } from "../contexts/RealmApp";
import PatientFooter from "../features/PatientFooter";
import { PATIENT } from "../constants/pagePaths";

/* メールアドレスからの認証画面 */
const USG009 = () => {
  const navigate = useNavigate();
  const realmAppContext = useRealmApp();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token") || "";
  const tokenId = queryParams.get("tokenId") || "";
  const mode = queryParams.get("mode") || "";

  useEffect(() => {
    // 認証処理
    const confirmUser = async () => {
      try {
        await realmAppContext.app.emailPasswordAuth.confirmUser({
          token,
          tokenId,
        });
        setIsConfirmed(true);
      } catch (error) {
        // メール再送画面に遷移
        navigate(PATIENT.USG008, { state: { isFailed: true } });
      }
    };

    if (mode === "verifyEmail") {
      void confirmUser();
    } else if (mode === "resetPassword") {
      // パスワード再設定画面へ
      navigate(PATIENT.USG003, { state: { token, tokenId } });
    }
  }, [token, tokenId, realmAppContext.app.emailPasswordAuth, navigate, mode]);

  // ログインボタン
  const handleNavigateLogin = () => {
    navigate(PATIENT.USA001);
  };

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser withRightControl={false} />
        <div className="admin-inner">
          <LayoutBox
            fullWidth
            justify="center"
            align="center"
            direction="column"
          >
            {isConfirmed && (
              <>
                <Title
                  padding="16px 108px 24px 108px"
                  background="default"
                  header="メールアドレス認証済"
                  borderBottom="primary"
                  withButton={false}
                />
                <Sheet className="util-py-40 util-sp-py-24">
                  <LayoutBox direction="column" maxWidth="440px" width="440px">
                    <Sheet
                      type="card"
                      padding="24px 16px 32px 16px"
                      className="util-sp-pt-16"
                    >
                      <LayoutBox
                        gap="3x"
                        direction="column"
                        align="center"
                        fullWidth
                      >
                        <LayoutBox direction="column" gap="2x" align="center">
                          <Icon color="#388e3c" icon="check_circle" size="xl" />
                          <Text
                            bold
                            size="2xl"
                            color="neutralUserBlack"
                            lineHeight="150%"
                            textAlign="center"
                          >
                            メールアドレス認証が <br />
                            完了しました
                          </Text>
                          <Text
                            size="xl"
                            color="neutralUserBlack"
                            lineHeight="150%"
                            textAlign="center"
                          >
                            ログインしてご利用ください。
                          </Text>
                        </LayoutBox>
                        <SpButton
                          type="primary"
                          width="100%"
                          size="medium"
                          onClick={handleNavigateLogin}
                        >
                          ログイン画面へ
                        </SpButton>
                      </LayoutBox>
                    </Sheet>
                  </LayoutBox>
                </Sheet>
              </>
            )}
          </LayoutBox>
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USG009;
