import React from "react";
import Icon from "../Icon/Icon";

export type StepBarItemProps = {
  lastStep?: boolean;
  status?: "complete" | "incomplete" | "current";
  stepCounter: number;
  label?: string;
  totalSteps: number;
};

const StepBarItem = ({
  lastStep = false,
  status = "incomplete",
  stepCounter,
  label,
  totalSteps,
}: StepBarItemProps) => {
  const StepBarItemClass = React.useMemo(() => {
    const statusClass = [`step-bar-item--${status}`];
    const lastStepClass = lastStep
      ? [`step-bar-item--last`]
      : [`step-bar-item--not-last`];

    return ["step-bar-item", ...statusClass, ...lastStepClass].join(" ");
  }, [status, lastStep]);

  const StepBarItemContainerClass = React.useMemo(() => {
    const statusClass = [`step-bar-item-container--${status}`];

    return ["step-bar-item-container", ...statusClass].join(" ");
  }, [status]);

  const zIndex = totalSteps - stepCounter;

  return (
    <div className={StepBarItemContainerClass} style={{ zIndex }}>
      <div className={StepBarItemClass}>
        <div className="step-bar-item__step-count">
          {status === "complete" && (
            <span className="step-bar-item__step-text">
              <Icon icon="check" color="#CF4900" />
            </span>
          )}
          {!lastStep && (
            <div>
              <span className="step-bar-item__step-text">STEP </span>
              {stepCounter}
            </div>
          )}
        </div>

        {status !== "complete" && (
          <span className="step-bar-item__step-text">{label}</span>
        )}
      </div>
    </div>
  );
};

StepBarItem.defaultProps = {
  lastStep: false,
  status: "incomplete",
  label: undefined,
};

export default StepBarItem;
