import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

// お知らせ一覧ダイアログの開閉状態を管理
const useUsm001Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openUsm001Dialog = () => {
  stateReactiveVar(true);
};

export const closeUsm001Dialog = () => {
  stateReactiveVar(false);
};

export default useUsm001Dialog;
