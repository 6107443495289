import { useCallback, useMemo } from "react";

import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Divider from "../components/Divider/Divider";
import Shortcut from "../components/Shortcut/Shortcut";
import SpButton from "../components/Button/SpButton";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Image from "../components/Image/Image";
import Title from "../components/Title/Title";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import CardShop from "../components/Card/CardShop";
import CartGroup from "../assets/cart-group-icon.svg";
import useUsf001Actions from "../hooks/pages/USF001/useUsf001DBActions";
import { setId } from "../hooks/common/useId";
import {
  useNavigateCustom,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import { useCheckErrorThrowError } from "../utils/checkError";
import USF006 from "./US-F006";
import { openUsf006Dialog } from "../hooks/pages/USF006/useUsf006Dialog";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import USF003 from "./US-F003";
import { openUsf003Dialog } from "../hooks/pages/USF003/useUsf003Dialog";
import Banner from "../components/Banner/Banner";
import { EmbeddedPageID } from "../constants/common";
import useGetCampaigns from "../hooks/common/useGetCampaigns";
import { addHyphenToZipcode } from "../utils/utils";
import PatientFooter from "../features/PatientFooter";

const SP_TAB_ITEMS = [
  {
    id: "1",
    label: "ショップ",
  },
  {
    id: "2",
    label: "注文履歴",
  },
];

const USF001 = () => {
  const navigate = useNavigateCustom();
  const navigateWithUrl = useNavigateWithUrl();
  const { result, error } = useUsf001Actions();
  const { campaigns, error: campaignsGetError } = useGetCampaigns({
    embeddedPage: EmbeddedPageID.EC_LIST, // TODO ECトップがDBのデータとして存在しないので確認
  });

  useCheckErrorThrowError([error, campaignsGetError]);

  const navigateToItemDetail = useCallback(
    (id: string) => {
      setId(id);
      navigateWithUrl(PATIENT.USF005);
    },
    [navigateWithUrl],
  );

  const navigateToItemList = useCallback(
    () => navigate(PATIENT.USF004),
    [navigate],
  );

  const getMinimumSkuAmount = useCallback(
    (skus: SkusType[]) => Math.min(...skus.map((sku) => sku.amount)),
    [],
  );

  const getImageUrl = useCallback(
    (imageUrls: string[]) =>
      imageUrls.length > 0
        ? `${imageUrls[imageUrls.length - 1]}?timestamp=${Date.now()}`
        : "",
    [],
  );

  const CardShopItems = useMemo(
    () =>
      (result?.recommendation_items ?? []).map((item) => ({
        title: item.item_name,
        price: getMinimumSkuAmount(item.skus_info).toLocaleString(),
        image: getImageUrl(item.image_urls),
        alt: item.item_name,
        onClick: () => navigateToItemDetail(item._id),
      })),
    [
      getImageUrl,
      getMinimumSkuAmount,
      navigateToItemDetail,
      result?.recommendation_items,
    ],
  );

  const handleClickTab = useCallback(
    (selected: string | number) => {
      if (selected === "2") navigate(PATIENT.USF002);
    },
    [navigate],
  );

  const handleClickBack = useCallback(
    () => navigate(PATIENT.USB001),
    [navigate],
  );

  const handleClickCart = useCallback(() => openUsf006Dialog(), []);

  const handleClickAddress = useCallback(() => openUsf003Dialog(), []);

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation minWidth="1076px" selectedTab={SELECTED_TAB_NUM.shop} />
        <Sheet
          padding="16px 108px"
          color="secondary-lightest"
          minWidth="1076px"
        >
          <LayoutBox justify="between" width="1064px" gap="1x">
            <div onClick={handleClickAddress} style={{ cursor: "pointer" }}>
              <LayoutBox direction="column" gap="1/2x" minWidth="0">
                <Text color="neutralLight" size="large">
                  お届け先
                </Text>
                <Text ellipsis size="xl" width="100%">
                  {addHyphenToZipcode(result?.zipcode)} {result?.pref}
                  {result?.city}
                  {result?.town1}
                  {result?.town2}
                </Text>
              </LayoutBox>
            </div>
            <LayoutBox minWidth="0">
              <SpButton
                type="shop"
                size="small"
                width="140px"
                badge={result?.cart_info?.item_num}
                onClick={handleClickCart}
              >
                カート
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <LayoutBox
          fullWidth
          direction="column"
          gap="5x"
          minWidth="1076px"
          className="util-sp-flex--gap24"
        >
          <Title
            padding="16px 108px 24px 108px"
            background="rough-blue"
            header="オンラインショップ"
            content="  日常でも認知症予防をしたい方向けの商品を販売しています"
            minWidth="1076px"
            onClickBack={handleClickBack}
          />
          {campaigns.top && (
            <Banner
              background="secondary-lightest"
              padding="24px"
              url={campaigns.top.url}
              imagePc={campaigns.top.pc_image}
              imageSp={campaigns.top.sp_image}
            />
          )}
          <Sheet padding="0" minWidth="1076px">
            <LayoutBox width="1064px" justify="center">
              <ToggleButton
                fullWidth
                size="large"
                width="100%"
                items={SP_TAB_ITEMS}
                selectedButton="1"
                onClick={handleClickTab}
              />
            </LayoutBox>
          </Sheet>
          <Sheet
            color="rough-blue-arch"
            padding="30px 0 24px 0"
            minWidth="1076px"
          >
            <LayoutBox width="1064px" minWidth="1064px" justify="center">
              <LayoutBox direction="column" align="center" gap="3x">
                <LayoutBox direction="column" align="center">
                  <LayoutBox justify="center">
                    <Image url={CartGroup} height="100%" width="100%" />
                  </LayoutBox>
                  <LayoutBox direction="column" align="center" gap="1x">
                    <Text size="3xl" lineHeight="150%" titleFont bold>
                      おすすめ
                    </Text>
                    <Text size="xl">
                      テストや検査の結果に応じておすすめの商品をご提案しています
                    </Text>
                  </LayoutBox>
                </LayoutBox>
                <CardShop cardShop={CardShopItems} />
              </LayoutBox>
            </LayoutBox>
          </Sheet>
          <Sheet padding="0" minWidth="1076px">
            <LayoutBox direction="column" width="1064px">
              <div className="product-list-title">
                <Text size="2xl" bold>
                  商品一覧
                </Text>
              </div>
              <div className="util-mt-8" />
              <LayoutBox direction="column" fullWidth gap="3x">
                <div className="product-list-title">
                  <Divider
                    width="100%"
                    orange
                    white={false}
                    margin="0px"
                    minWidth="343px"
                  />
                </div>
                <LayoutBox
                  gap="2x"
                  direction="row"
                  rowGap="3x"
                  wrap
                  fullWidth
                  justify="center"
                  className="product-list"
                >
                  {result?.new_items?.map((item) => (
                    <Shortcut
                      image={getImageUrl(item.image_urls)}
                      imageHeight="148px"
                      imageWidth="148px"
                      width="343.67px"
                      maxWidth="343.67px"
                      height="164px"
                      bottomText={getMinimumSkuAmount(
                        item.skus_info,
                      )?.toLocaleString()}
                      onClick={() => navigateToItemDetail(item._id)}
                      key={item._id}
                    >
                      {item.item_name}
                    </Shortcut>
                  ))}
                </LayoutBox>
                <LayoutBox justify="center" fullWidth>
                  <SpButton
                    width="344px"
                    size="medium"
                    fontSize="small"
                    withArrowRight
                    onClick={navigateToItemList}
                  >
                    すべての商品を見る
                  </SpButton>
                </LayoutBox>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
          {campaigns.bottom && (
            <Banner
              background="secondary-lightest"
              padding="24px"
              url={campaigns.bottom.url}
              imagePc={campaigns.bottom.pc_image}
              imageSp={campaigns.bottom.sp_image}
            />
          )}
        </LayoutBox>
        <div className="util-mb-48" />
      </div>
      <PatientFooter />
      <USF006 />
      <USF003 />
    </div>
  );
};

export default USF001;
