import { useEffect, useState } from "react";
import { useRealmApp } from "../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { Collection } from "../../constants/common";
import { getAggregateBrainCheck, getMongoDb } from "../../utils/query";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";

const useBrainCheckFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [brainChecks, setBrainChecks] = useState<BrainCheck[]>([]);
  const [brainChecksFetchError, setBrainChecksFetchError] =
    useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_BRAIN_CHECKS,
        );
        const aggregate = getAggregateBrainCheck(currentUser?.id ?? "");
        const result = (await collection.aggregate(aggregate)) as BrainCheck[];

        setBrainChecks(result);
      } catch (err) {
        setBrainChecksFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser]);

  return {
    brainChecks,
    brainChecksFetchError,
  };
};

export default useBrainCheckFetch;
