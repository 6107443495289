import React from "react";

// changeEmp
export type ProgressBarProps = {
  width?: string;
  percentage?: number;
  biggerBar?: boolean;
  barColor?: "primary" | "warning" | "error";
};

const ProgressBar = ({
  width = "",
  percentage = 0,
  biggerBar = false,
  barColor = "primary",
}: ProgressBarProps) => (
  <div className="progress-bar" style={{ width }}>
    {biggerBar ? (
      <progress
        value={percentage}
        max="50"
        className={`progress-bar__biggerBar-${barColor} progress-bar__biggerBar`}
      />
    ) : (
      <>
        <progress
          value={percentage}
          max="50"
          className="progress-bar__progress"
        />
        <span className="progress-bar__percentage">{percentage}%</span>
      </>
    )}
  </div>
);

ProgressBar.defaultProps = {
  width: "",
  percentage: 0,
  biggerBar: false,
  barColor: "primary",
};

export default ProgressBar;
