import { FORMAT_STYLE, INTERVIEW_STATUS } from "../../../constants/common";
import { PATIENT } from "../../../constants/pagePaths";
import { useCheckErrorThrowError } from "../../../utils/checkError";
import { formatDate } from "../../../utils/utils";
import { useNavigateWithUrl } from "../../base/usePageTransitionCustom";
import useGetPaidInterview from "../../common/useGetPaidInterview";
import { setInterviewType } from "../../common/useInterviewTypeConditions";

const useGetPaidInterviewsCardInfo = () => {
  const navigate = useNavigateWithUrl();
  const { interviewStatus, interview, error } = useGetPaidInterview();
  useCheckErrorThrowError([error]);

  const getDateContent = () =>
    // eslint-disable-next-line no-irregular-whitespace
    `${formatDate(interview?.interview_fix_date_start)}　${formatDate(
      interview?.interview_fix_date_start,
      FORMAT_STYLE["HH:MM"],
    )} ～ ${formatDate(
      interview?.interview_fix_date_end,
      FORMAT_STYLE["HH:MM"],
    )}`;

  const isPaidInit =
    interviewStatus === INTERVIEW_STATUS.beforePaidApplication ||
    (interviewStatus === INTERVIEW_STATUS.newBooking &&
      interview?.invitation_ticket_id) ||
    (interviewStatus === INTERVIEW_STATUS.interviewFixedAfterPayment &&
      interview?.invitation_ticket_id) ||
    (interviewStatus === INTERVIEW_STATUS.interviewDone &&
      interview?.invitation_ticket_id);

  if (isPaidInit) {
    return {
      content: "ご希望のタイミングで医師との面談が可能です。",
      footerColor: "gray",
      footerLabel: "面談1回 1,000円(税込)",
      footerText: "",
      onClick: () => {
        navigate(PATIENT.USD001);
        setInterviewType("1");
      },
    } as const;
  }

  if (
    interviewStatus === INTERVIEW_STATUS.newBooking &&
    !interview?.invitation_ticket_id
  ) {
    return {
      content: "予約調整中...",
      onClick: () => navigate(PATIENT.USD008),
      footerColor: "gray",
      footerLabel: "面談1回 1,000円(税込)",
      footerText: "",
    } as const;
  }

  if (
    interviewStatus === INTERVIEW_STATUS.interviewFixedBeforePayment &&
    !interview?.invitation_ticket_id
  ) {
    setInterviewType("3");

    return {
      content: getDateContent(),
      shouldShowZoom: true,
      onClick: () => navigate(PATIENT.USD002),
      footerColor: "gray",
      footerLabel: "面談1回 1,000円(税込)",
      footerText: "",
    } as const;
  }

  if (
    interviewStatus === INTERVIEW_STATUS.interviewFixedAfterPayment &&
    !interview?.invitation_ticket_id
  ) {
    setInterviewType("1");

    return {
      content: getDateContent(),
      shouldShowZoom: true,
      onClick: () => navigate(PATIENT.USD002),
      footerColor: "gray",
      footerLabel: "面談1回 1,000円(税込)",
      footerText: "",
    } as const;
  }

  return {
    content: "",
    shouldShowZoom: false,
    footerColor: undefined,
    footerLabel: undefined,
    footerText: undefined,
    onClick: () => undefined,
  };
};

export default useGetPaidInterviewsCardInfo;
