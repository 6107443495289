import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregatePatientOnlineTests, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";

// patient_online_testsテーブル検索
const usePatientOnlineTestsFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [patientOnlineTests, setPatientOnlineTests] = useStateCustomObj<
    PatientOnlineTests[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_ONLINE_TESTS,
        );
        const aggregate = getAggregatePatientOnlineTests(currentUser?.id ?? "");
        const fetchResult = (await collection.aggregate(
          aggregate,
        )) as PatientOnlineTests[];

        setPatientOnlineTests(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, setPatientOnlineTests]);

  return { error, patientOnlineTests };
};

export default usePatientOnlineTestsFetch;
