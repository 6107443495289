import { useEffect, useState } from "react";
import { Collection } from "../../constants/common";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateShippings, getMongoDb } from "../../utils/query";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";

// shippings取得
const useShippingsFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [fetchShippingResult, setFetchShippingResult] =
    useState<ShippingsType | null>(null);
  const [fetchShippingError, setFetchShippingError] = useState<Error | null>(
    null,
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collection = getMongoDb(currentUser, Collection.SHIPPINGS);
        const aggregate = getAggregateShippings();
        const result = (await collection.aggregate(
          aggregate,
        )) as ShippingsType[];

        setFetchShippingResult(result[0]);
      } catch (err) {
        setFetchShippingError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, setFetchShippingResult]);

  return { fetchShippingResult, fetchShippingError };
};

export default useShippingsFetch;
