import React from "react";
import StepBarItem from "./StepBarItem";

export type StepBarProps = {
  currentStepId?: string;
  steps: {
    id: string;
    label: string;
  }[];
};

const StepBar = ({ currentStepId = "", steps }: StepBarProps) => {
  const currentIndex = React.useMemo(
    () => steps.map((step) => step.id).indexOf(currentStepId),
    [currentStepId, steps],
  );

  return (
    <div className="step-bar">
      {steps.map((step, index) => {
        let status: "current" | "incomplete" | "complete" | undefined;
        if (currentStepId === step.id) {
          status = "current";
        } else if (currentIndex < index) {
          status = "incomplete";
        } else if (currentIndex > index) {
          status = "complete";
        } else {
          status = undefined;
        }

        return (
          <StepBarItem
            status={status}
            label={step.label}
            stepCounter={index + 1}
            totalSteps={steps.length}
            lastStep={steps.length === index + 1}
            key={`step-bar${index}`}
          />
        );
      })}
    </div>
  );
};

StepBar.defaultProps = {
  currentStepId: "",
};

export default StepBar;
