import { FORMAT_STYLE, INTERVIEW_STATUS } from "../../../constants/common";
import { PATIENT } from "../../../constants/pagePaths";
import { useCheckErrorThrowError } from "../../../utils/checkError";
import { formatDate } from "../../../utils/utils";
import { useNavigateWithUrl } from "../../base/usePageTransitionCustom";
import useGetFreeInterview from "../../common/useGetFreeInterview";
import { setInterviewType } from "../../common/useInterviewTypeConditions";

const useGetFreeInterviewsCardInfo = () => {
  const navigate = useNavigateWithUrl();
  const {
    interviewStatus,
    interview,
    ticketExpireStr,
    invitationTicketId,
    error,
  } = useGetFreeInterview();
  useCheckErrorThrowError([error]);

  const getDateContent = () =>
    // eslint-disable-next-line no-irregular-whitespace
    `${formatDate(interview?.interview_fix_date_start)}　${formatDate(
      interview?.interview_fix_date_start,
      FORMAT_STYLE["HH:MM"],
    )} ～ ${formatDate(
      interview?.interview_fix_date_end,
      FORMAT_STYLE["HH:MM"],
    )}`;

  const isFreeInit =
    interviewStatus === INTERVIEW_STATUS.beforeFreeApplication ||
    (interviewStatus === INTERVIEW_STATUS.newBooking &&
      !interview?.invitation_ticket_id) ||
    (interviewStatus === INTERVIEW_STATUS.interviewFixedAfterPayment &&
      !interview?.invitation_ticket_id) ||
    (interviewStatus === INTERVIEW_STATUS.interviewDone &&
      !interview?.invitation_ticket_id);

  if (isFreeInit) {
    return {
      content:
        "MRI検査のレポートを受け取られた方は、医師との面談を受診してください。",
      footerColor: "blue",
      footerLabel: "面談1回のみ",
      footerText: ticketExpireStr ? `${ticketExpireStr}まで` : "",
      onClick: () => {
        navigate(PATIENT.USD001);
        setInterviewType("0");
      },
      clickable: !!invitationTicketId,
    } as const;
  }

  if (
    interviewStatus === INTERVIEW_STATUS.newBooking &&
    interview?.invitation_ticket_id
  ) {
    return {
      content: "予約調整中...",
      onClick: () => navigate(PATIENT.USD010),
      clickable: true,
      footerColor: "blue",
      footerLabel: "面談1回のみ",
      footerText: ticketExpireStr ? `${ticketExpireStr}まで` : "",
    } as const;
  }

  if (
    interviewStatus === INTERVIEW_STATUS.interviewFixedBeforePayment &&
    interview?.invitation_ticket_id
  ) {
    return {
      content: getDateContent(),
      shouldShowZoom: true,
      shouldShowTag: {
        label: "決済を完了してください",
        state: "warning",
      },
      onClick: () => undefined,
      clickable: false,
    } as const;
  }

  if (
    interviewStatus === INTERVIEW_STATUS.interviewFixedAfterPayment &&
    interview?.invitation_ticket_id
  ) {
    setInterviewType("0");

    return {
      content: getDateContent(),
      shouldShowZoom: true,
      onClick: () => navigate(PATIENT.USD002),
      clickable: true,
      footerColor: "blue",
      footerLabel: "面談1回のみ",
      footerText: ticketExpireStr ? `${ticketExpireStr}まで` : "",
    } as const;
  }

  return {
    content: "",
    shouldShowZoom: false,
    footerColor: undefined,
    footerLabel: undefined,
    footerText: undefined,
    onClick: () => undefined,
    clickable: false,
  };
};

export default useGetFreeInterviewsCardInfo;
