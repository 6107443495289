import React from "react";
import SpButton from "../Button/SpButton";
import LayoutBox from "../LayoutBox/LayoutBox";
import Text from "../Text/Text";

export type SpPaginationProps = {
  pageNumber?: number;
  total?: number;
  padding?: string;
  justify?: "start" | "center" | "end" | "between" | "around";
  leftButtonText?: string;
  leftButtonType?: "primary" | "secondary" | "white" | "danger" | "shop";
  leftButtonDisabled?: boolean;
  onClickLeft?: (value?: React.MouseEvent<HTMLButtonElement>) => void;
  rightButtonText?: string;
  rightButtonType?: "primary" | "secondary" | "white" | "danger" | "shop";
  rightButtonDisabled?: boolean;
  onClickRight?: (value?: React.MouseEvent<HTMLButtonElement>) => void;
  width?: string;
};

const SpPagination = ({
  pageNumber,
  total,
  padding = "",
  justify = "center",
  leftButtonType = "secondary",
  leftButtonText = "",
  leftButtonDisabled = false,
  onClickLeft,
  rightButtonType = "primary",
  rightButtonText = "",
  rightButtonDisabled = false,
  onClickRight,
  width = "",
}: SpPaginationProps) => (
  <div className="sp-pagination" style={{ padding }}>
    <LayoutBox justify={justify} fullWidth align="center" gap="2x">
      <SpButton
        type={leftButtonType}
        width={width}
        disabled={leftButtonDisabled}
        withArrowLeft
        onClick={onClickLeft}
      >
        {leftButtonText}
      </SpButton>

      {pageNumber && total ? (
        <Text size="2xl" bold>
          {pageNumber}
          <span>/</span>
          {total}
        </Text>
      ) : (
        ""
      )}
      <SpButton
        type={rightButtonType}
        width={width}
        disabled={rightButtonDisabled}
        withArrowRight
        onClick={onClickRight}
      >
        {rightButtonText}
      </SpButton>
    </LayoutBox>
  </div>
);

SpPagination.defaultProps = {
  pageNumber: undefined,
  total: undefined,
  justify: "center",
  padding: "0",
  leftButtonType: "secondary",
  leftButtonText: "",
  leftButtonDisabled: false,
  rightButtonType: "primary",
  rightButtonText: "",
  rightButtonDisabled: false,
  width: "",
  onClickLeft: () => {},
  onClickRight: () => {},
};

export default SpPagination;
