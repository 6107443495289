import { useCallback, useState } from "react";
import useCartInfoFetch from "../../common/useCartInfoFetch";
import { useRealmApp } from "../../../contexts/RealmApp";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";

const useUsf006DBActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const { cartInfo, error } = useCartInfoFetch();

  const [actionError, setActionError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const deleteTransaction = useCallback(
    async (transactionId: string) => {
      openUpdateActionLoading();
      try {
        // 削除処理
        await currentUser?.functions.cartControlApi({
          transaction_id: transactionId,
        });

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    },
    [currentUser?.functions, forceUpdate.forceUpdateCount, setForceUpdate],
  );

  const changeTransaction = useCallback(
    async (transactionId: string, quantity: number) => {
      openUpdateActionLoading();
      try {
        // 更新処理
        await currentUser?.functions.cartControlApi({
          transaction_id: transactionId,
          quantity,
        });

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    },
    [currentUser?.functions, forceUpdate.forceUpdateCount, setForceUpdate],
  );

  return {
    cartInfo,
    deleteTransaction,
    changeTransaction,
    error,
    actionError,
  };
};

export default useUsf006DBActions;
