import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Icon from "../components/Icon/Icon";
import PatientFooter from "../features/PatientFooter";
import NotificationCard from "../features/NotificationCard";

const USA036 = () => (
  <div className="admin-area  user-body-sp-full-height">
    <HeaderUser />
    <div className="admin-inner area-with-header-footer">
      <LayoutBox justify="center">
        <Sheet maxWidth="1112px">
          <LayoutBox direction="column" gap="3x" fullWidth>
            <Sheet type="card">
              <LayoutBox direction="column" fullWidth align="center" gap="3x">
                <LayoutBox direction="column" fullWidth align="center" gap="2x">
                  <Icon color="#D6A000" icon="warning" size="xl" />
                  <Text bold size="2xl" color="neutralUserBlack">
                    登録申請中です。
                  </Text>
                  <LayoutBox direction="column" gap="none" align="center">
                    <Text size="xl" color="neutralUserBlack">
                      招待をした方が申請を承認したら
                    </Text>
                    <Text size="xl" color="neutralUserBlack">
                      サービスをご利用いただけます。
                    </Text>
                  </LayoutBox>
                </LayoutBox>
              </LayoutBox>
            </Sheet>
            <NotificationCard />
          </LayoutBox>
        </Sheet>
      </LayoutBox>
      <div className="util-mb-40" />
    </div>
    <PatientFooter />
  </div>
);

export default USA036;
