import { useCallback, useState } from "react";
import { useRealmApp } from "../../contexts/RealmApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import { Collection } from "../../constants/common";
import { getMongoDb } from "../../utils/query";
import useReceiptId from "./useReceiptId";

// salesテーブルの領収書発行日を更新
const useUpdateReceiptIssueDate = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [id] = useReceiptId();
  const [actionError, setActionError] = useState<Error | null>(null);

  const updateReceiptIssueDate = useCallback(async () => {
    try {
      const collection = getMongoDb(currentUser, Collection.SALES);
      await collection.updateOne(
        {
          _id: id,
        },
        {
          $set: {
            receipt_issue_date: new Date(),
            updated_at: new Date(),
          },
        },
      );
    } catch (err) {
      setActionError(checkActionErr(err));
    }
  }, [currentUser, id]);

  return {
    updateReceiptIssueDate,
    actionError,
  };
};

export default useUpdateReceiptIssueDate;
