import React from "react";
import Text from "../Text/Text";
import SpButton from "../Button/SpButton";
import LayoutBox from "../LayoutBox/LayoutBox";

export type TitleProps = {
  type?: "h1" | "h2";
  minWidth?: string;
  padding?: string;
  background?: "rough-blue" | "arc" | "default" | "";
  header?: string;
  headerNote?: string;
  subheader?: string;
  content?: string;
  withButton?: boolean;
  borderBottom?: "primary" | "secondary" | "primaryLight" | "";
  // changeEmp
  onClickBack?: () => void;
};

const Title = ({
  type = "h1",
  minWidth,
  padding = "",
  background = "",
  header = "",
  headerNote = "",
  subheader = "",
  content = "",
  withButton = true,
  borderBottom = "secondary",
  // changeEmp
  onClickBack,
}: TitleProps) => {
  const titleClass = React.useMemo(() => {
    const typeClass = [`title--${type}`];

    const backgroundClass = [`title--${background}`];

    const borderClass = [`title--border-${borderBottom}`];

    return [`title`, ...backgroundClass, ...borderClass, ...typeClass].join(
      " ",
    );
  }, [background, borderBottom, type]);

  return (
    <div className={titleClass} style={{ minWidth, padding }}>
      <div className="title--container">
        {withButton && (
          <SpButton
            size="small"
            type="white"
            icon="arrow_back"
            width="99px"
            // changeEmp
            onClick={onClickBack}
          >
            戻る
          </SpButton>
        )}

        <div className="title--inner">
          {headerNote !== "" ? (
            <LayoutBox gap="1x" justify="start" fullWidth align="end">
              <Text size="3xl" lineHeight="150%" bold color="neutralUserBlack">
                {header}
              </Text>
              <Text size="xl" lineHeight="150%" bold color="neutralUserBlack">
                {headerNote}
              </Text>
            </LayoutBox>
          ) : (
            <LayoutBox gap="1x" justify="between" fullWidth align="center">
              <Text size="3xl" lineHeight="150%" bold color="neutralUserBlack">
                {header}
              </Text>
              {subheader !== "" && (
                <Text
                  size="large"
                  lineHeight="150%"
                  color="neutralUserLight"
                  noWrap
                >
                  {subheader}
                </Text>
              )}
            </LayoutBox>
          )}
          {content !== "" && (
            <Text size="xl" color="neutralUserBlack">
              {content}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

Title.defaultProps = {
  type: "h1",
  minWidth: "",
  padding: "",
  background: "",
  header: "",
  headerNote: "",
  subheader: "",
  content: "",
  withButton: true,
  borderBottom: "secondary",
  // changeEmp
  onClickBack: undefined,
};

export default Title;
