import { useState, useEffect } from "react";
import { Collection, INTERVIEW_STATUS } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateInterview, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { formatDate } from "../../utils/utils";

enum INTERVIEW_TICKETS_STATUS {
  unused, // 未使用
  spent, // 使用済み
}

type PatientInterviewsType = {
  _id: string;
  expired_at: Date;
  status: number;
  interviewTicketsStatus: ValueOf<typeof INTERVIEW_TICKETS_STATUS>;
  id: string;
  interview_fix_date_start: Date;
  interview_fix_date_end: Date;
  invitation_ticket_id: string;
  candidate_interview_dates: {
    fifth?: string;
    first?: string;
    fourth?: string;
    second?: string;
    third?: string;
  };
};

const useGetFreeInterview = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [interviewStatus, setInterviewStatus] = useState<
    ValueOf<typeof INTERVIEW_STATUS>
  >(INTERVIEW_STATUS.beforePaidApplication);

  const [interview, setInterview] = useState<PatientInterviewsType>();
  const [error, setError] = useState<Error | null>(null);
  const [ticketExpireStr, setTicketExpireStr] = useState("");
  const [invitationTicketId, setInvitationTicketId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const toDay = new Date(
          new Date().setHours(0, 0, 0, 0) + 9 * 60 * 60 * 1000,
        );

        const mongoDb = getMongoDb(currentUser, Collection.PATIENT_INTERVIEWS);
        const aggregate = getAggregateInterview({
          patientId: currentUser?.id,
        });
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as PatientInterviewsType[];

        const withTicketResult = aggregateResult.find(
          (record) => record.invitation_ticket_id,
        );

        if (withTicketResult) {
          setInterview(withTicketResult);
        } else {
          setInterview(undefined);
        }

        // 無料チケット
        const ticketCollection = getMongoDb(
          currentUser,
          Collection.INVITATION_TICKETS,
        );
        const ticketResults = (await ticketCollection.find(
          {
            patient_id: currentUser?.id,
            status: INTERVIEW_TICKETS_STATUS.unused,
          },
          {
            sort: { created_at: -1 },
          },
        )) as InvitationTickets[];

        if (ticketResults.length > 0 && !withTicketResult) {
          const ticketResult = ticketResults[0];
          if (toDay <= ticketResult.expired_at) {
            // 無料チケットが利用可能 && 無料チケットの有効期限以内
            setInterviewStatus(INTERVIEW_STATUS.beforeFreeApplication);
            setTicketExpireStr(formatDate(ticketResult.expired_at));
            setInvitationTicketId(ticketResult._id);

            return;
          }
        }
        if (ticketResults.length > 0 && withTicketResult) {
          const ticketResult = ticketResults[0];
          if (toDay <= ticketResult.expired_at) {
            setInterviewStatus(withTicketResult.status);
            setTicketExpireStr(formatDate(ticketResult.expired_at));
            setInvitationTicketId(ticketResult._id);

            return;
          }
        }

        if (withTicketResult) {
          setInterviewStatus(withTicketResult.status);

          return;
        }
        // データが存在しない場合
        setInterviewStatus(INTERVIEW_STATUS.beforeFreeApplication);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser]);

  return {
    interview,
    interviewStatus,
    error,
    ticketExpireStr,
    invitationTicketId,
  };
};

export default useGetFreeInterview;
