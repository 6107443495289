import { useEffect, useRef, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsf002, getMongoDb } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import usePatientInfoFetch from "../../common/usePatientInfoFetch";
import usePagination, {
  setPage,
} from "../../component/pagination/usePagination";

const useUsf002Actions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  const [result, setResult] = useState<OrdersType[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [pagination] = usePagination();

  const { page, perPage, skip } = pagination;

  const innerPageRef = useRef<number>(0);

  // Patient取得
  useEffect(() => {
    const fetchData = async () => {
      const prevPage = innerPageRef.current;
      if (innerPageRef.current === page) return;
      innerPageRef.current = page;
      addLoadCount();
      try {
        const mongoDbOrders = getMongoDb(currentUser, Collection.ORDERS);
        const usf002Aggregate = getAggregateUsf002(
          currentUser?.id ?? "",
          skip,
          perPage,
        );

        const fetchResult = (await mongoDbOrders.aggregate(
          usf002Aggregate,
        )) as AggregateResultType<OrdersType>;
        if (fetchResult[0].result.length === 0) {
          setPage(prevPage);
          innerPageRef.current = prevPage;

          return;
        }

        setResult((prev) => [...prev, ...fetchResult[0].result]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, page, perPage, skip]);

  return {
    result,
    patientInfo,
    error,
    patientInfoError,
  };
};

export default useUsf002Actions;
