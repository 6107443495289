import Title from "../Title/Title";
import LayoutBox from "../LayoutBox/LayoutBox";
import Sheet from "../Sheet/Sheet";
import Text from "./Text";

const TermsOfServiceText = () => (
  <Sheet type="card" padding="16px 16px 32px" maxWidth="1064px">
    <LayoutBox direction="column" fullWidth gap="3x">
      <Title
        type="h2"
        header="利用規約・個人情報の取扱いについて"
        withButton={false}
        borderBottom="primaryLight"
      />
      <Text size="xl">利用規約に同意する</Text>
      <Text size="xl">個人情報の取扱いについて同意する</Text>
    </LayoutBox>
  </Sheet>
);
export default TermsOfServiceText;
