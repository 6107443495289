import { useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";

const useUsg005DBActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);

  // 退会処理 失敗時にはfalseを返します。
  const deleteMemberShip = async (id: string) => {
    openUpdateActionLoading();
    try {
      await currentUser?.functions["account/cancellation"](id);

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  return {
    error,
    deleteMemberShip,
  };
};

export default useUsg005DBActions;
