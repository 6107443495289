/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactNode, useEffect } from "react";
import * as Realm from "realm-web";

import handleException from "./errors";
import { redirectToInvalidFunctionPage } from "./CustomErrorBoundary";

const RealmAppContext = React.createContext<RealmAppContextInterface>(
  {} as RealmAppContextInterface,
);

const getGoogleAuthUrl = () => {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
  const state = `google_${Math.floor(Math.random() * Date.now()).toString(36)}`;
  // window.localStorage.setItem(`${id}:stateForAuth`, state);

  return `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${googleClientId}&redirect_uri=${encodeURIComponent(
    `${window.location.protocol}//${window.location.host}/ExternalLogin`,
  )}&state=${state}&scope=openid%20email&nonce=09876xyz`;
};

const getLineAuthUrl = () => {
  const lineClientId = process.env.REACT_APP_LINE_CLIENT_ID as string;
  const state = `line_${Math.floor(Math.random() * Date.now()).toString(36)}`;
  // window.localStorage.setItem(`${id}:stateForAuth`, state);

  return `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${lineClientId}&redirect_uri=${encodeURIComponent(
    `${window.location.protocol}//${window.location.host}/ExternalLogin`,
  )}&state=${state}&scope=profile%20openid&nonce=09876xyz`;
};

export const useRealmApp = () => {
  const appContext = React.useContext(RealmAppContext);
  if (!appContext) {
    return redirectToInvalidFunctionPage();
  }

  return appContext;
};

export const deleteCurrentUser = async (app: Realm.App) => {
  try {
    if (!app.currentUser)
      return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
    if (
      !window.confirm(
        "アカウントを削除します。この操作は取り消せません。よろしいですか？",
      )
    )
      return null;
    await app.currentUser?.functions.deleteAppUser();
    await app.currentUser.logOut();

    return null;
  } catch (e) {
    return handleException(e);
  }
};

export const RealmAppProvider = ({
  appId,
  children,
}: {
  appId: string;
  children: ReactNode;
}) => {
  const [app, setApp] = React.useState<Realm.App>(new Realm.App(appId));

  const firstRun = React.useRef<boolean>(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;

      return;
    }
    setApp(new Realm.App(appId));
  }, [appId]);
  const [currentUser, setCurrentUser] = React.useState(app.currentUser);

  const logIn = React.useCallback(
    async (credentials: Realm.Credentials) => {
      await app.logIn(credentials);
      setCurrentUser(app.currentUser);
    },
    [app],
  );
  const logOut = React.useCallback(async () => {
    await app.currentUser?.logOut();
    setCurrentUser(app.currentUser);
    // セッションストレージクリア
    sessionStorage.clear();
  }, [app, setCurrentUser]);

  const deleteUser = React.useCallback(
    async (userId: string) => {
      if (!app.currentUser)
        return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
      if (
        !window.confirm(
          "アカウントを削除します。この操作は取り消せません。よろしいですか？",
        )
      )
        return null;
      await app.currentUser.functions.deleteUser(userId);

      return null;
    },
    [app],
  );

  const providerSignIn = React.useCallback((provider: string) => {
    switch (provider) {
      case "google":
        window.open(getGoogleAuthUrl(), "_self")?.focus();
        break;
      case "line":
        window.open(getLineAuthUrl(), "_self")?.focus();
        break;
      default:
    }
  }, []);

  const memoizedRealmAppContext = React.useMemo(() => {
    const realmAppContext = {
      app,
      currentUser,
      logIn,
      logOut,
      deleteUser,
      providerSignIn,
    };

    return realmAppContext;
  }, [app, currentUser, deleteUser, logIn, logOut, providerSignIn]);

  return (
    <RealmAppContext.Provider value={memoizedRealmAppContext}>
      {children}
    </RealmAppContext.Provider>
  );
};
