import { useState, useCallback } from "react";
import _ from "lodash";

// オブジェクト用useStateカスタムフック
const useStateCustomObj = <T>(initialState: T) => {
  const [state, setState] = useState<T>(initialState);

  const setStateObj = useCallback((newState: T | ((prevState: T) => T)) => {
    setState((prevState: T) => {
      // newStateが関数の場合、その関数を使用して新しい状態を取得する
      const resolvedState =
        typeof newState === "function"
          ? (newState as (prevState: T) => T)(prevState)
          : newState;

      // オブジェクトの値が一致するときは前の値を返してレンダリングを行わない
      if (_.isEqual(prevState, resolvedState)) return prevState;

      return resolvedState;
    });
  }, []);

  return [state, setStateObj] as const;
};

export default useStateCustomObj;
