import { useCallback, useMemo } from "react";

import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import SpButton from "../components/Button/SpButton";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";

import CardCart from "../components/Card/CardCart";
import OrderDetails from "../components/OrderDetails/OrderDetails";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import useDetailOrderInfoFetch from "../hooks/common/useDetailOrderInfoFetch";
import usePatientInfoFetch from "../hooks/common/usePatientInfoFetch";
import { PATIENT } from "../constants/pagePaths";
import { setId } from "../hooks/common/useId";
import { addHyphenToZipcode, formatDate } from "../utils/utils";
import USF006 from "./US-F006";
import { openUsf006Dialog } from "../hooks/pages/USF006/useUsf006Dialog";
import { FORMAT_STYLE, OrderStatusID } from "../constants/common";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { openUsf003Dialog } from "../hooks/pages/USF003/useUsf003Dialog";
import USF003 from "./US-F003";
import { useCheckErrorThrowError } from "../utils/checkError";
import { isCancelableOrder } from "../utils/checkCancelableOrder";
import PatientFooter from "../features/PatientFooter";

const USF010 = () => {
  const { orderInfo, error } = useDetailOrderInfoFetch();
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  const navigate = useNavigateCustom();

  useCheckErrorThrowError([error, patientInfoError]);

  const navigateToBack = useCallback(() => {
    navigate(PATIENT.USF002);
  }, [navigate]);

  const handleClickCart = useCallback(() => openUsf006Dialog(), []);

  const navigateToCancel = useCallback(() => {
    if (orderInfo) {
      setId(orderInfo?._id);
      navigate(PATIENT.USF011);
    }
  }, [navigate, orderInfo]);

  const orderImages = useMemo(
    () =>
      orderInfo?.transactions_info.map((transaction) => ({
        alt: transaction.sku_name,
        url: transaction.skus_info.image_urls[0],
      })),
    [orderInfo?.transactions_info],
  );

  const cardCartItems = useMemo(
    () =>
      (orderInfo?.transactions_info ?? []).map((transaction) => ({
        id: transaction._id,
        name: transaction.item_name,
        description: transaction.sku_name,
        price: transaction.total_amount,
        quantity: transaction.quantity,
        image: transaction.skus_info.image_urls[0],
      })),
    [orderInfo?.transactions_info],
  );

  const taxAmount = useMemo(() => {
    const reducer = (sum: number, currentValue: number) => sum + currentValue;

    return orderInfo?.total_amount_per_tax
      .map((taxObj) => taxObj.tax)
      .reduce(reducer, 0);
  }, [orderInfo?.total_amount_per_tax]);

  const handleClickAddress = useCallback(() => openUsf003Dialog(), []);

  const isCancelable = useMemo(() => {
    if (orderInfo) {
      return isCancelableOrder(orderInfo.settlement_time, orderInfo.status);
    }

    return false;
  }, [orderInfo]);

  const _formatDate = (date: Date | undefined) =>
    formatDate(date, FORMAT_STYLE["YYYY/MM/DD HH:MM"]);

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation selectedTab={SELECTED_TAB_NUM.shop} />
        <Sheet
          padding="16px 108px"
          color="secondary-lightest"
          minWidth="1280px"
        >
          <LayoutBox justify="between" width="1064px" minWidth="1064px">
            <div onClick={handleClickAddress} style={{ cursor: "pointer" }}>
              <LayoutBox direction="column" gap="1/2x" minWidth="0">
                <Text color="neutralLight" size="large">
                  お届け先
                </Text>
                <Text ellipsis size="xl" width="100%">
                  {addHyphenToZipcode(patientInfo?.zipcode)} {patientInfo?.pref}
                  {patientInfo?.city}
                  {patientInfo?.town1}
                  {patientInfo?.town2}
                </Text>
              </LayoutBox>
            </div>
            <LayoutBox minWidth="0">
              <SpButton
                type="shop"
                size="small"
                width="140px"
                badge={patientInfo?.cart_info?.item_num}
                onClick={handleClickCart}
              >
                カート
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <Title
          background="rough-blue"
          header="注文詳細"
          minWidth="1280px"
          onClickBack={navigateToBack}
        />
        <Sheet padding="40px 108px 24px 108px" minWidth="1280px">
          <LayoutBox direction="column" width="1064px" gap="2x">
            {orderInfo && (
              <LayoutBox direction="column" fullWidth>
                <Sheet type="card" padding="16px">
                  <OrderDetails
                    deliveryDate={
                      orderInfo?.desired_delivery_date
                        ? _formatDate(orderInfo?.desired_delivery_date)
                        : undefined
                    }
                    shippedDate={
                      orderInfo?.delivery_date
                        ? _formatDate(orderInfo?.delivery_date)
                        : undefined
                    }
                    paymentDate={
                      orderInfo?.transaction_time
                        ? _formatDate(orderInfo?.transaction_time)
                        : undefined
                    }
                    orderDate={
                      orderInfo?.settlement_time
                        ? _formatDate(orderInfo?.settlement_time)
                        : undefined
                    }
                    cancelDate={
                      orderInfo?.cancel_time
                        ? _formatDate(orderInfo?.cancel_time)
                        : undefined
                    }
                    images={orderImages}
                  />
                </Sheet>
              </LayoutBox>
            )}

            <LayoutBox direction="column" fullWidth gap="2x">
              <Title
                withButton={false}
                header="注文情報"
                borderBottom="primaryLight"
                type="h2"
              />
              <Text color="neutralLight">注文番号：{orderInfo?._id}</Text>
            </LayoutBox>
            <LayoutBox direction="column" fullWidth>
              <Sheet type="card" padding="16px">
                <LayoutBox
                  fullWidth
                  justify="center"
                  align="center"
                  gap="2x"
                  toColumn
                >
                  <LayoutBox direction="column" fullWidth gap="1x">
                    <Text color="neutralLight" size="large">
                      お届け先
                    </Text>
                    <Text size="xl">
                      {patientInfo?.name_sei}
                      {patientInfo?.name_mei} 様
                    </Text>
                    <Text size="xl">
                      {orderInfo &&
                        `${orderInfo.order_zipcode} ${orderInfo.order_address}`}
                    </Text>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
            <LayoutBox direction="column" fullWidth>
              <Sheet type="card" padding="16px">
                <LayoutBox
                  direction="column"
                  fullWidth
                  justify="center"
                  align="center"
                  gap="10px"
                >
                  <CardCart
                    items={cardCartItems}
                    padding="0"
                    gap="10px"
                    type="view"
                    mobileType="view"
                    deliveryCharge={orderInfo?.delivery_fee}
                    tax={taxAmount ?? 0}
                  />
                </LayoutBox>
              </Sheet>
            </LayoutBox>
            <LayoutBox
              direction="column"
              fullWidth
              justify="center"
              align="center"
            >
              {isCancelable && (
                <SpButton
                  icon="block"
                  type="danger"
                  width="343px"
                  size="medium"
                  onClick={navigateToCancel}
                  disabled={orderInfo?.status === OrderStatusID.CANCELLED}
                >
                  注文キャンセル
                </SpButton>
              )}
            </LayoutBox>
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
      <USF006 />
      <USF003 />
    </div>
  );
};

export default USF010;
