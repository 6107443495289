import React from "react";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

export type DialogCustomProps = {
  open?: boolean;
  size?: "small" | "medium" | "large" | "fullscreen";
  title?: string;
  subTitle?: string;
  height?: string;
  isShowCloseButton?: boolean;
  closeDialog: () => void;
  footerLeft?: JSX.Element;
  footerRight?: JSX.Element;
  centerFooter?: boolean;
  children: React.ReactNode;
  isMobile?: boolean;
  paddingFooter?: React.CSSProperties["padding"];
  paddingBody?: React.CSSProperties["padding"];
  isShowBackButton?: boolean;
};

const DialogCustom = ({
  open = false,
  size = "medium",
  title,
  subTitle,
  height = "",
  isShowCloseButton = true,
  closeDialog,
  footerLeft,
  footerRight,
  centerFooter,
  children,
  isMobile = false,
  paddingFooter,
  paddingBody,
  isShowBackButton,
}: DialogCustomProps) => {
  const dialogRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const dialogCustomClass = React.useMemo(() => {
    const sizeClass = [`dialog-custom--${size}`];

    const dialogCustomMobileClass = isMobile ? [`dialog-custom-mobile`] : [];

    return ["dialog-custom", ...sizeClass, ...dialogCustomMobileClass].join(
      " ",
    );
  }, [size, isMobile]);

  const dialogCustomFooterClass = React.useMemo(() => {
    const centerFooterClass = centerFooter
      ? [`dialog-custom__footer--centered`]
      : [];

    return ["dialog__footer", ...centerFooterClass].join(" ");
  }, [centerFooter]);

  const _height = React.useMemo(() => {
    const defaultHeightBySize =
      size === "fullscreen" ? "calc(100% - 64px)" : "542px";

    return height || defaultHeightBySize;
  }, [height, size]);

  const toggleDialog = React.useCallback(() => {
    if (!dialogRef?.current) {
      return;
    }
    if (!open) {
      dialogRef.current.classList.add("open");
      dialogRef.current.addEventListener(
        "animationend",
        () => {
          if (dialogRef.current) {
            dialogRef.current.classList.remove("open");
          }
        },
        { once: true },
      );
    }
  }, [open]);

  const onClose = React.useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  React.useEffect(() => {
    if (open !== isOpen) {
      toggleDialog();
      setIsOpen(open);
    }
  }, [isOpen, open, toggleDialog]);

  const dialogCustomAnimationClass = React.useMemo(() => {
    const DialogCustomAnimation = open
      ? ["open-animation open"]
      : ["close-animation"];

    return ["dialog-custom-wrapper", ...DialogCustomAnimation].join(" ");
  }, [open]);

  const isShowHeader = React.useMemo(
    () => title !== undefined || subTitle !== undefined,
    [subTitle, title],
  );

  const isShowFooter = React.useMemo(
    () => footerLeft !== undefined || footerRight !== undefined,
    [footerLeft, footerRight],
  );

  return (
    <div className={dialogCustomAnimationClass} ref={dialogRef}>
      <div className={dialogCustomClass} style={{ height: _height }}>
        <div className="dialog-custom-content-container">
          {isShowHeader && (
            <div className="dialog-custom__header">
              <div className="dialog-custom__header-inner">
                {isShowBackButton && (
                  // changeEmp add onClick
                  <Icon icon="arrow_back" color="#CF4900" onClick={onClose} />
                )}
                <div>
                  <span className="dialog-custom__title">{title}</span>
                  <span className="dialog__sub-title">{subTitle}</span>
                </div>
              </div>
              {isShowCloseButton && (
                <Button
                  className="dialog-custom__close-button"
                  icon="close"
                  shape="square"
                  size="medium"
                  color="neutral"
                  type="sub"
                  onClick={onClose}
                />
              )}
            </div>
          )}

          <div
            className="dialog-custom__content"
            style={{ padding: paddingBody }}
          >
            {children}
          </div>
          {isShowFooter && (
            <div
              className={dialogCustomFooterClass}
              style={{ padding: paddingFooter }}
            >
              {/* <div> */}
              {footerLeft}
              {/* </div> */}
              {/* <div> */}
              {footerRight}
              {/* </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DialogCustom.defaultProps = {
  title: "",
  subTitle: "",
  open: false,
  size: "medium",
  height: "",
  footerLeft: undefined,
  footerRight: undefined,
  centerFooter: false,
  isShowCloseButton: true,
  isMobile: false,
  paddingFooter: undefined,
  paddingBody: "16px 32px",
  isShowBackButton: false,
};

export default DialogCustom;
