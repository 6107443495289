import React from "react";
import { mobileView } from "../../constants/common";

export type BannerProps = {
  padding?: string;
  imagePc?: string;
  imageSp?: string;
  background?: "gray" | "secondary-lightest" | "none";
  url?: string;
};

const Banner = ({
  padding,
  imagePc = "",
  imageSp = "",
  background = "gray",
  url = "",
}: BannerProps) => {
  const [isMobile, setIsMobile] = React.useState(false);

  const bannerClass = React.useMemo(() => {
    const backgroundClass =
      background !== "none" ? [`banner--${background}`] : [];

    return ["banner", ...backgroundClass].join(" ");
  }, [background]);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileView);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onBannerClicked = () => {
    window.open(url);
  };

  return (
    <>
      {(imageSp || imagePc) && (
        <div
          className={bannerClass}
          style={{ padding }}
          onClick={onBannerClicked}
        >
          <img
            src={isMobile ? imageSp : imagePc}
            width={isMobile ? "343px" : "1064px"}
            alt="banner"
          />
        </div>
      )}
    </>
  );
};

Banner.defaultProps = {
  padding: "",
  imagePc: "",
  imageSp: "",
  background: "gray",
  url: "",
};

export default Banner;
