import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateNotificationTop, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";

// トップ画面のお知らせ一覧を取得
const useNotificationTopFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [notifications, setNotifications] = useStateCustomObj<
    NotificationTop[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(currentUser, Collection.NOTIFICATIONS);
        const aggregate = getAggregateNotificationTop();
        const fetchResult = (await collection.aggregate(
          aggregate,
        )) as NotificationTop[];

        setNotifications(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, setNotifications]);

  return { error, notifications };
};

export default useNotificationTopFetch;
