import { useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";

const useUsa027DBActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);

  // 招待者登録 失敗時にはfalseを返します。
  const createInvitation = async (emails: Array<USA027TypeApi>) => {
    openUpdateActionLoading();
    try {
      // FIXME: 招待者登録API
      await currentUser?.functions["account/present/invitation"](
        currentUser?.id,
        emails,
      );

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  return {
    error,
    createInvitation,
  };
};

export default useUsa027DBActions;
