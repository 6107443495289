import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

// メッセージ詳細ダイアログの開閉状態を管理
const useUsj002Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openUsj002Dialog = () => {
  stateReactiveVar(true);
};

export const closeUsj002Dialog = () => {
  stateReactiveVar(false);
};

export default useUsj002Dialog;
