import React from "react";
import Image from "../Image/Image";
import LayoutBox from "../LayoutBox/LayoutBox";

export type ImageGalleryProps = {
  urls?: {
    id: string;
    url: string;
  }[];
};

const ImageGallery = ({ urls }: ImageGalleryProps) => {
  // changeEmp
  const [selectedImageId, setSelectedImageId] = React.useState<
    string | undefined
  >(urls?.[0]?.url);

  // changeEmp
  React.useEffect(() => {
    setSelectedImageId(urls?.[0]?.id);
  }, [urls]);

  // changeEmp
  const handleImageClick = (id?: string) => {
    setSelectedImageId(id);
  };

  const selectedImage = urls?.find((url) => url.id === selectedImageId);

  return (
    <LayoutBox gap="2x" direction="column" fullWidth>
      <LayoutBox fullWidth>
        {selectedImage && (
          <Image
            url={selectedImage.url}
            alt="Selected"
            width="348px"
            height="240px"
            className="image-gallery--displayed"
          />
        )}
      </LayoutBox>
      <LayoutBox justify="start" gap="1x">
        {urls?.map((url, index) => (
          <Image
            key={url.id}
            url={url.url}
            alt={`Thumbnail ${index + 1}`}
            onClick={() => handleImageClick(url.id)}
            width="64px"
            height="64px"
            className={
              // changeEmp
              selectedImageId === url.id ? "image-gallery--selected" : ""
            }
          />
        ))}
      </LayoutBox>
    </LayoutBox>
  );
};

ImageGallery.defaultProps = {
  urls: [],
};

export default ImageGallery;
