import { useCallback, useMemo } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import SpButton from "../components/Button/SpButton";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";

import CardCart from "../components/Card/CardCart";
import Icon from "../components/Icon/Icon";
import useOrderInfoFetch from "../hooks/common/useOrderInfoFetch";
import usePatientInfoFetch from "../hooks/common/usePatientInfoFetch";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import { OrderStatusID } from "../constants/common";
import { useCheckErrorThrowError } from "../utils/checkError";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { addHyphenToZipcode } from "../utils/utils";
import PatientFooter from "../features/PatientFooter";

const USF012 = () => {
  const { orderInfo, error } = useOrderInfoFetch(OrderStatusID.CANCELLED);
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  const navigate = useNavigateCustom();

  useCheckErrorThrowError([error, patientInfoError]);

  const cardCartItems = useMemo(
    () =>
      (orderInfo?.transactions_info ?? []).map((transaction) => ({
        tag: "キャンセル済み",
        name: transaction.item_name,
        description: transaction.sku_name,
        price: transaction.total_amount,
        quantity: transaction.quantity,
        image: transaction.skus_info.image_urls[0],
      })),
    [orderInfo?.transactions_info],
  );

  const navigateToShop = useCallback(() => {
    navigate(PATIENT.USF002);
  }, [navigate]);

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation minWidth="1076px" selectedTab={SELECTED_TAB_NUM.shop} />
        <Sheet padding="40px 108px 24px 108px" minWidth="1076px">
          <LayoutBox direction="column" width="440px" gap="2x">
            <LayoutBox direction="column" fullWidth>
              <Sheet type="card" padding="24px">
                <LayoutBox
                  fullWidth
                  justify="center"
                  align="center"
                  gap="2x"
                  toColumn
                >
                  <LayoutBox
                    direction="column"
                    fullWidth
                    gap="2x"
                    align="center"
                  >
                    <Icon color="#388e3c" icon="check_circle" size="xl" />
                    <Text size="xl" bold color="neutralUserBlack">
                      注文をキャンセルしました。
                    </Text>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
            <LayoutBox direction="column" fullWidth>
              <Sheet type="card" padding="24px">
                <LayoutBox
                  fullWidth
                  justify="center"
                  align="center"
                  gap="2x"
                  toColumn
                >
                  <LayoutBox direction="column" fullWidth gap="1x">
                    <Text color="neutralLight" size="large">
                      お届け先
                    </Text>
                    <Text size="xl">
                      {patientInfo?.name_sei}
                      {patientInfo?.name_mei} 様
                    </Text>
                    <Text size="xl">
                      {orderInfo &&
                        `${addHyphenToZipcode(orderInfo.order_zipcode) ?? ""} ${
                          orderInfo.order_address
                        }`}
                    </Text>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
            </LayoutBox>

            <LayoutBox direction="column" fullWidth>
              <Sheet type="card" padding="24px">
                <LayoutBox
                  direction="column"
                  fullWidth
                  justify="center"
                  align="center"
                  gap="10px"
                >
                  <CardCart
                    items={cardCartItems}
                    padding="0"
                    gap="10px"
                    type="view"
                    mobileType="view"
                    withBox={false}
                  />
                </LayoutBox>
              </Sheet>
            </LayoutBox>
            <LayoutBox
              direction="column"
              fullWidth
              justify="center"
              align="center"
            >
              <SpButton
                withArrowLeft
                type="secondary"
                width="440px"
                size="medium"
                mobileFullWidth
                onClick={navigateToShop}
              >
                ショップに戻る
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USF012;
