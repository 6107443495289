import { useCallback, useMemo, useState } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import List from "../components/List/List";
import Divider from "../components/Divider/Divider";
import Image from "../components/Image/Image";

import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import FooterButton from "../components/Footer/FooterButton";
import useUsf007DBActions from "../hooks/pages/USF007/useUsf007DBActions";
import usePatientInfoFetch from "../hooks/common/usePatientInfoFetch";
import {
  useNavigateWithBack,
  useNavigateWithCurrentState,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import { setId } from "../hooks/common/useId";
import { useCheckErrorThrowError } from "../utils/checkError";
import { openUsf006Dialog } from "../hooks/pages/USF006/useUsf006Dialog";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import SpButton from "../components/Button/SpButton";
import { addHyphenToZipcode } from "../utils/utils";
import PatientFooter from "../features/PatientFooter";

const DELIVERY_ITEMS = [
  {
    label: "通常配送",
    value: "1",
  },
  {
    label: "お届け日指定",
    value: "2",
  },
];

const USF007 = () => {
  const {
    cartInfo,
    handleOrder,
    error: cartError,
    actionError,
  } = useUsf007DBActions();
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  const handleGoBack = useNavigateWithBack(PATIENT.USF001);
  const navigateWithUrl = useNavigateWithUrl();
  const navigateWithCurrentState = useNavigateWithCurrentState();

  const [selectedDelivery, setSelectedDelivery] = useState("1");

  useCheckErrorThrowError([cartError, patientInfoError, actionError]);

  const zipcode = addHyphenToZipcode(patientInfo?.zipcode ?? "") as string;

  const navigateToChangeDeliveryDate = useCallback(() => {
    navigateWithCurrentState(PATIENT.USF008);
  }, [navigateWithCurrentState]);

  const onChangeDelivery = useCallback(
    (value: string) => {
      setSelectedDelivery(value);
      if (value === "2") navigateToChangeDeliveryDate();
    },
    [navigateToChangeDeliveryDate],
  );

  const handleConfirm = useCallback(async () => {
    const stripeUrl = await handleOrder();
    if (stripeUrl && cartInfo) {
      setId(cartInfo?._id);
      window.location.replace(stripeUrl);
    }
  }, [cartInfo, handleOrder]);

  const navigateToPatientEdit = useCallback(() => {
    if (patientInfo?._id) {
      setId(patientInfo?._id);
      navigateWithUrl(PATIENT.USG006);
    }
  }, [navigateWithUrl, patientInfo?._id]);

  const navigateToItemDetail = useCallback(
    (id: string) => {
      const transactionInfo = cartInfo?.transactions_info.find(
        (transactionInfo) => transactionInfo._id === id,
      );
      if (transactionInfo) {
        setId(transactionInfo.skus_info.item_id);
        navigateWithUrl(PATIENT.USF005);
      }
    },
    [cartInfo?.transactions_info, navigateWithUrl],
  );

  const cardList = useMemo(
    () =>
      cartInfo?.transactions_info.map((transactionInfo) => ({
        id: transactionInfo._id,
        left: (
          <>
            <Image
              url={transactionInfo.skus_info.image_urls[0]}
              width="88px"
              height="88px"
            />
          </>
        ),
        center: (
          <>
            <LayoutBox gap="1/2x" fullWidth direction="column">
              <Text size="xl" bold>
                {transactionInfo.item_name}
              </Text>
              <Text size="large">{transactionInfo.sku_name}</Text>
              <div className="util-mb-4" />
              <LayoutBox fullWidth>
                <Text size="2xl" bold>
                  {(transactionInfo.total_amount ?? 0).toLocaleString()}
                </Text>
                <Text size="xl" bold>
                  円
                </Text>
              </LayoutBox>
              <div className="util-mt-4" />
              <Text size="large">数量：{transactionInfo.quantity}</Text>
            </LayoutBox>
          </>
        ),
        right: <></>,
      })),
    [cartInfo?.transactions_info],
  );

  const taxAmount = useMemo(() => {
    const reducer = (sum: number, currentValue: number) => sum + currentValue;

    return cartInfo?.total_amount_per_tax
      .map((taxObj) => taxObj.tax)
      .reduce(reducer);
  }, [cartInfo?.total_amount_per_tax]);

  const handleGoBackCart = useCallback(() => {
    openUsf006Dialog();
    handleGoBack();
  }, [handleGoBack]);

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation minWidth="1076px" selectedTab={SELECTED_TAB_NUM.shop} />
        <Title
          padding="16px 108px 24px 108px"
          background="rough-blue"
          header="会計"
          onClickBack={handleGoBackCart}
          minWidth="1076px"
        />
        <Sheet padding="40px 108px" minWidth="1076px">
          <LayoutBox direction="column" gap="2x" width="1064px">
            <LayoutBox gap="2x" fullWidth direction="column">
              <Sheet
                type="border"
                color="white"
                borderRadius="8px"
                padding="16px"
              >
                <LayoutBox gap="2x" fullWidth direction="column">
                  <LayoutBox direction="column" gap="1/2x" fullWidth>
                    <Text size="large" color="neutralLight">
                      お届け先
                    </Text>
                    <Text size="xl">
                      {patientInfo?.name_sei}
                      {patientInfo?.name_mei} 様
                    </Text>
                    <Text size="xl">
                      {`${zipcode} ${patientInfo?.pref ?? ""}${
                        patientInfo?.city ?? ""
                      }${patientInfo?.town1 ?? ""}${patientInfo?.town2 ?? ""}`}
                    </Text>
                  </LayoutBox>
                  <LayoutBox fullWidth justify="center">
                    <SpButton
                      width="316px"
                      type="secondary"
                      icon="edit"
                      iconSize="medium"
                      fontSize="small"
                      onClick={navigateToPatientEdit}
                    >
                      会員情報を編集
                    </SpButton>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
              <Sheet
                type="border"
                color="white"
                borderRadius="8px"
                padding="16px"
              >
                <LayoutBox gap="1x" fullWidth>
                  <RadioButtonGroup
                    name="Delivert"
                    items={DELIVERY_ITEMS}
                    onChange={onChangeDelivery}
                    selectedValue={selectedDelivery}
                    withBorder
                    width="100%"
                    column={2}
                    radioGap="8px"
                  />
                </LayoutBox>
              </Sheet>
              <Sheet
                type="border"
                color="white"
                borderRadius="8px"
                padding="16px"
              >
                <LayoutBox gap="2x" fullWidth direction="column">
                  <Sheet
                    color="lightest"
                    borderRadius="8px"
                    padding="24px 16px"
                  >
                    <LayoutBox fullWidth gap="1x" direction="column">
                      <LayoutBox fullWidth justify="between">
                        <Text size="xl">合計</Text>
                        <Text size="xl" bold>
                          {(
                            (cartInfo?.total_item_amount ?? 0) +
                            (cartInfo?.delivery_fee ?? 0)
                          ).toLocaleString()}
                          円
                        </Text>
                      </LayoutBox>
                      <Divider orange borderWidth="1px" />
                      <LayoutBox fullWidth justify="between">
                        <Text size="large">商品の小計</Text>
                        <Text size="large">
                          {(cartInfo?.total_item_amount ?? 0).toLocaleString()}
                          円
                        </Text>
                      </LayoutBox>
                      <LayoutBox fullWidth justify="between">
                        <Text size="large">配送料</Text>
                        <Text size="large">
                          {cartInfo?.delivery_fee.toLocaleString()}円
                        </Text>
                      </LayoutBox>
                      <LayoutBox fullWidth justify="between">
                        <Text size="large">内税</Text>
                        <Text size="large">
                          {(taxAmount ?? 0).toLocaleString()}円
                        </Text>
                      </LayoutBox>
                    </LayoutBox>
                  </Sheet>
                  <List
                    onClick={navigateToItemDetail}
                    items={cardList ?? []}
                    selectedIds={[""]}
                  />
                  <LayoutBox fullWidth justify="center">
                    <SpButton
                      width="316px"
                      size="medium"
                      withArrowRight
                      onClick={() => {
                        void handleConfirm();
                      }}
                    >
                      <Text size="xl" underline color="white" bold>
                        注文を確定する
                      </Text>
                    </SpButton>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
      <FooterButton
        width="400px"
        leftButtonText="カートに戻る"
        rightButtonText="注文を確定する"
        justify="between"
        scrollable
        onClickRight={() => {
          void handleConfirm();
        }}
        onClickLeft={handleGoBackCart}
      />
    </div>
  );
};

export default USF007;
