import Footer from "../components/Footer/Footer";
import Text from "../components/Text/Text";

const PatientFooter = () => (
  <Footer>
    <Text size="base" color="white" noWrap>
      © NEXT-LOGIC, Inc. All Rights Reserved.
    </Text>
  </Footer>
);
export default PatientFooter;
