import { useCallback, useState } from "react";
import { useRealmApp } from "../../contexts/RealmApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { useNavigateCustom } from "../base/usePageTransitionCustom";
import { PATIENT } from "../../constants/pagePaths";

type BookingDates = {
  first?: Date;
  second?: Date;
  third?: Date;
  fourth?: Date;
  fifth?: Date;
};

// APIの形にデータ変換
const _combineDatesAndTimes = (
  selectedDates: (Date | undefined)[],
  selectedTimes: (string | undefined)[],
): BookingDates => {
  const keys = ["first", "second", "third", "fourth", "fifth"];

  return selectedDates
    .map((date, index) => {
      const timeRange = selectedTimes[index];
      if (date && timeRange) {
        const startTime = timeRange.split("～")[0];

        // 時間と分を取得
        const [hours, minutes] = startTime.split(":").map(Number);

        // 新しい Date オブジェクトを生成
        const dateTime = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          hours,
          minutes,
        );

        return { key: keys[index], value: dateTime };
      }

      return { key: keys[index], value: undefined };
    })
    .filter((item): item is { key: string; value: Date } => item !== undefined)
    .reduce((acc, { key, value }) => {
      acc[key as keyof BookingDates] = value;

      return acc;
    }, {} as BookingDates);
};

// 予約申込
const useBookingApply = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const navigate = useNavigateCustom();

  const [actionError, setActionError] = useState<Error | null>(null);

  const applyBooking = useCallback(
    async (
      bookingType: "interview" | "mri" | "pet",
      hospitalId: string,
      selectedDates: (Date | undefined)[],
      selectedTimes: (string | undefined)[],
      mriContraindications?: boolean | undefined,
      precautions?: Array<number> | undefined,
      invitationTicketId = "",
    ) => {
      addLoadCount();
      try {
        if (bookingType === "mri") {
          // MRI
          // 日付をデータ変換
          const candidateBookDates = _combineDatesAndTimes(
            selectedDates,
            selectedTimes,
          );

          // 仮予約登録API
          await currentUser?.functions["booking/savePreBooking"](
            bookingType,
            hospitalId,
            currentUser?.id,
            candidateBookDates,
            "",
            mriContraindications,
            precautions,
          );
          // 画面遷移
          navigate(PATIENT.USC009);
        } else if (bookingType === "interview") {
          // 面談
          // 日付をデータ変換
          const candidateBookDates = _combineDatesAndTimes(
            selectedDates,
            selectedTimes,
          );
          if (invitationTicketId) {
            // 無料
            // 仮予約登録API
            await currentUser?.functions["booking/savePreBooking"](
              bookingType,
              "",
              currentUser?.id,
              candidateBookDates,
              invitationTicketId,
            );
            navigate(PATIENT.USD005);
          } else {
            // 有料
            // StripeURL発行API
            const params = {
              booking_type: bookingType,
              user_id: currentUser?.id,
              candidate_book_dates: candidateBookDates,
            };
            const url = (await currentUser?.functions[
              "stripe/interview/createUrl"
            ](params)) as string;

            return url;
          }
        } else if (bookingType === "pet") {
          // PET
          // 日付をデータ変換
          const candidateBookDates = _combineDatesAndTimes(
            selectedDates,
            selectedTimes,
          );
          // StripeURL発行API
          const params = {
            booking_type: bookingType,
            user_id: currentUser?.id,
            hospital_id: hospitalId,
            candidate_book_dates: candidateBookDates,
            pet_precautions: precautions,
          };
          const url = (await currentUser?.functions["stripe/pet/createUrl"](
            params,
          )) as string;

          return url;
        }
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        decrementLoadCount();
      }

      return undefined;
    },
    [currentUser, navigate],
  );

  return {
    applyBooking,
    actionError,
  };
};

export default useBookingApply;
