import { useState, useEffect } from "react";
import { Collection, OrderStatusID } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateOneOrder, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useForceUpdate from "./useForceUpdate";

// ログインユーザのCartユーザ情報を取得
const useCartInfoFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [cartInfo, setCartInfo] = useState<OrdersType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const id = currentUser?.id;

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        // ログインユーザのCartユーザ情報を取得
        const mongoDbCart = getMongoDb(currentUser, Collection.ORDERS);
        const conditions = { patient_id: id, status: OrderStatusID.CART_KEPT };
        const aggregate = getAggregateOneOrder(conditions);
        const searchCartInfo = (await mongoDbCart.aggregate(
          aggregate,
        )) as OrdersType[];

        setCartInfo(searchCartInfo[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, forceUpdate.forceUpdateCount]);

  return { error, cartInfo };
};

export default useCartInfoFetch;
