import React from "react";

export type CheckboxProps = {
  checked?: boolean;
  label?: React.ReactNode;
  disabled?: boolean;
  indeterminate?: boolean;
  onChecked?: (checked: boolean) => void;
  className?: string;
  withContainer?: boolean;
  width?: React.CSSProperties["width"];
};

const Checkbox = ({
  checked = false,
  label = "",
  indeterminate = false,
  disabled = false,
  onChecked = () => {},
  className = "",
  withContainer = false,
  width = undefined,
}: CheckboxProps) => {
  const checkboxRef = React.useRef<HTMLInputElement>(null!);
  const [isChecked, setIsChecked] = React.useState(checked);

  React.useEffect(() => {
    if (indeterminate) {
      checkboxRef.current.indeterminate = true;
    } else {
      checkboxRef.current.indeterminate = false;
    }

    setIsChecked(checked);
  }, [indeterminate, checked]);

  const componentClass = React.useMemo(() => {
    const disabledClass = disabled ? ["checkbox--disabled"] : [];
    const checkedClass = isChecked ? ["checkbox--checked"] : [];

    return ["checkbox", ...disabledClass, ...checkedClass].join(" ");
  }, [disabled, isChecked]);

  const _onChecked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.currentTarget.checked);
      onChecked(e.currentTarget.checked);
    },
    [onChecked],
  );

  return (
    <label className={`${componentClass} ${className}`} style={{ width }}>
      <div className={withContainer ? "checkbox-container" : ""}>
        <input
          ref={checkboxRef}
          tabIndex={-1}
          checked={isChecked}
          type="checkbox"
          className="checkbox__input"
          disabled={disabled}
          onChange={_onChecked}
        />
        <span className="checkbox__label">{label}</span>
      </div>
    </label>
  );
};

Checkbox.defaultProps = {
  checked: false,
  label: "",
  disabled: false,
  indeterminate: false,
  onChecked: () => {},
  className: "",
  withContainer: false,
  width: undefined,
};

export default Checkbox;
