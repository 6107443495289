import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateInvitationUser, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

// 招待者の情報を取得
const useInvitationUserFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [invitationUserInfo, setInvitationUserInfo] =
    useState<InvitationUser | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const id = currentUser?.id;

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const mongoDbPatient = getMongoDb(
          currentUser,
          Collection.PATIENT_INVITATIONS,
        );
        const conditions = { invited_patient_id: id };
        const aggregate = getAggregateInvitationUser(conditions);
        const result = (await mongoDbPatient.aggregate(
          aggregate,
        )) as InvitationUser[];

        setInvitationUserInfo(result[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id]);

  return { error, invitationUserInfo };
};

export default useInvitationUserFetch;
