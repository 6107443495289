import { INIT_SEARCH_CRITERIA } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  search: typeof INIT_SEARCH_CRITERIA.USA031;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  search: INIT_SEARCH_CRITERIA.USA031,
});

const useUsa031Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useUsa031Conditions;
