import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  password: ValidationResultType<string>;
  rePassword: ValidationResultType<string>;
};

const INIT_STATE: DefaultValidationType = {
  password: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  rePassword: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
};

// 入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType>(INIT_STATE);
const usePasswordInput = (): ReactiveVarHooks<DefaultValidationType> =>
  useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setUserDetailValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setPassword = (
  value: DefaultValidationType["password"]["value"],
) => {
  _setUserDetailValidation(
    "password",
    value,
    VALIDATION_TYPE.PASSWORD_REQUIRED,
  );
};
export const setRePassword = (
  value: DefaultValidationType["rePassword"]["value"],
) => {
  // 再入力パスワードは入力チェック無し
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    rePassword: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};

// 値をすべて更新
export const setPasswordInput = ({
  password,
  rePassword,
}: {
  password: DefaultValidationType["password"]["value"];
  rePassword: DefaultValidationType["rePassword"]["value"];
}) => {
  setPassword(password);
  setRePassword(rePassword);
};

// 初期化
export const setInitPasswordInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkPasswordInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const { ...validationInfos } = currentInfos;
  const validationValues = Object.values(validationInfos);

  // 必須チェック
  const isEmpty = validationValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

// 同一パスワードチェック
export const setPasswordError = () => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    rePassword: {
      ...currentInput.rePassword,
      validationMessage: "パスワードは同一の値を入力してください。",
      isError: true,
    },
  });
};

export default usePasswordInput;
