import React from "react";
import Icon from "../Icon/Icon";
import Image from "../Image/Image";
import Text from "../Text/Text";
import LayoutBox from "../LayoutBox/LayoutBox";

export type ShortcutProps = {
  width?: string;
  maxWidth?: string;
  height?: string;
  imageWidth?: string;
  imageHeight?: string;
  icon?: string;
  url?: string;
  image?: string;
  disabled?: boolean;
  children: React.ReactNode;
  bottomText?: string;
  onClick?: () => void;
};

const Shortcut = ({
  width,
  maxWidth,
  height,
  imageHeight,
  imageWidth,
  icon,
  url,
  disabled,
  children,
  image,
  bottomText,
  // changeEmp
  onClick,
}: ShortcutProps) => {
  const shortcutClass = React.useMemo(() => {
    const classes = ["shortcut"];
    if (disabled) classes.push("shortcut--disabled");

    return classes.join(" ");
  }, [disabled]);

  return (
    <a
      className={shortcutClass}
      style={{ width, maxWidth, height }}
      href={url}
      // changeEmp
      onClick={onClick}
    >
      <LayoutBox fullWidth fullHeight className="shortcut__test">
        <span className="shortcut__image">
          {icon !== undefined && <Icon icon={icon} size="large" />}
          {image !== undefined && (
            <Image url={image} width={imageWidth} height={imageHeight} />
          )}
        </span>
        <LayoutBox fullWidth fullHeight>
          <div className="shortcut__text-container">
            <LayoutBox direction="column" fullWidth fullHeight flexShrink={0}>
              <div className="shortcut__text-main">
                <div className="shortcut__text">{children}</div>
              </div>

              {bottomText && (
                <div className="shortcut__text-bottom">
                  <Text size="xl" color="orange" bold>
                    {bottomText}
                  </Text>
                  <Text color="orange" size="large">
                    円
                  </Text>
                </div>
              )}
            </LayoutBox>
          </div>
          <LayoutBox fullWidth fullHeight align="center" justify="end">
            <Icon
              type="outlined"
              icon="expand_circle_right"
              size="medium"
              className="shortcut__arrow-icon"
              color="primary"
            />
          </LayoutBox>
        </LayoutBox>
      </LayoutBox>
    </a>
  );
};

Shortcut.defaultProps = {
  width: undefined,
  maxWidth: undefined,
  height: undefined,
  icon: undefined,
  url: undefined,
  disabled: false,
  image: undefined,
  imageWidth: undefined,
  imageHeight: undefined,
  bottomText: undefined,
  onClick: () => {},
};

export default Shortcut;
