import React from "react";
import Label from "./Label";

export type SpFormSetProps = {
  label?: string;
  toolTip?: { text: string; width: number; directionUnder?: boolean };
  required?: "badge" | "icon";
  vertical?: boolean;
  option?: {
    text: string;
    href: string;
  };
  base?: boolean;
  helperTextList?: string[];
  errorTextList?: string[];
  labelSize?: "small" | "medium" | "large" | "";
  labelWidth?: string;
  withRadio?: boolean;
  withCheckbox?: boolean;
  checked?: boolean;
  children: React.ReactNode;
  selectedValue?: string;
  minWidth?: string;
  flex?: string;
  maxWidth?: string;
  spToColumnAndFullWidth?: boolean;
  formVertical?: boolean;
  labelGap?: "small" | "medium" | "large";
};

const SpFormSet = ({
  label = undefined,
  toolTip,
  required,
  vertical = false,
  option,
  base = false,
  helperTextList = [],
  errorTextList = [],
  labelSize,
  labelWidth,
  withRadio = false,
  withCheckbox = false,
  checked = false,
  children,
  selectedValue,
  minWidth,
  maxWidth,
  flex,
  spToColumnAndFullWidth = false,
  formVertical,
  labelGap = "medium",
}: SpFormSetProps) => {
  const [disabled, setDisabled] = React.useState(!checked);

  const spFormClasses = React.useMemo(() => {
    const gapClass = [`sp-form-set-label-gap--${labelGap}`];
    const isVertical = vertical ? [`sp-form-set--vertical`] : [];
    const toColumn = spToColumnAndFullWidth ? [`sp-form-set--to-column`] : [];

    return ["sp-form-set", ...gapClass, ...isVertical, ...toColumn].join(" ");
  }, [vertical, labelGap, spToColumnAndFullWidth]);

  React.useEffect(() => {
    if (selectedValue) {
      setDisabled(selectedValue !== label);
    }
  }, [label, selectedValue]);

  return (
    <div
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      className={spFormClasses}
      style={{ minWidth, flex, maxWidth }}
    >
      {label && (
        <Label
          required={required}
          toolTip={toolTip}
          option={option}
          base={base}
          size={labelSize || (vertical ? "small" : "large")}
          withRadio={withRadio}
          withCheckbox={withCheckbox}
          disabled={disabled}
          setDisabled={setDisabled}
          radioValue={label}
          width={vertical ? null : labelWidth ?? "120px"}
        >
          {label}
        </Label>
      )}

      <div
        className={`${
          formVertical
            ? "sp-form-set__form sp-form-set__form--vertical"
            : "sp-form-set__form"
        }`}
      >
        {children}
        {helperTextList.map((helperText, index) => (
          <div
            key={`helper-text-${index}`}
            className="sp-form-set__helper-text"
          >
            {helperText}
          </div>
        ))}
        {errorTextList.map((errorText, index) => (
          <div
            key={`caption-massage-${index}`}
            className="sp-form-set__caption-massage--error"
          >
            {errorText}
          </div>
        ))}
      </div>
    </div>
  );
};

SpFormSet.defaultProps = {
  label: undefined,
  toolTip: undefined,
  required: undefined,
  vertical: false,
  option: undefined,
  base: false,
  helperTextList: undefined,
  errorTextList: undefined,
  labelSize: undefined,
  labelWidth: undefined,
  withRadio: false,
  withCheckbox: false,
  checked: false,
  selectedValue: "",
  minWidth: "",
  flex: "",
  maxWidth: "",
  spToColumnAndFullWidth: false,
  formVertical: true,
  labelGap: "medium",
};

export default SpFormSet;
