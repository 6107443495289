import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";

const initialState = sessionStorage.getItem("paymentPeriodId") || "";
const stateReactiveVar = createReactiveVar<string>(initialState);

const usePaymentPeriodId = (): ReactiveVarHooks<string> =>
  useReactiveVarHooks(stateReactiveVar);

export const setPaymentPeriodId = (paymentPeriodId: string) => {
  stateReactiveVar(paymentPeriodId);
  sessionStorage.setItem("paymentPeriodId", paymentPeriodId);
};

export default usePaymentPeriodId;
