import React from "react";
import Text from "../Text/Text";
import StepNavigation from "../StepNavigation/StepNavigation";
import LayoutBox from "../LayoutBox/LayoutBox";

export type OrderDetailsProps = {
  currentStep?: string;
  deliveryDate?: string;
  shippedDate?: string;
  paymentDate?: string;
  orderDate?: string;
  cancelDate?: string;
  images?: {
    url: string;
    alt: string;
  }[];
};

const OrderDetails = ({
  currentStep = "",
  deliveryDate,
  shippedDate,
  paymentDate,
  orderDate,
  cancelDate = "",
  images,
}: OrderDetailsProps) => {
  const [selectedImage, setSelectedImage] = React.useState<string | undefined>(
    images?.[0]?.url,
  );

  const handleImageClick = (image?: string) => {
    setSelectedImage(image);
  };

  const STEPS = [
    {
      id: "1",
      icon: "local_shipping",
      label: "",
      infoTitle: shippedDate,
      infoDetail: "発送済",
      cancelDate,
    },
    {
      id: "2",
      icon: "credit_card",
      label: "",
      infoTitle: paymentDate,
      infoDetail: "支払い完了",
    },
    {
      id: "3",
      icon: "shopping_cart",
      label: "",
      infoTitle: orderDate,
      infoDetail: "注文",
    },
  ];

  return (
    <>
      <div className="order-details">
        <div className="order-details-title">
          <Text size="xl" bold>
            お届け予定日:{deliveryDate}
          </Text>
        </div>
        <div className="order-details-body">
          <div className="order-details-image-container">
            <div
              className={
                selectedImage !== ""
                  ? "order-details--image order-details-preview"
                  : "order-details--image-empty order-details-preview"
              }
              style={{
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                backgroundImage: `url(${selectedImage})`,
                width: "204px",
                height: "204px",
              }}
            />
            <div className="order-details-image-group">
              {images &&
                images.map((imageUrl, index) => (
                  <div
                    key={index}
                    onClick={() => handleImageClick(imageUrl.url)}
                    className={
                      imageUrl.url !== ""
                        ? "order-details--image"
                        : "order-details--image-empty"
                    }
                    style={{
                      backgroundImage: `url(${imageUrl.url})`,
                      width: "64px",
                      height: "64px",
                    }}
                  />
                ))}
            </div>
          </div>
          <div className="order-details-info-container">
            <LayoutBox direction="row" fullWidth>
              <StepNavigation
                currentStepId={currentStep}
                steps={STEPS}
                isVertical
              />
            </LayoutBox>
          </div>
        </div>
      </div>
    </>
  );
};

OrderDetails.defaultProps = {
  images: [],
  deliveryDate: undefined,
  shippedDate: undefined,
  paymentDate: undefined,
  orderDate: undefined,
  cancelDate: "",
  currentStep: "",
};

export default OrderDetails;
