import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  isFromTopOpen: boolean;
  isFromM001Open: boolean;
};

// トップから開く場合と、M001から開く場合があるため、別々で状態管理
const stateReactiveVar = createReactiveVar<ConditionsType>({
  isFromTopOpen: false,
  isFromM001Open: false,
});

const useUsm002Dialog = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const openUsm002FromTopDialog = () => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    isFromTopOpen: true,
  });
};

export const closeUsm002FromTopDialog = () => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    isFromTopOpen: false,
  });
};

export const openUsm002FromM001Dialog = () => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    isFromM001Open: true,
  });
};

export const closeUsm002FromM001Dialog = () => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    isFromM001Open: false,
  });
};

export default useUsm002Dialog;
