import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Tabs from "../components/Tab/Tabs";
import Sheet from "../components/Sheet/Sheet";
import House from "../assets/tabs-icons/house-icon.svg";
import Notebook from "../assets/tabs-icons/notebook-icon.svg";
import Megaphone from "../assets/megaphone.png";
import Icon from "../components/Icon/Icon";
import List from "../components/List/List";
import SpButton from "../components/Button/SpButton";
import Card from "../components/Card/Card";
import PatientFooter from "../features/PatientFooter";

const TAB_ITEMS = [
  { title: "トップ", image: House },
  { title: "検査", image: Notebook, badge: "結果" },
];

const LIST_ITEMS = [
  {
    id: "1",
    left: <></>,
    center: (
      <>
        <LayoutBox gap="1/2x" fullWidth direction="column">
          <Text size="large" color="neutralLight">
            2022/01/12 10:00
          </Text>
          <Text size="1xl" bold>
            お知らせが入りますお知らせが入りますお知らせが入りますお知らせが入ります
          </Text>
        </LayoutBox>
      </>
    ),
    right: (
      <>
        <Icon icon="expand_circle_right" type="outlined" color="#CF4900" />
      </>
    ),
  },
  {
    id: "2",
    left: <></>,
    center: (
      <>
        <LayoutBox gap="1/2x" fullWidth direction="column">
          <Text size="large" color="neutralLight">
            2022/01/12 10:00
          </Text>
          <Text size="1xl" bold>
            お知らせが入りますお知らせが入ります
          </Text>
        </LayoutBox>
      </>
    ),
    right: (
      <>
        <Icon icon="expand_circle_right" type="outlined" color="#CF4900" />
      </>
    ),
  },
  {
    id: "3",
    left: <></>,
    center: (
      <>
        <LayoutBox gap="1/2x" fullWidth direction="column">
          <Text size="large" color="neutralLight">
            2022/01/12 10:00
          </Text>
          <Text size="1xl" bold>
            お知らせが入りますお知らせが入りますお知らせが入りますお知らせが入ります
          </Text>
        </LayoutBox>
      </>
    ),
    right: (
      <>
        <Icon icon="expand_circle_right" type="outlined" color="#CF4900" />
      </>
    ),
  },
];

const USG011 = () => (
  <div className="admin-area ">
    <HeaderUser backgroundColor="rough-yellow" />
    <div className="admin-inner area-with-header-footer">
      <Sheet padding="0 16px" minWidth="1076px" color="rough-yellow">
        <Tabs bottomBorder selectedTab={0} tabs={TAB_ITEMS} />
      </Sheet>
      <Sheet minWidth="1076px" padding="0" color="rough-yellow">
        <LayoutBox
          width="1064px"
          direction="column"
          gap="5x"
          className="util-py-40 util-sp-py-24 util-sp-flex--gap24"
        >
          <LayoutBox direction="column" gap="2x" fullWidth>
            <Sheet type="card" padding="24px 16px" className="util-sp-pt-16">
              <LayoutBox gap="3x" direction="column" align="center" fullWidth>
                <LayoutBox direction="column" gap="2x" align="center">
                  <Icon color="#D6A000" icon="warning" size="xl" />
                  <Text
                    bold
                    size="2xl"
                    color="neutralUserBlack"
                    lineHeight="150%"
                    textAlign="center"
                  >
                    退会済みです。
                  </Text>
                  <Text lineHeight="150%" textAlign="center" size="xl">
                    サービスをご利用いただく場合は、
                    再度会員登録を行なってください。
                  </Text>
                </LayoutBox>
                <SpButton type="primary" width="100%" size="medium">
                  会員登録
                </SpButton>
              </LayoutBox>
            </Sheet>

            <LayoutBox direction="column" gap="1x" fullWidth>
              <LayoutBox gap="1x" className="util-py-8">
                <img src={Megaphone} alt="megaphone" />
                <div className="util-mt-20">
                  <Text size="2xl" bold yellowUnderline lineHeight="50%">
                    検査
                  </Text>
                </div>
              </LayoutBox>
              <Text size="xl">検査結果を確認できます</Text>
              <Card
                header={
                  <div className="util-flex util-flex--column">
                    <Text bold size="xl">
                      のうKNOW
                    </Text>
                    <Text bold size="large">
                      オンラインでの認知機能テスト
                    </Text>
                  </div>
                }
              />
              <Card
                header={
                  <div className="util-flex util-flex--column">
                    <Text bold size="xl">
                      あたまの健康チェック
                    </Text>
                    <Text bold size="large">
                      お電話での認知機能テスト
                    </Text>
                  </div>
                }
              />
              <Card
                header={
                  <Text bold size="xl">
                    MRI検査
                  </Text>
                }
              />
              <Card
                header={
                  <Text bold size="xl">
                    FDG PET検査
                  </Text>
                }
              />
            </LayoutBox>
          </LayoutBox>
        </LayoutBox>
      </Sheet>

      <Sheet relative color="rough-yellow" padding="20px" minWidth="1076px">
        <div />
      </Sheet>
      <Sheet
        relative
        color="rough-yellow-wavy"
        padding="50px"
        minWidth="1076px"
      >
        <div />
      </Sheet>

      <Sheet minWidth="1076px">
        <LayoutBox
          width="1064px"
          direction="column"
          gap="7x"
          className="util-sp-flex--gap40"
        >
          <Sheet
            color="white"
            type="border"
            padding="24px 24px"
            minWidth="1064px"
            withBorderRadius
          >
            <LayoutBox direction="column" gap="1x" fullWidth>
              <LayoutBox gap="2x" rowGap="1/2x" wrap fullWidth>
                <Text size="2xl" bold yellowUnderline lineHeight="80%">
                  お知らせ
                </Text>
              </LayoutBox>
              <LayoutBox fullWidth>
                <List onClick={() => {}} selectedIds={[]} items={LIST_ITEMS} />
              </LayoutBox>
              <div className="util-mt-4" />
              <LayoutBox justify="center" fullWidth>
                <SpButton width="291px" fontSize="small" withArrowRight>
                  すべてのお知らせを確認する
                </SpButton>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </Sheet>
    </div>
    <PatientFooter />
  </div>
);

export default USG011;
