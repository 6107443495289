import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsc003, getMongoDb } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";

const useUsc003Actions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [result, setResult] = useState<USC003Type[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        const usc003Aggregate = getAggregateUsc003(currentUser?.id ?? "");
        const fetchResult = (await mongoDbPatients.aggregate(
          usc003Aggregate,
        )) as USC003Type[];
        setResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount]);

  return {
    result,
    error,
  };
};

export default useUsc003Actions;
