import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";

const initialState = sessionStorage.getItem("interviewType") || "";
const stateReactiveVar = createReactiveVar<string>(initialState);

const useInterviewTypeConditions = (): ReactiveVarHooks<string> =>
  useReactiveVarHooks(stateReactiveVar);

export const setInterviewType = (interviewType: string) => {
  stateReactiveVar(interviewType);
  sessionStorage.setItem("interviewType", interviewType);
};

export default useInterviewTypeConditions;
