import { useState, useEffect } from "react";

// モバイルデバイスの判定
const useDeviceType = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const { userAgent } = navigator;
    const platform = navigator.platform || "";

    const isMobileDevice = (): boolean => {
      // Check for Android
      if (/android/i.test(userAgent)) {
        return true;
      }
      // Check for iOS
      if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return true;
      }
      // Additional check for iOS devices (some older versions might not be caught by userAgent)
      if (platform === "MacIntel" && navigator.maxTouchPoints > 1) {
        return true;
      }

      return false;
    };

    setIsMobile(isMobileDevice());
  }, []);

  return isMobile;
};

export default useDeviceType;
