import React from "react";
import ArchBackground from "../SvgBackground/ArchBackground";
import ArchBackgroundSp from "../SvgBackground/ArchBackgroundSp";
import WavyBackground from "../SvgBackground/WavyBackground";
import WavyBackgroundSp from "../SvgBackground/WavyBackgroundSp";

export type SheetProps = {
  type?:
    | "default"
    | "border"
    | "gray"
    | "border-blue"
    | "border-red"
    | "header"
    | "card"
    | "left-border"
    | "colored-border"
    | "gray-sp";
  className?: string;
  children: React.ReactNode;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  maxHeight?: string;
  padding?: string;
  spPadding?: boolean;
  footer?: JSX.Element;
  color?:
    | "white"
    | "lightest"
    | "neutral-lightest"
    | "rough-orange"
    | "rough-blue"
    | "rough-blue-arch"
    | "yellow"
    | "primary-lightest"
    | "secondary-lightest"
    | "rough-yellow"
    | "rough-yellow-wavy"
    | "blue"
    | "";
  bottomBorder?: boolean;
  bottomBorderColor?: "primary" | "secondary";
  withBorderRadius?: boolean;
  withCornerText?: "new" | "end";
  borderRadius?: string;
  relative?: boolean;
  pointer?: boolean;
  border?: boolean;
  overflow?: boolean;
  // changeEmp
  onClick?: () => void;
};

const Sheet = ({
  type = "default",
  className = "",
  children,
  footer,
  width,
  minWidth,
  maxWidth,
  maxHeight,
  padding = "24px",
  spPadding = true,
  color = "",
  bottomBorder = false,
  bottomBorderColor = "primary",
  withBorderRadius = false,
  border = false,
  withCornerText,
  borderRadius = "",
  relative,
  pointer,
  overflow,
  // changeEmp
  onClick,
}: SheetProps) => {
  const sheetClass = React.useMemo(() => {
    const typeClass = [`sheet--${type}`];

    const colorClass = [`sheet--${color}`];

    const bottomBorderClass = bottomBorder
      ? [`sheet--border-bottom--${bottomBorderColor}`]
      : "";

    const withBorderRadiusClass = withBorderRadius
      ? [`sheet--border-radius`]
      : "";

    const relativeClass = relative ? [`sheet--relative`] : "";

    const spPaddingClass = spPadding ? [`sheet--sp-padding`] : "";

    const pointerClass = pointer ? [`sheet--pointer`] : "";
    const borderClass = border ? [`sheet--border`] : "";
    const overflowClass = overflow ? [`sheet--overflow`] : "";

    return [
      `sheet ${className}`,
      ...typeClass,
      ...colorClass,
      ...bottomBorderClass,
      ...withBorderRadiusClass,
      ...relativeClass,
      ...spPaddingClass,
      ...pointerClass,
      ...borderClass,
      ...overflowClass,
    ].join(" ");
  }, [
    type,
    className,
    color,
    bottomBorder,
    bottomBorderColor,
    withBorderRadius,
    relative,
    spPadding,
    pointer,
    border,
    overflow,
  ]);

  const isShowFooter = React.useMemo(() => footer !== undefined, [footer]);

  return (
    <div
      className={sheetClass}
      style={{ width, minWidth, maxWidth, padding, borderRadius, maxHeight }}
      onClick={onClick}
    >
      {color === "rough-blue-arch" && (
        <>
          <ArchBackground />
          <ArchBackgroundSp />
        </>
      )}

      {color === "rough-yellow-wavy" && (
        <>
          <WavyBackground />
          <WavyBackgroundSp />
        </>
      )}
      {withCornerText === "new" && (
        <div className="sheet--corner-background-new">
          <div className="sheet--corner-new">新着</div>
        </div>
      )}
      {withCornerText === "end" && (
        <div className="sheet--corner-background-end">
          <div className="sheet--corner-end">公開終了</div>
        </div>
      )}
      {children}
      {isShowFooter && (
        <div className="sheet--footer">
          <div>{footer}</div>
        </div>
      )}
    </div>
  );
};

Sheet.defaultProps = {
  type: "default",
  className: "",
  width: "",
  minWidth: "",
  maxWidth: "",
  maxHeight: "",
  padding: "24px",
  spPadding: true,
  footer: undefined,
  color: "",
  bottomBorder: false,
  bottomBorderColor: "primary",
  borderRadius: "",
  withBorderRadius: false,
  withCornerText: "",
  relative: false,
  pointer: false,
  border: false,
  overflow: false,
  // changeEmp
  onClick: undefined,
};

export default Sheet;
