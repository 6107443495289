import React, { useCallback, useEffect, useState } from "react";
import HeaderUser from "../components/Header/HeaderUser";
import Title from "../components/Title/Title";
import Text from "../components/Text/Text";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import PatientFooter from "../features/PatientFooter";
import { isActiveUser } from "../utils/utils";
import { useRealmApp } from "../contexts/RealmApp";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import SpButton from "../components/Button/SpButton";
import Divider from "../components/Divider/Divider";
import { PatientNouKnowStatusID, USC_021_TAB_ITEMS } from "../constants/common";
import usePatientNouknowsFetch from "../hooks/common/usePatientNouknowsFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import Nouknow from "../assets/nouknow.png";
import useNouknowEligibility from "../hooks/common/useNouknowEligibility";
import usePath from "../hooks/common/usePath";

const USC022 = () => {
  const realmAppContext = useRealmApp();
  const navigate = useNavigateWithUrl();
  const isActive = isActiveUser(realmAppContext.currentUser);
  const [selectedButton, setSelectedId] = React.useState<string | number>("1");
  const { patientNouknows, error: nouknowError } = usePatientNouknowsFetch();
  const [nouKnowUrl, setNouKnowUrl] = useState("");
  const { isNouknowsEligible } = useNouknowEligibility();
  const [path] = usePath();

  // エラー処理
  useCheckErrorThrowError([nouknowError]);

  // Top画面に遷移
  const handleClickBack = useCallback(() => navigate(path), [navigate, path]);

  // タブの切り替え
  const handleTabChange = useCallback(
    (selected: string | number) => {
      setSelectedId(selected);
      if (selected === "2") navigate(PATIENT.USC026);
    },
    [navigate],
  );

  // のうKNOWテスト
  useEffect(() => {
    if (!patientNouknows.length) {
      return;
    }

    // 期間内でテスト未実施のデータが対象
    const enabledPatientNouknows = patientNouknows.filter((item) => {
      const now = new Date();

      return (
        item.status === PatientNouKnowStatusID.PRETEST &&
        now >= item.exam_start &&
        now <= item.exam_end
      );
    });

    if (enabledPatientNouknows.length === 0) {
      return;
    }

    setNouKnowUrl(enabledPatientNouknows[0].nouknow_url);
  }, [patientNouknows]);

  // テスト開始画面に遷移
  const handleNavigateTestStart = () => {
    window.open(nouKnowUrl, "_blank");
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
          isActiveUser={isActive}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="検査"
          content="各種検査の受検と結果の確認ができます"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <ToggleButton
                size="large"
                width="100%"
                items={USC_021_TAB_ITEMS.slice(0, 2)}
                onClick={handleTabChange}
                selectedButton={selectedButton}
              />
            </LayoutBox>
            <Title
              type="h2"
              header="オンライン認知機能検査"
              borderBottom="primaryLight"
              withButton={false}
            />
          </LayoutBox>
        </Sheet>
        <LayoutBox direction="column" gap="3x" fullWidth>
          <Sheet>
            <Sheet type="card" maxWidth="1064px">
              <LayoutBox direction="column" fullWidth align="center" gap="3x">
                <LayoutBox direction="column" fullWidth align="start" gap="2x">
                  <LayoutBox direction="row" align="center" gap="2x" fullWidth>
                    <img
                      src={Nouknow}
                      alt="nouknow"
                      className="image--nouknow"
                    />
                    <Text size="xl">のうKNOW®</Text>
                  </LayoutBox>
                  <Divider darkColor orange={false} />
                  <Text size="xl">3か月ごとに受検してください。</Text>
                  <Text size="base">
                    「のうKNOW®」は、Cogstate
                    Ltd.が創出した認知機能チェック「Cogstate Brief
                    Battery」を、日本においてエーザイが、ブレインパフォーマンス（脳の健康度）をセルフチェックするための非医療機器として開発した製品です。
                  </Text>
                  <Text size="base">
                    ※疾病の診断又は予防に使用されることを目的としたものではありません。
                  </Text>
                </LayoutBox>
                <SpButton
                  size="medium"
                  icon="open_in_new"
                  mobileFullWidth
                  withArrowRight
                  onClick={handleNavigateTestStart}
                  disabled={!isNouknowsEligible || nouKnowUrl === ""}
                >
                  受検する
                </SpButton>
              </LayoutBox>
            </Sheet>
          </Sheet>
        </LayoutBox>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC022;
