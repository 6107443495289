import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  email: ValidationResultType<string>;
  password: ValidationResultType<string>;
};

const INIT_STATE: DefaultValidationType = {
  email: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  password: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
};

// 入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType>(INIT_STATE);
const useCreateAccountInput = (): ReactiveVarHooks<DefaultValidationType> =>
  useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setCreateAccountValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setEmail = (value: DefaultValidationType["email"]["value"]) => {
  _setCreateAccountValidation("email", value, VALIDATION_TYPE.EMAIL_REQUIRED);
};

export const setPassword = (
  value: DefaultValidationType["password"]["value"],
) => {
  _setCreateAccountValidation(
    "password",
    value,
    VALIDATION_TYPE.PASSWORD_REQUIRED,
  );
};

// 初期化
export const setInitCreateAccountInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkCreateAccountInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const { ...validationInfos } = currentInfos;
  const validationValues = Object.values(validationInfos);

  // 必須チェック
  const isEmpty = validationValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

// 同一Emailチェック
export const setExistingEmailError = (message: string) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    email: {
      ...currentInput.email,
      validationMessage: message,
    },
  });
};

export default useCreateAccountInput;
