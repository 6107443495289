import { CANCELABLE_HOUR, OrderStatusID } from "../constants/common";

export const isCancelableOrder = (settlementTime: Date, status: number) => {
  if (status === OrderStatusID.CANCELLED) return false;
  const now = new Date();
  const cancelableTime = new Date();
  cancelableTime.setHours(now.getHours() - CANCELABLE_HOUR);

  return cancelableTime < settlementTime && settlementTime < now;
};
