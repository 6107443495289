import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import SpButton from "../components/Button/SpButton";
import { INVITATION, PATIENT } from "../constants/pagePaths";
import usePatientInvitationsFetch from "../hooks/common/usePatientInvitationsFetch";
import PatientFooter from "../features/PatientFooter";
import { setPath } from "../hooks/common/usePath";
import InvitationUserCard from "../features/InvitationUserCard";

const SP_TAB_ITEMS = [
  {
    id: "1",
    label: "招待したアカウント",
  },
  {
    id: "2",
    label: "支払い状況",
  },
];

const USA030 = () => {
  const { patientInvitations } = usePatientInvitationsFetch("all");
  const navigate = useNavigate();
  const [selectedButton, setSelectedId] = React.useState<string | number>("1");

  // 登録済と登録前のアカウントで分ける
  const registered: PatientInvitation[] = [];
  const unregistered: PatientInvitation[] = [];
  patientInvitations.forEach((data) => {
    if (data.name) {
      registered.push(data);
    } else {
      unregistered.push(data);
    }
  });

  // 画面遷移
  const handleClickTab = useCallback(
    (selected: string | number) => {
      setSelectedId(selected);
      if (selected === "2") navigate(INVITATION.USA033);
    },
    [navigate],
  );

  const handleClickBack = () => navigate(INVITATION.USA029);

  const handleClickInvitationAdd = () => {
    setPath(INVITATION.USA030);
    navigate(PATIENT.USA027);
  };

  return (
    <div className="admin-area user-body-sp-full-height">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header="招待したアカウント"
          borderBottom="primary"
          minWidth="1076px"
          onClickBack={handleClickBack}
        />
        <Sheet padding="40px 0" className="util-sp-py-24" minWidth="1076px">
          <LayoutBox direction="column" width="1064px" gap="3x">
            <ToggleButton
              selectedButton={selectedButton}
              size="large"
              width="343px"
              items={SP_TAB_ITEMS}
              onClick={handleClickTab}
            />
            {/* 招待したアカウント */}
            <InvitationUserCard
              registered={registered}
              unregistered={unregistered}
            />
            <LayoutBox className="util-py-16 util-mb-40">
              <SpButton
                icon="add"
                size="medium"
                width="343px"
                onClick={handleClickInvitationAdd}
              >
                招待枠を追加
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div />
      </div>
      <PatientFooter />
    </div>
  );
};
export default USA030;
