import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import SpButton from "../components/Button/SpButton";
import Text from "../components/Text/Text";
import DialogCustom from "../components/Dialog/DialogCustom";
import useUsk001Dialog, {
  closeUsk001Dialog,
} from "../hooks/pages/USK001/useUsk001Dialog";
import useUpdateMessageStatus from "../hooks/common/useUpdateMessageStatus";
import { useCheckErrorThrowError } from "../utils/checkError";
import usePatientMessageFetchById from "../hooks/common/usePatientMessageFetchById";
import useDeviceType from "../hooks/common/useDeviceType";
import Icon from "../components/Icon/Icon";
import useUsk001DBFetch from "../hooks/pages/USK001/useUsk001DBFetch";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import { closeUsj001Dialog } from "../hooks/pages/USJ001/useUsj001Dialog";
import { BRAIN_CHECKS_PHONE_NUMBER } from "../constants/common";

type USK001Props = {
  messageId: string;
};

const USK001: React.FC<USK001Props> = ({ messageId }) => {
  const [isHeadCheckDialog] = useUsk001Dialog();
  const { updateStatus, actionError } = useUpdateMessageStatus();
  const { patientMessage, error: messageFetchError } =
    usePatientMessageFetchById(messageId);
  const isMobile = useDeviceType();
  const { brainChecks, brainChecksFetchError } = useUsk001DBFetch();
  const navigateWithUrl = useNavigateWithUrl();

  useCheckErrorThrowError([
    messageFetchError,
    actionError,
    brainChecksFetchError,
  ]);

  useEffect(() => {
    const updateMessageStatus = async () => {
      try {
        await updateStatus(messageId);
      } catch (error) {
        const err = error as Error;
        throw new Error(err.message);
      }
    };
    void updateMessageStatus();
  }, [messageId, updateStatus]);

  const isTestUnavailable = () => {
    if (!patientMessage?.headCheckTerms) {
      return true;
    }
    const { start, end } = patientMessage.headCheckTerms;
    const currentDate = new Date();

    return (
      brainChecks.length === 0 || !(currentDate >= start && currentDate <= end)
    );
  };

  const handleCallPhoneNumber = () => {
    const phoneLink = `tel:${BRAIN_CHECKS_PHONE_NUMBER}`;

    // ダミーアンカータグを作成してクリックイベントを発火
    const a = document.createElement("a");
    a.href = phoneLink;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleNavigateResult = () => {
    closeUsk001Dialog();
    closeUsj001Dialog();
    navigateWithUrl(PATIENT.USK002);
  };

  return (
    <DialogCustom
      open={isHeadCheckDialog}
      closeDialog={() => closeUsk001Dialog()}
      title="メッセージ"
      isShowCloseButton={false}
      height="1198px"
      isMobile
      isShowBackButton
      footerRight={
        <SpButton
          size="medium"
          type="secondary"
          width="311px"
          onClick={() => {
            closeUsk001Dialog();
            closeUsj001Dialog();
          }}
        >
          閉じる
        </SpButton>
      }
    >
      <div className="util-py-16">
        <LayoutBox fullWidth gap="3x" direction="column">
          <LayoutBox direction="column" gap="2x">
            <Text color="neutralUserBlack">{patientMessage?.created_at}</Text>
            <Text bold size="xl">
              あたまの健康チェック
            </Text>
          </LayoutBox>
          <LayoutBox direction="column" gap="2x">
            <Text size="large">
              あたまの健康チェックを受けられるようになりました。
              <br />
              {isMobile
                ? "あたまの健康チェックはお電話での実施となります。以下ボタンを押すと電話発信となり、受検を開始できます。"
                : "あたまの健康チェックはお電話での実施となるため、下記の電話番号から受検を開始してください。"}
              <br />
              <br />
              受検期間: {patientMessage?.headCheckTermsStr}
            </Text>
            {isMobile ? (
              <>
                <SpButton
                  width="100%"
                  withArrowRight
                  disabled={isTestUnavailable()}
                  onClick={handleCallPhoneNumber}
                >
                  受検する
                </SpButton>
                <LayoutBox gap="none">
                  <Text color="neutralUserLight">※</Text>
                  <Text color="neutralUserLight">
                    すでに受検したか受検期間を過ぎた場合、ボタンは押せなくなります。
                  </Text>
                </LayoutBox>
              </>
            ) : (
              <LayoutBox gap="2x" align="center" justify="center" width="100%">
                <Icon size="small" icon="phone" />
                <Text bold size="large">
                  {BRAIN_CHECKS_PHONE_NUMBER}
                </Text>
              </LayoutBox>
            )}
            <LayoutBox fullWidth direction="column" gap="2x">
              <Text size="large">検査結果はこちらから確認できます。</Text>
              <SpButton
                type="secondary"
                width="100%"
                onClick={handleNavigateResult}
              >
                検査結果
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </LayoutBox>
      </div>
    </DialogCustom>
  );
};

export default USK001;
