import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  redirectToNoDataPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateOneSale, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useForceUpdate from "./useForceUpdate";
import useReceiptId from "./useReceiptId";

const useSalesFetchById = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [id] = useReceiptId();
  const [sale, setSale] = useState<Receipt | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const collection = getMongoDb(currentUser, Collection.SALES);
        const conditions = { _id: id };
        const aggregate = getAggregateOneSale(conditions);
        const result = (await collection.aggregate(aggregate)) as Receipt[];

        if (result.length < 1) redirectToNoDataPage();
        setSale(result[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, forceUpdate.forceUpdateCount]);

  return { error, sale };
};

export default useSalesFetchById;
