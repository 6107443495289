import React from "react";

export type PopoverProps = {
  open?: boolean; // 外部から開閉制御するようにしたい場合に使用
  activatorRef: React.RefObject<HTMLElement>;
  withInteraction?: boolean;
  width?: number;
  direction?:
    | "top"
    | "right"
    | "under"
    | "left"
    | "topLeft"
    | "topRight"
    | "underLeft"
    | "underRight";
  aligned?: "left" | "right" | "center";
  forceDirection?: boolean;
  withShadow?: boolean;
  tail?: boolean;
  toggleOpen?: (open: boolean) => void;
  onClose?: () => void;
  children: React.ReactNode;
};

const Popover = ({
  open = false,
  withInteraction = false,
  width = 0,
  direction = "under",
  aligned = "left",
  forceDirection = false,
  withShadow = false,
  tail = false,
  activatorRef,
  toggleOpen,
  children,
  onClose,
}: PopoverProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [popoverWidth, setPopoverWidth] = React.useState(100);
  const [popoverPosition, setPopoverPosition] = React.useState({
    top: 0,
    left: 0,
    translateX: "",
    translateY: "",
  });
  const nonStaticParent = React.useRef<HTMLElement>();
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const popoverFinalWidth = React.useMemo(
    () => (width > 0 ? width : popoverWidth),
    [popoverWidth, width],
  );
  const [popoverDirection, setPopoverDirection] = React.useState(direction);

  const popoverStyle = React.useMemo(
    () => ({
      width: `${popoverFinalWidth}px`,
      top: `${popoverPosition.top ? `${popoverPosition.top}px` : "0"}`,
      left: `${popoverPosition.left ? `${popoverPosition.left}px` : "0"}`,
      transform: `${popoverPosition.translateX} ${popoverPosition.translateY}`,
    }),
    [
      popoverFinalWidth,
      popoverPosition.left,
      popoverPosition.top,
      popoverPosition.translateX,
      popoverPosition.translateY,
    ],
  );

  React.useLayoutEffect(() => {
    const bodyDom = document.body;
    const fullScreenHeight = bodyDom.clientHeight;
    const fullScreenWidth = bodyDom.clientWidth;

    const popoverDom = popoverRef?.current;
    const popoverRect = popoverDom?.getBoundingClientRect();
    const offsetY = popoverRect?.top ?? 0;
    const offsetX = popoverRect?.left ?? 0;
    const popoverHeight = popoverDom?.clientHeight ?? 0;

    const activatorDom = activatorRef?.current;
    const activatorRect = activatorDom?.getBoundingClientRect();
    const activatorY = activatorRect?.top ?? 0;
    const activatorX = activatorRect?.right ?? 0;
    const activatorBottom = activatorRect?.bottom ?? 0;
    const activatorWidth = activatorDom?.clientWidth ?? 0;

    const isStickOutUnder =
      offsetY + popoverHeight > fullScreenHeight ||
      activatorBottom + popoverHeight > fullScreenHeight;
    const isStickOutTop =
      offsetY <= 0 || activatorY <= 0 || activatorY - offsetY <= 0;
    const isStickOutRight =
      offsetX + popoverWidth > fullScreenWidth ||
      activatorX + popoverWidth > fullScreenWidth;
    const isStickOutLeft = offsetX <= 0 || activatorX - activatorWidth <= 0;

    let exactDirection = direction;

    if (!forceDirection) {
      if (isStickOutRight) exactDirection = "left";
      if (isStickOutLeft) exactDirection = "right";
      if (
        isStickOutTop ||
        (isStickOutTop && isStickOutRight) ||
        (isStickOutTop && isStickOutLeft)
      ) {
        exactDirection = "under";
      }
      if (
        isStickOutUnder ||
        (isStickOutUnder && isStickOutRight) ||
        (isStickOutUnder && isStickOutLeft)
      ) {
        exactDirection = "top";
      }
    }

    setPopoverDirection(exactDirection);
  }, [activatorRef, direction, isOpen, popoverWidth, forceDirection]);

  const getPopoverPosition = React.useCallback(
    (rect: DOMRect, offsetX: number, offsetY: number) => {
      const _aligned =
        aligned === "right"
          ? `translateX(calc(-100% + ${rect.width}px))`
          : aligned === "center" || tail
          ? `translateX(calc(-50% + ${rect.width / 2}px))`
          : "";

      switch (popoverDirection) {
        case "top":
          return {
            top: rect.top - offsetY + rect.height + (tail ? -12 : 0),
            left: rect.left - offsetX,
            translateY: `translateY(calc(-100% - ${rect.height}px))`,
            translateX: _aligned,
          };
        case "under":
          return {
            top: rect.top - offsetY + rect.height + (tail ? 12 : 0),
            left: rect.left - offsetX,
            translateY: "",
            translateX: _aligned,
          };
        case "left":
          return {
            top: rect.top - offsetY + rect.height - 4,
            left: rect.left - offsetX + (tail ? -12 : 0),
            translateY: `translateY(calc(-50% - ${rect.height / 2}px))`,
            translateX: `translateX(-100%)`,
          };
        case "right":
          return {
            top: rect.top - offsetY - 4,
            left: rect.left - offsetX + (tail ? 12 : 0),
            translateY: `translateY(calc(-50% + ${rect.height / 2}px))`,
            translateX: `translateX(${rect.width}px)`,
          };
        case "topLeft":
          return {
            top: rect.top - offsetY + rect.height + (tail ? -12 : 0),
            left: rect.left - offsetX + (tail ? -12 : 0),
            translateY: `translateY(calc(-100% - ${rect.height}px))`,
            translateX: "",
          };
        case "topRight":
          return {
            top: rect.top - offsetY + rect.height + (tail ? -12 : 0),
            left: rect.left - offsetX + (tail ? 12 : 0),
            translateY: `translateY(calc(-100% - ${rect.height}px))`,
            translateX: `translateX(calc(-100% + ${rect.width}px))`,
          };
        case "underLeft":
          return {
            top: rect.top - offsetY + rect.height + (tail ? 12 : 0),
            left: rect.left - offsetX + (tail ? -12 : 0),
            translateY: "",
            translateX: ``,
          };
        case "underRight":
          return {
            top: rect.top - offsetY + rect.height + (tail ? 12 : 0),
            left: rect.left - offsetX + (tail ? 12 : 0),
            translateY: "",
            translateX: `translateX(calc(-100% + ${rect.width}px))`,
          };
        default:
          return {
            top: 0,
            left: 0,
            translateY: "",
            translateX: "",
          };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [popoverDirection, aligned, tail, isOpen],
  );

  const onScroll = React.useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      if (onClose) {
        onClose();
      }
    }
    const rect = activatorRef.current?.getBoundingClientRect();
    const parentRect = nonStaticParent.current?.getBoundingClientRect();
    const offsetY = parentRect?.top || 0;
    const offsetX = parentRect?.left || 0;

    if (rect) {
      setPopoverPosition(getPopoverPosition(rect, offsetY, offsetX));
    }
  }, [activatorRef, getPopoverPosition, isOpen, onClose]);

  const calculatePosition = React.useCallback(
    (activatorRef: HTMLElement) => {
      if (activatorRef) {
        const refDom = activatorRef;

        let parent = refDom?.parentElement;
        while (parent?.tagName !== "BODY") {
          if (!parent) {
            break;
          }
          parent.addEventListener("scroll", onScroll);
          const elStyle = window.getComputedStyle(parent);
          if (!nonStaticParent.current && elStyle.position !== "static") {
            nonStaticParent.current = parent;
          }

          parent = parent.parentElement;
        }

        const rect = refDom?.getBoundingClientRect();

        const parentRect = nonStaticParent.current?.getBoundingClientRect();
        const offsetY = parentRect?.top || 0;
        const offsetX = parentRect?.left || 0;

        if (rect) {
          setPopoverWidth(rect.width || 100);
          setPopoverPosition(getPopoverPosition(rect, offsetY, offsetX));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activatorRef, getPopoverPosition, onScroll],
  );

  const activatorHeight = React.useRef(0);
  /** Resize Observer */
  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (activatorRef.current && isOpen) {
      const activatorResizeObserver = new ResizeObserver(() => {
        const activator = activatorRef.current;
        const newHeight = activator?.getBoundingClientRect().height;
        if (
          activatorHeight.current &&
          newHeight &&
          activatorHeight.current !== newHeight &&
          activatorRef.current
        ) {
          activatorHeight.current = newHeight;
          calculatePosition(activatorRef.current);
        }
      });
      activatorResizeObserver.observe(activatorRef.current);

      return () => activatorResizeObserver.disconnect();
    }
  }, [activatorRef, calculatePosition, isOpen]);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  React.useEffect(() => {
    if (activatorRef.current) {
      calculatePosition(activatorRef.current);
    }
  }, [activatorRef, calculatePosition]);

  const popoverClass = React.useMemo(() => {
    const withInteractionClass = withInteraction
      ? ["popover--with-interaction"]
      : [];
    const withShadowClass = withShadow ? ["popover--with-shadow"] : [];
    const withTailClass = tail ? ["popover--with-tail"] : [];
    const directionClass = popoverDirection
      ? [`popover--${popoverDirection}`]
      : [];

    return [
      "popover",
      ...withInteractionClass,
      ...withShadowClass,
      ...withTailClass,
      ...directionClass,
    ].join(" ");
  }, [popoverDirection, tail, withInteraction, withShadow]);

  const onClickOutside = React.useCallback(
    (e: MouseEvent) => {
      const refDom = popoverRef.current;
      if (
        e.target &&
        (refDom?.contains(e.target as Node) ||
          activatorRef.current?.contains(e.target as Node))
      ) {
        return;
      }
      setIsOpen(false);
      if (toggleOpen) {
        toggleOpen(false);
      }
    },
    [activatorRef, toggleOpen],
  );

  React.useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", onClickOutside);
    } else {
      window.removeEventListener("click", onClickOutside);
      const activatorDom = activatorRef.current;
      let parent = activatorDom?.parentElement;
      while (parent?.tagName !== "BODY") {
        if (!parent) {
          break;
        }
        parent.removeEventListener("scroll", onScroll);
        parent = parent.parentElement;
      }
    }
  }, [activatorRef, isOpen, onClickOutside, onScroll]);

  if (!isOpen) return null;

  return (
    <div ref={popoverRef} className={popoverClass} style={popoverStyle}>
      <div className="popover__item-container">{children}</div>
    </div>
  );
};

Popover.defaultProps = {
  open: false,
  withInteraction: false,
  width: 0,
  direction: "under",
  aligned: "left",
  withShadow: false,
  forceDirection: false,
  tail: false,
  toggleOpen: () => {},
  onClose: () => {},
};

export default Popover;
