import { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import SpButton from "../components/Button/SpButton";
import useUsj002Dialog, {
  closeUsj002Dialog,
} from "../hooks/pages/USJ002/useUsj002Dialog";
import usePatientMessageFetchById from "../hooks/common/usePatientMessageFetchById";
import { useCheckErrorThrowError } from "../utils/checkError";
import useUpdateMessageStatus from "../hooks/common/useUpdateMessageStatus";
import DialogCustom from "../components/Dialog/DialogCustom";
import { closeUsj001Dialog } from "../hooks/pages/USJ001/useUsj001Dialog";

type USJ002Props = {
  messageId: string;
};

const USJ002: React.FC<USJ002Props> = ({ messageId }) => {
  const [isMessageDetailDialog] = useUsj002Dialog();
  const { patientMessage, error } = usePatientMessageFetchById(messageId);
  const { updateStatus, actionError } = useUpdateMessageStatus();

  useCheckErrorThrowError([error, actionError]);

  useEffect(() => {
    const updateMessageStatus = async () => {
      try {
        await updateStatus(messageId);
      } catch (error) {
        const err = error as Error;
        throw new Error(err.message);
      }
    };
    void updateMessageStatus();
  }, [messageId, updateStatus]);

  return (
    <DialogCustom
      open={isMessageDetailDialog}
      closeDialog={() => closeUsj002Dialog()}
      title="メッセージ"
      isShowCloseButton={false}
      height="1198px"
      isMobile
      isShowBackButton
      footerRight={
        <SpButton
          size="medium"
          type="secondary"
          width="311px"
          onClick={() => {
            closeUsj002Dialog();
            closeUsj001Dialog();
          }}
          withArrowLeft
        >
          閉じる
        </SpButton>
      }
    >
      <LayoutBox gap="3x" direction="column" fullWidth>
        <LayoutBox gap="2x" direction="column" fullWidth>
          <Text color="neutralLight" size="small">
            {patientMessage?.created_at}
          </Text>
          <Text size="xl" bold>
            {patientMessage?.subject}
          </Text>
        </LayoutBox>
        <Text size="large" whiteSpace="pre-line">
          {patientMessage?.description}
        </Text>
      </LayoutBox>
    </DialogCustom>
  );
};

export default USJ002;
