import React from "react";
import SpButton from "../components/Button/SpButton";
import DialogCustom from "../components/Dialog/DialogCustom";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import useUsm002Dialog, {
  closeUsm002FromM001Dialog,
  closeUsm002FromTopDialog,
} from "../hooks/pages/USM002/useUsm002Dialog";
import { useCheckErrorThrowError } from "../utils/checkError";
import useNotificationFetchById from "../hooks/common/useNotificationFetchById";

type USM002Props = {
  notificationId: string;
  isTop?: boolean;
};

const USM002: React.FC<USM002Props> = ({ notificationId, isTop }) => {
  const [{ isFromM001Open, isFromTopOpen }] = useUsm002Dialog();
  const { notification, error } = useNotificationFetchById(notificationId);

  useCheckErrorThrowError([error]);

  const isOpen = isTop ? isFromTopOpen : isFromM001Open;
  const closeDialog = isTop
    ? () => closeUsm002FromTopDialog()
    : () => closeUsm002FromM001Dialog();
  const buttonName = isTop ? "閉じる" : "戻る";

  return (
    <DialogCustom
      open={isOpen}
      closeDialog={closeDialog}
      title="お知らせ"
      isShowCloseButton={false}
      isShowBackButton={!isTop}
      height="100%"
      isMobile
      paddingFooter="16px"
      paddingBody="16px 0"
      footerRight={
        <SpButton
          size="medium"
          type="secondary"
          width="100%"
          onClick={closeDialog}
        >
          {buttonName}
        </SpButton>
      }
    >
      <div className="util-full-width util-overflow-y util-px-16">
        <LayoutBox direction="column" gap="3x">
          <LayoutBox direction="column" gap="2x">
            <Text size="base" color="neutralLight">
              {notification?.deliveriedAt}
            </Text>
            <Text size="large" bold>
              {notification?.title}
            </Text>
          </LayoutBox>
          {notification?.imageUrl && (
            <img
              src={notification?.imageUrl || ""}
              className="util-full-width"
              alt=""
            />
          )}
          <Text size="large" color="neutralUserBlack" whiteSpace="pre-line">
            {notification?.detail}
          </Text>
        </LayoutBox>
      </div>
    </DialogCustom>
  );
};

USM002.defaultProps = {
  isTop: false,
};

export default USM002;
