import React from "react";

const ArchBackgroundSp = () => (
  <svg width="0" height="0">
    <defs>
      <clipPath id="ArchBackgroundSp" clipPathUnits="objectBoundingBox">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.695,0.073 H1 V1 H0 V0.073 H0.305 C0.314,0.032,0.398,0,0.5,0 C0.602,0,0.686,0.032,0.695,0.073"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ArchBackgroundSp;
