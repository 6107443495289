import LayoutBox from "../LayoutBox/LayoutBox";
import SpButton from "../Button/SpButton";

export type CardShopProps = {
  cardShop: {
    title: string;
    price: string;
    image?: string;
    alt?: string;
    // changeEmp
    onClick?: () => void;
  }[];
  buttonText?: string;
  withButton?: boolean;
  // changeEmp
  onClickWithButton?: () => void;
};

const CardShop = ({
  cardShop,
  buttonText = "商品の詳細を見る",
  withButton,
  // changeEmp
  onClickWithButton,
}: CardShopProps) => (
  <>
    <div className="card-shop">
      <LayoutBox direction="column" gap="3x" fullWidth>
        <LayoutBox gap="2x" justify="center" wrap fullWidth>
          {cardShop.map((item, index) => (
            <div className="card-shop-item" key={index}>
              <div className="card-shop--container">
                <div className="card-shop--inner">
                  <img
                    src={item.image}
                    alt={item.alt}
                    height="74px"
                    width="74px"
                  />
                  <div className="card-shop--content">
                    <div className="card-shop__title">{item.title}</div>
                    <div className="card-shop__price">
                      {item.price}
                      <span>円</span>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="card-shop__button"
                  onClick={item.onClick}
                >
                  <span>{buttonText}</span>
                </button>
              </div>
            </div>
          ))}
        </LayoutBox>
        {withButton && (
          <LayoutBox justify="center" fullWidth>
            <SpButton
              width="299px"
              size="medium"
              withArrowRight
              // changeEmp
              onClick={onClickWithButton}
            >
              他の商品を見る
            </SpButton>
          </LayoutBox>
        )}
      </LayoutBox>
    </div>
  </>
);

CardShop.defaultProps = {
  buttonText: "商品の詳細を見る",
  withButton: false,
  // changeEmp
  onClickWithButton: undefined,
};

export default CardShop;
