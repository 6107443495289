import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  redirectToNoDataPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateOneItem, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useForceUpdate from "./useForceUpdate";
import useId from "./useId";

// Item情報を取得
const useItemInfoFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [itemInfo, setItemInfo] = useState<ItemsType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const [id] = useId();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const mongoDbItem = getMongoDb(currentUser, Collection.ITEMS);
        const conditions = { _id: id };
        const aggregate = getAggregateOneItem(conditions);
        const searchItemInfo = (await mongoDbItem.aggregate(
          aggregate,
        )) as ItemsType[];

        if (searchItemInfo.length < 1) redirectToNoDataPage();
        setItemInfo(searchItemInfo[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, forceUpdate.forceUpdateCount]);

  return { error, itemInfo };
};

export default useItemInfoFetch;
