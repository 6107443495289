import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import { RealmAppProvider } from "./contexts/RealmApp";

const APP_ID = process.env.REACT_APP_APP_ID as string;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <RealmAppProvider appId={APP_ID}>
      <App />
    </RealmAppProvider>
  </React.StrictMode>,
);
