import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Card from "../components/Card/Card";
import Title from "../components/Title/Title";
import PatientFooter from "../features/PatientFooter";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import useUsk003Conditions from "../hooks/pages/USK003/useUsk003Conditions";
import useBrainCheckFetchById from "../hooks/common/useBrainCheckFetchById";
import { useCheckErrorThrowError } from "../utils/checkError";
import useSignedBrainCheckUrl from "../hooks/common/useSignedBrainCheckUrl";

const USK003 = () => {
  const navigate = useNavigate();
  const [{ selectBrainCheckId }] = useUsk003Conditions();

  const { brainCheck, error } = useBrainCheckFetchById(selectBrainCheckId);
  const { fetchSignedBrainCheckUrl, signedBrainCheckError } =
    useSignedBrainCheckUrl();

  useCheckErrorThrowError([error, signedBrainCheckError]);

  const handleClickBack = useCallback(
    () => navigate(PATIENT.USC026),
    [navigate],
  );

  const handleOpenReport = async () => {
    const signedUrl = await fetchSignedBrainCheckUrl(selectBrainCheckId);
    if (signedUrl) {
      window.open(signedUrl, "_blank");
    }
  };

  const replaceNewLinesWithBr = (text: string | undefined) =>
    text?.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="結果"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <Title
                type="h2"
                header="あたまの健康チェック"
                subheader={`受検案内日: ${brainCheck.exam_start_date}`}
                borderBottom="primaryLight"
                withButton={false}
              />
              <Card
                type="button"
                buttonItem={{
                  label: "レポートを見る",
                  width: "311px",
                  size: "medium",
                }}
                content={
                  <Text size="xl">
                    {replaceNewLinesWithBr(brainCheck.note)}
                  </Text>
                }
                onButtonItemClick={() => {
                  void handleOpenReport();
                }}
              />
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USK003;
