import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import PatientFooter from "../features/PatientFooter";

const USG002 = () => (
  <div className="admin-area user-body-sp-full-height">
    <LayoutBox direction="column" fullWidth>
      <HeaderUser withRightControl={false} />
      <div className="admin-inner area-with-header-footer">
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header="パスワードを忘れた方"
          withButton={false}
          borderBottom="primary"
        />
        <Sheet padding="40px 0" className="util-sp-py-24">
          <LayoutBox direction="column" maxWidth="440px">
            <Sheet type="card" padding="16px 16px 32px 16px">
              <LayoutBox direction="column" gap="2x">
                <Text bold size="2xl" color="neutralBlack" lineHeight="150%">
                  メールの送信が完了しました
                </Text>
                <Text size="xl" color="neutralBlack" lineHeight="150%">
                  メールの内容をご確認いただき、次の手順にお進みください。
                </Text>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </Sheet>
        <div />
      </div>
    </LayoutBox>
    <PatientFooter />
  </div>
);

export default USG002;
