import { useMemo, useCallback } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Icon from "../components/Icon/Icon";

import { PATIENT } from "../constants/pagePaths";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import usePatientInfoFetch from "../hooks/common/usePatientInfoFetch";
import CardCart from "../components/Card/CardCart";
import { useCheckErrorThrowError } from "../utils/checkError";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import useUsf009DBActions from "../hooks/pages/USF009/useUsf009DBActions";
import SpButton from "../components/Button/SpButton";
import { DeliveryMethodID } from "../constants/common";
import { formatDate } from "../utils/utils";
import PatientFooter from "../features/PatientFooter";

const USF009 = () => {
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  const navigate = useNavigateCustom();

  const { orderInfo, error } = useUsf009DBActions();

  useCheckErrorThrowError([error, patientInfoError]);

  const cardCartItems = useMemo(
    () =>
      (orderInfo?.transactions_info ?? []).map((transaction) => ({
        name: transaction.item_name,
        description: transaction.sku_name,
        price: transaction.total_amount,
        quantity: transaction.quantity,
        image: transaction.skus_info?.image_urls
          ? transaction.skus_info.image_urls[0]
          : "",
      })),
    [orderInfo?.transactions_info],
  );

  const navigateToShop = useCallback(() => {
    navigate(PATIENT.USF001);
  }, [navigate]);

  const taxAmount = useMemo(() => {
    const reducer = (sum: number, currentValue: number) => sum + currentValue;

    return orderInfo?.total_amount_per_tax
      .map((taxObj) => taxObj.tax)
      .reduce(reducer);
  }, [orderInfo?.total_amount_per_tax]);

  if (!orderInfo) return <></>;

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation minWidth="1076px" selectedTab={SELECTED_TAB_NUM.shop} />
        <Sheet padding="40px 108px" minWidth="1076px">
          <LayoutBox direction="column" gap="2x" width="1064px">
            <LayoutBox gap="2x" fullWidth direction="column">
              <Sheet type="border" color="white" borderRadius="8px">
                <LayoutBox gap="2x" fullWidth direction="column" align="center">
                  <Icon icon="check_circle" size="xl" color="#388E3C" />
                  <Text size="2xl" bold>
                    注文が確定しました。
                  </Text>
                  <Text size="2xl" bold>
                    ありがとうございました。
                  </Text>
                  {orderInfo.delivery_method === DeliveryMethodID.SPECIFIED && (
                    <Text size="xl">
                      お届け予定日：
                      {formatDate(orderInfo.desired_delivery_date)}
                    </Text>
                  )}
                </LayoutBox>
              </Sheet>
              <Sheet type="border" color="white" borderRadius="8px">
                <LayoutBox gap="1x" fullWidth direction="column">
                  <Text size="large" bold color="neutralLight">
                    お届け先
                  </Text>
                  <Text size="xl">
                    {patientInfo?.name_sei}
                    {patientInfo?.name_mei} 様
                  </Text>
                  <Text size="xl">
                    {`${orderInfo.order_zipcode} ${orderInfo.order_address}`}
                  </Text>
                </LayoutBox>
              </Sheet>
              <Sheet type="border" color="white" borderRadius="8px">
                <LayoutBox gap="2x" fullWidth direction="column">
                  <CardCart
                    items={cardCartItems}
                    padding="0"
                    gap="10px"
                    type="view"
                    mobileType="view"
                    deliveryCharge={orderInfo?.delivery_fee}
                    tax={taxAmount}
                  />
                </LayoutBox>
              </Sheet>
              <LayoutBox fullWidth justify="center">
                <SpButton
                  width="316px"
                  type="secondary"
                  size="medium"
                  withArrowLeft
                  onClick={navigateToShop}
                >
                  ショップに戻る
                </SpButton>
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USF009;
