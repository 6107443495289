import React from "react";

export type ContentFooterProps = {
  isFixed?: boolean;
  children: React.ReactNode;
};

const ContentFooter = ({ isFixed, children }: ContentFooterProps) => {
  const contentFooterClass = React.useMemo(() => {
    const fixedClass = isFixed ? ["content-footer--fixed"] : [];

    return ["content-footer", ...fixedClass].join(" ");
  }, [isFixed]);

  return <div className={contentFooterClass}>{children}</div>;
};

ContentFooter.defaultProps = {
  isFixed: false,
};

export default ContentFooter;
