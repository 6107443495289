import Card from "../components/Card/Card";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import BrainHealth from "../assets/brain_health.png";
import { useCheckErrorThrowError } from "../utils/checkError";
import useUsc021Actions from "../hooks/pages/USC021/useUsc021DBActions";

const TopBrainCheckCardWithdrawal = () => {
  const navigate = useNavigateWithUrl();
  const { error, brainListResult } = useUsc021Actions({ orderYear: null });

  // エラー処理
  useCheckErrorThrowError([error]);

  const handleChangeExamination = () => {
    if (brainListResult.length === 0) return;

    const queryParams = new URLSearchParams();
    queryParams.set("category", "2");

    navigate(`${PATIENT.USC026}?${queryParams.toString()}`);
  };

  return (
    <Card
      header={
        <>
          <LayoutBox
            gap="1/2x"
            justify="start"
            align="start"
            flexShrink={0}
            direction="row"
            minHeight="65px"
          >
            <img
              src={BrainHealth}
              alt="brainHealth"
              className={`image--icon image--brain_health ${
                brainListResult.length === 0 ? "image--icon--disabled" : ""
              }`}
            />
            <LayoutBox
              gap="1/2x"
              justify="center"
              align="start"
              flexShrink={0}
              direction="column"
            >
              <Text bold size="xl">
                あたまの健康チェック®
              </Text>
              <Text size="large">電話認知機能検査</Text>
            </LayoutBox>
          </LayoutBox>
        </>
      }
      onClick={handleChangeExamination}
      disabled={brainListResult.length === 0}
    />
  );
};
export default TopBrainCheckCardWithdrawal;
