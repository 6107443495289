import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

const useUsk001Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openUsk001Dialog = () => {
  stateReactiveVar(true);
};

export const closeUsk001Dialog = () => {
  stateReactiveVar(false);
};

export default useUsk001Dialog;
