import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsk002, getMongoDb } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";

const useUsk002DBFetch = (selectedResult: string) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [fetchResult, setFetchResult] = useState<USK002Type[]>([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_BRAIN_CHECKS,
        );
        const usc001Aggregate = getAggregateUsk002(
          selectedResult,
          currentUser?.id ?? "",
        );
        const fetchResult = (await mongoDbPatients.aggregate(
          usc001Aggregate,
        )) as USK002Type[];

        setFetchResult(fetchResult);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, selectedResult]);

  return {
    fetchResult,
    fetchError,
  };
};

export default useUsk002DBFetch;
