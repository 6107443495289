import { useCallback } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Card from "../components/Card/Card";
import Title from "../components/Title/Title";

import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import PatientFooter from "../features/PatientFooter";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import Divider from "../components/Divider/Divider";
import { CONSULTATION_PHONE_NUMBER } from "../constants/common";
import { useRealmApp } from "../contexts/RealmApp";
import useDeviceType from "../hooks/common/useDeviceType";
import Link from "../components/Link/Link";
import FreeNumber from "../assets/toll-free-number_logo.png";

const USD009 = () => {
  const navigateWithBack = useNavigateWithBack(PATIENT.USB001);
  const realmAppContext = useRealmApp();
  const isMobile = useDeviceType();

  const userId =
    (realmAppContext.currentUser?.customData.user_id as string) ?? "";

  const handleCall = () => {
    window.location.href = `tel:${CONSULTATION_PHONE_NUMBER}`;
  };

  const handleClickBack = useCallback(
    () => navigateWithBack(),
    [navigateWithBack],
  );

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation selectedTab={SELECTED_TAB_NUM.healthAdvice} />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="認知症相談ダイヤル24"
          content="24時間電話相談"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px 16px">
          <LayoutBox width="1064px" direction="column" gap="3x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <LayoutBox gap="1x" direction="column" fullWidth>
                <Card
                  clickable={false}
                  type="button"
                  header={
                    <LayoutBox
                      gap="1x"
                      justify="center"
                      align="start"
                      flexShrink={0}
                      direction="column"
                    >
                      <Text size="large">
                        会員ご本人様やご家族様からの相談を、医師や保健師・看護師が24時間365日対応いたします。
                      </Text>
                      <Sheet
                        padding="16px"
                        type="gray-sp"
                        borderRadius="8px"
                        onClick={isMobile ? handleCall : undefined}
                        pointer={!!isMobile}
                      >
                        <LayoutBox
                          gap="1x"
                          justify="center"
                          align="center"
                          flexShrink={0}
                          fullWidth
                          direction="column"
                        >
                          <LayoutBox gap="1x" align="center" justify="start">
                            <img
                              className="image--free_number"
                              src={FreeNumber}
                              alt="freeNumber"
                            />
                            <Text size="xl" bold>
                              {CONSULTATION_PHONE_NUMBER}
                            </Text>
                          </LayoutBox>
                          <Divider darkColor height="100%" margin="10px" />
                          <LayoutBox gap="1x" align="center" justify="start">
                            <Text size="xl" color="neutralUserLight">
                              会員ID
                            </Text>
                            <Text size="xl" color="neutralUserBlack">
                              {userId}
                            </Text>
                          </LayoutBox>
                        </LayoutBox>
                      </Sheet>
                    </LayoutBox>
                  }
                  content={
                    <>
                      <LayoutBox direction="column" gap="1x" fullWidth>
                        <LayoutBox direction="column" gap="none" fullWidth>
                          <Text size="large">※通話料は無料です。</Text>
                          <Text size="large">
                            ※サービスをご利用いただく際に、「お名前」と「会員ID」をお伺いします。
                          </Text>
                          <Text size="large">
                            ※「認知症と向き合う365」サービスの会員本人と、その同居のご家族の方にご利用いただけます。
                          </Text>
                          <br />
                        </LayoutBox>
                        <Text size="large" color="neutralUserLight">
                          【ご利用に際して】
                        </Text>
                        <Text size="large" color="neutralUserLight">
                          ・&nbsp;本サービスは、医療行為および診察行為を行うものではなく、ティーペック（株）の保健師、看護師等の有資格者が当サービスご利用者のご要望に沿った医療健康情報または受診や治療に関する情報やアドバイス等を提供するものです。
                        </Text>
                        <Text size="large" color="neutralUserLight">
                          ・&nbsp;ご利用の際は、発信者番号を通知してお掛けください。非通知発信ではご利用いただけません。
                        </Text>
                        <Text size="large" color="neutralUserLight">
                          ・&nbsp;国外の相談および国外からの相談はお受けできません。
                        </Text>
                        <Text size="large" color="neutralUserLight">
                          ・&nbsp;本サービス利用中にご利用者の病気や症状が改善することを保証するものではありません。
                        </Text>
                        <Text size="large" color="neutralUserLight">
                          ・&nbsp;サービス提供の際の録音、録画、撮影のご要望には原則として応じられません。
                        </Text>
                        <Text size="large" color="neutralUserLight">
                          ・&nbsp;ご利用者の状況またはご相談内容により相談の制限・停止をさせていただく場合があります。
                        </Text>
                        <Text size="large" color="neutralUserLight">
                          ・&nbsp;利用条件や、地域・内容により、ご要望に沿えない場合がありますので、不明点はお問い合わせください。
                        </Text>
                        <LayoutBox direction="row" gap="1/2x" align="center">
                          <LayoutBox
                            direction="row"
                            gap="none"
                            align="center"
                            wrap
                          >
                            <Text size="large" color="neutralUserLight">
                              ・&nbsp;個人情報の取扱いは、当社の
                            </Text>
                            <Link
                              size="medium"
                              color="primary"
                              url="https://dementia365.com/"
                            >
                              プライバシーポリシー
                            </Link>
                            <Text size="large" color="neutralUserLight">
                              をご確認ください。
                            </Text>
                          </LayoutBox>
                        </LayoutBox>
                      </LayoutBox>
                    </>
                  }
                />
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USD009;
