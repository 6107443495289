import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUserComponent from "../components/Header/HeaderUserComponent";
import Sheet from "../components/Sheet/Sheet";
import Icon from "../components/Icon/Icon";
import SpButton from "../components/Button/SpButton";
import PatientFooter from "../features/PatientFooter";

const USI001: React.FC<{ resetErrorBoundary: () => void }> = ({
  resetErrorBoundary,
}) => (
  <div className="admin-area admin-area_no-header user-body-sp-full-height">
    <HeaderUserComponent withRightControl={false} />
    <div className="admin-inner area-with-header-footer">
      <Sheet
        padding="40px"
        className="util-py-40 util-sp-py-24"
        minWidth="1088px"
      >
        <LayoutBox direction="column" maxWidth="440px" width="440px">
          <Sheet
            type="card"
            padding="24px 16px 32px 16px"
            className="util-sp-pt-16"
          >
            <LayoutBox
              gap="3x"
              direction="column"
              align="center"
              fullWidth
              className="util-sp-flex--gap16"
            >
              <LayoutBox direction="column" gap="2x" align="center">
                <Icon color="#D33A2F" icon="error" size="xl" />
                <LayoutBox wrap justify="center">
                  <Text
                    bold
                    size="2xl"
                    color="neutralUserBlack"
                    lineHeight="150%"
                    textAlign="center"
                  >
                    データを取得
                  </Text>
                  <Text
                    bold
                    size="2xl"
                    color="neutralUserBlack"
                    lineHeight="150%"
                    textAlign="center"
                  >
                    できませんでした。
                  </Text>
                </LayoutBox>
                <LayoutBox wrap justify="center">
                  <Text
                    size="xl"
                    color="neutralUserBlack"
                    lineHeight="150%"
                    textAlign="center"
                  >
                    ページを再読み込みして
                  </Text>
                  <Text
                    size="xl"
                    color="neutralUserBlack"
                    lineHeight="150%"
                    textAlign="center"
                  >
                    お試しください。
                  </Text>
                </LayoutBox>
              </LayoutBox>
              <SpButton
                type="primary"
                width="100%"
                size="medium"
                icon="refresh"
                iconSize="large"
                onClick={resetErrorBoundary}
              >
                ページを再読み込みする
              </SpButton>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </Sheet>
    </div>
    <PatientFooter />
  </div>
);

export default USI001;
