import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  checkFetchErr,
  redirectToNoDataPage,
} from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregatePatientMessageById, getMongoDb } from "../../utils/query";
import useStateCustomObj from "../base/useStateCustomObj";

const usePatientMessageFetchById = (messageId: string) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [patientMessage, setPatientMessage] =
    useStateCustomObj<PatientMessage | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MESSAGE_RELATIONSHIPS,
        );
        const aggregate = getAggregatePatientMessageById(messageId);
        const fetchResult = (await collection.aggregate(
          aggregate,
        )) as PatientMessage[];

        if (fetchResult.length < 1) redirectToNoDataPage();

        setPatientMessage(fetchResult[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, setPatientMessage, messageId]);

  return { error, patientMessage };
};

export default usePatientMessageFetchById;
