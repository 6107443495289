import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { Collection, PatientNouKnowStatusID } from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";

// のうKNOW完了
const useUsc014DBActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const location = useLocation();
  const isFirstRender = useRef(true);

  useEffect(() => {
    void (async () => {
      addLoadCount();
      try {
        if (isFirstRender.current) {
          // 初回のみ実行
          isFirstRender.current = false;

          const queryParams = new URLSearchParams(location.search);
          const brainsuiteNouknowId = queryParams.get("nouknowId") || "";
          const collection = getMongoDb(
            currentUser,
            Collection.PATIENT_NOUKNOWS,
          );
          await collection.updateOne(
            { brainsuite_nouknow_id: brainsuiteNouknowId },
            {
              $set: {
                status: PatientNouKnowStatusID.COMPLETED,
                updated_at: new Date(),
              },
            },
          );
        }
      } catch (e) {
        checkActionErr(e);
      } finally {
        decrementLoadCount();
      }
    })();
  }, [currentUser, location.search]);

  return <></>;
};

export default useUsc014DBActions;
