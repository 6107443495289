import React from "react";
import Icon from "../Icon/Icon";
import Popover from "../Popover/Popover";
import SpDropdownItem from "./SpDropdownItem";

export type SpDropdownProps = {
  size?: "default" | "small";
  status?: "error" | "default";
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  width?: string;
  disabled?: boolean;
  items: {
    value: string;
    label: string;
  }[];
};

const SpDropdown = ({
  size = "default",
  status = "default",
  value = "",
  onChange,
  placeholder = "",
  width = "240px",
  disabled = false,
  items,
}: SpDropdownProps) => {
  const [selectedValue, setSelectedValue] = React.useState(value);
  const [isOpen, setIsOpen] = React.useState(false);
  const activatorRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => setSelectedValue(value), [value]);

  const selectedItemLabel = React.useMemo(() => {
    const item = items.find((item) => item.value === selectedValue);

    return item && item.label ? item.label : item?.value ?? "";
  }, [items, selectedValue]);

  const SpDropdownButtonClass = React.useMemo(() => {
    const sizeClass = [`sp-dropdown-button--${size}`];
    const statusClass =
      status !== "default" ? [`sp-dropdown-button--${status}`] : [];
    const filledClass =
      selectedItemLabel !== "" ? ["sp-dropdown-button--filled"] : [];

    return [
      "sp-dropdown-button",
      ...sizeClass,
      ...statusClass,
      ...filledClass,
    ].join(" ");
  }, [size, status, selectedItemLabel]);

  const onPopoverToggleOpen = React.useCallback((open: boolean) => {
    setIsOpen(open);
  }, []);

  const closePopoverOnScroll = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const selectItem = React.useCallback(
    (value: string) => {
      setIsOpen(false);
      setSelectedValue(value);
      // eslint-disable-next-line no-unused-expressions
      onChange && onChange(value);
    },
    [onChange],
  );

  return (
    <div className="sp-dropdown" style={{ width }}>
      <button
        type="button"
        ref={activatorRef}
        className={SpDropdownButtonClass}
        disabled={disabled}
        onClick={(e) => {
          // changeEmp
          e.stopPropagation();
          onPopoverToggleOpen(true);
        }}
      >
        {selectedValue ? (
          <span className="sp-dropdown-button__inner">{selectedItemLabel}</span>
        ) : (
          <span className="sp-dropdown-button__inner sp-dropdown-button__inner--placeholder">
            {placeholder}
          </span>
        )}

        {status === "error" && (
          <span className="sp-dropdown-button__error-icon">
            <Icon icon="error" size="xs" />
          </span>
        )}

        <Icon icon="arrow_drop_down" size="small" />
      </button>
      <Popover
        open={isOpen}
        activatorRef={activatorRef}
        toggleOpen={onPopoverToggleOpen}
        onClose={closePopoverOnScroll}
        direction="under"
      >
        <div className="sp-dropdown__item-list">
          {items.map((item) => {
            const isSelected = item.value === selectedValue;

            return (
              <SpDropdownItem
                {...item}
                isSelected={isSelected}
                selectItem={selectItem}
                key={`sp-dropdown-${item.value}`}
              />
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

SpDropdown.defaultProps = {
  size: "default",
  status: "default",
  value: "",
  onChange: () => {},
  placeholder: "",
  width: "240px",
  disabled: false,
};

export default SpDropdown;
