import { useEffect } from "react";
import { useRealmApp } from "../contexts/RealmApp";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import {
  INVITATION,
  NON_ACTIVE_PRESENT,
  PATIENT,
} from "../constants/pagePaths";
import {
  getAggregatePatientLogin,
  getAggregateUsaInvitations,
  getMongoDb,
} from "../utils/query";
import { Collection, PatientTypeID, UserStatusID } from "../constants/common";

interface CustomData {
  status?: {
    $numberInt: string;
  };
}

// ログイン分岐処理
const LoginProcessing = () => {
  const navigate = useNavigateCustom();
  const realmAppContext = useRealmApp();

  useEffect(() => {
    const fetchData = async () => {
      // ファンクション
      if (!realmAppContext.currentUser?.customData) {
        await realmAppContext.currentUser?.refreshCustomData();
      }

      // 招待ユーザー情報
      const mongoDbPatients = getMongoDb(
        realmAppContext.currentUser,
        Collection.PATIENTS,
      );
      const LoginProcessingAggregate = getAggregatePatientLogin(
        realmAppContext.currentUser?.id ?? "",
      );

      return (await mongoDbPatients.aggregate(
        LoginProcessingAggregate,
      )) as PatientLogin[];
    };

    const fetchInvitationData = async () => {
      // ファンクション
      if (!realmAppContext.currentUser?.customData) {
        await realmAppContext.currentUser?.refreshCustomData();
      }

      // 招待ユーザー情報
      const mongoDbPatients = getMongoDb(
        realmAppContext.currentUser,
        Collection.PATIENT_INVITATIONS,
      );
      const LoginProcessingAggregate = getAggregateUsaInvitations(
        realmAppContext.currentUser?.id ?? "",
      );

      return (await mongoDbPatients.aggregate(
        LoginProcessingAggregate,
      )) as USA028Type[];
    };

    void (async () => {
      try {
        // アクティベート日付
        const activationDate =
          realmAppContext.currentUser?.customData.activation_date;
        const fetchInvitationResult = await fetchInvitationData();

        if (!activationDate && fetchInvitationResult.length > 0) {
          // 会員・招待選択画面へ
          navigate(PATIENT.USA004);

          return;
        }
        if (!activationDate) {
          const status = realmAppContext.currentUser?.customData.status as {
            $numberInt: string;
          };
          if (status && status.$numberInt === String(UserStatusID.INVITATION)) {
            // 招待トップ
            navigate(INVITATION.USA029);

            return;
          }
          // 会員・招待選択画面へ
          navigate(PATIENT.USA022);

          return;
        }

        // patient検索
        const fetchResult = await fetchData();
        if (fetchResult.length === 0) {
          // 会員・招待選択画面へ
          navigate(PATIENT.USA022);

          return;
        }

        // status フィールドの取得
        const customData: CustomData =
          realmAppContext.currentUser?.customData || {};

        // statusフィールドの値を取得し、$numberIntプロパティを含む場合はそれを使用
        const isStatus =
          Number(customData.status?.$numberInt) === UserStatusID.UNREGISTERED;

        const isPresentUser =
          fetchResult[0].patient_type === PatientTypeID.INVITATION;
        const isPatientInvitationUser =
          fetchResult[0]?.patient_type === PatientTypeID.PRESENT;

        if (isStatus && isPatientInvitationUser) {
          // 承認待ち
          navigate(NON_ACTIVE_PRESENT.USA036);
        } else if (isPresentUser) {
          // 招待トップ
          navigate(INVITATION.USA029);
        } else {
          // トップ
          navigate(PATIENT.USB001);
        }
      } catch (e) {
        // ログアウトしてエラー画面
        void realmAppContext.logOut();
        navigate(PATIENT.USG010);
      }
    })();
  }, [navigate, realmAppContext]);

  return <></>;
};

export default LoginProcessing;
