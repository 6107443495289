import React from "react";
import Icon from "../Icon/Icon";
import Popover from "../Popover/Popover";
import DropdownItem from "./DropdownItem";

export type DropdownProps = {
  size?: "default" | "small";
  status?: "error" | "default";
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  width?: string;
  disabled?: boolean;
  items: {
    value: string;
    label: string;
  }[];
};

const Dropdown = ({
  size = "default",
  status = "default",
  value = "",
  onChange,
  placeholder = "",
  width = "240px",
  disabled = false,
  items,
}: DropdownProps) => {
  const [selectedValue, setSelectedValue] = React.useState(value);
  const [isOpen, setIsOpen] = React.useState(false);
  const activatorRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => setSelectedValue(value), [value]);

  const selectedItemLabel = React.useMemo(() => {
    const item = items.find((item) => item.value === selectedValue);

    return item && item.label ? item.label : item?.value ?? "";
  }, [items, selectedValue]);

  const dropdownButtonClass = React.useMemo(() => {
    const sizeClass = [`dropdown-button--${size}`];
    const statusClass =
      status !== "default" ? [`dropdown-button--${status}`] : [];

    return ["dropdown-button", ...sizeClass, ...statusClass].join(" ");
  }, [size, status]);

  const onPopoverToggleOpen = React.useCallback((open: boolean) => {
    setIsOpen(open);
  }, []);

  const closePopoverOnScroll = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const selectItem = React.useCallback(
    (value: string) => {
      setIsOpen(false);
      setSelectedValue(value);
      // eslint-disable-next-line no-unused-expressions
      onChange && onChange(value);
    },
    [onChange],
  );

  return (
    <div className="dropdown" style={{ width }}>
      <button
        type="button"
        ref={activatorRef}
        className={dropdownButtonClass}
        disabled={disabled}
        onClick={() => onPopoverToggleOpen(true)}
      >
        {selectedValue ? (
          <span className="dropdown-button__inner">{selectedItemLabel}</span>
        ) : (
          <span className="dropdown-button__inner dropdown-button__inner--placeholder">
            {placeholder}
          </span>
        )}

        {status === "error" && (
          <span className="dropdown-button__error-icon">
            <Icon icon="error" size="xs" />
          </span>
        )}

        <Icon icon="arrow_drop_down" size="small" />
      </button>
      <Popover
        open={isOpen}
        activatorRef={activatorRef}
        toggleOpen={onPopoverToggleOpen}
        onClose={closePopoverOnScroll}
        direction="under"
      >
        <div className="dropdown__item-list">
          {items.map((item) => {
            const isSelected = item.value === selectedValue;

            return (
              <DropdownItem
                {...item}
                isSelected={isSelected}
                selectItem={selectItem}
                key={`dropdown-${item.value}`}
              />
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

Dropdown.defaultProps = {
  size: "default",
  status: "default",
  value: "",
  onChange: () => {},
  placeholder: "",
  width: "240px",
  disabled: false,
};

export default Dropdown;
