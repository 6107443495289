import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  selectedResult: string;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  selectedResult: "All",
});

const useUsk002Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setSelectedResult = (selectedResult: string) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    selectedResult,
  });
};

export default useUsk002Conditions;
