import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";

type LoadingType = {
  text: string;
  isLoadingAction: boolean;
};

const initState = {
  text: "",
  isLoadingAction: false,
};

// DB処理中かどうかと表示文言を管理
const stateReactiveVar = createReactiveVar<LoadingType>(initState);
export const useLoadingAction = (): ReactiveVarHooks<LoadingType> =>
  useReactiveVarHooks(stateReactiveVar);

export const closeActionLoading = () => {
  stateReactiveVar(initState);
};

const _openActionLoading = (text: string) => {
  stateReactiveVar({
    text,
    isLoadingAction: true,
  });
};

export const openCreateActionLoading = () => {
  _openActionLoading("作成中");
};

export const openDeleteActionLoading = () => {
  _openActionLoading("削除中");
};

export const openUpdateActionLoading = () => {
  _openActionLoading("更新中");
};
