import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsa028, getMongoDb } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";

const useUsa028Actions = (emailInputs: Array<USA027Type>) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [result, setResult] = useState<USA028Type[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  // Patient取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_INVITATIONS,
        );
        const usa028Aggregate = getAggregateUsa028(currentUser?.id ?? "");
        const fetchResult = (await mongoDbPatients.aggregate(
          usa028Aggregate,
        )) as USA028Type[];
        const matchingResults = fetchResult.filter((result) =>
          emailInputs.some((input) => input.email === result.email),
        );
        setResult(matchingResults);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, emailInputs, forceUpdate.forceUpdateCount]);

  return {
    result,
    error,
  };
};

export default useUsa028Actions;
