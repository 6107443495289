import React from "react";
import Icon from "../Icon/Icon";
import Text from "../Text/Text";

export type CollapseProps = {
  open?: boolean;
  label: string;
  children: React.ReactNode;
  width?: React.CSSProperties["width"];
  onChange?: (key?: string) => void;
  accordion?: boolean;
  accordionKey?: string;
  activeKey?: string;
};

const Collapse = ({
  open = false,
  label,
  children,
  width,
  onChange,
  accordion = false,
  accordionKey,
  activeKey,
}: CollapseProps) => {
  const [isOpen, setIsOpen] = React.useState(open);

  const collapseClass = (() => {
    const openStatusClass = isOpen ? [`collapse--is-open`] : [];
    const accordionClass = accordion ? [`collapse--accordion`] : [];

    return ["collapse", ...openStatusClass, ...accordionClass].join(" ");
  })();

  const onClick = () => {
    setIsOpen((prev) => !prev);
    onChange?.(accordionKey);
  };

  React.useLayoutEffect(() => {
    if (accordion) {
      setIsOpen(accordionKey === activeKey);
    }
  }, [accordionKey, activeKey, accordion]);

  React.useLayoutEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div className={collapseClass} style={{ width }}>
      <div className="collapse__header" onClick={onClick}>
        <Text color="neutralUserBlack">{label}</Text>
        <span className="collapse__header-icon-container">
          <Icon
            icon={isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            size="medium"
          />
        </span>
      </div>
      {isOpen && <div className="collapse__content">{children}</div>}
    </div>
  );
};

Collapse.defaultProps = {
  open: false,
  width: undefined,
  onChange: undefined,
  accordion: false,
  accordionKey: undefined,
  activeKey: undefined,
};

export default Collapse;
