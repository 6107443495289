import { useCallback, useState } from "react";
import useCartInfoFetch from "../../common/useCartInfoFetch";
import { useRealmApp } from "../../../contexts/RealmApp";

import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { DeliveryMethodID } from "../../../constants/common";
import { paymentUrlFormatDate } from "../../../utils/utils";

const useUsf008DBActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const { cartInfo, error } = useCartInfoFetch();
  const [actionError, setActionError] = useState<Error | null>(null);

  const handleOrder = useCallback(
    async (desiredDeliveryDate: Date) => {
      openUpdateActionLoading();
      if (cartInfo) {
        try {
          const desiredDeliveryDateStr =
            paymentUrlFormatDate(desiredDeliveryDate);
          const updateData = {
            order_id: cartInfo._id,
            delivery_method: DeliveryMethodID.SPECIFIED,
            desired_delivery_date: desiredDeliveryDateStr,
          };

          const url = (await currentUser?.functions[
            "stripe/ec/createPaymentUrl"
          ](updateData)) as string;

          return url;
        } catch (err) {
          setActionError(checkActionErr(err));
        } finally {
          closeActionLoading();
        }
      }

      return undefined;
    },
    [cartInfo, currentUser?.functions],
  );

  return { cartInfo, handleOrder, actionError, error };
};

export default useUsf008DBActions;
