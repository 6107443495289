import React from "react";
import { Navigate } from "react-router-dom";

import { useRealmApp } from "../contexts/RealmApp";
import { PATIENT } from "../constants/pagePaths";

// ログイン必須画面(ユーザの状態に関係なくアクセス)
const RequiredLoginElement: React.FC<{ element: JSX.Element }> = ({
  element,
}) => {
  const realmAppContext = useRealmApp();
  const isLogin = Boolean(realmAppContext.currentUser);

  return isLogin ? element : <Navigate to={PATIENT.USA001} />;
};

export default RequiredLoginElement;
