import React from "react";
import LayoutBox from "../LayoutBox/LayoutBox";
import Divider from "../Divider/Divider";
import Link from "../Link/Link";

export type FooterProps = {
  children?: React.ReactNode;
};

const Footer = ({ children }: FooterProps) => (
  <div className="footer-container">
    <LayoutBox direction="column" fullWidth>
      <div className="footer">
        <div className="footer__content">
          <LayoutBox justify="between" fullWidth className="footer__control">
            <LayoutBox align="center" flexShrink={0} className="footer__pc">
              <Link
                url="https://next-logic.co.jp/"
                linkClassName="footer__link"
                externalIcon={false}
              >
                運営会社
              </Link>
              <Divider vertical ghost orange={false} height="100%" />
              <Link
                url="https://dementia365.com/"
                linkClassName="footer__link"
                externalIcon={false}
              >
                サービス紹介サイト
              </Link>
              <Divider vertical ghost orange={false} height="100%" />
              <Link
                url="https://dementia365.com/"
                linkClassName="footer__link"
                externalIcon={false}
              >
                使い方マニュアル
              </Link>
              <Divider vertical ghost orange={false} height="100%" />
              <Link
                url="https://dementia365.com/"
                linkClassName="footer__link"
                externalIcon={false}
              >
                プライバシーポリシー
              </Link>
              <Divider vertical ghost orange={false} height="100%" />
              <Link
                url="https://dementia365.com/"
                linkClassName="footer__link"
                externalIcon={false}
              >
                特定商取引法に基づく表記
              </Link>
              <Divider vertical ghost orange={false} height="100%" />
              <Link
                url="https://dementia365.com/"
                externalIcon={false}
                linkClassName="footer__link"
              >
                利用規約
              </Link>
            </LayoutBox>

            <LayoutBox
              align="center"
              flexShrink={0}
              direction="column"
              className="footer__sp"
              justify="center"
              gap="1x"
              fullWidth
            >
              <LayoutBox
                gap="1/2x"
                align="center"
                fullWidth
                className="footer__sp-top"
              >
                <div className="link--wrapper">
                  <Link
                    url="https://next-logic.co.jp/"
                    linkClassName="footer__link"
                    externalIcon={false}
                  >
                    運営会社
                  </Link>
                </div>
                <Divider
                  vertical
                  ghost
                  orange={false}
                  height="100%"
                  margin="0"
                />
                <div className="link--wrapper">
                  <Link
                    url="https://dementia365.com/"
                    linkClassName="footer__link"
                    externalIcon={false}
                  >
                    サービス紹介サイト
                  </Link>
                </div>
                <Divider
                  vertical
                  ghost
                  orange={false}
                  height="100%"
                  margin="0"
                />
                <div className="link--wrapper">
                  <Link
                    url="https://dementia365.com/"
                    linkClassName="footer__link"
                    externalIcon={false}
                  >
                    使い方マニュアル
                  </Link>
                </div>
                <Divider
                  vertical
                  ghost
                  orange={false}
                  height="100%"
                  margin="0"
                />
                <div className="link--wrapper">
                  <Link
                    url="https://dementia365.com/"
                    linkClassName="footer__link"
                    externalIcon={false}
                  >
                    プライバシーポリシー
                  </Link>
                </div>
              </LayoutBox>
              <Divider ghost orange={false} width="100%" margin="0" />
              <LayoutBox
                align="center"
                fullWidth
                gap="2x"
                className="footer__sp-bottom"
              >
                <div className="link--wrapper">
                  <Link
                    url="https://dementia365.com/"
                    externalIcon={false}
                    linkClassName="footer__link"
                  >
                    特定商取引法に基づく表記
                  </Link>
                </div>
                <Divider
                  vertical
                  ghost
                  orange={false}
                  height="100%"
                  margin="0"
                />
                <div className="link--wrapper">
                  <Link
                    url="https://dementia365.com/"
                    externalIcon={false}
                    linkClassName="footer__link"
                  >
                    利用規約
                  </Link>
                </div>
              </LayoutBox>
            </LayoutBox>
            <LayoutBox fullWidth justify="end" className="footer__copyright">
              {children}
            </LayoutBox>
          </LayoutBox>
        </div>
        <div className="footer__content-sp">
          <LayoutBox fullWidth justify="end" className="footer__copyright-sp">
            {children}
          </LayoutBox>
        </div>
      </div>
    </LayoutBox>
  </div>
);

Footer.defaultProps = {
  children: undefined,
};

export default Footer;
