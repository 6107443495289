import { useState } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

type UserDataType = {
  _id: string;
  email: string;
};

// usersに同一emailが存在するかチェックします
const useCheckUsersEmail = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [error, setError] = useState<Error | null>(null);

  const checkExistingEmail = async (email: string) => {
    addLoadCount();
    try {
      const collection = getMongoDb(currentUser, Collection.USERS);
      const existingEmail = (await collection.find({
        _id: { $ne: currentUser?.id },
        email,
      })) as UserDataType[];

      if (existingEmail.length > 0) {
        return true;
      }

      return false;
    } catch (err) {
      setError(checkFetchErr(err));

      return false;
    } finally {
      decrementLoadCount();
    }
  };

  return { error, checkExistingEmail };
};

export default useCheckUsersEmail;
