import React from "react";
import LayoutBox from "../LayoutBox/LayoutBox";
import LineIcon from "../../assets/sp_buttons/line_icon.svg";
import GoogleIcon from "../../assets/sp_buttons/google_icon.svg";

export type SpButtonAccountProps = {
  type?: "line" | "google" | "";
  size?: "small" | "medium";
  disabled?: boolean;
  width?: string;
  className?: string;
  onClick?: (value?: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  mobileFullWidth?: boolean;
};

const SpButtonAccount = ({
  type = "",
  size = "medium",
  disabled = false,
  width = "",
  className = "",
  onClick,
  children,
  mobileFullWidth = false,
}: SpButtonAccountProps) => {
  const buttonClass = React.useMemo(() => {
    const typeClass = [`sp-btn-account--${type}`];
    const sizeClass = [`sp-btn--${size}`];
    const iconOnlyClass = !children ? [`sp-btn--icon-only`] : [];
    const mobileClass = mobileFullWidth ? [`sp-btn--mobile-fullwidth`] : [];

    return [
      "sp-btn",
      ...typeClass,
      ...sizeClass,
      ...iconOnlyClass,
      ...mobileClass,
    ].join(" ");
  }, [children, type, size, mobileFullWidth]);

  return (
    <button
      type="button"
      className={`${buttonClass} ${className}`}
      disabled={disabled}
      style={{ width }}
      onClick={onClick}
    >
      <span className="sp-btn__inner sp-btn-account-margin">
        <>
          <LayoutBox
            width="100%"
            justify="center"
            align="center"
            flexShrink={0}
            gap="1/2x"
          >
            <img
              src={type === "google" ? GoogleIcon : LineIcon}
              height="40px"
              width="40px"
              alt="accouunt_icon"
              className="sp-btn-account__icon"
            />

            {children && <span className="sp-btn__content">{children}</span>}
          </LayoutBox>
        </>
      </span>
    </button>
  );
};

SpButtonAccount.defaultProps = {
  type: "",
  size: "medium",
  disabled: false,
  width: "",
  className: "",
  onClick: () => {},
  mobileFullWidth: false,
};

export default SpButtonAccount;
