import { useCallback } from "react";
import Dialog from "../components/Dialog/Dialog";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import usePatientInfoFetch from "../hooks/common/usePatientInfoFetch";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import { useCheckErrorThrowError } from "../utils/checkError";
import useUsf003Dialog, {
  closeUsf003Dialog,
} from "../hooks/pages/USF003/useUsf003Dialog";
import SpButton from "../components/Button/SpButton";
import { addHyphenToZipcode } from "../utils/utils";

const USF003 = () => {
  const [isOpen] = useUsf003Dialog();

  const { patientInfo, error } = usePatientInfoFetch();
  const navigate = useNavigateWithUrl();

  useCheckErrorThrowError([error]);

  const zipcode = addHyphenToZipcode(patientInfo?.zipcode ?? "") as string;

  const navigateToDetail = useCallback(() => {
    closeUsf003Dialog();
    navigate(PATIENT.USG006);
  }, [navigate]);

  const handleClickCloseButton = useCallback(() => {
    closeUsf003Dialog();
  }, []);

  return (
    <Dialog
      open={isOpen}
      closeDialog={handleClickCloseButton}
      size="small"
      title="住所"
      isShowCloseButton={false}
      height="407px"
      footerLeft={
        <LayoutBox justify="center" fullWidth wrapReverse gap="2x">
          <SpButton
            size="medium"
            type="secondary"
            width="311px"
            onClick={handleClickCloseButton}
          >
            <Text size="large" underline color="orange" bold>
              キャンセル
            </Text>
          </SpButton>
          <SpButton
            size="medium"
            width="311px"
            icon="edit"
            iconSize="medium"
            fontSize="small"
            onClick={() => navigateToDetail()}
          >
            会員情報を編集
          </SpButton>
        </LayoutBox>
      }
    >
      <Sheet type="left-border">
        <LayoutBox direction="column" gap="1x" fullWidth>
          <Text size="xl" bold>
            {patientInfo?.name_sei}
            {patientInfo?.name_mei} 様
          </Text>
          <Text size="xl">
            {`${zipcode} ${patientInfo?.pref ?? ""}${patientInfo?.city ?? ""}${
              patientInfo?.town1 ?? ""
            }${patientInfo?.town2 ?? ""}`}
          </Text>
        </LayoutBox>
      </Sheet>
    </Dialog>
  );
};

export default USF003;
