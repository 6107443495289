import { useState } from "react";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../base/useLoadingAction";

// 予約取消
const usePatientInterviewsActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);

  // 予約取消API 失敗時にはfalseを返します。
  const updateInterviewsCancel = async (id: string | null | undefined) => {
    openUpdateActionLoading();
    try {
      // 予約取消API
      await currentUser?.functions["interview/delete"](id);

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  return {
    error,
    updateInterviewsCancel,
  };
};

export default usePatientInterviewsActions;
