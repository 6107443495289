import Text from "../components/Text/Text";
import InformationDialog from "../components/Dialog/InformationDialog";
import useNlj002Dialog, {
  closeNlj002Dialog,
} from "../hooks/pages/NLJ002/useNlj002Dialog";
import SpButton from "../components/Button/SpButton";

// TODO デザイン反映
// TODO isOpen周りを調整
// TODO ファイル名変更

/* 汎用ポップアップ */
const NLJ002 = () => {
  const [{ isOpen, title, message, isError }] = useNlj002Dialog();

  return (
    <>
      {isOpen && (
        <InformationDialog
          open={isOpen}
          closeDialog={() => closeNlj002Dialog()}
          title={title}
          footer={
            <SpButton width="275px" onClick={() => closeNlj002Dialog()}>
              OK
            </SpButton>
          }
          alertLevel={isError ? "error" : "normal"}
        >
          <Text size="xl">{message}</Text>
        </InformationDialog>
      )}
    </>
  );
};

export default NLJ002;
