import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import {
  redirectToInvalidAccessPage,
  redirectToNoDataPage,
  checkFetchErr,
} from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateOneOrder, getMongoDb } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useForceUpdate from "./useForceUpdate";
import useId from "./useId";

// ログインユーザのOrderユーザ情報を取得
const useDetailOrderInfoFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [orderInfo, setOrderInfo] = useState<OrdersType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const [id] = useId();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        // ログインユーザのOrderユーザ情報を取得
        const mongoDbOrder = getMongoDb(currentUser, Collection.ORDERS);
        const conditions = { _id: id };
        const aggregate = getAggregateOneOrder(conditions);
        const searchOrderInfo = (await mongoDbOrder.aggregate(
          aggregate,
        )) as OrdersType[];

        if (searchOrderInfo.length < 1) redirectToNoDataPage();
        setOrderInfo(searchOrderInfo[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, forceUpdate.forceUpdateCount]);

  return { error, orderInfo };
};

export default useDetailOrderInfoFetch;
