import { useEffect } from "react";

import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Divider from "../components/Divider/Divider";
import FormSet from "../components/FormSet/FormSet";
import StepBar from "../components/StepBar/StepBar";
import {
  BULLET_LIST_ITEMS,
  PATIENT_INVITATIONS_STATUS,
  REGISTER_STEP_ITEMS,
  UserStatusID,
} from "../constants/common";
import PatientFooter from "../features/PatientFooter";
import useUserRegisterInput, {
  checkUserRegisterInputError,
} from "../hooks/input/useUserRegisterInput";
import { NON_ACTIVE_PRESENT, PATIENT } from "../constants/pagePaths";
import {
  useCurrentLocationState,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import SpFormSet from "../components/FormSet/SpFormSet";
import SpButton from "../components/Button/SpButton";
import { redirectToInvalidAccessPage } from "../contexts/CustomErrorBoundary";
import useUsa006DBActions from "../hooks/pages/USA006/useUSA006DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { addHyphenToZipcode } from "../utils/utils";
import BulletList from "../components/List/BulletList";
import Collapse from "../components/Collapse/Collapse";
import TermsOfServiceText from "../components/Text/TermsOfServiceText";
import useUsa004DBActions from "../hooks/pages/USA004/useUsa004DBActions";

const USA006 = () => {
  const [
    {
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      gender,
      birthYear,
      birthMonth,
      birthDay,
      tel,
      zipcode,
      pref,
      city,
      town1,
      town2,
      enabledModality,
      // selectedNotice,
      email,
      campaignCode,
    },
  ] = useUserRegisterInput();
  const { result, error, updateUser } = useUsa006DBActions();
  const { result: userResult, error: userError } = useUsa004DBActions();
  // エラー処理
  useCheckErrorThrowError([error, userError]);
  const navigate = useNavigateWithUrl();

  const onNextButtonClicked = () => navigate(PATIENT.USA024);
  const onNextButtonUpdateClicked = () => {
    void (async () => {
      const isSuccess = await updateUser();
      if (!isSuccess) return;
      navigate(NON_ACTIVE_PRESENT.USA036);
      window.location.reload(); // ヘッダーを無理やり更新
    })();
  };

  const handleGoBack = () => navigate(PATIENT.USA004);

  // URLの直打ち対応
  const transitionURL = useCurrentLocationState();
  useEffect(() => {
    // ページアクセス不正の場合
    const isInvalidRedirect = ![PATIENT.USA004, PATIENT.USA024].includes(
      transitionURL.from as PATIENT,
    );
    // 入力項目が不正の場合
    const isInvalidInput = checkUserRegisterInputError();
    const shouldRedirect = isInvalidRedirect || isInvalidInput;
    if (shouldRedirect) redirectToInvalidAccessPage();
  }, [transitionURL]);

  return (
    <div className="admin-area admin-area_no-header">
      <div className="admin-inner area-with-header-footer">
        <HeaderUser withRightControl={false} />
        {!result && <StepBar currentStepId="1" steps={REGISTER_STEP_ITEMS} />}
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header="お客様情報の確認"
          withButton={false}
          borderBottom="primary"
        />
        <div className="util-overflow-x">
          <Sheet padding="40px 0">
            <LayoutBox gap="3x" direction="column" fullWidth>
              <LayoutBox
                direction="column"
                justify="center"
                align="center"
                gap="2x"
                fullWidth
                minWidth="1064px"
              >
                <Sheet
                  type="card"
                  padding="16px 32px 32px 32px"
                  maxWidth="1064px"
                  width="100%"
                >
                  <LayoutBox direction="column" gap="3x" fullWidth>
                    <LayoutBox direction="column" fullWidth>
                      <Text size="2xl" bold>
                        会員情報
                      </Text>
                      <Divider borderWidth="3px" margin="0" />
                    </LayoutBox>
                    <LayoutBox direction="column" gap="3x" fullWidth>
                      <SpFormSet
                        label="お名前"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          className="util-sp-full-width"
                        >
                          <LayoutBox gap="1x" fullWidth justify="between">
                            <FormSet label="姓" vertical width="196px">
                              <LayoutBox
                                direction="column"
                                gap="1/2x"
                                fullWidth
                              >
                                <Text size="xl">{nameSei.value}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                            <FormSet label="名" vertical width="196px">
                              <LayoutBox
                                direction="column"
                                gap="1/2x"
                                fullWidth
                              >
                                <Text size="xl">{nameMei.value}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                          </LayoutBox>
                          <LayoutBox gap="1x" fullWidth justify="between">
                            <FormSet label="セイ" vertical width="196px">
                              <LayoutBox
                                direction="column"
                                gap="1/2x"
                                fullWidth
                              >
                                <Text size="xl">{nameSeiKana.value}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                            <FormSet label="メイ" vertical width="196px">
                              <LayoutBox
                                direction="column"
                                gap="1/2x"
                                fullWidth
                              >
                                <Text size="xl">{nameMeiKana.value}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                          </LayoutBox>
                        </LayoutBox>
                      </SpFormSet>
                      <SpFormSet
                        label="性別"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          gap="1/2x"
                          minWidth="400px"
                          className="util-sp-full-width"
                          justify="end"
                          fullHeight
                        >
                          <Text size="xl">
                            {gender.value === "1" ? "男性" : "女性"}
                          </Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                      {userResult?.status !== UserStatusID.CANCELLATION && (
                        <SpFormSet
                          label="生年月日"
                          base
                          labelSize="medium"
                          labelWidth="200px"
                          spToColumnAndFullWidth
                          labelGap="large"
                        >
                          <LayoutBox
                            direction="column"
                            gap="1/2x"
                            minWidth="400px"
                            className="util-sp-full-width"
                            justify="end"
                            fullHeight
                          >
                            <Text size="xl">{`${birthYear.value}年${birthMonth.value}月${birthDay.value}日`}</Text>
                            <Divider margin="0" darkColor />
                          </LayoutBox>
                        </SpFormSet>
                      )}
                      <SpFormSet
                        label="電話番号"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          gap="1/2x"
                          minWidth="400px"
                          className="util-sp-full-width"
                          justify="end"
                          fullHeight
                        >
                          <Text size="xl">{tel.value}</Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                      <SpFormSet
                        label="住所"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          gap="1/2x"
                          minWidth="400px"
                          maxWidth="774px"
                          className="util-sp-full-width"
                          justify="end"
                          fullHeight
                        >
                          <Text size="xl" width="100%">
                            {addHyphenToZipcode(zipcode.value)} <br />
                            {pref.value}
                            {city.value}
                            {town1.value}
                            {town2}
                          </Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                      {userResult?.status !== UserStatusID.CANCELLATION &&
                        !result && (
                          <SpFormSet
                            label="メールアドレス"
                            base
                            labelSize="medium"
                            labelWidth="200px"
                            spToColumnAndFullWidth
                            labelGap="large"
                          >
                            <LayoutBox
                              direction="column"
                              gap="1/2x"
                              minWidth="400px"
                              maxWidth="774px"
                              className="util-sp-full-width"
                              justify="end"
                              fullHeight
                            >
                              <Text size="xl" width="100%">
                                {email.value}
                              </Text>
                              <Divider margin="0" darkColor />
                            </LayoutBox>
                          </SpFormSet>
                        )}
                    </LayoutBox>
                  </LayoutBox>
                </Sheet>
                <Sheet
                  type="card"
                  padding="16px 32px 32px 32px"
                  maxWidth="1064px"
                  width="100%"
                >
                  <LayoutBox direction="column" gap="3x" fullWidth>
                    <LayoutBox direction="column" fullWidth>
                      <Text size="2xl" bold>
                        過去の診断について
                      </Text>
                      <Divider borderWidth="3px" margin="0" />
                    </LayoutBox>
                    <LayoutBox direction="column" gap="2x" fullWidth>
                      <Text size="xl" color="neutralUserBlack">
                        認知症 もしくはMCI（軽度認知障害）
                        と診断されたことがない
                      </Text>
                      <Collapse label="内容" open width="100%">
                        <Text size="xl">
                          本サービスは、認知症 もしくはMCI（軽度認知障害）
                          と診断されたことがない方を対象とします。
                          これまでに該当する診断をされていないことを確認してください。
                        </Text>
                      </Collapse>
                    </LayoutBox>
                  </LayoutBox>
                </Sheet>
                <Sheet
                  type="card"
                  padding="16px 32px 32px 32px"
                  maxWidth="1064px"
                  width="100%"
                >
                  <LayoutBox direction="column" gap="3x" fullWidth>
                    <LayoutBox direction="column" fullWidth>
                      <Text size="2xl" bold>
                        MRI検査の禁忌事項について
                      </Text>
                      <Divider borderWidth="3px" margin="0" />
                    </LayoutBox>
                    <LayoutBox direction="column" gap="2x" fullWidth>
                      <Text size="xl">
                        {enabledModality[0].checked
                          ? "当てはまらない"
                          : "当てはまる"}
                      </Text>
                      <Collapse label="内容" open width="100%">
                        <LayoutBox direction="column">
                          <Text size="xl" color="error">
                            本サービスにはMRI検査が含まれるため、MRI検査を受検できない方は会員登録することがきません。
                          </Text>
                          <Text size="xl" color="error">
                            以下に当てはまる項目がないことをご確認ください。
                          </Text>
                        </LayoutBox>
                        <Sheet
                          type="border-red"
                          className="util-mt-16"
                          borderRadius="8px"
                          padding="16px"
                        >
                          <BulletList
                            title="体内に下記の医療機器がある方"
                            items={BULLET_LIST_ITEMS}
                            color="red"
                          />
                        </Sheet>
                      </Collapse>
                    </LayoutBox>
                  </LayoutBox>
                </Sheet>
                {/* FIXME: 仕様未決定のため、一旦非表示 */}
                {/* <Sheet
                  type="card"
                  padding="16px 32px 32px 32px"
                  maxWidth="1064px"
                  width="100%"
                >
                  <LayoutBox direction="column" gap="3x" fullWidth>
                    <LayoutBox direction="column" fullWidth>
                      <Text size="2xl" bold>
                        お知らせ
                      </Text>
                      <Divider borderWidth="3px" margin="0" />
                    </LayoutBox>
                    <Text size="xl">
                      {selectedNotice.value === "1" ? "希望する" : "希望しない"}
                    </Text>
                  </LayoutBox>
                </Sheet> */}
                {!result && (
                  <Sheet type="card" padding="16px 16px 32px" maxWidth="1064px">
                    <LayoutBox direction="column" fullWidth gap="3x">
                      <LayoutBox direction="column" fullWidth>
                        <Text size="2xl" bold>
                          お支払い方法
                        </Text>
                        <Divider borderWidth="3px" margin="0" />
                      </LayoutBox>
                      <LayoutBox direction="column" fullWidth>
                        <SpFormSet
                          label="キャンペーンコード"
                          base
                          labelSize="medium"
                          labelWidth="200px"
                          spToColumnAndFullWidth
                          labelGap="large"
                        >
                          <Text size="xl" width="100%">
                            {campaignCode.value ? campaignCode.value : "なし"}
                          </Text>
                          <Divider margin="0" darkColor />
                        </SpFormSet>
                      </LayoutBox>
                    </LayoutBox>
                  </Sheet>
                )}
                {result?.status !== UserStatusID.INVITATION ? (
                  <TermsOfServiceText />
                ) : null}
              </LayoutBox>
              <LayoutBox
                justify="between"
                gap="2x"
                className="util-sp-full-width util-mx-auto"
                wrapReverse
                width="1064px"
              >
                <SpButton
                  type="secondary"
                  width="400px"
                  size="medium"
                  withArrowLeft
                  mobileFullWidth
                  onClick={handleGoBack}
                >
                  戻る
                </SpButton>
                {result?.status === PATIENT_INVITATIONS_STATUS.notRegistered ||
                result?.status ===
                  PATIENT_INVITATIONS_STATUS.currentlyApplying ? (
                  <SpButton
                    type="primary"
                    width="400px"
                    size="medium"
                    withArrowRight
                    mobileFullWidth
                    onClick={onNextButtonUpdateClicked}
                  >
                    会員登録
                  </SpButton>
                ) : (
                  <SpButton
                    type="primary"
                    width="400px"
                    size="medium"
                    withArrowRight
                    mobileFullWidth
                    onClick={onNextButtonClicked}
                  >
                    次へ(お支払いコース選択)
                  </SpButton>
                )}
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </div>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USA006;
