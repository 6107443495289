import Spinner from "../components/Loading/Spinner";
import { useLoadingAction } from "../hooks/base/useLoadingAction";
import { useLoadingPage } from "../hooks/base/useLoadingPage";
import NLJ002 from "../pages/NL-J002";

const PageCommon = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [{ isLoadingPage }] = useLoadingPage();
  const [{ text, isLoadingAction }] = useLoadingAction();

  return (
    <>
      {/* ページ全体のスピナー */}
      {isLoadingPage && <Spinner color="white" size="large" isLoad />}
      {/* DBアクション中のスピナー */}
      {isLoadingAction && (
        <Spinner color="white" isActionLoad actionText={text} />
      )}
      {children}
      <NLJ002 />
    </>
  );
};

export default PageCommon;
