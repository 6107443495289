import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsf001, getMongoDb } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";

const useUsf001Actions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [result, setResult] = useState<USF001Type>();
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  // Patient取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(currentUser, Collection.PATIENTS);
        const usf001Aggregate = getAggregateUsf001(currentUser?.id ?? "");
        const fetchResult = (await mongoDbPatients.aggregate(
          usf001Aggregate,
        )) as USF001Type[];
        setResult(fetchResult[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount]);

  return {
    result,
    error,
  };
};

export default useUsf001Actions;
