import React from "react";
import SpPagination from "../Pagination/SpPagination";
import LayoutBox from "../LayoutBox/LayoutBox";
import Text from "../Text/Text";
import { mobileView } from "../../constants/common";
// changeEmp
const DAYS_TEXT = ["日", "月", "火", "水", "木", "金", "土"];

export type CalendarDayProps = {
  daysNumber: number;
  // changeEmp
  onChange: (date: Date) => void;
  // changeEmp
  initialCurrentDate?: Date;
};

const CalendarDay = ({
  daysNumber = 7,
  onChange,
  // changeEmp
  initialCurrentDate,
}: CalendarDayProps) => {
  const [selectedDate, setSelectedDate] = React.useState<number | null>(
    initialCurrentDate?.getDate() ?? new Date().getDate(),
  );
  const [currentDate, setCurrentDate] = React.useState(
    initialCurrentDate ?? new Date(),
  );
  const [isMobile, setIsMobile] = React.useState(false);
  const [daysNum, setDaysNum] = React.useState(daysNumber);

  const today = initialCurrentDate ?? new Date();
  const currentDayOfWeek = today.getDay();
  const daysToMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;

  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - daysToMonday);

  const daysInWeek = Array.from({ length: daysNum }, (_, index) => {
    const day = new Date(startOfWeek);
    day.setDate(startOfWeek.getDate() + index);

    return day;
  });

  const firstDayOfCurrentWeek = new Date(startOfWeek);
  const lastDayOfCurrentWeek = new Date(startOfWeek);
  lastDayOfCurrentWeek.setDate(startOfWeek.getDate() + 6);

  const isCurrentWeek =
    today >= firstDayOfCurrentWeek && today <= lastDayOfCurrentWeek;

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileView);
      setDaysNum(Math.max(1, isMobile ? daysNumber - 2 : daysNumber));
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile, daysNumber]);

  const goToNext = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + daysNum);
    setCurrentDate(newDate);
    setSelectedDate(null);
  };

  const goToPrevious = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - daysNum);
    setCurrentDate(newDate);
    setSelectedDate(null);
  };

  const handleChange = React.useCallback(
    (date: Date) => {
      setSelectedDate(date.getDate());
      // changeEmp
      onChange(date);
    },
    [onChange],
  );

  return (
    <LayoutBox direction="column" gap="1x" fullWidth>
      <div className="calendar-day">
        {daysInWeek.map((day, index) => {
          // changeEmp
          if (initialCurrentDate && initialCurrentDate > day) {
            return <div className="calendar-day__holder" key={day.getDate()} />;
          }

          return (
            <div
              className="calendar-day__holder"
              key={
                day.getDate() //  changeEmp
              }
            >
              <div className="calendar-day__month">
                {
                  index === 0
                    ? `${day.getMonth() + 1}月`
                    : day.getDate() === 1
                    ? `${day.getMonth() + 1}月`
                    : `${initialCurrentDate?.getMonth() ?? ""}-${
                        initialCurrentDate?.getDate() ?? ""
                      }` === `${day?.getMonth()}-${day?.getDate()}`
                    ? `${day.getMonth() + 1}月`
                    : "" // changeEmp
                }
              </div>
              <label
                key={index}
                className={`calendar-day__container ${
                  selectedDate === day.getDate() ? "calendar-day--selected" : ""
                }`}
              >
                <div
                  className={`calendar-day__item calendar-day__item${
                    day.getDay() === 6
                      ? "--saturday"
                      : day.getDay() === 0
                      ? "--sunday"
                      : ""
                  }`}
                >
                  <input
                    type="radio"
                    className="calendar-day__input"
                    value={day.getDate()}
                    name="calendarDay"
                    onClick={() => handleChange(day)}
                  />
                  <Text size="2xl" bold>
                    {day.getDate()}
                  </Text>
                  <Text size="large" bold>
                    {DAYS_TEXT[day.getDay()]}
                  </Text>
                </div>
              </label>
            </div>
          );
        })}
      </div>
      <SpPagination
        justify="between"
        width="148px"
        leftButtonType="secondary"
        leftButtonText={`前の${daysNum}日`}
        leftButtonDisabled={isCurrentWeek}
        onClickLeft={goToPrevious}
        rightButtonType="secondary"
        rightButtonText={`次の${daysNum}日`}
        onClickRight={goToNext}
      />
    </LayoutBox>
  );
};

CalendarDay.defaultProps = {
  initialCurrentDate: undefined,
};

export default CalendarDay;
