import handleException from "../../contexts/errors";
import commonValidation, {
  VALIDATION_TYPE,
} from "../../utils/commonValidation";
import createReactiveVar from "./createReactiveVar";
import {
  type ReactiveVarHooks,
  useReactiveVarHooks,
} from "./useReactiveVarHooks";

type DefaultStateType = LoginStateType;
type DefaultRequiredInputType = LoginRequiredInputType;
type DefaultErrorType = LoginErrorType;

// 必須チェック一覧
const requiredItemsList: Array<keyof DefaultRequiredInputType> = [
  "id",
  "password",
];
// エラーチェック一覧
const errorItemsList: Array<keyof DefaultErrorType> = [
  "isErrorId",
  "isErrorPassword",
];

// デフォルト値
const defaultState: DefaultStateType = {
  id: "",
  password: "",
  isErrorId: false,
  isErrorPassword: false,
  idValidationMessage: "",
  passwordValidationMessage: "",
  isLoginFailed: false,
  loginFailedMessage: "",
};
// ログインに必要な入力値、エラー状態を管理するフック
const stateReactiveVar = createReactiveVar(defaultState);
const useLogInInputObj = (): ReactiveVarHooks<DefaultStateType> =>
  useReactiveVarHooks(stateReactiveVar);

// IDの入力チェックとセット
export const setId = (
  id: DefaultStateType["id"],
  state: DefaultStateType,
): void => {
  const { isError: isErrorId, validationMessage: idValidationMessage } =
    commonValidation(id, VALIDATION_TYPE.ID_REQUIRED);
  stateReactiveVar({
    ...state,
    id,
    isErrorId,
    idValidationMessage,
  });
};

// パスワードの入力チェックとセット
export const setPassword = (
  password: DefaultStateType["password"],
  state: DefaultStateType,
): void => {
  const {
    isError: isErrorPassword,
    validationMessage: passwordValidationMessage,
  } = commonValidation(password, VALIDATION_TYPE.PASSWORD_REQUIRED);
  stateReactiveVar({
    ...state,
    password,
    isErrorPassword,
    passwordValidationMessage,
  });
};

// 必須入力に未入力がある場合true
export const getIsEmpty = (state: DefaultStateType): boolean =>
  requiredItemsList.some((stateKey) => !state[stateKey]);

// 入力にエラーがある場合true
export const getIsError = (state: DefaultStateType): boolean =>
  errorItemsList.some((stateKey) => Boolean(state[stateKey]));

// ログイン失敗時の処理
export const handleLoginFailure = (state: DefaultStateType, e: Error): void => {
  const loginFailedMessage = handleException(e);
  stateReactiveVar({
    ...state,
    id: "",
    password: "",
    isErrorId: true,
    isErrorPassword: true,
    isLoginFailed: true,
    loginFailedMessage,
  });
};

// 初期化
export const setInitLoginInput = () => {
  stateReactiveVar(defaultState);
};

export default useLogInInputObj;
