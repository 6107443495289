import React from "react";
import Icon from "../Icon/Icon";
import LayoutBox from "../LayoutBox/LayoutBox";
import Badge from "../Badge/Badge";

export type SpButtonProps = {
  type?:
    | "primary"
    | "secondary"
    | "white"
    | "danger"
    | "shop"
    | "blue"
    | "iconOnly";
  icon?: string;
  size?: "small" | "medium" | "large" | "2xs" | "xs" | "xl" | "2xl" | undefined;
  iconSize?:
    | "small"
    | "medium"
    | "large"
    | "2xs"
    | "xs"
    | "xl"
    | "2xl"
    | undefined;
  withArrowLeft?: boolean;
  withArrowRight?: boolean;
  disabled?: boolean;
  width?: string;
  className?: string;
  onClick?: (value?: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  badge?: number;
  mobileFullWidth?: boolean;
  buttonType?: "button" | "reset" | "submit";
  fontSize?: "small" | "medium" | "large" | undefined;
};

const SpButton = ({
  type = "primary",
  size = "small",
  iconSize = "small",
  disabled = false,
  width = "",
  className = "",
  onClick,
  children,
  withArrowLeft = false,
  withArrowRight = false,
  badge = 0,
  icon = "",
  mobileFullWidth = false,
  buttonType = "button",
  fontSize = "large",
}: SpButtonProps) => {
  const buttonClass = React.useMemo(() => {
    const typeClass = [`sp-btn--${type}`];
    const sizeClass = [`sp-btn--${size}`];
    const iconOnlyClass = !children ? [`sp-btn--icon-only`] : [];
    const mobileClass = mobileFullWidth ? [`sp-btn--mobile-fullwidth`] : [];
    const withArrowClass = withArrowLeft
      ? [`sp-btn--arrow-left`]
      : withArrowRight
      ? [`sp-btn--arrow-right`]
      : [];

    return [
      "sp-btn",
      ...typeClass,
      ...sizeClass,
      ...iconOnlyClass,
      ...mobileClass,
      ...withArrowClass,
    ].join(" ");
  }, [children, type, size, mobileFullWidth, withArrowLeft, withArrowRight]);

  const labelClass = React.useMemo(() => {
    const sizeClass = [`sp-btn__content--${fontSize}`];

    return ["sp-btn__content", ...sizeClass].join(" ");
  }, [fontSize]);

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={buttonType}
      className={`${buttonClass} ${className}`}
      disabled={disabled}
      style={{ width }}
      onClick={onClick}
    >
      <div className="sp-btn__inner">
        <>
          <LayoutBox
            width="100%"
            justify="center"
            align="center"
            flexShrink={0}
            gap="1/2x"
          >
            {withArrowLeft && (
              <span className="sp-btn__arrow sp-btn__arrow--left">
                <Icon icon="keyboard_arrow_left" size="small" />
              </span>
            )}
            {(icon !== "" || type === "shop") && (
              <Icon
                icon={type === "shop" ? "shopping_cart" : icon}
                size={iconSize}
              />
            )}

            {children && <span className={labelClass}>{children}</span>}
            {badge > 0 && <Badge size="large" color="red" counter={badge} />}
            {withArrowRight && (
              <span className="sp-btn__arrow sp-btn__arrow--right">
                <Icon icon="keyboard_arrow_right" size="small" />
              </span>
            )}
            {withArrowRight && type === "blue" && (
              <div className="sp-btn__arrow sp-btn__arrow-blue sp-btn__arrow--right">
                <Icon icon="keyboard_arrow_right" size="small" />
              </div>
            )}
          </LayoutBox>
        </>
      </div>
    </button>
  );
};

SpButton.defaultProps = {
  type: "primary",
  size: "small",
  iconSize: "small",
  disabled: false,
  width: "",
  className: "",
  onClick: () => {},
  withArrowLeft: false,
  withArrowRight: false,
  badge: 0,
  icon: "",
  mobileFullWidth: false,
  buttonType: "button",
  children: undefined,
  fontSize: undefined,
};

export default SpButton;
