import { useCallback } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import PatientFooter from "../features/PatientFooter";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import ConsultationDialCard from "../features/ConsultationDialCard";
import ChatCard from "../features/ChatCard";
import FreeInterviewCard from "../features/FreeInterviewCard";
import PaidInterviewCard from "../features/PaidInterviewCard";

const USD004 = () => {
  const navigate = useNavigateWithUrl();

  const handleClickBack = useCallback(
    () => navigate(PATIENT.USB001),
    [navigate],
  );

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation selectedTab={SELECTED_TAB_NUM.healthAdvice} />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="健康相談"
          content="認知症に関する各種相談メニューをご用意しています"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px 16px">
          <LayoutBox width="1064px" direction="column" gap="3x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <LayoutBox gap="1x" direction="column" fullWidth>
                {/* オンライン面談 無料 */}
                <FreeInterviewCard />
                {/* オンライン面談 有料 */}
                <PaidInterviewCard />
                {/* チャット */}
                <ChatCard />
                {/* 認知症相談ダイヤル24 */}
                <ConsultationDialCard />
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USD004;
