import createReactiveVar from "../base/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../base/useReactiveVarHooks";

type ForceUpdateType = {
  forceUpdateCount: number;
};

const stateReactiveVar = createReactiveVar<ForceUpdateType>({
  forceUpdateCount: 0,
});

const useForceUpdate = (): ReactiveVarHooks<ForceUpdateType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useForceUpdate;
