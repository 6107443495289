import React from "react";
import {
  ApolloClient,
  HttpLink,
  from,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { useRealmApp } from "./RealmApp";
import { redirectToInvalidFunctionPage } from "./CustomErrorBoundary";

const createRealmApolloClient = (appContext: RealmAppContextInterface) => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_MONGO_DB_URI,
    fetch: async (uri, options) => {
      if (!appContext.currentUser) {
        return redirectToInvalidFunctionPage();
      }
      await appContext.currentUser.refreshCustomData();
      const headers = options?.headers
        ? new Headers(options.headers)
        : new Headers();
      if (options && !headers.has("Authorization")) {
        headers.set(
          "Authorization",
          `Bearer ${appContext.currentUser.accessToken ?? ""}`,
        );
      }

      return fetch(uri, { ...options, headers });
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        // eslint-disable-next-line no-console
        console.log(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-console
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });
  const cache = new InMemoryCache();
  const connectToDevTools = true;

  return new ApolloClient({
    link: from([errorLink, httpLink]),
    cache,
    connectToDevTools,
  });
};

const RealmApolloProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const appContext = useRealmApp();
  const [client, setClient] = React.useState(
    createRealmApolloClient(appContext),
  );
  const clientRef = React.useRef(client);

  React.useEffect(() => {
    setClient(createRealmApolloClient(appContext));
    const currentClient = clientRef.current;

    return () => {
      void currentClient.clearStore();
    };
  }, [appContext, clientRef]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default RealmApolloProvider;
