import { useCallback, useEffect, useState } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import FormSet from "../components/FormSet/FormSet";
import Dropdown from "../components/Dropdown/Dropdown";
import Card from "../components/Card/Card";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { PATIENT } from "../constants/pagePaths";
import useUsc001Actions from "../hooks/pages/USC001/useUsc001DBActions";
import { PatientNouKnowStatusID, USC_TAB_ITEMS } from "../constants/common";
import { useCheckErrorThrowError } from "../utils/checkError";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import useUsc001Conditions, {
  setSelectedYear,
} from "../hooks/pages/USC001/useUsc001Conditions";
import PatientFooter from "../features/PatientFooter";
import usePatientNouknowsFetch from "../hooks/common/usePatientNouknowsFetch";
import {
  setNouknowId,
  setSelectedDate,
} from "../hooks/pages/USC004/useUsc004Conditions";
import { useRealmApp } from "../contexts/RealmApp";
import { isActiveUser } from "../utils/utils";

const USC001 = () => {
  const realmAppContext = useRealmApp();
  const [{ selectedYear }] = useUsc001Conditions();
  const { result, error, finishedYears } = useUsc001Actions(selectedYear);
  const [nouKnowUrl, setNouKnowUrl] = useState("");
  const [previousNouknowFinishedAt, setPreviousNouknowFinishedAt] =
    useState("");
  const navigate = useNavigateWithUrl();

  const { patientNouknows, error: nouknowError } = usePatientNouknowsFetch();

  // エラー処理
  useCheckErrorThrowError([error, nouknowError]);

  const isActive = isActiveUser(realmAppContext.currentUser);

  // のうKNOWテスト
  useEffect(() => {
    if (!patientNouknows.length) {
      return;
    }

    const validDates = patientNouknows.filter((p) => p.finished_at_date);
    if (validDates.length > 0) {
      // 前回受検日取得
      const mostRecentPatient = patientNouknows.reduce(
        (mostRecent, current) => {
          const mostRecentDate = mostRecent.finished_at_date
            ? new Date(mostRecent.finished_at_date)
            : new Date(0);
          const currentDate = current.finished_at_date
            ? new Date(current.finished_at_date)
            : new Date(0);

          return mostRecentDate > currentDate ? mostRecent : current;
        },
      );
      setPreviousNouknowFinishedAt(mostRecentPatient.finished_at);
    }

    // 期間内でテスト未実施のデータが対象
    const enabledPatientNouknows = patientNouknows.filter((item) => {
      const now = new Date();

      return (
        item.status === PatientNouKnowStatusID.PRETEST &&
        now >= item.exam_start &&
        now <= item.exam_end
      );
    });

    if (enabledPatientNouknows.length === 0) {
      return;
    }

    setNouKnowUrl(enabledPatientNouknows[0].nouknow_url);
  }, [patientNouknows]);

  // セレクトボックス 受検した年
  const handleChangeYear = (value: string) => {
    setSelectedYear(value);
  };

  // タブの切り替え
  const handleTabChange = (index: string | number) => {
    const selectedTab = typeof index === "string" ? parseInt(index, 10) : index;
    switch (selectedTab) {
      case 1:
        navigate(PATIENT.USC001);
        break;
      case 2:
        navigate(PATIENT.USC002);
        break;
      case 3:
        navigate(PATIENT.USC003);
        break;
      case 4:
        navigate(PATIENT.USK002);
        break;
      default:
        break;
    }
  };

  // テスト結果画面に遷移
  const handleNavigateResult = (id: string, finishedAt: string) => {
    setNouknowId(id);
    setSelectedDate(finishedAt);
    navigate(PATIENT.USC004);
  };

  // Top画面に遷移
  const handleClickBack = useCallback(
    () => navigate(PATIENT.USB001),
    [navigate],
  );

  // テスト開始画面に遷移
  const handleNavigateTestStart = () => {
    window.open(nouKnowUrl, "_blank");
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
          isActiveUser={isActive}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="テスト・結果"
          content="認知機能テストや各種検査の結果を確認できます"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px">
          <LayoutBox width="1064px" direction="column" gap="5x">
            <LayoutBox direction="column" gap="3x" fullWidth>
              <Title
                type="h2"
                header="テスト"
                subheader={
                  previousNouknowFinishedAt
                    ? `前回受検日: ${previousNouknowFinishedAt}`
                    : ""
                }
                borderBottom="primaryLight"
                withButton={false}
              />
              <Card
                type="button"
                buttonItem={{
                  label: "テスト開始",
                  width: "311px",
                  disabled: nouKnowUrl === "" || !isActive,
                }}
                header={
                  <Text bold size="xl" color="neutralUserBlack">
                    のうKNOW
                  </Text>
                }
                content={
                  <Text size="large" color="neutralUserLight">
                    あなたの海馬状態を把握するためのテストです。
                  </Text>
                }
                onButtonItemClick={handleNavigateTestStart}
              />
            </LayoutBox>
            <LayoutBox direction="column" gap="3x" fullWidth>
              <Title
                type="h2"
                header="結果"
                borderBottom="primaryLight"
                withButton={false}
              />
              <div className="util-flex util-flex-col util-sp-flex--column util-flex--gap8 util-full-width">
                <ToggleButton
                  selectedButton="1"
                  fullWidth
                  size="large"
                  width="100%"
                  items={USC_TAB_ITEMS.slice(0, 2)}
                  onClick={handleTabChange}
                />
                <ToggleButton
                  selectedButton="1"
                  fullWidth
                  size="large"
                  width="100%"
                  items={USC_TAB_ITEMS.slice(2, 4)}
                  onClick={handleTabChange}
                />
              </div>
              <FormSet label="受検した年で絞り込む" vertical>
                <Dropdown
                  items={finishedYears}
                  width="195px"
                  value={selectedYear}
                  onChange={handleChangeYear}
                />
              </FormSet>
              <LayoutBox direction="column" gap="1x" fullWidth>
                {result[0] ? (
                  result.map((data, index) => (
                    <Card
                      status={
                        data.status === PatientNouKnowStatusID.COMPLETED
                          ? "pending"
                          : "description"
                      }
                      clickable={[
                        PatientNouKnowStatusID.UPLOADED,
                        PatientNouKnowStatusID.CONFIRMED,
                        PatientNouKnowStatusID.COMMENTED,
                      ].includes(data.status)}
                      onClick={() =>
                        handleNavigateResult(data._id, data.finished_at)
                      }
                      key={index}
                      header={
                        data.finished_at ? (
                          <Text size="large" color="neutralUserLight">
                            受検日：{data.finished_at}
                          </Text>
                        ) : (
                          <></>
                        )
                      }
                      content={
                        <Text size="xl" bold>
                          のうKNOW
                        </Text>
                      }
                    />
                  ))
                ) : (
                  <LayoutBox justify="center" align="center" fullWidth>
                    <Text size="large" color="neutralLight">
                      表示する結果がありません
                    </Text>
                  </LayoutBox>
                )}
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC001;
