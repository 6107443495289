import { INIT_PAGE, INIT_PER_PAGE } from "../../../constants/common";
import createReactiveVar from "../../base/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../base/useReactiveVarHooks";

type PaginationType = {
  page: number;
  perPage: number;
  skip: number;
  total: number;
};

// paginationコンポーネントで管理する値
const stateReactiveVar = createReactiveVar<PaginationType>({
  page: INIT_PAGE, // 現在ページ
  perPage: INIT_PER_PAGE, // 1ページあたりの表示数
  skip: 0, // DB検索時のスタート位置(skip)
  total: 0, // 全データ数
});
const usePagination = (): ReactiveVarHooks<PaginationType> =>
  useReactiveVarHooks(stateReactiveVar);

// DB検索時のスタート位置(skip)を計算
const _calcSkip = (
  perPage: PaginationType["perPage"],
  page: PaginationType["page"],
) => perPage * (page - 1);

// ページ数をセット
export const setPage = (page: PaginationType["page"]): void => {
  const currentState = stateReactiveVar();
  if (page !== currentState.page) {
    const skip = _calcSkip(currentState.perPage, page);
    stateReactiveVar({ ...currentState, page, skip });
  }
};

// 前ページへ
export const prevPage = () => {
  const currentState = stateReactiveVar();
  const prevPage = currentState.page - 1;
  const skip = _calcSkip(currentState.perPage, prevPage);
  stateReactiveVar({ ...currentState, skip, page: prevPage });
};

// 次ページへ
export const nextPage = () => {
  const currentState = stateReactiveVar();
  const nextPage = currentState.page + 1;
  const skip = _calcSkip(currentState.perPage, nextPage);
  stateReactiveVar({ ...currentState, skip, page: nextPage });
};

// 1ページあたりの表示数をセット
export const setPerPage = (perPage: PaginationType["perPage"]): void => {
  const currentState = stateReactiveVar();
  if (perPage !== currentState.perPage) {
    // 1ページ目に設定
    const page = INIT_PAGE;
    const skip = _calcSkip(perPage, page);
    stateReactiveVar({ ...currentState, page, perPage, skip });
  }
};

// 全データ数をセット
export const setTotal = (total: PaginationType["total"]): void => {
  const currentState = stateReactiveVar();
  if (total !== currentState.total) {
    stateReactiveVar({
      ...currentState,
      total,
    });

    // 削除処理などで、現在ページ > 最大ページになった場合
    const maxPage =
      total === 0 ? INIT_PAGE : Math.ceil(total / currentState.perPage);
    if (currentState.page > maxPage) setPage(maxPage);
  }
};

export default usePagination;
