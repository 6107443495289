import { useNavigate } from "react-router-dom";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Icon from "../components/Icon/Icon";
import { PATIENT } from "../constants/pagePaths";
import SpButton from "../components/Button/SpButton";
import HeaderUser from "../components/Header/HeaderUser";
import PatientFooter from "../features/PatientFooter";

/* 権限エラー画面 */
const USG010 = () => {
  const navigate = useNavigate();

  // ログイン画面に遷移
  const handleNavigateLogin = () => {
    navigate(PATIENT.USA001);
  };

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser withRightControl={false} />
        <div className="admin-inner">
          <LayoutBox
            fullWidth
            justify="center"
            align="center"
            direction="column"
          >
            <Title
              padding="16px 108px 24px 108px"
              background="default"
              header=""
              borderBottom="primary"
              withButton={false}
            />
            <Sheet className="util-py-40 util-sp-py-24">
              <LayoutBox direction="column" maxWidth="440px" width="440px">
                <Sheet
                  type="card"
                  padding="24px 16px 32px 16px"
                  className="util-sp-pt-16"
                >
                  <LayoutBox
                    gap="3x"
                    direction="column"
                    align="center"
                    fullWidth
                  >
                    <LayoutBox direction="column" gap="2x" align="center">
                      <Icon icon="error" size="large" color="#A21B11" />
                      <Text
                        bold
                        size="2xl"
                        color="neutralUserBlack"
                        lineHeight="150%"
                        textAlign="center"
                      >
                        ログインに失敗しました
                      </Text>
                      <Text
                        size="xl"
                        color="neutralUserBlack"
                        lineHeight="150%"
                        textAlign="center"
                      >
                        ログイン権限がありません。
                      </Text>
                    </LayoutBox>
                    <SpButton
                      type="primary"
                      width="100%"
                      size="medium"
                      onClick={handleNavigateLogin}
                    >
                      ログイン
                    </SpButton>
                  </LayoutBox>
                </Sheet>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USG010;
