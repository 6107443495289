import SpButton from "../components/Button/SpButton";
import Divider from "../components/Divider/Divider";
import Icon from "../components/Icon/Icon";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import { INVITATION, PATIENT } from "../constants/pagePaths";
import useStripeActions from "../hooks/common/useStripeActions";
import { useCheckErrorThrowError } from "../utils/checkError";

type PaymentFailureCardProps = {
  stripeSubscriptionId: string | undefined;
  backUrl: PATIENT.USB001 | INVITATION.USA031;
};

const PaymentFailureCard: React.FC<PaymentFailureCardProps> = ({
  stripeSubscriptionId,
  backUrl,
}) => {
  const { fetchStripeCardUpdateLink, stripeError } = useStripeActions();

  // エラー処理
  useCheckErrorThrowError([stripeError]);

  const handleEditCreditCard = async () => {
    if (stripeSubscriptionId) {
      const stripeUrl = await fetchStripeCardUpdateLink(
        stripeSubscriptionId,
        backUrl,
      );
      if (stripeUrl) {
        window.location.href = stripeUrl;
      }
    }
  };

  return (
    <Sheet type="card" padding="24px 16px" className="util-sp-pt-16">
      <LayoutBox gap="3x" direction="column" align="center" fullWidth>
        <LayoutBox direction="column" gap="2x" align="center">
          <Icon color="#D6A000" icon="warning" size="xl" />
          <Text
            bold
            size="2xl"
            color="neutralUserBlack"
            lineHeight="150%"
            textAlign="center"
          >
            会費のカード決済が行えませんでした。
          </Text>
          <LayoutBox direction="column" align="start">
            <Text lineHeight="150%" textAlign="left" size="large">
              登録カードを変更いただくか、カード会社にお問い合わせください。
              決済できない状態が一定期間続いた場合は自動退会となります。
            </Text>
            <Divider white />
            <Text lineHeight="150%" textAlign="left" size="large">
              ※
              有効期限の更新等カード情報に変更があった場合は、改めてカード情報を登録してください。
            </Text>
            <Text lineHeight="150%" textAlign="left" size="large">
              ※ 今月分の会費はカード変更後即時決済されます。
            </Text>
          </LayoutBox>
        </LayoutBox>
        <SpButton
          type="primary"
          width="311px"
          size="medium"
          onClick={() => {
            void handleEditCreditCard();
          }}
        >
          カード情報を編集
        </SpButton>
      </LayoutBox>
    </Sheet>
  );
};
export default PaymentFailureCard;
