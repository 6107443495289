import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HeaderUser from "../components/Header/HeaderUser";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Title from "../components/Title/Title";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import PatientFooter from "../features/PatientFooter";
import { PATIENT } from "../constants/pagePaths";
import usePatientModalityFetchById from "../hooks/common/usePatientModalityFetchById";
import useUsc013Conditions from "../hooks/pages/USC013/useUsc013Conditions";
import { useCheckErrorThrowError } from "../utils/checkError";

const USC013 = () => {
  const navigate = useNavigate();
  const [{ selectPetId }] = useUsc013Conditions();
  const { patientModality, error } = usePatientModalityFetchById(selectPetId);

  // エラー処理
  useCheckErrorThrowError([error, error]);

  // テスト結果 PETタブ画面に遷移
  const handleClickBack = useCallback(
    () => navigate(PATIENT.USC026),
    [navigate],
  );

  const replaceNewLinesWithBr = (text: string | undefined) =>
    text?.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="結果"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet minWidth="1076px" padding="24px 16px">
          <LayoutBox width="1064px" direction="column" gap="3x" fullWidth>
            <Title
              type="h2"
              header="FDG-PET検査"
              borderBottom="primaryLight"
              withButton={false}
              subheader={`受検日: ${patientModality.fix_book_date_start}`}
            />
            <LayoutBox gap="1x" direction="column" fullWidth>
              <Sheet color="white" borderRadius="8px" type="card">
                <LayoutBox direction="column">
                  <Text size="xl">
                    {replaceNewLinesWithBr(patientModality.interpretation_note)}
                  </Text>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC013;
