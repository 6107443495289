import React from "react";

// changeEmp
export type BadgeProps = {
  counter?: number;
  size?: "small" | "medium" | "large";
  color?:
    | "primary"
    | "secondary"
    | "red"
    | "blue"
    | "information"
    | "disabled"
    | "warning";
  children?: React.ReactNode;
};

const Badge = ({
  counter,
  size = "medium",
  color = "primary",
  children,
}: BadgeProps) => {
  const badgeClass = React.useMemo(() => {
    const colorClass = [`badge--${color}`];
    const sizeClass = [`badge--${size}`];

    return ["badge", ...colorClass, ...sizeClass].join(" ");
  }, [color, size]);

  return (
    <span className={badgeClass}>
      {counter && counter > 99 ? "99+" : counter}
      {children}
    </span>
  );
};

Badge.defaultProps = {
  size: "medium",
  color: "primary",
  counter: undefined,
  children: undefined,
};

export default Badge;
