import React from "react";

const WavyBackground = () => (
  <svg width="0" height="0">
    <defs>
      <clipPath id="WavyBackgroundSp" clipPathUnits="objectBoundingBox">
        <path d="M-0.001,-0.017 C0.332,-0.017,0.665,-0.017,0.999,-0.017 C0.999,0.284,0.999,0.585,0.999,0.887 C0.922,1,0.845,1,0.769,0.8 C0.716,0.615,0.663,0.441,0.609,0.278 C0.563,0.155,0.517,0.166,0.471,0.313 C0.41,0.543,0.348,0.752,0.287,0.939 C0.233,1,0.179,0.98,0.127,0.8 C0.084,0.637,0.041,0.458,-0.001,0.261 C-0.001,0.168,-0.001,0.075,-0.001,-0.017" />
      </clipPath>
    </defs>
  </svg>
);

export default WavyBackground;
