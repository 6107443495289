import Card from "../components/Card/Card";
import Divider from "../components/Divider/Divider";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { MriStatusID, PetStatusID } from "../constants/common";
import { PATIENT } from "../constants/pagePaths";
import { useRealmApp } from "../contexts/RealmApp";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import usePatientMriFetch from "../hooks/common/usePatientMriFetch";
import usePatientPetFetch from "../hooks/common/usePatientPetFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  formatDate,
  getNextExaminationStartDate,
  getUserYearSinceActivation,
  endOfPeriodForPet,
} from "../utils/utils";

const PetCard = () => {
  const realmAppContext = useRealmApp();
  const navigate = useNavigateWithUrl();

  // 何年目のユーザか
  const userYearSinceActivation = getUserYearSinceActivation(
    realmAppContext.currentUser,
  );

  const { patientPet, error: petGetError } = usePatientPetFetch(
    userYearSinceActivation,
  );
  const { patientMri, error: mriGetError } = usePatientMriFetch(
    userYearSinceActivation,
  );

  // エラー処理
  useCheckErrorThrowError([petGetError, mriGetError]);

  // PET受検可能か
  const isPetPossible = () => {
    if (patientMri.length > 0) {
      const mriData = patientMri[0];
      if (mriData.mri_status === MriStatusID.USER_REPORTED) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // ユーザー報告レポート日付
        const userReportedAt = new Date(mriData.user_reported_at);
        userReportedAt.setHours(0, 0, 0, 0);

        const activationDateObject = realmAppContext.currentUser?.customData
          .activation_date as CustomDateType;
        const activationDate = new Date(
          Number(activationDateObject.$date.$numberLong),
        );
        activationDate.setHours(0, 0, 0, 0);

        // アクティベート日付にPET受検可能期間を加算
        activationDate.setDate(
          activationDate.getDate() +
            endOfPeriodForPet(realmAppContext.currentUser),
        );

        if (userReportedAt <= today && today <= activationDate) {
          return true;
        }
      }
    }

    return false;
  };

  // 次回のPET検査可能かどうか
  const isNextPetPossible = () => {
    if (patientPet.length > 0) {
      return false;
    }

    return true;
  };

  // MRI、PET次回開始日取得
  const nextExaminationStartDate = getNextExaminationStartDate(
    realmAppContext.currentUser,
    userYearSinceActivation,
  );

  const renderContent = () => {
    if (patientPet.length) {
      const petObj = patientPet[0];

      switch (petObj.pet_status) {
        case PetStatusID.RESERVED:
          return (
            <Text size="large" color="neutralUserLight">
              予約調整中...
            </Text>
          );
        case PetStatusID.RESERVATION_CONFIRMED:
        case PetStatusID.EXAMINED:
        case PetStatusID.DIAGNOSED:
          return (
            <>
              <Text size="large" color="neutralUserLight">
                {petObj.fix_book_date || ""}
              </Text>
              <Text size="large" color="neutralUserLight">
                {petObj.hospital_name}
              </Text>
            </>
          );
        case PetStatusID.NO_EXAMINATION:
          return (
            <>
              <Text size="large" color="neutralUserLight">
                {petObj.fix_book_date || ""} (受検なし)
              </Text>
              <Text size="large" color="neutralUserLight">
                {petObj.hospital_name}
              </Text>
            </>
          );
        case PetStatusID.USER_REPORTED:
          if (isNextPetPossible()) {
            return (
              <Text size="large" color="neutralUserLight">
                MRI検査のレポートを受け取られた方は、ご希望により別途料金で精密検査の受検が可能です。
              </Text>
            );
          }

          return (
            <>
              <Text size="large" color="neutralUserLight">
                FDG PET検査は年に1回受検できます。
              </Text>
              <Text size="large" color="neutralUserLight">
                次回:{formatDate(nextExaminationStartDate)}以降
              </Text>
            </>
          );

        default:
          return (
            <Text size="large" color="neutralUserLight">
              MRI検査のレポートを受け取られた方は、ご希望により別途料金で精密検査の受検が可能です。
            </Text>
          );
      }
    } else {
      return (
        <>
          <Text size="large" color="neutralUserLight">
            MRI検査のレポートを受け取られた方は、ご希望により別途料金で精密検査の受検が可能です。
          </Text>
        </>
      );
    }
  };

  const getOnClickHandler = () => {
    if (patientPet.length) {
      const petObj = patientPet[0];
      switch (petObj.pet_status) {
        case PetStatusID.RESERVED:
        case PetStatusID.RESERVATION_CONFIRMED:
        case PetStatusID.EXAMINED:
        case PetStatusID.DIAGNOSED:
        case PetStatusID.NO_EXAMINATION:
        case PetStatusID.USER_REPORTED:
          return () => navigate(PATIENT.USC025);
        default:
          return () => navigate(PATIENT.USC012);
      }
    } else {
      return () => navigate(PATIENT.USC025);
    }
  };

  return (
    <Card
      header={
        <>
          <Text bold size="xl">
            FDG-PET検査(別途料金)
          </Text>
          <Divider darkColor margin="4px" />
        </>
      }
      content={
        <LayoutBox direction="column" gap="1x">
          {renderContent()}
        </LayoutBox>
      }
      onClick={getOnClickHandler()}
      clickable={isPetPossible()}
      disabled={!isPetPossible()}
      minHeight="mh-85px"
    />
  );
};
export default PetCard;
