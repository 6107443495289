import React from "react";
import Checkbox from "../Checkbox/Checkbox";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import RadioButton from "../RadioButton/RadioButton";

export type LabelProps = {
  toolTip?: { text: string; width: number; directionUnder?: boolean };
  required?: "badge" | "icon";
  option?: {
    text: string;
    href: string;
  };
  base?: boolean;
  size?: "small" | "medium" | "large";
  withRadio?: boolean;
  withCheckbox?: boolean;
  disabled?: boolean;
  setDisabled?: (value: boolean) => void;
  radioValue?: string;
  width: string | null | undefined;
  children: React.ReactNode;
};

const Label = ({
  required,
  toolTip,
  option,
  base,
  size,
  withRadio,
  withCheckbox,
  disabled,
  setDisabled,
  radioValue,
  width,
  children,
}: LabelProps) => {
  const labelClasses = React.useMemo(() => {
    const classes = size ? [`label--${size}`] : [];
    if (base) {
      classes.push("label--base");
    }

    return ["label", ...classes].join(" ");
  }, [base, size]);

  const toolTipDirection = React.useMemo(
    () => (toolTip?.directionUnder ? "under" : "top"),
    [toolTip?.directionUnder],
  );

  const onClickInput = (value: boolean) => {
    // eslint-disable-next-line no-unused-expressions
    setDisabled && setDisabled(!value);
  };

  return (
    <div className={labelClasses} style={width ? { width } : {}}>
      {withRadio && (
        <span className="label__radio">
          <RadioButton
            value={radioValue}
            checked={!disabled}
            onSelect={onClickInput}
          />
        </span>
      )}
      {withCheckbox && (
        <span className="label__checkbox">
          <Checkbox
            className="label__checkbox"
            checked={!disabled}
            onChecked={onClickInput}
          />
        </span>
      )}

      {children}
      {toolTip !== undefined && (
        <div className="label__help-container">
          <div className="label__help-icon-container">
            <Tooltip direction={toolTipDirection} content={toolTip.text}>
              <Icon
                icon="help_outline"
                size="2xs"
                className="label__help-icon"
              />
            </Tooltip>
          </div>
        </div>
      )}
      {option !== undefined && (
        <a href={option.href} className="label__option">
          {option.text}
        </a>
      )}
      {required === "badge" && (
        <div className="label__required-badge">必須</div>
      )}
      {required === "icon" && <div className="label__required-icon">＊</div>}
    </div>
  );
};

Label.defaultProps = {
  toolTip: undefined,
  required: undefined,
  option: undefined,
  base: false,
  size: "large",
  withRadio: false,
  withCheckbox: false,
  disabled: false,
  setDisabled: () => {},
  radioValue: "",
};

export default Label;
