import { useCallback, useEffect, useMemo, useState } from "react";
import SpButton from "../components/Button/SpButton";
import DialogCustom from "../components/Dialog/DialogCustom";
import CardCart from "../components/Card/CardCart";
import useUsf006Dialog, {
  closeUsf006Dialog,
} from "../hooks/pages/USF006/useUsf006Dialog";
import { useCheckErrorThrowError } from "../utils/checkError";
import useUsf006DBActions from "../hooks/pages/USF006/useUsf006DBActions";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import { setId } from "../hooks/common/useId";
import { mobileView } from "../constants/common";

const USF006 = () => {
  const navigateWithUrl = useNavigateWithUrl();

  const { cartInfo, deleteTransaction, changeTransaction, error, actionError } =
    useUsf006DBActions();
  const [isOpen] = useUsf006Dialog();
  const [isMobile, setIsMobile] = useState(false);
  const onCloseButton = useCallback(() => closeUsf006Dialog(), []);

  useCheckErrorThrowError([error, actionError]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileView);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigateToConfirm = useCallback(() => {
    closeUsf006Dialog();
    navigateWithUrl(PATIENT.USF007);
  }, [navigateWithUrl]);

  const handleClickDelete = useCallback(
    async (id: string) => {
      await deleteTransaction(id);
    },
    [deleteTransaction],
  );

  const handleChangeQuantity = useCallback(
    async (id: string, quantity: number) => {
      await changeTransaction(id, quantity);
    },
    [changeTransaction],
  );

  const navigateToItemDetail = useCallback(
    (id: string) => {
      const transactionInfo = cartInfo?.transactions_info.find(
        (transactionInfo) => transactionInfo._id === id,
      );
      if (transactionInfo) {
        closeUsf006Dialog();
        setId(transactionInfo.skus_info.item_id);
        navigateWithUrl(PATIENT.USF005);
      }
    },
    [cartInfo?.transactions_info, navigateWithUrl],
  );

  const cardCartItems = useMemo(
    () =>
      (cartInfo?.transactions_info ?? []).map((transactionInfo) => ({
        id: transactionInfo._id,
        name: transactionInfo.item_name,
        description: transactionInfo.sku_name,
        price: transactionInfo.skus_info.amount,
        image: transactionInfo.skus_info.image_urls[0],
        quantity: transactionInfo.quantity,
        stock: transactionInfo.skus_info.stock,
      })),
    [cartInfo?.transactions_info],
  );

  const taxAmount = useMemo(() => {
    const reducer = (sum: number, currentValue: number) => sum + currentValue;

    return cartInfo?.total_amount_per_tax
      .map((taxObj) => taxObj.tax)
      .reduce(reducer);
  }, [cartInfo?.total_amount_per_tax]);

  return (
    <DialogCustom
      title="カート"
      open={isOpen}
      size="medium"
      height="754px"
      closeDialog={onCloseButton}
      isShowCloseButton={false}
      centerFooter
      isMobile={isMobile}
      footerLeft={
        <SpButton
          width="280px"
          size="medium"
          type="secondary"
          onClick={onCloseButton}
        >
          キャンセル
        </SpButton>
      }
      footerRight={
        <SpButton
          width="280px"
          size="medium"
          onClick={navigateToConfirm}
          disabled={cardCartItems.length === 0}
        >
          レジに進む
        </SpButton>
      }
    >
      <CardCart
        items={cardCartItems}
        type="cart"
        mobileType="cart"
        onClickItem={navigateToItemDetail}
        onClickDelete={(id: string) => {
          void handleClickDelete(id);
        }}
        onChangeQuantity={(id, quantity) => {
          void handleChangeQuantity(id, quantity);
        }}
        deliveryCharge={cartInfo?.delivery_fee}
        subtotal={cartInfo?.total_item_amount}
        total={cartInfo?.total_amount}
        tax={taxAmount}
      />
    </DialogCustom>
  );
};

export default USF006;
