import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { getMongoDb, getAggregateReceipts } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { Collection } from "../../../constants/common";

const useUsg012Fetch = (props: { skip?: number; perPage?: number }) => {
  const { perPage = 10, skip = 0 } = props;
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [receipts, setReceipts] = useState<ReceiptList[]>([]);
  const [count, setCount] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(currentUser, Collection.SALES);
        const aggregateUse002 = getAggregateReceipts({
          id: currentUser?.id ?? "",
          skip,
          perPage,
        });

        const aggregateResult = (await collection.aggregate(
          aggregateUse002,
        )) as AggregateResultType<ReceiptList>;

        const { result, count } = aggregateResult[0];

        setCount(count[0]?.count ?? 0);
        setReceipts(result);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, perPage, skip]);

  return {
    receipts,
    count,
    error,
  };
};

export default useUsg012Fetch;
