import { useCallback } from "react";
import { Link } from "react-router-dom";

import Table from "../components/Table/Table";
import TableColumn from "../components/Table/TableColumn";
import TableRow from "../components/Table/TableRow";
import { setId } from "../hooks/common/useId";
import { INVITATION, PATIENT } from "../constants/pagePaths";

const header = ["大カテゴリ", "中カテゴリ", "小カテゴリ", "ID", "link"];
const data = [
  ["登録申込", "ログイン・新規会員登録", "", "US-A001", PATIENT.USA001],
  ["", "アカウント作成", "", "US-A002", PATIENT.USA002],
  ["", "メール送信完了", "", "US-A003", PATIENT.USA003],
  ["", "アカウント情報の登録", "デフォルト", "US-A004", PATIENT.USA004],
  ["", "", "エラー", "US-A005", "USA005"],
  ["", "アカウント情報の登録", "確認表示", "US-A006", PATIENT.USA006],
  ["", "会員登録完了", "", "US-A007", "USA007"],
  ["", "新規お申込み", "", "US-A008", "USA008"],
  ["", "お客様情報の入力", "入力", "US-A009", "USA009"],
  ["", "", "確認", "US-A010", "USA010"],
  ["", "本人確認書類", "入力", "US-A011", "USA011"],
  ["", "", "確認", "US-A012", "USA012"],
  ["", "代理人情報の入力", "入力", "US-A013", "USA013"],
  ["", "", "確認", "US-A014", "USA014"],
  ["", "重要事項の確認", "未確認", "US-A015", "USA015"],
  ["", "", "詳細（モーダル）", "US-A016", "USA016"],
  ["", "", "確認済", "US-A017", "USA017"],
  ["", "被保険者告知入力", "入力", "US-A018", "USA018"],
  ["", "", "確認", "US-A019", "USA019"],
  ["", "申込完了", "", "US-A020", PATIENT.USA020],
  ["", "登録内容一覧", "", "US-A021", "USA021"],
  ["", "", "", "US-A022", PATIENT.USA022],
  ["", "", "", "US-A023", PATIENT.USA023],
  ["", "プラン・お支払いコース", "", "US-A024", PATIENT.USA024],
  ["", "", "キャンペーンあり", "US-A024_Campaign", "USA024_Campaign"],
  ["", "", "", "US-A025", PATIENT.USA025],
  ["", "", "", "US-A026", PATIENT.USA026],
  ["", "", "", "US-A028", PATIENT.USA028],
  ["", "", "", "US-A029", INVITATION.USA029],
  ["", "", "", "US-A030", INVITATION.USA030],
  ["", "", "", "US-A031", INVITATION.USA031],
  ["", "", "", "US-A032", INVITATION.USA032],
  ["", "", "", "US-A033", INVITATION.USA033],
  ["", "", "", "US-A034", INVITATION.USA034],
  ["", "", "", "US-A035", INVITATION.USA035],
  ["", "", "", "US-A036", "USA036"],
  ["", "", "", "US-A037", "USA037"],
  ["", "", "", "US-A038", "USA038"],
  ["TOP", "", "保険加入後", "US-B001", PATIENT.USB001],
  ["", "", "保険未加入", "US-B002", "USB002"],
  ["", "", "MRI検査受検可能", "US-B003", PATIENT.USB003],
  ["", "", "PET検査受検可能", "US-B004", PATIENT.USB004],
  ["", "", "PET検査予約済み", "US-B005", PATIENT.USB005],
  ["", "", "口座情報リマインド", "US-B006", "USB006"],
  [
    "テスト・結果",
    "トップ",
    "結果：スクリーニングテスト",
    "US-C001",
    PATIENT.USC001,
  ],
  ["", "", "結果：MRI検査", "US-C002", PATIENT.USC002],
  ["", "", "結果：PET検査", "US-C003", PATIENT.USC003],
  ["", "スクリーニングテスト結果", "レポート前", "US-C004", PATIENT.USC004],
  ["", "", "レポートあり", "US-C005", "USC005"],
  ["", "レポート", "", "US-C006", "USC006"],
  ["", "MRI検査", "検査前テスト未実施", "US-C007", PATIENT.USC007],
  ["", "", "検査前テスト実施後", "US-C008", PATIENT.USC008],
  ["", "MRI申込完了", "", "US-C009", PATIENT.USC009],
  ["", "", "", "US-C011", PATIENT.USC011],
  ["", "", "", "US-C012", PATIENT.USC012],
  ["", "PET検査結果", "", "US-C013", PATIENT.USC013],
  ["", "", "", "US-C014", "USC014"],
  ["", "", "", "US-C015", "USC015"],
  ["健康相談", "面談", "予約前", "US-D001", PATIENT.USD001],
  ["", "", "予約後", "US-D002", PATIENT.USD002],
  ["", "", "予約後", "US-D003", PATIENT.USD003],
  ["", "", "予約後", "US-D004", PATIENT.USD004],
  ["", "", "", "US-D004", "usd004-card-sample"],
  ["", "", "", "US-J001", PATIENT.USJ001],
  ["", "", "", "US-J002", PATIENT.USJ002],
  ["", "", "", "US-K001", "USK001"],
  ["", "", "", "US-K002", "USK002"],
  ["", "", "", "US-K003", "USK003"],
  ["寄席", "一覧", "デフォルト", "US-E001", PATIENT.USE001],
  ["", "", "キャンペーンバナーあり", "US-E002", PATIENT.USE002],
  ["", "詳細", "デフォルト", "US-E003", PATIENT.USE003],
  ["", "", "キャンペーンバナーあり", "US-E004", PATIENT.USE004],
  ["EC", "トップ", "ショップ", "US-F001", PATIENT.USF001],
  ["", "", "注文履歴", "US-F002", PATIENT.USF002],
  ["", "住所詳細", "", "US-F003", PATIENT.USF003],
  ["", "全ての商品一覧", "", "US-F004", PATIENT.USF004],
  ["", "商品詳細", "", "US-F005", PATIENT.USF005],
  ["", "カート", "", "US-F006", PATIENT.USF006],
  ["", "会計", "通常配送", "US-F007", PATIENT.USF007],
  ["", "", "お届け日指定", "US-F008", PATIENT.USF008],
  ["", "注文完了", "", "US-F009", PATIENT.USF009],
  ["", "注文詳細", "", "US-F010", PATIENT.USF010],
  ["", "注文キャンセル", "", "US-F011", PATIENT.USF011],
  ["", "キャンセル完了", "", "US-F012", PATIENT.USF012],
  [
    "その他",
    "パスワードを忘れた方",
    "メールアドレス入力",
    "US-G001",
    PATIENT.USG001,
  ],
  ["", "", "送信完了", "US-G002", PATIENT.USG002],
  ["", "パスワード再設定", "入力", "US-G003", PATIENT.USG003],
  ["", "", "完了", "US-G004", PATIENT.USG004],
  ["会員情報編集・退会", "", "閲覧", "US-G005", PATIENT.USG005],
  ["", "", "編集", "US-G006", PATIENT.USG006],
  ["", "", "", "US-G007", PATIENT.USG007],
  ["", "", "", "US-G008", PATIENT.USG008],
  ["", "", "", "US-G011", PATIENT.USG011],
  ["新規会員登録", "", "", "US-H001", PATIENT.USH001],
  ["", "", "", "US-H002", PATIENT.USH002],
  ["", "", "", "US-I001", PATIENT.USI001],
];

const CANCEL_ORDER_ID = "657fb6fe7445d2d75ca3add8";
const DETAIL_ITEM_ID = "1697705529147MFSml6bmCgAo";
const ORDER_ORDER_ID = "6530a81424633c3e5553dddb";
const SUCCESS_ORDER_ID = "6538ce1f520efbc82679ree6";
const DETAIL_ORDER_ID = "6538ce1f520efbc82679ree6";

const IndexPage = () => {
  const onClickLink = useCallback((item: string) => {
    if (item === "USF012") setId(CANCEL_ORDER_ID);
    if (item === "USF005") setId(DETAIL_ITEM_ID);
    if (item === "USF007") setId(ORDER_ORDER_ID);
    if (item === "USF008") setId(ORDER_ORDER_ID);
    if (item === "USF009") setId(SUCCESS_ORDER_ID);
    if (item === "USF010") setId(DETAIL_ORDER_ID);
    if (item === "USF011") setId("657fdc557445d2d75ca43c25");
  }, []);

  return (
    <div className="util-px-32 util-py-32">
      <h1>管理者画面</h1>
      <br />
      <Table
        height="90vh"
        head={
          <TableRow>
            {header.map((item, index) => (
              <TableColumn id={`table-head-column-${index}`} key={index}>
                {item}
              </TableColumn>
            ))}
          </TableRow>
        }
        body={
          <>
            {data.map((row, index) => (
              <TableRow key={index}>
                {row.map((item, index) => (
                  <TableColumn id={`table-body-column-${index}`} key={index}>
                    {row.length !== index + 1 && item}
                    {row.length === index + 1 && (
                      <Link to={item} onClick={() => onClickLink(item)}>
                        {item}
                      </Link>
                    )}
                  </TableColumn>
                ))}
              </TableRow>
            ))}
          </>
        }
      />
    </div>
  );
};

export default IndexPage;
