import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Icon from "../components/Icon/Icon";
import SpButton from "../components/Button/SpButton";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { INVITATION } from "../constants/pagePaths";
import PatientFooter from "../features/PatientFooter";
import useCancelEmailConditions from "../hooks/common/useCancelEmailConditions";

const USA038 = () => {
  const navigate = useNavigateWithUrl();
  const [email] = useCancelEmailConditions();

  // 前画面に戻る
  const handleClickBack = () => navigate(INVITATION.USA030);

  // マイページへ
  const handleClick = () => navigate(INVITATION.USA029);

  return (
    <div className="admin-area  user-body-sp-full-height">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="招待したアカウント"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet>
          <Sheet type="card" maxWidth="1064px">
            <LayoutBox direction="column" fullWidth align="center" gap="3x">
              <LayoutBox direction="column" fullWidth align="center" gap="2x">
                <Icon color="#388e3c" icon="check_circle" size="xl" />
                <Text bold size="2xl">
                  招待をキャンセルしました。
                </Text>
                <Text size="xl">{email}</Text>
              </LayoutBox>
              <SpButton
                size="medium"
                mobileFullWidth
                withArrowRight
                onClick={handleClick}
              >
                マイページへ
              </SpButton>
            </LayoutBox>
          </Sheet>
        </Sheet>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};
export default USA038;
