import { type ReactiveVar, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";

export type ReactiveVarHooks<T> = [
  T,
  (payload: T | ((prevValue: T) => T)) => void,
];

export const useReactiveVarHooks = <T>(
  reactiveVar: ReactiveVar<T>,
): ReactiveVarHooks<T> => {
  const value = useReactiveVar(reactiveVar);
  const setValue = useCallback(
    (payload: T | ((prevValue: T) => T)): void => {
      if (typeof payload === "function") {
        const updater = payload as (prevValue: T) => T;
        const prevValue = reactiveVar();
        const nextValue = updater(prevValue);
        reactiveVar(nextValue);
      } else {
        reactiveVar(payload);
      }
    },
    [reactiveVar],
  );

  return [value, setValue];
};
