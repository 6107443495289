import { useState } from "react";
import { useRealmApp } from "../../contexts/RealmApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../base/useLoadingAction";

const useCancelPresent = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);

  // 退会キャンセルAPI 失敗時にはfalseを返します。
  const cancelPresent = async (email: string | undefined) => {
    openUpdateActionLoading();
    try {
      await currentUser?.functions["account/present/cancellation"](
        currentUser?.id,
        email,
      );

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  return {
    error,
    cancelPresent,
  };
};

export default useCancelPresent;
