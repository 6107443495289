import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import { getAggregateUsf004, getMongoDb } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import usePatientInfoFetch from "../../common/usePatientInfoFetch";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";

export const INIT_USF004_PER_PAGE = 12;

const useUsf004Actions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const { patientInfo, error: patientInfoError } = usePatientInfoFetch();
  const [result, setResult] = useState<USF004Type[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [pagination] = usePagination();

  const { page, skip, perPage, total } = pagination;

  // itemsの取得
  useEffect(() => {
    const fetchData = async (fetchSkip: number) => {
      addLoadCount();
      try {
        const mongoDbOrders = getMongoDb(currentUser, Collection.ITEMS);
        const usf004Aggregate = getAggregateUsf004(
          fetchSkip,
          INIT_USF004_PER_PAGE,
        );

        const fetchResult = (await mongoDbOrders.aggregate(
          usf004Aggregate,
        )) as AggregateResultType<USF004Type>;

        setTotal(fetchResult[0].count[0].count);
        setResult(fetchResult[0].result);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData(skip);
  }, [currentUser, skip]);

  return {
    result,
    page,
    skip,
    perPage,
    total,
    patientInfo,
    error,
    patientInfoError,
  };
};

export default useUsf004Actions;
