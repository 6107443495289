import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Icon from "../components/Icon/Icon";
import Divider from "../components/Divider/Divider";
import SpButton from "../components/Button/SpButton";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import PatientFooter from "../features/PatientFooter";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";

const USC009 = () => {
  const navigate = useNavigateCustom();

  // トップに戻る
  const handleGoTop = () => {
    navigate(PATIENT.USB001);
  };

  return (
    <div className="admin-area">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser />
        <div className="admin-inner area-with-header-footer">
          <MenuNavigation
            selectedTab={SELECTED_TAB_NUM.examination}
            minWidth="1076px"
          />
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="MRI検査"
            withButton={false}
            borderBottom="primary"
            minWidth="1076px"
          />
          <Sheet padding="40px 0" className="util-sp-py-24">
            <LayoutBox direction="column" width="343px">
              <Sheet type="card" padding="24px 16px">
                <LayoutBox
                  direction="column"
                  justify="center"
                  align="center"
                  fullWidth
                  gap="2x"
                >
                  <Icon icon="check_circle" size="xl" color="#388E3C" />
                  <Text size="2xl" bold>
                    申込みが完了しました
                  </Text>
                  <LayoutBox
                    direction="column"
                    gap="1/2x"
                    justify="center"
                    align="center"
                    fullWidth
                  >
                    <Text size="xl" color="orange">
                      予約は確定ではありません。
                    </Text>
                    <Text size="xl">確定し次第ご連絡いたします。</Text>
                  </LayoutBox>
                  <Divider darkColor />
                  <SpButton
                    type="secondary"
                    width="311px"
                    size="medium"
                    mobileFullWidth
                    iconSize="large"
                    withArrowLeft
                    onClick={handleGoTop}
                  >
                    トップに戻る
                  </SpButton>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USC009;
