import { YOSE_TAB_MENUS } from "../../../constants/common";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";
import createReactiveVar from "../../common/createReactiveVar";

type selectedTabMenu = ValueOf<typeof YOSE_TAB_MENUS>;
const stateReactiveVar = createReactiveVar<selectedTabMenu>(
  YOSE_TAB_MENUS.public,
);

// タブの選択状態(公開中or公開終了)を保持します。
export const useSelectedTabMenu = (): ReactiveVarHooks<selectedTabMenu> =>
  useReactiveVarHooks(stateReactiveVar);
