import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

// キャンペーン登録ダイアログの開閉状態を管理
const useUsf003Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openUsf003Dialog = () => {
  stateReactiveVar(true);
};

export const closeUsf003Dialog = () => {
  stateReactiveVar(false);
};

export default useUsf003Dialog;
