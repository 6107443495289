import { useCallback, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";

import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useOrderInfoFetch from "../../common/useOrderInfoFetch";
import { OrderStatusID } from "../../../constants/common";

const useUsf011DBActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const { orderInfo, error } = useOrderInfoFetch(
    OrderStatusID.PAYMENT_COMPLETED,
  );
  const [actionError, setActionError] = useState<Error | null>(null);

  const orderCancel = useCallback(async () => {
    openUpdateActionLoading();
    if (orderInfo) {
      try {
        await currentUser?.functions["stripe/ec/cancelPayment"](orderInfo?._id);
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    }

    return undefined;
  }, [orderInfo, currentUser?.functions]);

  return { orderInfo, orderCancel, actionError, error };
};

export default useUsf011DBActions;
