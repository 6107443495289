import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import InformationDialog from "../components/Dialog/InformationDialog";
import SpButton from "../components/Button/SpButton";

const USG007 = (props: {
  onCloseBtnClicked: () => void;
  onUnsubscribeBtnClicked: () => void;
}) => {
  const { onCloseBtnClicked, onUnsubscribeBtnClicked } = props;

  return (
    <InformationDialog
      open
      closeDialog={onCloseBtnClicked}
      title="本当に退会しますか？"
      isShowCloseButton={false}
      alertLevel="error"
      width="350px"
      footer={
        <>
          <LayoutBox justify="center" wrapReverse gap="2x">
            <SpButton width="275px" onClick={onUnsubscribeBtnClicked}>
              退会
            </SpButton>
            <SpButton
              type="secondary"
              width="275px"
              onClick={onCloseBtnClicked}
            >
              キャンセル
            </SpButton>
          </LayoutBox>
        </>
      }
    >
      <LayoutBox align="center">
        <LayoutBox direction="column" gap="none" align="start" justify="start">
          <Text size="xl">
            退会するとサービスに含まれる各種メニューをご利用いただけなくなりますが、これまでに受けた検査結果は引き続き確認できます。なお、再度ご利用いただくには再登録が必要です。
          </Text>
        </LayoutBox>
      </LayoutBox>
    </InformationDialog>
  );
};

export default USG007;
