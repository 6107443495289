import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";

// `initialState` は配列として初期化
const initialStateString = sessionStorage.getItem("policyChecks");
const initialState: boolean[] = initialStateString
  ? (JSON.parse(initialStateString) as boolean[])
  : [false, false];

const stateReactiveVar = createReactiveVar<boolean[]>(initialState);

const usePolicyChecks = (): ReactiveVarHooks<boolean[]> =>
  useReactiveVarHooks(stateReactiveVar);

export const setPolicyChecks = (policyChecks: boolean[]) => {
  stateReactiveVar(policyChecks);
  sessionStorage.setItem("policyChecks", JSON.stringify(policyChecks));
};

export default usePolicyChecks;
