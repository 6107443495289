import React, { useEffect } from "react";
import ToggleButtonItem from "./ToggleButtonItem";

export type ToggleButtonProps = {
  selectedButton?: string | number;
  type?: "regular" | "negative";
  size?: "small" | "medium" | "large";
  width?: string;
  items: {
    id: string;
    label: string;
  }[];
  fullWidth?: boolean;
  // changeEmp
  onClick?: (id: string | number) => void;
};

const ToggleButton = ({
  selectedButton,
  type = "regular",
  size = "medium",
  width,
  items,
  fullWidth = false,
  // changeEmp
  onClick,
}: ToggleButtonProps) => {
  const [selectedId, setSelectedId] = React.useState(selectedButton);

  const toggleButtonSetClass = React.useMemo(() => {
    const typeClass = [`toggle-button-set--${type}`];

    return ["toggle-button-set", ...typeClass].join(" ");
  }, [type]);

  // changeEmp
  const handleClick = React.useCallback(
    (id: string | number) => {
      setSelectedId(id);
      if (onClick) {
        onClick(id);
      }
    },
    [onClick],
  );

  useEffect(() => {
    setSelectedId(selectedButton);
  }, [selectedButton]);

  return (
    <div className={toggleButtonSetClass} style={{ width }}>
      {items.map(({ label, ...item }) => {
        const isSelected = selectedId === item.id;

        return (
          <ToggleButtonItem
            {...item}
            isSelected={isSelected}
            type={type}
            size={size}
            // changeEmp
            onClick={() => handleClick(item.id)}
            key={item.id}
            fullWidth={fullWidth}
          >
            {label}
          </ToggleButtonItem>
        );
      })}
    </div>
  );
};

ToggleButton.defaultProps = {
  selectedButton: "1",
  type: "regular",
  size: "medium",
  width: "",
  fullWidth: false,
  // changeEmp
  onClick: undefined,
};

export default ToggleButton;
