import { useNavigate } from "react-router-dom";

import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Divider from "../components/Divider/Divider";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Image from "../components/Image/Image";
import Title from "../components/Title/Title";
import FormSet from "../components/FormSet/FormSet";
import Banner from "../components/Banner/Banner";
import Video from "../components/Video/Video";
import VideoThumbnail from "../components/Video/VideoThumbnail";
import USE004DbActions from "../hooks/pages/USE004/USE004DbActions";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { useCheckErrorThrowError } from "../utils/checkError";
import { EmbeddedPageID, YOSE_STATUS } from "../constants/common";
import useGetCampaigns from "../hooks/common/useGetCampaigns";
import { formatDate } from "../utils/utils";
import useId from "../hooks/common/useId";
import PatientFooter from "../features/PatientFooter";

const USE004 = () => {
  const navigate = useNavigate();
  const [id] = useId(); // 寄席IDを取得
  const { yoseDetail, error } = USE004DbActions({ id });

  const yose = yoseDetail?.document;

  const { campaigns, error: campaignsGetError } = useGetCampaigns({
    embeddedPage: EmbeddedPageID.YOSE_DETAILS,
  });

  const handleClickBack = () => {
    navigate(-1);
  };

  // エラー処理
  useCheckErrorThrowError([error, campaignsGetError]);

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation selectedTab={SELECTED_TAB_NUM.yose} />
        <LayoutBox
          direction="column"
          className="util-flex--gap40 util-sp-flex--gap24"
        >
          <Title
            padding="16px 108px 24px 108px"
            background="arc"
            header={yose?.yose_title}
            minWidth="1076px"
            onClickBack={handleClickBack}
          />
          {campaigns.top && (
            <Banner
              url={campaigns.top.url}
              imagePc={campaigns.top.pc_image}
              imageSp={campaigns.top.sp_image}
            />
          )}
          <Sheet padding="0" minWidth="1076px">
            <LayoutBox direction="column" gap="2x" width="1064px">
              <Sheet type="border" color="white" borderRadius="16px">
                <LayoutBox gap="2x" direction="column" fullWidth>
                  <LayoutBox justify="center" fullWidth>
                    {yose?.status === YOSE_STATUS.publicOpen ||
                    yose?.status === YOSE_STATUS.newPeriod ? (
                      <Video
                        url={`https://player.vimeo.com/video/${
                          yose?.vimeo_id ?? ""
                        }`}
                        width="800px"
                        height="450px"
                        spWidth="311px"
                        spHeight="204px"
                      />
                    ) : (
                      <VideoThumbnail
                        videoId={yose?.vimeo_id ?? ""}
                        width="800px"
                        height="450px"
                        spWidth="311px"
                        spHeight="204px"
                      />
                    )}
                  </LayoutBox>
                  <Text size="xl">{yose?.yose_description}</Text>
                  <FormSet label="落語家" vertical base width="88px">
                    <LayoutBox direction="column" gap="1x" fullWidth>
                      <Text size="xl">{yose?.cast}</Text>
                      <Image
                        type="avatar"
                        url={yoseDetail?.sign_url ?? undefined}
                      />
                    </LayoutBox>
                  </FormSet>
                  <FormSet label="略歴" vertical base width="88px">
                    <Text size="xl">{yose?.cast_note}</Text>
                  </FormSet>
                  <Divider darkColor orange={false} />
                  <FormSet
                    label="配信日"
                    base
                    labelWidth="88px"
                    labelSize="small"
                  >
                    <Text size="xl">
                      {`${formatDate(yose?.streaming_date)} ～ ${formatDate(
                        yose?.streaming_end_date,
                      )}`}
                    </Text>
                  </FormSet>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
          {campaigns.bottom && (
            <Banner
              url={campaigns.bottom.url}
              imagePc={campaigns.bottom.pc_image}
              imageSp={campaigns.bottom.sp_image}
            />
          )}
        </LayoutBox>

        <div className="util-mb-72 util-sp-mb-15" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USE004;
