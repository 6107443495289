import React from "react";
import SpButton from "../Button/SpButton";
import Text from "../Text/Text";

export type OrderHistoryProps = {
  orders: {
    number: string;
    date: string;
    total?: string;
    deliveryDate?: string;
    buttonLeft?: string;
    cancelled: boolean;
    // changeEmp
    onClickButtonLeft?: () => void;
    buttonRight?: string;
    // changeEmp
    onClickButtonRight?: () => void;
    images?: {
      url: string;
      alt: string;
    }[];
  }[];
};

const OrderHistory = ({ orders }: OrderHistoryProps) => (
  <>
    <div className="order-history">
      {orders.map((order, index) => (
        <div className="order-history-item" key={index}>
          <div className="order-history--top">
            <span className="order-history--top-left">
              注文番号：{order.number}
            </span>
            <div className="order-history--top-right">
              <span>注文日：{order.date}</span>
              <span>合計：{order.total}</span>
            </div>
          </div>
          <div className="order-history-content">
            {order.cancelled ? (
              <Text color="neutralUserLightest" size="large" bold>
                キャンセル済み
              </Text>
            ) : (
              <span>お届け予定日：{order.deliveryDate}</span>
            )}

            <div className="order-history-images">
              {order.images && (
                <>
                  {order.images.map((imageUrl, index) => (
                    <div
                      key={index}
                      className={
                        imageUrl.url !== ""
                          ? "order-history--image"
                          : "order-history--image-empty"
                      }
                      style={{
                        backgroundImage: `url(${imageUrl.url})`,
                        width: "80px",
                        height: "80px",
                      }}
                    />
                  ))}
                </>
              )}
            </div>
            <div className="order-history-button">
              <div className="order-history-button--left">
                {order.buttonLeft !== "" && (
                  <SpButton
                    type="secondary"
                    width="100%"
                    // changeEmp
                    onClick={order.onClickButtonLeft}
                  >
                    {order.buttonLeft}
                  </SpButton>
                )}
              </div>
              <div className="order-history-button--right">
                {order.buttonRight !== "" && (
                  <SpButton
                    width="100%"
                    // changeEmp
                    onClick={order.onClickButtonRight}
                  >
                    {order.buttonRight}
                  </SpButton>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
);

OrderHistory.defaultProps = {};

export default OrderHistory;
