import React from "react";
import Icon from "../Icon/Icon";
import { mobileView } from "../../constants/common";

export type VideoProps = {
  url?: string;
  width?: string;
  height?: string;
  spWidth?: string;
  spHeight?: string;
};

const Video = ({
  url = "",
  width = "306px",
  height = "172px",
  spWidth,
  spHeight,
}: VideoProps) => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileView);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (url === "") {
    return (
      <div className="video--placeholder">
        <Icon
          icon="play_circle"
          size="large"
          color="white"
          type="outlined"
          className="icon--center"
        />
      </div>
    );
  }

  return (
    <iframe
      src={url}
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title="video"
      width={spWidth !== undefined && isMobile ? spWidth : width}
      height={spHeight !== undefined && isMobile ? spHeight : height}
    />
  );
};

Video.defaultProps = {
  url: "",
  height: "172px",
  width: "306px",
  spWidth: undefined,
  spHeight: undefined,
};

export default Video;
