import SpButton from "../components/Button/SpButton";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Gift from "../assets/gift.png";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";

const ServiceGiftSheet = () => {
  const navigate = useNavigateWithUrl();

  const handlePresentButtonClicked = () => {
    navigate(PATIENT.USA027);
  };

  return (
    <Sheet minWidth="1076px" padding="42px 0 0" relative>
      <img src={Gift} alt="gift" className="image--enjoy" />
      <LayoutBox fullWidth>
        <Sheet color="rough-orange" padding="50px 16px 24px" minWidth="375px">
          <LayoutBox
            justify="center"
            align="center"
            gap="2x"
            direction="column"
            fullWidth
          >
            <Text
              size="2xl"
              bold
              color="white"
              yellowUnderline
              lineHeight="80%"
            >
              プレゼント
            </Text>
            <Text size="xl" color="white">
              「認知症と向き合う365」サービスを、他の方にプレゼントすることができます
            </Text>
            <SpButton
              width="343px"
              size="medium"
              type="white"
              withArrowRight
              onClick={handlePresentButtonClicked}
            >
              サービスをプレゼントする
            </SpButton>
          </LayoutBox>
        </Sheet>
      </LayoutBox>
    </Sheet>
  );
};
export default ServiceGiftSheet;
