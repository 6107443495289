import Card from "../components/Card/Card";
import Divider from "../components/Divider/Divider";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import BrainHealth from "../assets/brain_health.png";
import useBrainCheckEligibility from "../hooks/common/useBrainCheckEligibility";

const TopBrainCheckCard = () => {
  const navigate = useNavigateWithUrl();
  const { isBrainCheckEligible } = useBrainCheckEligibility();

  return (
    <Card
      header={
        <>
          <LayoutBox
            gap="1/2x"
            justify="start"
            align="start"
            flexShrink={0}
            direction="row"
            minHeight="65px"
          >
            <img
              src={BrainHealth}
              alt="brainHealth"
              className={`image--icon image--brain_health ${
                !isBrainCheckEligible ? "image--icon--disabled" : ""
              }`}
            />
            <LayoutBox
              gap="1/2x"
              justify="center"
              align="start"
              flexShrink={0}
              direction="column"
            >
              <Text bold size="xl">
                あたまの健康チェック®
              </Text>
              <Text size="large">電話認知機能検査</Text>
            </LayoutBox>
          </LayoutBox>
          <Divider darkColor margin="4px" />
        </>
      }
      content={
        <Text size="large" color="neutralUserLight">
          年に1回受検してください。
        </Text>
      }
      onClick={() => navigate(PATIENT.USC023)}
      disabled={!isBrainCheckEligible}
      clickable={isBrainCheckEligible}
    />
  );
};
export default TopBrainCheckCard;
