import React, { useCallback } from "react";

import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Shortcut from "../components/Shortcut/Shortcut";
import SpButton from "../components/Button/SpButton";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";

import SpPagination from "../components/Pagination/SpPagination";
import useUsf004Actions from "../hooks/pages/USF004/useUsf004DBActions";
import {
  useNavigateWithBack,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import {
  nextPage,
  prevPage,
} from "../hooks/component/pagination/usePagination";
import { getMinimumSkuAmount } from "../utils/getMinimumSkusAmount";
import { setId } from "../hooks/common/useId";
import { useCheckErrorThrowError } from "../utils/checkError";
import { openUsf006Dialog } from "../hooks/pages/USF006/useUsf006Dialog";
import USF006 from "./US-F006";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { openUsf003Dialog } from "../hooks/pages/USF003/useUsf003Dialog";
import USF003 from "./US-F003";
import Banner from "../components/Banner/Banner";
import { EmbeddedPageID } from "../constants/common";
import useGetCampaigns from "../hooks/common/useGetCampaigns";
import { addHyphenToZipcode } from "../utils/utils";
import PatientFooter from "../features/PatientFooter";

const USF004 = () => {
  const navigateWithUrl = useNavigateWithUrl();
  const navigateWithBack = useNavigateWithBack(PATIENT.USF001);

  const { result, total, perPage, page, patientInfo, error, patientInfoError } =
    useUsf004Actions();

  const { campaigns, error: campaignsGetError } = useGetCampaigns({
    embeddedPage: EmbeddedPageID.EC_LIST,
  });

  useCheckErrorThrowError([error, patientInfoError, campaignsGetError]);

  const handleClickBack = useCallback(
    () => navigateWithBack(),
    [navigateWithBack],
  );

  const handleClickPrev = useCallback(() => prevPage(), []);

  const handleClickNext = useCallback(() => nextPage(), []);

  const totalPageAmount = React.useMemo(
    () => Math.ceil(total / perPage),
    [perPage, total],
  );

  const navigateToItemDetail = useCallback(
    (id: string) => {
      setId(id);
      navigateWithUrl(PATIENT.USF005);
    },
    [navigateWithUrl],
  );

  const handleClickCart = useCallback(() => openUsf006Dialog(), []);

  const handleClickAddress = useCallback(() => openUsf003Dialog(), []);

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation minWidth="1076px" selectedTab={SELECTED_TAB_NUM.shop} />
        <Sheet
          padding="16px 108px"
          color="secondary-lightest"
          minWidth="1076px"
        >
          <LayoutBox justify="between" width="1064px" gap="1x">
            <div onClick={handleClickAddress} style={{ cursor: "pointer" }}>
              <LayoutBox direction="column" gap="1/2x" minWidth="0">
                <Text color="neutralLight" size="large">
                  お届け先
                </Text>
                <Text ellipsis size="xl" width="100%">
                  {addHyphenToZipcode(patientInfo?.zipcode)} {patientInfo?.pref}
                  {patientInfo?.city}
                  {patientInfo?.town1}
                  {patientInfo?.town2}
                </Text>
              </LayoutBox>
            </div>
            <LayoutBox minWidth="0">
              <SpButton
                type="shop"
                size="small"
                width="140px"
                badge={patientInfo?.cart_info?.item_num}
                onClick={handleClickCart}
              >
                カート
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <LayoutBox
          fullWidth
          direction="column"
          gap="5x"
          minWidth="1076px"
          className="util-sp-flex--gap24"
        >
          <Title
            padding="16px 108px 24px 108px"
            background="rough-blue"
            header="すべての商品"
            minWidth="1076px"
            onClickBack={handleClickBack}
          />
          {campaigns.top && (
            <Banner
              background="secondary-lightest"
              padding="24px"
              url={campaigns.top.url}
              imagePc={campaigns.top.pc_image}
              imageSp={campaigns.top.sp_image}
            />
          )}
          <Sheet padding="0" minWidth="1076px">
            <LayoutBox direction="column" width="1064px">
              <div className="util-mt-8" />
              <LayoutBox direction="column" fullWidth gap="3x">
                <LayoutBox
                  gap="2x"
                  direction="row"
                  rowGap="3x"
                  wrap
                  fullWidth
                  justify="center"
                  className="product-list"
                >
                  {(result ?? []).map((item) => (
                    <Shortcut
                      image={item?.image_urls ? item?.image_urls[0] : undefined}
                      imageHeight="148px"
                      imageWidth="148px"
                      width="343.67px"
                      maxWidth="343.67px"
                      height="164px"
                      bottomText={
                        item?.skus_info
                          ? getMinimumSkuAmount(item.skus_info).toLocaleString()
                          : "0"
                      }
                      onClick={() => navigateToItemDetail(item._id)}
                    >
                      {item.item_name}
                    </Shortcut>
                  ))}
                </LayoutBox>
                <Sheet padding="0">
                  <SpPagination
                    pageNumber={page}
                    total={totalPageAmount}
                    leftButtonText="前へ"
                    rightButtonText="次へ"
                    width="118px"
                    onClickLeft={handleClickPrev}
                    onClickRight={handleClickNext}
                    leftButtonDisabled={page === 1}
                    rightButtonDisabled={page === totalPageAmount}
                  />
                </Sheet>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
          {campaigns.bottom && (
            <Banner
              background="secondary-lightest"
              padding="24px"
              url={campaigns.bottom.url}
              imagePc={campaigns.bottom.pc_image}
              imageSp={campaigns.bottom.sp_image}
            />
          )}
        </LayoutBox>
        <div className="util-mb-48" />
      </div>
      <PatientFooter />
      <USF006 />
      <USF003 />
    </div>
  );
};

export default USF004;
