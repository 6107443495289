import { useEffect, useState } from "react";
import { PatientBrainCheckStatusID } from "../../constants/common";

import useBrainCheckFetch from "./useBrainCheckFetch";
import { useCheckErrorThrowError } from "../../utils/checkError";
import { isDateWithinRange } from "../../utils/utils";

// 頭の健康チェックが受検可能か
const useBrainCheckEligibility = () => {
  const { brainChecks, brainChecksFetchError } = useBrainCheckFetch();
  const [isBrainCheckEligible, setIsBrainCheckEligible] = useState(false);

  useCheckErrorThrowError([brainChecksFetchError]);

  useEffect(() => {
    // 活性条件: 1年目会員登録から151日目~会員登録から240日後に活性
    // patient_brain_checks.exam_termで判断する
    const checkBrainCheckEligibility = () => {
      if (brainChecks.length === 0) {
        setIsBrainCheckEligible(false);

        return;
      }

      // 最新の brainCheck を取得
      const brainCheck = brainChecks[0];

      // 受検可能かどうかの条件を判定
      if (
        isDateWithinRange(
          brainCheck.exam_term.start,
          brainCheck.exam_term.end,
          new Date(),
        ) &&
        brainCheck.status !== PatientBrainCheckStatusID.REPORTED
      ) {
        setIsBrainCheckEligible(true);
      } else {
        setIsBrainCheckEligible(false);
      }
    };

    checkBrainCheckEligibility();
  }, [brainChecks]);

  return { isBrainCheckEligible };
};

export default useBrainCheckEligibility;
