import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";

const initialState = sessionStorage.getItem("email") || "";
const stateReactiveVar = createReactiveVar<string>(initialState);

const useCancelEmailConditions = (): ReactiveVarHooks<string> =>
  useReactiveVarHooks(stateReactiveVar);

export const setCancelEmailConditions = (email: string) => {
  stateReactiveVar(email);
  sessionStorage.setItem("email", email);
};

export default useCancelEmailConditions;
