import React from "react";
import Icon from "../Icon/Icon";
import LayoutBox from "../LayoutBox/LayoutBox";
import Text from "../Text/Text";
import Divider from "../Divider/Divider";

export type StepProps = {
  icon?: string;
  error?: boolean;
  isToDo: boolean;
  isDone: boolean;
  isCurrent: boolean;
  stepCounter: number;
  totalCount: number;
  children: React.ReactNode;
  isVertical?: boolean;
  infoTitle?: string;
  infoDetail?: string;
  cancelDate?: string;
  canceled?: boolean;
};

const Step = ({
  icon = "",
  error,
  isToDo,
  isDone,
  isCurrent,
  stepCounter,
  totalCount,
  children,
  isVertical,
  infoTitle = "",
  infoDetail = "",
  cancelDate = "",
  canceled,
}: StepProps) => {
  const topContainerClass = React.useMemo(() => {
    const nextConnectorClass =
      stepCounter < totalCount ? ["step__top-container--next-connector"] : [];
    const prevConnectorClass =
      isVertical && stepCounter > 1
        ? ["step__top-container--prev-connector--vertical"]
        : ["step__top-container--prev-connector"];
    const currentStepClass =
      isCurrent && isVertical
        ? ["step__top-container--current--vertical"]
        : ["step__top-container--current"];

    const toDoStepClass =
      isToDo && isVertical
        ? ["step__top-container--to-do--vertical"]
        : ["step__top-container--to-do"];
    const errorClass = error ? ["step__top-container--error"] : [];
    // const canceledClass = canceled ? ["step__top-container--error"] : [];

    return [
      isVertical ? "step__top-container--vertical" : "step__top-container",
      ...nextConnectorClass,
      ...prevConnectorClass,
      ...currentStepClass,
      ...toDoStepClass,
      ...errorClass,
    ].join(" ");
  }, [error, isCurrent, isToDo, stepCounter, totalCount, isVertical]);

  const stepContentClass = React.useMemo(() => {
    const toDoStepClass = isToDo ? ["step__content--to-do"] : [];
    const errorClass = error ? ["step__content--error"] : [];

    return ["step__content", ...toDoStepClass, ...errorClass].join(" ");
  }, [error, isToDo]);

  const content = React.useMemo(() => {
    if (error) return <Icon icon="error" size="xs" />;
    if (isDone) return <Icon icon="check" size="xs" />;
    if (canceled) return <Icon icon="block" size="xs" />;
    if (icon) return <Icon icon={icon} size="xs" />;

    return <span>{stepCounter}</span>;
  }, [error, icon, isDone, stepCounter, canceled]);

  if (isVertical) {
    return (
      <div className={`step${isVertical ? "--vertical" : ""}`}>
        <LayoutBox gap="1x" align="center">
          <LayoutBox
            gap="1/2x"
            direction="column"
            justify="center"
            align="center"
          >
            <Divider
              primaryMain
              orange={false}
              margin="0"
              vertical
              height="24px"
            />
            <div className={topContainerClass}>{content}</div>
            {children && <span className={stepContentClass}>{children}</span>}

            <Divider
              primaryMain
              orange={false}
              margin="0"
              vertical
              height="24px"
            />
          </LayoutBox>

          <LayoutBox direction="column" gap="1/2x">
            <Text color="neutralLight" size="small" lineHeight="19.5px">
              {canceled ? cancelDate : infoTitle}
            </Text>
            <Text size="large">{canceled ? "キャンセル済" : infoDetail}</Text>
          </LayoutBox>
        </LayoutBox>
      </div>
    );
  }

  return (
    <div className="step--vertical">
      <div className={topContainerClass}>{content}</div>
      <span className={stepContentClass}>{children}</span>
    </div>
  );
};

Step.defaultProps = {
  icon: "",
  error: undefined,
  canceled: undefined,
  isVertical: false,
  infoTitle: "",
  infoDetail: "",
  cancelDate: "",
};

export default Step;
