import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  Collection,
  PATIENT_INVITATIONS_STATUS,
} from "../../../constants/common";
import {
  getAggregateUsa031,
  getAggregateUsaInvitationsUser,
  getMongoDb,
  getAggregateAllOrderYear,
  getAggregateUsc002,
  getAggregateUsc003,
  getAggregateUsk002,
} from "../../../utils/query";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import { extractUniqueYearsFromData, formatDate } from "../../../utils/utils";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useId from "../../common/useId";

type YearType = {
  value: string;
  label: string;
};

type SettlementTimeType = {
  finished_at: Date;
};

type SubscriptionInfo = {
  nextBillingDate: Date;
  price: number;
  interval: string;
};

const useUsa031Actions = (
  orderYear: { orderYear: string },
  selectedResult: string,
) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [result, setResult] = useState<PatientInvitation>();
  const [listResult, setListResult] = useState<USC001Type[]>([]);
  const [mriListResult, setMriListResult] = useState<USC002Type[]>([]);
  const [petListResult, setPetListResult] = useState<USC003Type[]>([]);
  const [brainListResult, setBrainListResult] = useState<USK002Type[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [selectOrderYear, setSelectOrderYear] = useState<YearType[]>([
    { value: "すべて", label: "すべて" },
  ]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();
  const [id] = useId();

  // Patient取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_INVITATIONS,
        );
        const usa031Aggregate = getAggregateUsaInvitationsUser(id, [
          PATIENT_INVITATIONS_STATUS.registered,
          PATIENT_INVITATIONS_STATUS.paymentError,
        ]);
        const fetchResult = (await mongoDbPatients.aggregate(
          usa031Aggregate,
        )) as PatientInvitation[];

        // 次回更新日取得のため
        if (fetchResult[0].stripeSubscriptionId) {
          const stripeInfo = (await currentUser?.functions[
            "stripe/subscription/info"
          ](fetchResult[0].stripeSubscriptionId)) as SubscriptionInfo;
          const nextBillingDateStr = stripeInfo?.nextBillingDate
            ? formatDate(stripeInfo.nextBillingDate)
            : "";
          setResult({ ...fetchResult[0], nextBillingDateStr });
        } else {
          setResult(fetchResult[0]);
        }
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, id]);

  // のうKnow取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_NOUKNOWS,
        );
        const usc001Aggregate = getAggregateUsa031(id, orderYear.orderYear);
        const fetchResult = (await mongoDbPatients.aggregate(
          usc001Aggregate,
        )) as USC001Type[];
        setListResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, id, orderYear.orderYear]);

  // MRI取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        const usc001Aggregate = getAggregateUsc002(id);
        const fetchResult = (await mongoDbPatients.aggregate(
          usc001Aggregate,
        )) as USC002Type[];
        setMriListResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, id, orderYear.orderYear]);

  // PET取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        const usc001Aggregate = getAggregateUsc003(id);
        const fetchResult = (await mongoDbPatients.aggregate(
          usc001Aggregate,
        )) as USC003Type[];
        setPetListResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, id, orderYear.orderYear]);

  // 受検した年のセレクトボックス
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.PATIENT_NOUKNOWS);
        const aggregate = getAggregateAllOrderYear(id);
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as SettlementTimeType[];
        setSelectOrderYear(() => {
          const uniqueYears = extractUniqueYearsFromData({
            key: "finished_at",
            data: aggregateResult,
          });

          return [{ value: "すべて", label: "すべて" }, ...uniqueYears];
        });
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id]);

  // 健康チェック
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_BRAIN_CHECKS,
        );
        const usc001Aggregate = getAggregateUsk002(selectedResult, id);
        const fetchResult = (await mongoDbPatients.aggregate(
          usc001Aggregate,
        )) as USK002Type[];

        setBrainListResult(fetchResult);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, id, selectedResult]);

  // 退会キャンセルAPI 失敗時にはfalseを返します。
  const updateUser = async (email: string | undefined) => {
    openUpdateActionLoading();
    try {
      await currentUser?.functions["account/present/cancellation"](
        currentUser?.id,
        email,
      );

      return true;
    } catch (err) {
      setError(checkActionErr(err));

      return false;
    } finally {
      closeActionLoading();
    }
  };

  return {
    result,
    error,
    listResult,
    selectOrderYear,
    fetchError,
    mriListResult,
    petListResult,
    brainListResult,
    updateUser,
  };
};

export default useUsa031Actions;
