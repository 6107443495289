import { useCallback, useEffect, useMemo, useState } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import SpButton from "../components/Button/SpButton";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import ImageGallery from "../components/ImageGallery/ImageGallery";
import Tag from "../components/Tag/Tag";
import FormSet from "../components/FormSet/FormSet";

import Dropdown from "../components/Dropdown/Dropdown";
import SimpleCard from "../components/Card/SimpleCard";
import { PATIENT } from "../constants/pagePaths";
import {
  useNavigateCustom,
  useNavigateWithBack,
} from "../hooks/base/usePageTransitionCustom";
import useUsf005DBActions from "../hooks/pages/USF005/useUsf005DBActions";
import { openUsf006Dialog } from "../hooks/pages/USF006/useUsf006Dialog";
import USF006 from "./US-F006";
import { getAmountDropdownItems } from "../utils/getAmountDropdownItems";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { openUsf003Dialog } from "../hooks/pages/USF003/useUsf003Dialog";
import USF003 from "./US-F003";
import Banner from "../components/Banner/Banner";
import { EmbeddedPageID } from "../constants/common";
import useGetCampaigns from "../hooks/common/useGetCampaigns";
import { useCheckErrorThrowError } from "../utils/checkError";
import { addHyphenToZipcode } from "../utils/utils";
import PatientFooter from "../features/PatientFooter";

const USF005 = () => {
  const navigate = useNavigateCustom();
  const { itemInfo, patientInfo, addCart } = useUsf005DBActions();
  const { campaigns, error: campaignsGetError } = useGetCampaigns({
    embeddedPage: EmbeddedPageID.EC_DETAILS,
  });

  useCheckErrorThrowError([campaignsGetError]);

  const [selectedSkuId, setSelectedSkuId] = useState<string>();
  const [quantity, setQuantity] = useState<number>(1);

  const handleGoBack = useNavigateWithBack(PATIENT.USF001);

  useEffect(() => {
    if (itemInfo) {
      setSelectedSkuId(itemInfo.skus_info[0]._id ?? "");
    }
  }, [itemInfo]);

  const onClickSku = useCallback((id: string) => setSelectedSkuId(id), []);

  const selectedSku = useMemo<SkusType | undefined>(
    () => itemInfo?.skus_info.find((sku) => sku._id === selectedSkuId),
    [itemInfo, selectedSkuId],
  );

  const images = useMemo(
    () =>
      selectedSku?.image_urls.map((url, index) => ({
        id: `image-${index}`,
        url,
      })),
    [selectedSku?.image_urls],
  );

  const handleChangeQuantity = useCallback((value: string) => {
    setQuantity(Number(value));
  }, []);

  const handleClickAddCart = useCallback(async () => {
    if (quantity && selectedSkuId) {
      const success = await addCart(quantity, selectedSkuId);

      if (success) {
        navigate(PATIENT.USF001);
      }
    }
  }, [addCart, navigate, quantity, selectedSkuId]);

  const handleClickCart = useCallback(() => openUsf006Dialog(), []);

  const handleClickAddress = useCallback(() => openUsf003Dialog(), []);

  const optionList = selectedSku?.option_contents_info
    .filter((content) => content.description)
    .map((content) => {
      const title = itemInfo?.option_configs_info.find(
        (x) => x._id === content.option_config_id,
      )?.option_title;

      return {
        title,
        description: content.description,
      };
    });

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation minWidth="1076px" selectedTab={SELECTED_TAB_NUM.shop} />
        <Sheet
          padding="16px 108px"
          color="secondary-lightest"
          minWidth="1076px"
        >
          <LayoutBox justify="between" width="1064px" fullWidth>
            <div onClick={handleClickAddress} style={{ cursor: "pointer" }}>
              <LayoutBox direction="column" gap="1/2x" minWidth="0">
                <Text color="neutralLight" size="large">
                  お届け先
                </Text>
                <Text ellipsis size="xl" width="100%">
                  {addHyphenToZipcode(patientInfo?.zipcode)} {patientInfo?.pref}
                  {patientInfo?.city}
                  {patientInfo?.town1}
                  {patientInfo?.town2}
                </Text>
              </LayoutBox>
            </div>
            <LayoutBox minWidth="0" flexShrink={0}>
              <SpButton
                type="shop"
                size="small"
                width="140px"
                badge={patientInfo?.cart_info?.item_num}
                onClick={handleClickCart}
              >
                カート
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <LayoutBox
          fullWidth
          direction="column"
          gap="5x"
          minWidth="1076px"
          className="util-sp-flex--gap24"
        >
          <Title
            padding="16px 108px 24px 108px"
            background="rough-blue"
            header={itemInfo?.item_name}
            onClickBack={handleGoBack}
            minWidth="1076px"
          />
          {campaigns.top && (
            <Banner
              background="secondary-lightest"
              padding="24px"
              url={campaigns.top.url}
              imagePc={campaigns.top.pc_image}
              imageSp={campaigns.top.sp_image}
            />
          )}
          <Sheet padding="0" minWidth="1064px">
            <LayoutBox
              direction="column"
              gap="2x"
              width="1064px"
              justify="center"
            >
              {images ? (
                <LayoutBox fullWidth>
                  <ImageGallery urls={images ?? []} />
                </LayoutBox>
              ) : null}

              <LayoutBox fullWidth>
                {itemInfo?.item_tags_info.map((itemTag) => (
                  <Tag
                    label={itemTag.tag_name}
                    showIcon={false}
                    state="neutral"
                  />
                ))}
              </LayoutBox>
              <LayoutBox gap="3x" direction="column">
                <Text size="xl">{itemInfo?.item_description}</Text>
                {optionList?.map((x, i) => (
                  <Text size="xl" key={i}>
                    {x.title}：{x.description}
                  </Text>
                ))}
                <LayoutBox gap="1x" fullWidth wrap>
                  {itemInfo?.skus_info.map((sku) => (
                    <SimpleCard
                      type={selectedSkuId !== sku._id ? "secondary" : undefined}
                      onClick={() => onClickSku(sku._id)}
                      key={sku._id}
                    >
                      <LayoutBox direction="column" gap="1x" justify="center">
                        <Text size="large">{sku.sku_name}</Text>
                        <Text size="large" bold>
                          {sku.amount.toLocaleString()}円
                        </Text>
                      </LayoutBox>
                    </SimpleCard>
                  ))}
                </LayoutBox>
                <LayoutBox fullWidth direction="row" gap="1/2x">
                  <Text size="3xl" bold>
                    {selectedSku?.amount.toLocaleString()}
                  </Text>
                  <Text size="2xl" bold>
                    円(税込)
                  </Text>
                </LayoutBox>
                <LayoutBox gap="2x" align="center" fullWidth>
                  <FormSet label="数量" vertical>
                    <Dropdown
                      items={getAmountDropdownItems(selectedSku?.stock)}
                      value={quantity.toString()}
                      width="94px"
                      onChange={handleChangeQuantity}
                    />
                  </FormSet>
                  <SpButton
                    size="medium"
                    width="238px"
                    icon="add_shopping_cart"
                    iconSize="medium"
                    onClick={() => {
                      void handleClickAddCart();
                    }}
                  >
                    カートに追加する
                  </SpButton>
                </LayoutBox>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
          {campaigns.bottom && (
            <Banner
              background="secondary-lightest"
              padding="24px"
              url={campaigns.bottom.url}
              imagePc={campaigns.bottom.pc_image}
              imageSp={campaigns.bottom.sp_image}
            />
          )}
        </LayoutBox>
        <div className="util-mb-48" />
      </div>
      <PatientFooter />
      <USF006 />
      <USF003 />
    </div>
  );
};

export default USF005;
