export const ERROR_MESSAGES = {
  DUPLICATE_BOOKING:
    "同じ日付と時間が選択されています。\n異なる日付と時間を選択してください。",
  REQUIRED_SELECTION: "未入力の項目があります。",
  ALREADY_EMAIL_REGISTERED:
    "すでに登録されているメールアドレスです。別のメールアドレスを入力してください。",
  ALREADY_INVITATION_EMAIL_REGISTERED:
    "このメールアドレスには既にパスワードが設定されています。ログイン画面からログインしてください。",
};

export const SUCCESS_MESSAGES = {};
