// changeEmp コンポーネントを追加
// Containerコンポーネント(機能や状態などロジックを記載)

import Image from "../Image";
import useGetThumbnail from "../../../hooks/common/useGetThumbnail";

type propsType = {
  vimeoId: string;
  width?: string | undefined;
  height?: string | undefined;
};

const USE002ImageContainer = ({
  vimeoId,
  width = "313px",
  height = "100%",
}: propsType) => {
  const { imgUrl } = useGetThumbnail({ vimeoId });

  return <Image fullWidth={false} url={imgUrl} width={width} height={height} />;
};

USE002ImageContainer.defaultProps = {
  width: "313px",
  height: "100%",
};

export default USE002ImageContainer;
