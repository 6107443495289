export const getAmountDropdownItems = (stock?: number) => {
  if (stock === 0) return [];
  if (stock && stock < 100) {
    return new Array(stock).fill(0).map((_, i) => ({
      value: (i + 1).toString(),
      label: (i + 1).toString(),
    }));
  }

  return new Array(100).fill(0).map((_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  }));
};
