import { customAlphabet } from "nanoid";

const nanoid = customAlphabet(
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  12,
);

const generateUniqueId = () => `${Date.now()}${nanoid()}`;

export default generateUniqueId;
