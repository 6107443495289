import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { Collection } from "../../../constants/common";
import {
  getAggregateSubscriptionOrderYear,
  getAggregateUsa033,
  getMongoDb,
} from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import { extractUniqueYearsFromData } from "../../../utils/utils";

type YearType = {
  value: string;
  label: string;
};

type SettlementTimeType = {
  payment_date: Date;
};

const useUsa033Actions = (orderYear: { orderYear: string }) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [result, setResult] = useState<USA033Type[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [selectOrderYear, setSelectOrderYear] = useState<YearType[]>([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_SUBSCRIPTIONS,
        );
        const usa033Aggregate = getAggregateUsa033(
          currentUser?.id ?? "",
          orderYear.orderYear,
        );
        const fetchResult = (await mongoDbPatients.aggregate(
          usa033Aggregate,
        )) as USA033Type[];
        setResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, orderYear.orderYear]);

  // セレクトボックス
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDb = getMongoDb(
          currentUser,
          Collection.PATIENT_SUBSCRIPTIONS,
        );
        const aggregate = getAggregateSubscriptionOrderYear(
          currentUser?.id ?? "",
        );
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as SettlementTimeType[];
        setSelectOrderYear(
          extractUniqueYearsFromData({
            key: "payment_date",
            data: aggregateResult,
          }),
        );
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser]);

  return {
    result,
    error,
    selectOrderYear,
    fetchError,
  };
};

export default useUsa033Actions;
