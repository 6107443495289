import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import useUserRegisterInput from "../../input/useUserRegisterInput";
import {
  Collection,
  PATIENT_INVITATIONS_STATUS,
  PAYMENT_PERIOD,
  USER_PLAN_CATEGORY,
} from "../../../constants/common";
import {
  getAggregateUsaInvitationsUser,
  getCampaignCoupons,
  getMongoDb,
  getSubscriptionPlans,
} from "../../../utils/query";
import useId from "../../common/useId";
import usePath from "../../common/usePath";
import { INVITATION } from "../../../constants/pagePaths";

const genderObject = {
  male: "1",
  female: "2",
} as const;

const useUSA024DbActions = () => {
  const realmAppContext = useRealmApp();
  const [result, setResult] = useState<PatientInvitation>();
  const [campaignResult, setCampaignResult] = useState<USA024Type>();
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlansType<typeof PAYMENT_PERIOD>[]
  >([]);
  const [registrationUrls, setRegistrationUrls] = useState({
    monthlyPaymentUrl: "",
    annualPaymentUrl: "",
  });
  const [reEntryUrls, setReEntryUrls] = useState({
    monthlyPaymentUrl: "",
    annualPaymentUrl: "",
  });
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [apiError, setApiError] = useState<Error | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { currentUser } = realmAppContext;

  const [invitationId] = useId();
  const [path] = usePath();

  // 被招待者承認時、被招待者のidでURL作成
  const registerUserId =
    invitationId && path === INVITATION.USA032 ? invitationId : currentUser?.id;

  const [registerInputValues] = useUserRegisterInput();
  const {
    nameSei,
    nameMei,
    nameSeiKana,
    nameMeiKana,
    gender,
    birthYear,
    birthMonth,
    birthDay,
    tel,
    zipcode,
    pref,
    city,
    town1,
    town2,
    enabledModality,
    pastDiagnosis,
    // selectedNotice,
    // selectedWithCode,//phase2以降
    email,
    campaignCode,
  } = registerInputValues;

  useEffect(() => {
    // プラン情報取得
    void (async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.SUBSCRIPTION_PLANS,
        );

        const aggregate = getSubscriptionPlans();
        const result = (await collection.aggregate(
          aggregate,
        )) as SubscriptionPlansType<typeof PAYMENT_PERIOD>[];
        setSubscriptionPlans(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    })();

    // 決済URL作成
    void (async () => {
      addLoadCount();
      const params = {
        user_id: registerUserId,
        payment_type: 1, // 会員ユーザー phase2以降 動的に変更
        gender: gender.value === genderObject.male ? "male" : "female",
        birth_year: birthYear.value,
        birth_month: birthMonth.value,
        birth_day: birthDay.value,
        is_enabled_modality: !enabledModality[0].checked, // trueで当てはまる
        past_diagnosis: pastDiagnosis[0].checked,
        is_notification: true,
        tel: tel.value,
        email: email.value,
        zipcode: zipcode.value,
        pref: pref.value,
        city: city.value,
        town1: town1.value,
        town2,
        name_sei: nameSei.value,
        name_mei: nameMei.value,
        name_sei_kana: nameSeiKana.value,
        name_mei_kana: nameMeiKana.value,
        plan_type: USER_PLAN_CATEGORY.standardPlan,
        campaign_code: campaignCode.value,
        // バックエンド側で招待者or会員の判別に使用
        source:
          invitationId && path === INVITATION.USA032 ? "invitation" : "normal",
      };
      try {
        const getRegistrationUrl = async (
          span: ValueOf<typeof PAYMENT_PERIOD>,
        ) => {
          const url = (await currentUser?.functions["stripe/entry/createUrl"]({
            ...params,
            span,
          })) as unknown as string;

          return url;
        };

        const monthlyPaymentUrl = await getRegistrationUrl(
          PAYMENT_PERIOD.monthlyPayment,
        );
        const annualPaymentUrl = await getRegistrationUrl(
          PAYMENT_PERIOD.annualPayment,
        );

        setRegistrationUrls({
          monthlyPaymentUrl,
          annualPaymentUrl,
        });
      } catch (err) {
        setApiError(checkActionErr(err));
      } finally {
        decrementLoadCount();
      }
    })();
  }, [
    birthDay,
    birthMonth,
    birthYear,
    campaignCode.value,
    city,
    currentUser,
    email,
    enabledModality,
    gender,
    nameMei,
    nameMeiKana,
    nameSei,
    nameSeiKana,
    pastDiagnosis,
    pref,
    tel,
    town1,
    town2,
    zipcode,
    registerUserId,
    invitationId,
    path,
  ]);

  useEffect(() => {
    // プラン情報取得
    void (async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.SUBSCRIPTION_PLANS,
        );

        const aggregate = getSubscriptionPlans();
        const result = (await collection.aggregate(
          aggregate,
        )) as SubscriptionPlansType<typeof PAYMENT_PERIOD>[];
        setSubscriptionPlans(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    })();

    // 決済URL作成
    void (async () => {
      addLoadCount();
      const params = {
        user_id: registerUserId,
        payment_type: 1, // 会員ユーザー phase2以降 動的に変更
        gender: gender.value === genderObject.male ? "male" : "female",
        birth_year: birthYear.value,
        birth_month: birthMonth.value,
        birth_day: birthDay.value,
        is_enabled_modality: !enabledModality[0].checked, // trueで当てはまる
        past_diagnosis: pastDiagnosis[0].checked,
        is_notification: true,
        tel: tel.value,
        email: email.value,
        zipcode: zipcode.value,
        pref: pref.value,
        city: city.value,
        town1: town1.value,
        town2,
        name_sei: nameSei.value,
        name_mei: nameMei.value,
        name_sei_kana: nameSeiKana.value,
        name_mei_kana: nameMeiKana.value,
        plan_type: USER_PLAN_CATEGORY.standardPlan,
        campaign_code: campaignCode.value,
      };
      try {
        const getRegistrationUrl = async (
          span: ValueOf<typeof PAYMENT_PERIOD>,
        ) => {
          const url = (await currentUser?.functions["stripe/entry/reEntryUrl"]({
            ...params,
            span,
          })) as unknown as string;

          return url;
        };

        const monthlyPaymentUrl = await getRegistrationUrl(
          PAYMENT_PERIOD.monthlyPayment,
        );
        const annualPaymentUrl = await getRegistrationUrl(
          PAYMENT_PERIOD.annualPayment,
        );

        setReEntryUrls({
          monthlyPaymentUrl,
          annualPaymentUrl,
        });
      } catch (err) {
        setApiError(checkActionErr(err));
      } finally {
        decrementLoadCount();
      }
    })();
  }, [
    birthDay,
    birthMonth,
    birthYear,
    campaignCode.value,
    city,
    currentUser,
    email,
    enabledModality,
    gender,
    nameMei,
    nameMeiKana,
    nameSei,
    nameSeiKana,
    pastDiagnosis,
    pref,
    registerInputValues,
    tel,
    town1,
    town2,
    zipcode,
    registerUserId,
  ]);

  useEffect(() => {
    // キャンペーン情報取得
    void (async () => {
      addLoadCount();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.CAMPAIGN_COUPONS);
        const aggregate = getCampaignCoupons(campaignCode.value);
        const fetchResult = (await mongoDb.aggregate(
          aggregate,
        )) as USA024Type[];

        setCampaignResult(fetchResult[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    })();
  }, [campaignCode.value, currentUser]);

  // Patient取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbPatients = getMongoDb(
          currentUser,
          Collection.PATIENT_INVITATIONS,
        );

        const usa006Aggregate = getAggregateUsaInvitationsUser(
          currentUser?.id ?? "",
          [PATIENT_INVITATIONS_STATUS.notRegistered],
        );
        const fetchResult = (await mongoDbPatients.aggregate(
          usa006Aggregate,
        )) as PatientInvitation[];
        setResult(fetchResult[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };
    void fetchData();
  }, [currentUser]);

  return {
    result,
    fetchError,
    subscriptionPlans,
    registrationUrls,
    reEntryUrls,
    campaignResult,
    error,
    apiError,
  };
};

export default useUSA024DbActions;
