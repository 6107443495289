import { useCallback, useState } from "react";
import useCartInfoFetch from "../../common/useCartInfoFetch";
import { useRealmApp } from "../../../contexts/RealmApp";

import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import {
  DELIVERY_AVAILABLE_DATE,
  DeliveryMethodID,
} from "../../../constants/common";
import { paymentUrlFormatDate } from "../../../utils/utils";

const useUsf007DBActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const { cartInfo, error } = useCartInfoFetch();
  const [actionError, setActionError] = useState<Error | null>(null);

  const handleOrder = useCallback(async () => {
    openUpdateActionLoading();
    if (cartInfo) {
      try {
        const deliveryDate = new Date();
        deliveryDate.setDate(deliveryDate.getDate() + DELIVERY_AVAILABLE_DATE);
        const desiredDeliveryDateStr = paymentUrlFormatDate(deliveryDate);
        const updateData = {
          order_id: cartInfo._id,
          delivery_method: DeliveryMethodID.NORMAL,
          desired_delivery_date: desiredDeliveryDateStr,
        };

        const url = (await currentUser?.functions["stripe/ec/createPaymentUrl"](
          updateData,
        )) as string;

        return url;
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    }

    return undefined;
  }, [cartInfo, currentUser?.functions]);

  return { cartInfo, handleOrder, actionError, error };
};

export default useUsf007DBActions;
